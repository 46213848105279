:root {
  --cache-version: 1;
}

@media (max-width: 679px) {
  :root {
    --cache-version: 1;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  :root {
    --cache-version: 1;
  }
}

:root, :before {
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-6: 6px;
  --spacing-8: 8px;
  --spacing-10: 10px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-18: 18px;
  --spacing-20: 20px;
  --spacing-22: 22px;
  --spacing-24: 24px;
  --spacing-25: 25px;
  --spacing-26: 26px;
  --spacing-28: 28px;
  --spacing-30: 30px;
  --spacing-32: 32px;
  --spacing-36: 36px;
  --spacing-40: 40px;
  --spacing-42: 42px;
  --spacing-44: 44px;
  --spacing-48: 48px;
  --spacing-52: 52px;
  --spacing-60: 60px;
  --spacing-64: 64px;
  --spacing-68: 68px;
  --spacing-72: 72px;
  --spacing-80: 80px;
  --spacing-88: 88px;
  --spacing-96: 96px;
  --spacing-98: 98px;
  --shadow-xs: 0 1px 2px 0 #1919190d;
  --shadow-s: 1px 3px 3px #1919191a, 1px 2px 2px #19191912;
  --shadow-m: 0 2px 4px -2px #19191912, 0 0 8px 2px #1919191a;
  --shadow-l: 12px 16px 16px -4px #19191914, 4px 6px 6px -2px #1919190a;
  --shadow-xl: 20px 24px 24px -4px #19191914, 8px 8px 8px -4px #1919190a;
  --shadow-xxl: 24px 48px 48px -12px #1919192e;
  --shadow-xxxl: 32px 64px 64px -12px #19191924;
  --shadow-newsletter-in: inset 4px 4px 4px #00000026;
  --shadow-newsletter-drop: 0 -4px 4px #00000026;
  --shadow-newsleter-detail-selected: 0 12px 16px -4px #19191914, 0 4px 6px -2px #1919190a;
  --gradient-1: linear-gradient(90deg, var(--themes-gradient-01-front) 0%, var(--themes-gradient-01-back) 100%);
  --gradient-2: linear-gradient(90deg, var(--themes-gradient-02-front) 0%, var(--themes-gradient-02-back) 100%);
  --gradient-3: linear-gradient(90deg, var(--themes-gradient-03-front) 0%, var(--themes-gradient-03-back) 100%);
  --gradient-4: linear-gradient(180deg, var(--themes-gradient-04-front) 0%, var(--themes-gradient-04-back) 100%);
  --gradient-5: linear-gradient(0deg, var(--themes-gradient-05-back) 0%, var(--themes-gradient-05-back) 20%, var(--themes-gradient-05-front) 100%);
  --gradient-overlay-homepage: linear-gradient(0deg, var(--themes-gradient-05-back) 67.09%, var(--themes-gradient-05-middle) 75.03%, var(--themes-gradient-05-front) 100%);
  --avatar-size-2xs: 16px;
  --avatar-size-xs: 24px;
  --avatar-size-sm: 32px;
  --avatar-size-md: 40px;
  --avatar-size-lg: 48px;
  --avatar-size-xl: 60px;
  --avatar-size-2xl: 72px;
  --avatar-size-3xl: 88px;
  --avatar-size-4xl: 112px;
  --avatar-icon-size-2xs: 8px;
  --avatar-icon-size-xs: 12px;
  --avatar-icon-size-sm: 16px;
  --avatar-icon-size-md: 20px;
  --avatar-icon-size-lg: 24px;
  --avatar-icon-size-xl: 28px;
  --avatar-icon-size-2xl: 32px;
  --avatar-icon-size-3xl: 36px;
  --avatar-icon-size-4xl: 40px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, main {
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  overflow-wrap: break-word;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

picture {
  line-height: 0;
  display: block;
}

@font-face {
  font-family: SofiaSans Bold;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("/dist-isport/assets/default/fonts/sofia-sans-bold.woff2") format("woff2");
}

@font-face {
  font-family: SofiaSans ExtraBold;
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("/dist-isport/assets/default/fonts/sofia-sans-extra-bold.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Regular;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/dist-isport/assets/default/fonts/roboto-regular.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Bold;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/dist-isport/assets/default/fonts/roboto-bold.woff2") format("woff2");
}

.flex-line-break {
  flex: 1 0 100%;
  height: 0;
}

.landscape-16by9-wrapper {
  aspect-ratio: 16 / 9;
  line-height: 0;
  position: relative;
}

.gvjp-video-placeholder {
  width: 100%;
}

.piano-top-uniweb {
  grid-column: lend / rend;
}

.piano-top-uniweb:empty, .piano-hp-dalsi-clanky-uniweb:empty {
  display: none;
}

.space-below-header:has(:not(.piano-top-uniweb:empty)) {
  display: block;
}

.space-below-header:has(.piano-top-uniweb:empty):not(:has(:not(.piano-top-uniweb))) {
  display: none;
}

.space-below-header:has(.section):not(:empty) {
  display: grid;
}

.piano-uniweb--hp, .piano-uniweb--hp-odjinud {
  grid-column: 1 / 3;
  display: contents;
}

.piano-uniweb--hp:empty, .piano-uniweb--hp-odjinud:empty {
  display: none;
}

.hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hideScrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: 0 0 -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.image__placeholder {
  background: url("/dist-isport/assets/default/image-placeholder.svg") center / cover no-repeat;
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1 1 0;
}

.flex-column {
  flex-direction: column;
}

a {
  scroll-margin-top: var(--spacing-64);
}

[class$="line-limit-5"], [class$="line-limit-4"], [class$="line-limit-3"], [class$="line-limit-2"] {
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

[class$="line-limit-2"] {
  -webkit-line-clamp: 2;
}

[class$="line-limit-3"] {
  -webkit-line-clamp: 3;
}

[class$="line-limit-4"] {
  -webkit-line-clamp: 4;
}

[class$="line-limit-5"] {
  -webkit-line-clamp: 5;
}

@media (max-width: 679px) {
  .hide-on-mobile {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1079px), (min-width: 1080px) {
  .hide-on-tablet-desktop {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .hide-on-tablet {
    display: none;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-right {
  text-align: right;
}

@keyframes pulse-animation-on-black-bg {
  0% {
    box-shadow: 0 0 #fff3;
  }

  100% {
    box-shadow: 0 0 0 20px #fff0;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fade-slide-in {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

:root {
  --sofia-sans-bold: "SofiaSans Bold", "Arial", "Helvetica", sans-serif;
  --sofia-sans-extrabold: "SofiaSans ExtraBold", "Arial", "Helvetica", sans-serif;
  --roboto-regular: "Roboto Regular", "Arial", "Helvetica", sans-serif;
  --roboto-bold: "Roboto Bold", "Arial", "Helvetica", sans-serif;
  --heading-3xl-font-family: var(--sofia-sans-extrabold);
  --heading-2xl-font-family: var(--sofia-sans-extrabold);
  --heading-xl-font-family: var(--sofia-sans-extrabold);
  --heading-lg-font-family: var(--sofia-sans-extrabold);
  --heading-md-font-family: var(--sofia-sans-extrabold);
  --heading-sm-font-family: var(--sofia-sans-extrabold);
  --heading-xs-font-family: var(--sofia-sans-extrabold);
  --heading-2xs-font-family: var(--sofia-sans-extrabold);
  --inter-title-font-family: var(--sofia-sans-bold);
  --section-title-font-family: var(--sofia-sans-bold);
  --subtitle-lg-font-family: var(--roboto-regular);
  --subtitle-lg-bold-font-family: var(--roboto-bold);
  --subtitle-md-font-family: var(--roboto-regular);
  --subtitle-md-bold-font-family: var(--roboto-bold);
  --body-lg-font-family: var(--roboto-regular);
  --body-lg-bold-font-family: var(--roboto-bold);
  --body-md-font-family: var(--roboto-regular);
  --body-md-bold-font-family: var(--roboto-bold);
  --body-sm-font-family: var(--roboto-regular);
  --body-sm-bold-font-family: var(--roboto-bold);
  --body-xs-font-family: var(--roboto-regular);
  --body-xs-bold-font-family: var(--roboto-bold);
  --button-lg-font-family: var(--sofia-sans-bold);
  --button-md-font-family: var(--sofia-sans-bold);
  --button-sm-font-family: var(--sofia-sans-bold);
  --button-xs-font-family: var(--sofia-sans-bold);
  --menu-item-font-family: var(--sofia-sans-bold);
  --label-font-family: var(--roboto-regular);
  --helper-font-family: var(--roboto-regular);
  --font-mobile-h1: var(--typography-mobile-heading-3xl-font-size) / var(--typography-mobile-heading-3xl-line-height) var(--heading-3xl-font-family);
  --font-mobile-h2: var(--typography-mobile-heading-xl-font-size) / var(--typography-mobile-heading-xl-line-height) var(--heading-xl-font-family);
  --font-mobile-h3: var(--typography-mobile-heading-lg-font-size) / var(--typography-mobile-heading-lg-line-height) var(--heading-lg-font-family);
  --font-mobile-h4: var(--typography-mobile-heading-md-font-size) / var(--typography-mobile-heading-md-line-height) var(--heading-md-font-family);
  --font-mobile-h5: var(--typography-mobile-heading-sm-font-size) / var(--typography-mobile-heading-sm-line-height) var(--heading-sm-font-family);
  --font-mobile-inter-title: var(--typography-mobile-inter-title-font-size) / var(--typography-mobile-inter-title-line-height) var(--inter-title-font-family);
  --font-mobile-section-title: var(--typography-mobile-section-title-font-size) / var(--typography-mobile-section-title-line-height) var(--section-title-font-family);
  --font-mobile-subtitle1: var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  --font-mobile-subtitle1-bold: var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  --font-mobile-subtitle2: var(--typography-mobile-subtitle-md-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-font-family);
  --font-mobile-subtitle2-bold: var(--typography-mobile-subtitle-md-bold-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  --font-mobile-body1: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-font-family);
  --font-mobile-body1-bold: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-bold-font-family);
  --font-mobile-body2: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-font-family);
  --font-mobile-body2-bold: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-bold-font-family);
  --font-mobile-meta-regular: var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-font-family);
  --font-mobile-meta-bold: var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-bold-font-family);
  --font-mobile-button-lg: var(--typography-mobile-button-lg-font-size) / var(--typography-mobile-button-lg-line-height) var(--button-lg-font-family);
  --font-mobile-button-md: var(--typography-mobile-button-md-font-size) / var(--typography-mobile-button-md-line-height) var(--button-md-font-family);
  --font-mobile-button-sm: var(--typography-mobile-button-sm-font-size) / var(--typography-mobile-button-sm-line-height) var(--button-sm-font-family);
  --font-mobile-button-xs: var(--typography-mobile-button-xs-font-size) / var(--typography-mobile-button-xs-line-height) var(--button-xs-font-family);
  --font-mobile-menu-item: var(--typography-mobile-menu-item-font-size) / var(--typography-mobile-menu-item-line-height) var(--menu-item-font-family);
  --font-mobile-label: var(--typography-mobile-label-font-size) / var(--typography-mobile-label-line-height) var(--label-font-family);
  --font-mobile-helper: var(--typography-mobile-helper-font-size) / var(--typography-mobile-helper-line-height) var(--helper-font-family);
  --font-desktop-h1: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  --font-desktop-h2: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  --font-desktop-h3: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  --font-desktop-h4: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
  --font-desktop-h5: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  --font-desktop-inter-title: var(--typography-desktop-inter-title-font-size) / var(--typography-desktop-inter-title-line-height) var(--inter-title-font-family);
  --font-desktop-section-title: var(--typography-desktop-section-title-font-size) / var(--typography-desktop-section-title-line-height) var(--section-title-font-family);
  --font-desktop-subtitle1: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  --font-desktop-subtitle1-bold: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  --font-desktop-subtitle2: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-font-family);
  --font-desktop-subtitle2-bold: var(--typography-desktop-subtitle-md-bold-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  --font-desktop-body1: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
  --font-desktop-body1-bold: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
  --font-desktop-body2: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
  --font-desktop-body2-bold: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
  --font-desktop-meta-regular: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-font-family);
  --font-desktop-meta-bold: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  --font-desktop-button-lg: var(--typography-desktop-button-lg-font-size) / var(--typography-desktop-button-lg-line-height) var(--button-lg-font-family);
  --font-desktop-button-md: var(--typography-desktop-button-md-font-size) / var(--typography-desktop-button-md-line-height) var(--button-md-font-family);
  --font-desktop-button-sm: var(--typography-desktop-button-sm-font-size) / var(--typography-desktop-button-sm-line-height) var(--button-sm-font-family);
  --font-desktop-button-xs: var(--typography-desktop-button-xs-font-size) / var(--typography-desktop-button-xs-line-height) var(--button-xs-font-family);
  --font-desktop-menu-item: var(--typography-desktop-menu-item-font-size) / var(--typography-desktop-menu-item-line-height) var(--menu-item-font-family);
  --font-desktop-label: var(--typography-desktop-label-font-size) / var(--typography-desktop-label-line-height) var(--label-font-family);
  --font-desktop-helper: var(--typography-desktop-helper-font-size) / var(--typography-desktop-helper-line-height) var(--helper-font-family);
}

.flash-message__list {
  transform: translate(-50%, calc(-1 * var(--spacing-32)));
  z-index: 1000;
  pointer-events: none;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 50%;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .flash-message__list {
    max-width: calc(100% - var(--spacing-64));
  }
}

@media (max-width: 679px) {
  .flash-message__list {
    padding: 0 var(--spacing-16);
  }
}

.flash-message {
  pointer-events: all;
  padding: var(--spacing-24);
  background-color: var(--color-surface-flash-messages-info-normal-background);
  color: var(--color-text-flash-messages-info-normal-title);
  box-shadow: var(--shadow-m);
  margin-top: var(--spacing-16);
  visibility: hidden;
  opacity: 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-16);
  border-radius: 12px;
  flex-direction: row;
  transition: opacity .25s ease-in, visibility 0s ease-in .25s;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .flash-message {
    padding: var(--spacing-8) var(--spacing-12);
    gap: var(--spacing-12);
  }
}

.flash-message--visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s ease-in, visibility 0s ease-in .25s;
}

.flash-message a {
  text-underline-offset: var(--spacing-4);
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
  text-decoration: underline;
}

.flash-message a:hover {
  text-decoration: none;
}

.flash-message--info {
  background-color: var(--color-surface-flash-messages-info-normal-background);
  color: var(--color-text-flash-messages-info-normal-title);
}

.flash-message--info svg * {
  fill: var(--color-text-flash-messages-info-normal-title);
}

.flash-message--error {
  background-color: var(--color-surface-flash-messages-alert-normal-background);
  color: var(--color-text-flash-messages-alert-normal-title);
}

.flash-message--error svg * {
  fill: var(--color-text-flash-messages-alert-normal-title);
}

.flash-message--warning {
  background-color: var(--color-surface-flash-messages-warning-normal-background);
  color: var(--color-text-flash-messages-warning-normal-title);
}

.flash-message--warning svg * {
  fill: var(--color-text-flash-messages-warning-normal-title);
}

.flash-message--success {
  background-color: var(--color-surface-flash-messages-success-normal-background);
  color: var(--color-text-flash-messages-success-normal-title);
}

.flash-message--success svg * {
  fill: var(--color-text-flash-messages-success-normal-title);
}

.flash-message__icon {
  float: left;
  margin-right: var(--spacing-16);
  position: relative;
  top: 2px;
}

.flash-message__text {
  width: calc(100% - var(--spacing-24)  - var(--spacing-24));
  display: inline-block;
}

.flash-message__close {
  cursor: pointer;
  height: var(--spacing-24);
  width: var(--spacing-24);
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
  top: 2px;
}

.flash-message__close svg {
  width: 16px;
  height: 16px;
  transition: transform .2s;
}

.flash-message__close svg * {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.flash-message__close:hover svg {
  transform: rotate(90deg);
}

:root, :before {
  --white: #fff;
  --grey-50: #f8f8f8;
  --grey-100: #f2f2f2;
  --grey-200: #e7e7e7;
  --grey-300: #d7d7d7;
  --grey-400: #b3b3b3;
  --grey-500: #939393;
  --grey-600: #6b6b6b;
  --grey-700: #575757;
  --grey-800: #393939;
  --grey-900: #191919;
  --black: #000;
  --pink-light: #ffeef1;
  --pink: #fce5e9;
  --pink-alt: #fbe0e5;
  --pink-dark: #e4294a;
  --red-light: #ff272f;
  --red: #df0028;
  --red-dark: #bc0012;
  --red-dark-alt: #bb0525;
  --error-alert-background: var(--pink-light);
  --error-alert-content: #d32e2f;
  --error-contrast: var(--white);
  --error-dark: #b71a1c;
  --error-light: #ef5250;
  --error-main: #d32e2f;
  --error-states-contained-hover-background: #d32e2f;
  --error-states-outlined-hover-background: #fce5e9;
  --error-states-outlined-resting-border: #da4f50;
  --warning-alert-background: #fff8e9;
  --warning-alert-content: #d67300;
  --warning-contrast: var(--grey-900);
  --warning-dark: #f9a825;
  --warning-light: #fdd835;
  --warning-main: #fbc02d;
  --warning-states-contained-hover-background: #d2a229;
  --warning-states-outlined-hover-background: #feecc0;
  --warning-states-outlined-resting-border: #fcca4f;
  --info-alert-background: #eff8ff;
  --info-alert-content: #2979ff;
  --info-contrast: var(--white);
  --info-dark: #004599;
  --info-light: #2196f3;
  --info-main: #1764c0;
  --info-states-contained-hover-background: #004599;
  --info-states-outlined-hover-background: #e3f2fd;
  --info-states-outlined-resting-border: #1565c0;
  --success-alert-background: #f4f8f5;
  --success-alert-content: #388e3b;
  --success-contrast: var(--white);
  --success-dark: #1b5e1f;
  --success-light: #4caf50;
  --success-main: #388e3b;
  --success-states-contained-hover-background: #2f6b31;
  --success-states-outlined-hover-background: #dbebdc;
  --success-states-outlined-resting-border: #58a05a;
  --green: green;
  --orange: #f66533;
  --primary-main: var(--red);
  --primary-light: var(--red-light);
  --primary-dark: var(--red-dark);
  --primary-contrast: var(--white);
  --primary-contained-hover-background: var(--red-dark-alt);
  --primary-outlined-hover-background: var(--pink);
  --primary-outlined-resting-border: var(--pink-dark);
  --secondary-main: var(--grey-500);
  --secondary-light: var(--grey-300);
  --secondary-dark: var(--grey-600);
  --secondary-contrast: var(--white);
  --secondary-contained-hover-background: var(--grey-700);
  --secondary-outlined-hover-background: var(--grey-200);
  --secondary-outlined-resting-border: var(--grey-400);
  --text-primary: var(--grey-900);
  --text-secondary: var(--grey-600);
  --text-disabled: var(--grey-400);
  --action-active: var(--grey-600);
  --action-hover: var(--grey-50);
  --action-selected: var(--pink-alt);
  --action-disabled: var(--grey-400);
  --action-disabled-background: var(--grey-100);
  --action-focus: var(--grey-200);
  --background-paper: var(--grey-900);
  --background-paper-contrast: var(--white);
  --background-default: var(--white);
  --other-divider: var(--grey-200);
  --other-outline-border: var(--grey-300);
  --other-filled-background: var(--grey-200);
  --other-backdrop-overlay: var(--grey-600);
  --other-common-white: var(--white);
  --other-common-black: var(--grey-900);
  --other-premium-brand: var(--orange);
  --surface-neutral-darker: var(--grey-900);
  --surface-default-disabled: var(--action-disabled-background);
}

:root {
  --digiarena-50: #f5f8f7;
  --digiarena-100: #ddeae8;
  --digiarena-200: #bad5d3;
  --digiarena-300: #90b8b6;
  --digiarena-400: #699897;
  --digiarena-500: #558787;
  --digiarena-600: #3d6464;
  --digiarena-700: #345051;
  --digiarena-800: #283839;
  --digiarena-900: #132020;
  --reflex-red-50: #fff0f3;
  --reflex-red-100: #ffdde3;
  --reflex-red-200: #ffc1cc;
  --reflex-red-300: #ff95a8;
  --reflex-red-400: #ff5977;
  --reflex-red-500: #ff264d;
  --reflex-red-600: #fc0632;
  --reflex-red-700: #df0028;
  --reflex-red-800: #af0523;
  --reflex-red-900: #900c24;
  --reflex-red-950: #50000e;
  --reflex-gray-50: #f6f6f6;
  --reflex-gray-100: #e7e7e7;
  --reflex-gray-200: #d1d1d1;
  --reflex-gray-300: #b0b0b0;
  --reflex-gray-400: #888;
  --reflex-gray-500: #6d6d6d;
  --reflex-gray-600: #5d5d5d;
  --reflex-gray-700: #4f4f4f;
  --reflex-gray-800: #454545;
  --reflex-gray-900: #3d3d3d;
  --reflex-gray-950: #191919;
  --zeny-gray-50: #f5f5f6;
  --zeny-gray-100: #e5e5e8;
  --zeny-gray-200: #cfced3;
  --zeny-gray-300: #acacb4;
  --zeny-gray-400: #83838d;
  --zeny-gray-500: #686872;
  --zeny-gray-600: #595961;
  --zeny-gray-700: #4e4d54;
  --zeny-gray-800: #444248;
  --zeny-gray-900: #3c3a3f;
  --zeny-gray-950: #252428;
  --zeny-salmon-50: #fff4f1;
  --zeny-salmon-100: #ffe8e1;
  --zeny-salmon-200: #ffd4c7;
  --zeny-salmon-300: #ffb6a0;
  --zeny-salmon-400: #ff9e81;
  --zeny-salmon-500: #f8663b;
  --zeny-salmon-600: #e54b1d;
  --zeny-salmon-700: #c13c14;
  --zeny-salmon-800: #a03414;
  --zeny-salmon-900: #843118;
  --zeny-salmon-950: #481607;
  --moje-psychologie-purple-50: #f8f7fb;
  --moje-psychologie-purple-100: #f2f0f7;
  --moje-psychologie-purple-200: #e7e4f0;
  --moje-psychologie-purple-300: #d4cee4;
  --moje-psychologie-purple-400: #bdb1d4;
  --moje-psychologie-purple-500: #b1a0ca;
  --moje-psychologie-purple-600: #9277b0;
  --moje-psychologie-purple-700: #80649d;
  --moje-psychologie-purple-800: #6b5483;
  --moje-psychologie-purple-900: #59466c;
  --moje-psychologie-purple-950: #3a2d48;
  --moje-psychologie-nepal-50: #f4f7fa;
  --moje-psychologie-nepal-100: #e6edf3;
  --moje-psychologie-nepal-200: #d3dfea;
  --moje-psychologie-nepal-300: #b5cadb;
  --moje-psychologie-nepal-400: #95b1ca;
  --moje-psychologie-nepal-500: #7796ba;
  --moje-psychologie-nepal-600: #6581ab;
  --moje-psychologie-nepal-700: #59709c;
  --moje-psychologie-nepal-800: #4d5d80;
  --moje-psychologie-nepal-900: #414e67;
  --moje-psychologie-nepal-950: #2b3240;
  --moje-psychologie-sundown-50: #fcf4f4;
  --moje-psychologie-sundown-100: #f8e8e8;
  --moje-psychologie-sundown-200: #f3d5d5;
  --moje-psychologie-sundown-300: #e7abac;
  --moje-psychologie-sundown-400: #dd8c8d;
  --moje-psychologie-sundown-500: #cc6769;
  --moje-psychologie-sundown-600: #b74b4d;
  --moje-psychologie-sundown-700: #993c3e;
  --moje-psychologie-sundown-800: #7f3536;
  --moje-psychologie-sundown-900: #6b3132;
  --moje-psychologie-sundown-950: #391617;
  --e15-red-50: #fff1f0;
  --e15-red-100: #ffe9e7;
  --e15-red-200: #fdafa7;
  --e15-red-300: #ffaaa0;
  --e15-red-400: #ff7869;
  --e15-red-500: #f93822;
  --e15-red-600: #e7301b;
  --e15-red-700: #c32412;
  --e15-red-800: #a12213;
  --e15-red-900: #852217;
  --e15-red-950: #490d06;
  --e15-blue-50: #eef9ff;
  --e15-blue-100: #dcf3ff;
  --e15-blue-200: #b2eaff;
  --e15-blue-300: #6ddaff;
  --e15-blue-400: #00b2ff;
  --e15-blue-500: #008edf;
  --e15-blue-600: #0071b4;
  --e15-blue-700: #006094;
  --e15-blue-800: #00517e;
  --e15-blue-900: #003251;
  --e15-blue-950: #00111d;
  --auto-red-50: #fff1f2;
  --auto-red-100: #ffdfe1;
  --auto-red-200: #ffc5c8;
  --auto-red-300: #ff9ca1;
  --auto-red-400: #ff636a;
  --auto-red-500: #ff323c;
  --auto-red-600: #df0f19;
  --auto-red-700: #c90c15;
  --auto-red-800: #a60e15;
  --auto-red-900: #891319;
  --auto-red-950: #4b0407;
  --auto-gray-1-50: #f7f7f7;
  --auto-gray-1-100: #ededec;
  --auto-gray-1-200: #dfdfde;
  --auto-gray-1-300: #c6c6c5;
  --auto-gray-1-400: #aeaeac;
  --auto-gray-1-500: #999998;
  --auto-gray-1-600: #898987;
  --auto-gray-1-700: #7b7b7a;
  --auto-gray-1-800: #676766;
  --auto-gray-1-900: #545454;
  --auto-gray-1-950: #363635;
  --auto-gray-2-50: #f8f8f7;
  --auto-gray-2-100: #efefef;
  --auto-gray-2-200: #e3e3e2;
  --auto-gray-2-300: #bebebb;
  --auto-gray-2-400: #9a9a96;
  --auto-gray-2-500: #7e7e79;
  --auto-gray-2-600: #666663;
  --auto-gray-2-700: #535351;
  --auto-gray-2-800: #474745;
  --auto-gray-2-900: #3e3e3c;
  --auto-gray-2-950: #292928;
  --lide-a-zeme-blue-50: #effaff;
  --lide-a-zeme-blue-100: #def3ff;
  --lide-a-zeme-blue-200: #b6eaff;
  --lide-a-zeme-blue-300: #75dbff;
  --lide-a-zeme-blue-400: #2ccaff;
  --lide-a-zeme-blue-500: #00aeef;
  --lide-a-zeme-blue-600: #0090d4;
  --lide-a-zeme-blue-700: #0073ab;
  --lide-a-zeme-blue-800: #00608d;
  --lide-a-zeme-blue-900: #065074;
  --lide-a-zeme-blue-950: #04334d;
  --lide-a-zeme-yellow-50: #ffffea;
  --lide-a-zeme-yellow-100: #fffbc5;
  --lide-a-zeme-yellow-200: #fff885;
  --lide-a-zeme-yellow-300: #ffee46;
  --lide-a-zeme-yellow-400: #ffdf1b;
  --lide-a-zeme-yellow-500: #f7ba00;
  --lide-a-zeme-yellow-600: #e29400;
  --lide-a-zeme-yellow-700: #bb6902;
  --lide-a-zeme-yellow-800: #985108;
  --lide-a-zeme-yellow-900: #7c420b;
  --lide-a-zeme-yellow-950: #482200;
  --blesk-red-50: #fff1f1;
  --blesk-red-100: #ffdfe0;
  --blesk-red-200: #ffc5c7;
  --blesk-red-300: #ff9da0;
  --blesk-red-400: #ff656a;
  --blesk-red-500: #fe353c;
  --blesk-red-600: #ed1c23;
  --blesk-red-700: #c70e14;
  --blesk-red-800: #a41015;
  --blesk-red-900: #881418;
  --blesk-red-950: #4a0507;
  --blesk-gray-50: #e8e9ea;
  --blesk-gray-100: #d0d3d5;
  --blesk-gray-200: #b9bcc0;
  --blesk-gray-300: #a2a6ab;
  --blesk-gray-400: #8b9096;
  --blesk-gray-500: #737a81;
  --blesk-gray-600: #5c646c;
  --blesk-gray-700: #454d57;
  --blesk-gray-800: #2d3742;
  --blesk-gray-900: #16212d;
  --blesk-gray-950: #0f171f;
  --isport-iron-50: #f8fafc;
  --isport-iron-100: #f1f5f9;
  --isport-iron-200: #d6dae1;
  --isport-iron-300: #b3bbc6;
  --isport-iron-400: #929eaf;
  --isport-iron-500: #7d899c;
  --isport-iron-600: #687588;
  --isport-iron-700: #586479;
  --isport-iron-800: #52596b;
  --isport-iron-900: #3f425f;
  --isport-iron-950: #0f1120;
  --isport-spring-50: #effef6;
  --isport-spring-100: #d9ffec;
  --isport-spring-200: #b6fcda;
  --isport-spring-300: #7ef7bd;
  --isport-spring-400: #30e890;
  --isport-spring-500: #15d279;
  --isport-spring-600: #0ba85e;
  --isport-spring-700: #0d884e;
  --isport-spring-800: #106b41;
  --isport-spring-900: #0f5837;
  --isport-spring-950: #02311c;
  --isport-aquamarine-50: #edfefe;
  --isport-aquamarine-100: #d2fbfa;
  --isport-aquamarine-200: #aaf6f7;
  --isport-aquamarine-300: #70ecf0;
  --isport-aquamarine-400: #4ddfe6;
  --isport-aquamarine-500: #12bdc8;
  --isport-aquamarine-600: #18a3aa;
  --isport-aquamarine-700: #167a88;
  --isport-aquamarine-800: #1b626f;
  --isport-aquamarine-900: #1b525e;
  --isport-aquamarine-950: #0c3640;
  --isport-brick-50: #fff4ed;
  --isport-brick-100: #ffe6d4;
  --isport-brick-200: #ffcaa8;
  --isport-brick-300: #ffa472;
  --isport-brick-400: #fe7439;
  --isport-brick-500: #fd4506;
  --isport-brick-600: #ee3308;
  --isport-brick-700: #c52309;
  --isport-brick-800: #9c1d10;
  --isport-brick-900: #7e1b10;
  --isport-brick-950: #440906;
  --isport-flame-50: #fef2f2;
  --isport-flame-100: #fee2e3;
  --isport-flame-200: #fecacc;
  --isport-flame-300: #fda4a7;
  --isport-flame-400: #f97075;
  --isport-flame-500: #ef2f36;
  --isport-flame-600: #de242b;
  --isport-flame-700: #ba1b21;
  --isport-flame-800: #9a1a1f;
  --isport-flame-900: #801c20;
  --isport-flame-950: #46090b;
  --isport-iron-950-alfa-50: #0f112000;
  --isport-iron-950-alfa-100: #0f11200d;
  --isport-iron-950-alfa-200: #0f11201a;
  --isport-iron-950-alfa-300: #0f112033;
  --isport-iron-950-alfa-400: #0f11204d;
  --isport-iron-950-alfa-500: #0f112066;
  --isport-iron-950-alfa-600: #0f112080;
  --isport-iron-950-alfa-700: #0f112099;
  --isport-iron-950-alfa-800: #0f1120b2;
  --isport-iron-950-alfa-900: #0f1120cc;
  --isport-iron-950-alfa-950: #0f1120e5;
  --isport-iron-950-alfa-1000: #0f1120;
  --isport-lime: #30e890;
  --isport-azure: #4ddfe6;
  --tipsport-tip-blue-50: #eefaff;
  --tipsport-tip-blue-100: #f1f5fd;
  --tipsport-tip-blue-200: #c5daf8;
  --tipsport-tip-blue-300: #8fdeff;
  --tipsport-tip-blue-400: #5ac8ff;
  --tipsport-tip-blue-500: #33acfe;
  --tipsport-tip-blue-600: #1d8ff3;
  --tipsport-tip-blue-700: #167be8;
  --tipsport-tip-blue-800: #185fb5;
  --tipsport-tip-blue-900: #1a518e;
  --tipsport-tip-blue-950: #143357;
  --tipsport-tip-orange-50: #fff9ed;
  --tipsport-tip-orange-100: #fff2d4;
  --tipsport-tip-orange-200: #ffe1a8;
  --tipsport-tip-orange-300: #ffca70;
  --tipsport-tip-orange-400: #ffa737;
  --tipsport-tip-orange-500: #ff8e13;
  --tipsport-tip-orange-600: #f07006;
  --tipsport-tip-orange-700: #c75407;
  --tipsport-tip-orange-800: #9e420e;
  --tipsport-tip-orange-900: #7f380f;
  --tipsport-tip-orange-950: #451a05;
  --tipsport-gray-50: #e8e9ea;
  --tipsport-gray-100: #d0d3d5;
  --tipsport-gray-200: #b9bcc0;
  --tipsport-gray-300: #a2a6ab;
  --tipsport-gray-400: #8b9096;
  --tipsport-gray-500: #737a81;
  --tipsport-gray-600: #5c646c;
  --tipsport-gray-700: #454d57;
  --tipsport-gray-800: #2d3742;
  --tipsport-gray-900: #16212d;
  --tipsport-gray-950: #0f171f;
  --poggers-radical-red-50: #fff1f3;
  --poggers-radical-red-100: #fee5e7;
  --poggers-radical-red-200: #fdced6;
  --poggers-radical-red-300: #fba6b4;
  --poggers-radical-red-400: #f8748d;
  --poggers-radical-red-500: #ef355e;
  --poggers-radical-red-600: #dd2153;
  --poggers-radical-red-700: #ba1646;
  --poggers-radical-red-800: #9c1541;
  --poggers-radical-red-900: #85163d;
  --poggers-radical-red-950: #4a071d;
  --poggers-tangaroa-50: #eef6ff;
  --poggers-tangaroa-100: #dfeeff;
  --poggers-tangaroa-200: #c6ddff;
  --poggers-tangaroa-300: #a3c7fe;
  --poggers-tangaroa-400: #7ea5fb;
  --poggers-tangaroa-500: #6083f4;
  --poggers-tangaroa-600: #425ce9;
  --poggers-tangaroa-700: #3449ce;
  --poggers-tangaroa-800: #2d3fa6;
  --poggers-tangaroa-900: #2c3c83;
  --poggers-tangaroa-950: #141a3b;
  --poggers-tangaroa-950-50: #141a3b80;
  --poggers-finn-50: #fbf4fa;
  --poggers-finn-100: #f8ebf5;
  --poggers-finn-200: #f3d7ee;
  --poggers-finn-300: #eab7e0;
  --poggers-finn-400: #dd89ca;
  --poggers-finn-500: #cf65b3;
  --poggers-finn-600: #bb4798;
  --poggers-finn-700: #a0367c;
  --poggers-finn-800: #852f67;
  --poggers-finn-900: #582245;
  --poggers-finn-950: #431433;
  --poggers-revolver-50: #f2f0fd;
  --poggers-revolver-100: #e8e4fb;
  --poggers-revolver-200: #d5cdf8;
  --poggers-revolver-300: #bdaff2;
  --poggers-revolver-400: #ab8fea;
  --poggers-revolver-500: #9b73e1;
  --poggers-revolver-600: #8f58d3;
  --poggers-revolver-700: #7d49b9;
  --poggers-revolver-800: #663d96;
  --poggers-revolver-900: #533877;
  --poggers-revolver-950: #2c1d3e;
  --blesk-mobil-red-50: #fff0f1;
  --blesk-mobil-red-100: #ffdee0;
  --blesk-mobil-red-200: #ffc3c7;
  --blesk-mobil-red-300: #ff999f;
  --blesk-mobil-red-400: #ff5e67;
  --blesk-mobil-red-500: #ff2b37;
  --blesk-mobil-red-600: #f60c1a;
  --blesk-mobil-red-700: #e30613;
  --blesk-mobil-red-800: #ab0913;
  --blesk-mobil-red-900: #8d0f16;
  --blesk-mobil-red-950: #4d0206;
  --blesk-mobil-gray-50: #f6f6f5;
  --blesk-mobil-gray-100: #e7e7e6;
  --blesk-mobil-gray-200: #d1d1d0;
  --blesk-mobil-gray-300: #b1b0af;
  --blesk-mobil-gray-400: #898887;
  --blesk-mobil-gray-500: #6e6d6c;
  --blesk-mobil-gray-600: #5e5d5c;
  --blesk-mobil-gray-700: #50504e;
  --blesk-mobil-gray-800: #464644;
  --blesk-mobil-gray-900: #403f3e;
  --blesk-mobil-gray-950: #262626;
  --blesk-mobil-yellow-50: #fdffe7;
  --blesk-mobil-yellow-100: #f8ffc1;
  --blesk-mobil-yellow-200: #f6ff86;
  --blesk-mobil-yellow-300: #f9ff41;
  --blesk-mobil-yellow-400: #fffb0d;
  --blesk-mobil-yellow-500: #ffed00;
  --blesk-mobil-yellow-600: #e5b909;
  --blesk-mobil-yellow-700: #b66c05;
  --blesk-mobil-yellow-800: #89630a;
  --blesk-mobil-yellow-900: #74500f;
  --blesk-mobil-yellow-950: #442b04;
  --maminka-pink-50: #fce8ee;
  --maminka-pink-100: #f7bbcc;
  --maminka-pink-200: #f18daa;
  --maminka-pink-300: #eb6088;
  --maminka-pink-400: #e63366;
  --maminka-pink-500: #cc194d;
  --maminka-pink-600: #9f143c;
  --maminka-pink-700: #720e2b;
  --maminka-pink-800: #44081a;
  --maminka-pink-900: #2d0611;
  --maminka-pink-950: #170309;
  --maminka-gray-50: #f2f2f2;
  --maminka-gray-100: #d9d9d9;
  --maminka-gray-200: #bfbfbf;
  --maminka-gray-300: #a6a6a6;
  --maminka-gray-400: #8c8c8c;
  --maminka-gray-500: #737373;
  --maminka-gray-600: #595959;
  --maminka-gray-700: #404040;
  --maminka-gray-800: #262626;
  --maminka-gray-900: #1a1a1a;
  --maminka-gray-950: #0d0d0d;
  --color-yellow-50: #fff9eb;
  --color-yellow-100: #fdeec8;
  --color-yellow-200: #fcd98b;
  --color-yellow-300: #fac04f;
  --color-yellow-400: #f9a825;
  --color-yellow-500: #f3850d;
  --color-yellow-600: #d76208;
  --color-yellow-700: #b2420b;
  --color-yellow-800: #91330f;
  --color-yellow-900: #772a10;
  --color-yellow-950: #441304;
  --color-white-0: #fffffe00;
  --color-white-50: #fffffe0d;
  --color-white-100: #fffffe1a;
  --color-white-200: #fffffe33;
  --color-white-300: #fffffe4d;
  --color-white-400: #fffffe66;
  --color-white-500: #fffffe80;
  --color-white-600: #fffffe99;
  --color-white-700: #fffffeb2;
  --color-white-800: #fffffecc;
  --color-white-900: #fffffee5;
  --color-white-950: #fffffef2;
  --color-white-1000: #fffffe;
  --color-orange-50: #fef6ee;
  --color-orange-100: #fdebd7;
  --color-orange-200: #fad2ae;
  --color-orange-300: #f7b27a;
  --color-orange-400: #f38844;
  --color-orange-500: #ef6820;
  --color-orange-600: #e04f16;
  --color-orange-700: #ba3a14;
  --color-orange-800: #942f18;
  --color-orange-900: #772917;
  --color-orange-950: #40120a;
  --color-red-50: #fdf3f3;
  --color-red-100: #fde3e3;
  --color-red-200: #fbcdcd;
  --color-red-300: #f8a9a9;
  --color-red-400: #f17879;
  --color-red-500: #e74c4d;
  --color-red-600: #d32e2f;
  --color-red-700: #b12425;
  --color-red-800: #932122;
  --color-red-900: #7a2223;
  --color-red-950: #420d0d;
  --color-pink-50: #fdf2fa;
  --color-pink-100: #fce7f7;
  --color-pink-200: #fccef2;
  --color-pink-300: #faa7e5;
  --color-pink-400: #f670d2;
  --color-pink-500: #ee46bc;
  --color-pink-600: #dd259d;
  --color-pink-700: #c01680;
  --color-pink-800: #9f156a;
  --color-pink-900: #84175a;
  --color-pink-950: #510634;
  --color-purple-50: #faf3ff;
  --color-purple-100: #f3e4ff;
  --color-purple-200: #e9ceff;
  --color-purple-300: #d8a7ff;
  --color-purple-400: #c06fff;
  --color-purple-500: #a938ff;
  --color-purple-600: #9513ff;
  --color-purple-700: #8003ed;
  --color-purple-800: #6d09c0;
  --color-purple-900: #640aab;
  --color-purple-950: #3d0075;
  --color-violet-50: #f4f3ff;
  --color-violet-100: #ebe9fe;
  --color-violet-200: #d9d5ff;
  --color-violet-300: #bbb4fe;
  --color-violet-400: #9a8afb;
  --color-violet-500: #7a5af8;
  --color-violet-600: #6838ef;
  --color-violet-700: #5926db;
  --color-violet-800: #4b1fb8;
  --color-violet-900: #3f1b97;
  --color-violet-950: #250f66;
  --color-blue-50: #eefaff;
  --color-blue-100: #daf3ff;
  --color-blue-200: #bdebff;
  --color-blue-300: #8fe0ff;
  --color-blue-400: #5accff;
  --color-blue-500: #34b1fd;
  --color-blue-600: #2196f3;
  --color-blue-700: #167bdf;
  --color-blue-800: #1863b5;
  --color-blue-900: #1a548e;
  --color-blue-950: #153456;
  --color-cyan-50: #ecfdff;
  --color-cyan-100: #cff8fe;
  --color-cyan-200: #a5f0fc;
  --color-cyan-300: #67e2f9;
  --color-cyan-400: #22cbee;
  --color-cyan-500: #06aed4;
  --color-cyan-600: #088ab2;
  --color-cyan-700: #0e6f90;
  --color-cyan-800: #155a75;
  --color-cyan-900: #164b63;
  --color-cyan-950: #083144;
  --color-teal-50: #effefd;
  --color-teal-100: #c7fffa;
  --color-teal-200: #90fff5;
  --color-teal-300: #51f7ef;
  --color-teal-400: #1de4e0;
  --color-teal-500: #04c8c7;
  --color-teal-600: #00a8ab;
  --color-teal-700: #057d80;
  --color-teal-800: #0a6165;
  --color-teal-900: #0d5154;
  --color-teal-950: #002f33;
  --color-lime-50: #f3fde8;
  --color-lime-100: #e3facd;
  --color-lime-200: #c9f6a0;
  --color-lime-300: #a7ed69;
  --color-lime-400: #86e03b;
  --color-lime-500: #66c61c;
  --color-lime-600: #4c9e12;
  --color-lime-700: #3c7813;
  --color-lime-800: #325f15;
  --color-lime-900: #2c5116;
  --color-lime-950: #132d06;
  --color-green-50: #f3faf3;
  --color-green-100: #e3f5e3;
  --color-green-200: #c8eac9;
  --color-green-300: #9dd89e;
  --color-green-400: #6abe6d;
  --color-green-500: #45a248;
  --color-green-600: #388e3b;
  --color-green-700: #2c692f;
  --color-green-800: #275429;
  --color-green-900: #224524;
  --color-green-950: #0e2510;
  --color-gray-50: #f6f6f6;
  --color-gray-100: #e7e7e7;
  --color-gray-200: #d1d1d1;
  --color-gray-300: #b0b0b0;
  --color-gray-400: #939393;
  --color-gray-500: #6d6d6d;
  --color-gray-600: #5d5d5d;
  --color-gray-700: #4f4f4f;
  --color-gray-800: #454545;
  --color-gray-900: #3d3d3d;
  --color-gray-950: #262626;
  --color-black-50: #0000010d;
  --color-black-100: #0000011a;
  --color-black-200: #00000133;
  --color-black-300: #0000014d;
  --color-black-400: #00000166;
  --color-black-500: #00000180;
  --color-black-600: #00000199;
  --color-black-700: #000001b2;
  --color-black-800: #000001cc;
  --color-black-900: #000001e5;
  --color-black-950: #000001f2;
  --color-black-1000: #000001;
  --color-orange-02-50: #fff4ed;
  --color-orange-02-100: #ffe6d4;
  --color-orange-02-200: #ffc8a8;
  --color-orange-02-300: #ffa271;
  --color-orange-02-400: #ff692e;
  --color-orange-02-500: #fe4a11;
  --color-orange-02-600: #ef3007;
  --color-orange-02-700: #c61f08;
  --color-orange-02-800: #9d1b0f;
  --color-orange-02-900: #7e1a10;
  --color-orange-02-950: #440906;
  --color-red-02-50: #fff1f0;
  --color-red-02-100: #ffdfdd;
  --color-red-02-200: #ffc5c1;
  --color-red-02-300: #ff9d96;
  --color-red-02-400: #ff655a;
  --color-red-02-500: #ff3527;
  --color-red-02-600: #f61404;
  --color-red-02-700: #d40f01;
  --color-red-02-800: #ae1106;
  --color-red-02-900: #90150c;
  --color-red-02-950: #4f0500;
  --color-green-02-50: #f3ffe6;
  --color-green-02-100: #e3fdca;
  --color-green-02-200: #c9fc9a;
  --color-green-02-300: #a6f660;
  --color-green-02-400: #84eb30;
  --color-green-02-500: #65d211;
  --color-green-02-600: #4ba709;
  --color-green-02-700: #3a7f0c;
  --color-green-02-800: #316410;
  --color-green-02-900: #2b5512;
  --color-green-02-950: #132f04;
  --number-scale-0: 0;
  --number-scale-2: 2px;
  --number-scale-4: 4px;
  --number-scale-6: 6px;
  --number-scale-8: 8px;
  --number-scale-10: 10px;
  --number-scale-12: 12px;
  --number-scale-16: 16px;
  --number-scale-20: 20px;
  --number-scale-24: 24px;
  --number-scale-32: 32px;
  --number-scale-40: 40px;
  --number-scale-48: 48px;
  --number-scale-64: 64px;
  --number-scale-72: 72px;
  --number-scale-96: 96px;
  --number-scale-112: 112px;
  --number-scale-9999: 9999px;
  --font-family-roboto: roboto;
  --text-decoration-none: none;
  --text-decoration-underline: underline;
  --color-surface-article-card-background: var(--color-white-1000);
  --color-surface-article-card-desktop-xl-v01-title-default: var(--color-white-0);
  --color-surface-article-card-desktop-xl-v01-card-default: var(--color-white-1000);
  --color-surface-article-card-desktop-xl-v02-title-default: var(--color-white-0);
  --color-surface-article-card-desktop-xl-v02-card-default: var(--isport-iron-100);
  --color-surface-article-card-desktop-2xl-v01-elements-default: var(--color-white-0);
  --color-surface-article-card-desktop-2xl-v01-card-default: var(--isport-iron-100);
  --color-surface-article-card-desktop-lg-v01-card-default: var(--color-white-1000);
  --color-surface-article-card-desktop-md-v01-card-default: var(--color-white-1000);
  --color-surface-article-card-desktop-md-v02-card-default: var(--isport-iron-100);
  --color-surface-article-card-desktop-md-other-v01-card-default: var(--color-white-1000);
  --color-surface-article-card-desktop-md-other-v02-card-default: var(--isport-iron-100);
  --color-surface-article-card-desktop-sm-v01-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-xs-v01-card-default: var(--color-white-0);
  --color-surface-article-card-mobile-lg-v1-card-default: var(--color-white-1000);
  --color-surface-article-card-mobile-xl-v01-card-default: var(--color-white-1000);
  --color-surface-article-card-mobile-xl-v02-card-default: var(--isport-iron-100);
  --color-surface-article-card-mobile-md-other-v01-card-default: var(--color-white-1000);
  --color-surface-article-card-mobile-md-other-v02-card-default: var(--isport-iron-100);
  --color-surface-article-card-mobile-md-v01-card-default: var(--color-white-1000);
  --color-surface-article-card-xs-v01-card-default: var(--color-white-1000);
  --color-surface-application-card-background: var(--isport-iron-50);
  --color-surface-button-primary-solid-inverted-contained-default: var(--isport-spring-400);
  --color-surface-button-primary-solid-inverted-contained-hover: var(--isport-spring-300);
  --color-surface-button-primary-solid-inverted-contained-focus: var(--isport-spring-300);
  --color-surface-button-primary-solid-inverted-contained-active: var(--isport-spring-500);
  --color-surface-button-primary-solid-inverted-contained-disabled: var(--isport-iron-800);
  --color-surface-button-primary-solid-inverted-outlined-hover: var(--color-white-50);
  --color-surface-button-primary-solid-inverted-outlined-focus: var(--color-white-50);
  --color-surface-button-primary-solid-inverted-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-inverted-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-normal-contained-default: var(--isport-spring-400);
  --color-surface-button-primary-solid-normal-contained-hover: var(--isport-spring-300);
  --color-surface-button-primary-solid-normal-contained-focus: var(--isport-spring-300);
  --color-surface-button-primary-solid-normal-contained-active: var(--isport-spring-400);
  --color-surface-button-primary-solid-normal-contained-disabled: var(--isport-iron-800);
  --color-surface-button-primary-solid-normal-outlined-hover: var(--isport-iron-950-alfa-50);
  --color-surface-button-primary-solid-normal-outlined-focus: var(--isport-iron-950-alfa-50);
  --color-surface-button-primary-solid-normal-text-hover: var(--color-white-100);
  --color-surface-button-primary-solid-normal-text-focus: var(--color-white-50);
  --color-surface-button-primary-gradient-inverted-contained-default: var(--isport-spring-400);
  --color-surface-button-primary-gradient-inverted-contained-hover: var(--isport-spring-300);
  --color-surface-button-primary-gradient-inverted-contained-focus: var(--isport-spring-300);
  --color-surface-button-primary-gradient-inverted-contained-active: var(--isport-spring-400);
  --color-surface-button-primary-gradient-inverted-contained-disabled: var(--isport-iron-800);
  --color-surface-button-primary-gradient-inverted-outlined-hover: var(--color-white-50);
  --color-surface-button-primary-gradient-inverted-outlined-focus: var(--color-white-50);
  --color-surface-button-primary-gradient-inverted-text-hover: var(--color-white-100);
  --color-surface-button-primary-gradient-inverted-text-focus: var(--color-white-100);
  --color-surface-button-primary-gradient-normal-contained-default: var(--color-white-500);
  --color-surface-button-primary-gradient-normal-contained-hover: var(--isport-spring-300);
  --color-surface-button-primary-gradient-normal-contained-focus: var(--isport-spring-300);
  --color-surface-button-primary-gradient-normal-contained-active: var(--isport-spring-400);
  --color-surface-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-lighter);
  --color-surface-button-primary-gradient-normal-outlined-hover: var(--isport-iron-950-alfa-50);
  --color-surface-button-primary-gradient-normal-outlined-focus: var(--isport-iron-950-alfa-50);
  --color-surface-button-primary-gradient-normal-outlined-default: var(--color-white-500);
  --color-surface-button-primary-gradient-normal-text-hover: var(--color-white-100);
  --color-surface-button-primary-gradient-normal-text-focus: var(--color-white-50);
  --color-surface-button-secondary-solid-normal-contained-default: var(--themes-action-secondary-dark);
  --color-surface-button-secondary-solid-normal-contained-hover: var(--themes-action-secondary-main);
  --color-surface-button-secondary-solid-normal-contained-focus: var(--themes-action-secondary-main);
  --color-surface-button-secondary-solid-normal-contained-active: var(--themes-action-secondary-light);
  --color-surface-button-secondary-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-subtle);
  --color-surface-button-secondary-solid-normal-outlined-focus: var(--themes-action-secondary-subtle);
  --color-surface-button-secondary-solid-normal-text-hover: var(--themes-action-secondary-subtle);
  --color-surface-button-secondary-solid-normal-text-focus: var(--themes-action-secondary-subtle);
  --color-surface-button-info-contained-default: var(--themes-info-main);
  --color-surface-button-info-contained-hover: var(--themes-info-dark);
  --color-surface-button-info-contained-focus: var(--themes-info-dark);
  --color-surface-button-info-contained-active: var(--themes-info-light);
  --color-surface-button-info-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-info-outlined-hover: var(--themes-info-subtle);
  --color-surface-button-info-outlined-focus: var(--themes-info-subtle);
  --color-surface-button-info-text-hover: var(--themes-info-subtle);
  --color-surface-button-info-text-focus: var(--themes-info-subtle);
  --color-surface-button-error-contained-default: var(--themes-error-main);
  --color-surface-button-error-contained-hover: var(--themes-error-dark);
  --color-surface-button-error-contained-focus: var(--themes-error-dark);
  --color-surface-button-error-contained-active: var(--themes-error-light);
  --color-surface-button-error-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-error-outlined-hover: var(--themes-error-subtle);
  --color-surface-button-error-outlined-focus: var(--themes-error-subtle);
  --color-surface-button-error-text-hover: var(--themes-error-subtle);
  --color-surface-button-error-text-focus: var(--themes-error-subtle);
  --color-surface-button-social-login-default: var(--color-white-1000);
  --color-surface-button-social-login-hover: var(--themes-neutral-subtle);
  --color-surface-button-social-login-focus: var(--color-white-1000);
  --color-surface-button-social-login-active: var(--themes-brand-primary-subtle);
  --color-surface-button-social-login-disabled: var(--themes-disabled-subtle);
  --color-surface-content-top-background: var(--color-white-1000);
  --color-surface-content-top-body-background: var(--isport-iron-50);
  --color-surface-play-button-default: var(--isport-flame-500);
  --color-surface-play-button-hover: var(--isport-flame-700);
  --color-surface-play-button-focus: var(--isport-flame-600);
  --color-surface-play-button-active: var(--isport-brick-500);
  --color-surface-play-button-disabled: var(--themes-disabled-light);
  --color-surface-badge-success: var(--themes-success-main);
  --color-surface-badge-warning: var(--color-yellow-400);
  --color-surface-badge-error: var(--themes-error-main);
  --color-surface-badge-info: var(--themes-info-main);
  --color-surface-badge-disabled: var(--themes-disabled-subtle);
  --color-surface-badge-secondary: var(--themes-brand-secondary-main);
  --color-surface-badge-primary: var(--isport-spring-400);
  --color-surface-badgepill-soft-success: var(--themes-success-subtle);
  --color-surface-badgepill-soft-warning: var(--themes-warning-subtle);
  --color-surface-badgepill-soft-error: var(--themes-error-subtle);
  --color-surface-badgepill-soft-info: var(--themes-info-subtle);
  --color-surface-badgepill-soft-disabled: var(--themes-disabled-subtle);
  --color-surface-badgepill-soft-secondary: var(--themes-brand-secondary-subtle);
  --color-surface-badgepill-soft-primary: var(--isport-spring-100);
  --color-surface-badgepill-solid-success: var(--themes-success-main);
  --color-surface-badgepill-solid-warning: var(--themes-warning-light);
  --color-surface-badgepill-solid-error: var(--themes-error-main);
  --color-surface-badgepill-solid-info: var(--themes-info-main);
  --color-surface-badgepill-solid-disabled: var(--themes-disabled-light);
  --color-surface-badgepill-solid-secondary: var(--themes-brand-secondary-main);
  --color-surface-badgepill-solid-primary: var(--isport-spring-400);
  --color-surface-chip-inverted-contained-default: var(--isport-iron-700);
  --color-surface-chip-inverted-contained-hover: var(--isport-iron-500);
  --color-surface-chip-inverted-contained-focus: var(--isport-brick-500);
  --color-surface-chip-inverted-contained-active: var(--isport-brick-500);
  --color-surface-chip-inverted-contained-disabled: var(--color-white-200);
  --color-surface-chip-primary-contained-default: var(--themes-neutral-lighter);
  --color-surface-chip-primary-contained-hover: var(--themes-action-primary-subtle);
  --color-surface-chip-primary-contained-focus: var(--themes-action-primary-subtle);
  --color-surface-chip-primary-contained-active: var(--themes-action-primary-subtle);
  --color-surface-chip-primary-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-chip-primary-outlined-default: var(--color-white-1000);
  --color-surface-chip-primary-outlined-hover: var(--color-white-1000);
  --color-surface-chip-primary-outlined-focus: var(--themes-action-primary-main);
  --color-surface-chip-primary-outlined-active: var(--themes-action-primary-main);
  --color-surface-chip-primary-outlined-disabled: var(--color-white-1000);
  --color-surface-chip-secondary-contained-default: var(--themes-neutral-lighter);
  --color-surface-chip-secondary-contained-hover: var(--themes-neutral-subtle);
  --color-surface-chip-secondary-contained-focus: var(--themes-action-secondary-darker);
  --color-surface-chip-secondary-contained-active: var(--themes-action-secondary-darker);
  --color-surface-chip-secondary-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-chip-secondary-outlined-default: var(--color-white-1000);
  --color-surface-chip-secondary-outlined-hover: var(--color-white-1000);
  --color-surface-chip-secondary-outlined-focus: var(--themes-action-secondary-darker);
  --color-surface-chip-secondary-outlined-active: var(--themes-action-secondary-darker);
  --color-surface-chip-secondary-outlined-disabled: var(--color-white-1000);
  --color-surface-default-page: var(--color-white-1000);
  --color-surface-default-subtle: var(--themes-neutral-subtle);
  --color-surface-default-homepage: var(--isport-iron-100);
  --color-surface-default-inverted: var(--themes-neutral-strong);
  --color-surface-default-content-top: var(--isport-iron-950);
  --color-surface-default-color: #fff;
  --color-surface-gallery-arrows-default: var(--color-black-500);
  --color-surface-gallery-arrows-hover: var(--color-black-950);
  --color-surface-gradients-white-0: var(--color-white-0);
  --color-surface-gradients-gray: var(--themes-neutral-light);
  --color-surface-header-default: var(--isport-iron-950);
  --color-surface-header-inverted: var(--isport-iron-950);
  --color-surface-icon-button-brand-solid-normal-contained-default: var(--themes-action-primary-main);
  --color-surface-icon-button-brand-solid-normal-contained-hover: var(--themes-action-primary-dark);
  --color-surface-icon-button-brand-solid-normal-contained-focus: var(--themes-action-primary-dark);
  --color-surface-icon-button-brand-solid-normal-contained-active: var(--themes-action-primary-light);
  --color-surface-icon-button-brand-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-icon-button-brand-solid-normal-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-normal-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-normal-text-hover: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-normal-text-focus: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-inverted-contained-default: var(--themes-action-inverted-main);
  --color-surface-icon-button-brand-solid-inverted-contained-hover: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-contained-focus: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-contained-active: var(--themes-action-inverted-darker);
  --color-surface-icon-button-brand-solid-inverted-contained-disabled: var(--themes-action-inverted-light);
  --color-surface-icon-button-brand-solid-inverted-outlined-hover: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-outlined-focus: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-outlined-active: var(--themes-action-inverted-darker);
  --color-surface-icon-button-brand-solid-inverted-text-hover: var(--themes-action-inverted-subtle);
  --color-surface-icon-button-brand-solid-inverted-text-focus: var(--themes-action-inverted-subtle);
  --color-surface-icon-button-brand-solid-inverted-text-active: var(--themes-action-inverted-lighter);
  --color-surface-icon-button-secondary-solid-normal-contained-default: var(--themes-action-secondary-darker);
  --color-surface-icon-button-secondary-solid-normal-contained-hover: var(--themes-action-secondary-strong);
  --color-surface-icon-button-secondary-solid-normal-contained-focus: var(--themes-action-secondary-strong);
  --color-surface-icon-button-secondary-solid-normal-contained-active: var(--themes-action-secondary-light);
  --color-surface-icon-button-secondary-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-icon-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-secondary-solid-normal-outlined-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-secondary-solid-normal-text-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-secondary-solid-normal-text-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-solid-normal-contained-default: var(--isport-iron-50);
  --color-surface-icon-button-primary-solid-normal-contained-hover: var(--isport-spring-400);
  --color-surface-icon-button-primary-solid-normal-contained-focus: var(--isport-spring-300);
  --color-surface-icon-button-primary-solid-normal-contained-active: var(--isport-spring-300);
  --color-surface-icon-button-primary-solid-normal-contained-disabled: var(--isport-iron-800);
  --color-surface-icon-button-primary-solid-normal-outlined-hover: var(--color-white-100);
  --color-surface-icon-button-primary-solid-normal-outlined-focus: var(--color-white-100);
  --color-surface-icon-button-primary-solid-normal-text-hover: var(--isport-iron-950);
  --color-surface-icon-button-primary-solid-normal-text-focus: var(--isport-iron-950);
  --color-surface-icon-button-primary-solid-inverted-contained-default: var(--isport-iron-50);
  --color-surface-icon-button-primary-solid-inverted-contained-hover: var(--isport-spring-400);
  --color-surface-icon-button-primary-solid-inverted-contained-focus: var(--isport-spring-300);
  --color-surface-icon-button-primary-solid-inverted-contained-active: var(--isport-spring-300);
  --color-surface-icon-button-primary-solid-inverted-contained-disabled: var(--isport-iron-800);
  --color-surface-icon-button-primary-gradient-normal-contained-default: var(--isport-iron-50);
  --color-surface-icon-button-primary-gradient-normal-contained-hover: var(--isport-spring-400);
  --color-surface-icon-button-primary-gradient-normal-contained-focus: var(--isport-spring-300);
  --color-surface-icon-button-primary-gradient-normal-contained-active: var(--isport-spring-300);
  --color-surface-icon-button-primary-gradient-normal-contained-disabled: var(--isport-iron-800);
  --color-surface-icon-button-primary-gradient-normal-outlined-hover: var(--color-white-100);
  --color-surface-icon-button-primary-gradient-normal-outlined-focus: var(--color-white-100);
  --color-surface-icon-button-primary-gradient-normal-text-hover: var(--isport-iron-950);
  --color-surface-icon-button-primary-gradient-normal-text-focus: var(--isport-iron-950);
  --color-surface-icon-button-primary-gradient-inverted-contained-default: var(--isport-iron-50);
  --color-surface-icon-button-primary-gradient-inverted-contained-hover: var(--isport-spring-400);
  --color-surface-icon-button-primary-gradient-inverted-contained-focus: var(--isport-spring-300);
  --color-surface-icon-button-primary-gradient-inverted-contained-active: var(--isport-spring-300);
  --color-surface-icon-button-primary-gradient-inverted-contained-disabled: var(--isport-iron-800);
  --color-surface-pagination-default: var(--color-white-1000);
  --color-surface-pagination-hover: var(--themes-neutral-subtle);
  --color-surface-pagination-active: var(--color-white-1000);
  --color-surface-pagination-focus: var(--themes-neutral-subtle);
  --color-surface-pagination-disabled: var(--color-white-1000);
  --color-surface-premium-card-normal-normal: var(--themes-neutral-subtle);
  --color-surface-premium-card-inverted-best: var(--themes-neutral-strong);
  --color-surface-premium-card-inverted-vip: var(--color-violet-950);
  --color-surface-premium-card-dark: var(--themes-neutral-strong);
  --color-surface-premium-card-subtle: var(--themes-neutral-subtle);
  --color-surface-tag-solid-normal-default: var(--color-white-1000);
  --color-surface-tag-solid-normal-hover: var(--color-white-1000);
  --color-surface-tag-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-tag-solid-normal-active: var(--themes-action-primary-light);
  --color-surface-tag-solid-normal-disabled: var(--themes-disabled-subtle);
  --color-surface-tag-solid-inverted-default: var(--isport-iron-900);
  --color-surface-tag-solid-inverted-hover: var(--isport-iron-900);
  --color-surface-tag-solid-inverted-focus: var(--isport-iron-950);
  --color-surface-tag-solid-inverted-active: var(--isport-spring-500);
  --color-surface-tag-solid-inverted-disabled: var(--isport-iron-950);
  --color-surface-tag-gradient-normal-default: var(--color-white-1000);
  --color-surface-tag-gradient-normal-hover: var(--color-white-1000);
  --color-surface-tag-gradient-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-tag-gradient-normal-active: var(--themes-action-primary-light);
  --color-surface-tag-gradient-normal-disabled: var(--themes-disabled-subtle);
  --color-surface-tag-gradient-inverted-default: var(--isport-iron-900);
  --color-surface-tag-gradient-inverted-hover: var(--isport-iron-900);
  --color-surface-tag-gradient-inverted-focus: var(--isport-iron-950);
  --color-surface-tag-gradient-inverted-active: var(--isport-spring-500);
  --color-surface-tag-gradient-inverted-disabled: var(--isport-iron-950);
  --color-surface-textlink-background: var(--isport-brick-500);
  --color-surface-textlink-contained-gradient-default: var(--color-white-0);
  --color-surface-textlink-contained-gradient-hover: var(--color-white-0);
  --color-surface-textlink-contained-gradient-active: var(--color-white-0);
  --color-surface-textlink-contained-gradient-focus: var(--isport-brick-50);
  --color-surface-textlink-contained-gradient-disabled: var(--isport-iron-50);
  --color-surface-textlink-contained-solid-default: var(--isport-brick-500);
  --color-surface-textlink-contained-solid-hover: var(--isport-brick-400);
  --color-surface-textlink-contained-solid-active: var(--isport-brick-500);
  --color-surface-textlink-contained-solid-focus: var(--isport-brick-50);
  --color-surface-textlink-contained-solid-disabled: var(--isport-iron-50);
  --color-surface-textlink-category-gradient-focus: var(--isport-brick-50);
  --color-surface-textlink-category-solid-hover: var(--isport-brick-400);
  --color-surface-textlink-category-solid-active: var(--isport-brick-500);
  --color-surface-textlink-category-solid-focus: var(--isport-brick-50);
  --color-surface-textlink-category-solid-disabled: var(--isport-iron-50);
  --color-surface-textlink-menu-item-gradient-default: var(--color-white-0);
  --color-surface-textlink-menu-item-gradient-hover: var(--color-white-0);
  --color-surface-textlink-menu-item-gradient-active: var(--color-white-0);
  --color-surface-textlink-menu-item-gradient-focus: var(--isport-brick-50);
  --color-surface-textlink-menu-item-gradient-disabled: var(--isport-iron-50);
  --color-surface-textlink-menu-item-solid-default: var(--isport-brick-500);
  --color-surface-textlink-menu-item-solid-hover: var(--isport-brick-400);
  --color-surface-textlink-menu-item-solid-active: var(--isport-brick-500);
  --color-surface-textlink-menu-item-solid-focus: var(--isport-brick-50);
  --color-surface-textlink-menu-item-solid-disabled: var(--isport-iron-50);
  --color-surface-textlink-stand-alone-solid-focus: var(--isport-brick-50);
  --color-surface-textlink-megamenu-solid-default: var(--isport-brick-500);
  --color-surface-textlink-megamenu-solid-hover: var(--isport-brick-400);
  --color-surface-textlink-megamenu-solid-active: var(--isport-brick-500);
  --color-surface-textlink-megamenu-solid-focus: var(--isport-brick-50);
  --color-surface-textlink-megamenu-solid-disabled: var(--isport-iron-50);
  --color-surface-video-strip-inverted-background: var(--themes-neutral-strong);
  --color-surface-video-strip-normal-background: var(--themes-neutral-lighter);
  --color-surface-topics-background-normal: var(--themes-neutral-subtle);
  --color-surface-ads-background: #f6f6f6;
  --color-surface-avatar-background: var(--themes-neutral-subtle);
  --color-surface-avatar-photo-background: var(--color-white-1000);
  --color-surface-info-wrapper-background: var(--color-black-700);
  --color-surface-stepper-finished: var(--themes-brand-primary-main);
  --color-surface-stepper-hover: var(--themes-brand-primary-darker);
  --color-surface-stepper-active: var(--color-white-1000);
  --color-surface-stepper-inactive: var(--color-white-1000);
  --color-surface-video-box-inverted-background: var(--color-surface-video-strip-inverted-background);
  --color-surface-video-box-normal-background: var(--color-surface-video-strip-normal-background);
  --color-surface-comments-box-background: var(--isport-iron-50);
  --color-surface-comments-card-background: var(--isport-iron-50);
  --color-surface-label-link-gradient-contained-default: var(--themes-accent-strong);
  --color-surface-label-link-gradient-contained-hover: var(--themes-accent-dark);
  --color-surface-label-link-gradient-contained-active: var(--themes-accent-darker);
  --color-surface-label-link-gradient-contained-focus: var(--themes-action-primary-subtle);
  --color-surface-label-link-gradient-contained-disabled: var(--themes-neutral-subtle);
  --color-surface-label-link-gradient-text-focus: var(--themes-action-primary-subtle);
  --color-surface-label-link-solid-contained-default: var(--themes-accent-strong);
  --color-surface-label-link-solid-contained-hover: var(--themes-accent-dark);
  --color-surface-label-link-solid-contained-active: var(--themes-accent-darker);
  --color-surface-label-link-solid-contained-focus: var(--themes-action-primary-subtle);
  --color-surface-label-link-solid-contained-disabled: var(--themes-neutral-subtle);
  --color-surface-label-link-solid-text-focus: var(--themes-action-primary-subtle);
  --color-surface-megamenu-link-title-focus: var(--themes-action-primary-subtle);
  --color-surface-quotation-box-default: var(--color-white-1000);
  --color-surface-vote-strip-default: var(--isport-iron-100);
  --color-surface-vote-strip-hover: var(--color-white-1000);
  --color-surface-vote-strip-answer: var(--isport-iron-200);
  --color-surface-survey-background: var(--themes-brand-primary-subtle);
  --color-surface-text-link-stand-alone-primary-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-stand-alone-primary-solid-inverted-focus: var(--isport-iron-900);
  --color-surface-text-link-stand-alone-primary-gradient-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-stand-alone-primary-gradient-inverted-focus: var(--isport-iron-900);
  --color-surface-text-link-stand-alone-secondary-solid-normal-focus: var(--isport-iron-100);
  --color-surface-text-link-stand-alone-secondary-solid-inverted-focus: var(--isport-iron-900);
  --color-surface-text-link-stand-alone-secondary-gradient-normal-focus: var(--isport-iron-100);
  --color-surface-text-link-stand-alone-secondary-gradient-inverted-focus: var(--isport-iron-900);
  --color-surface-text-link-hyperlink-primary-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-primary-solid-inverted-focus: var(--isport-iron-900);
  --color-surface-text-link-hyperlink-primary-gradient-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-primary-gradient-inverted-focus: var(--isport-iron-900);
  --color-surface-text-link-hyperlink-secondary-solid-normal-focus: var(--isport-iron-100);
  --color-surface-text-link-hyperlink-secondary-solid-inverted-focus: var(--isport-iron-900);
  --color-surface-text-link-hyperlink-secondary-gradient-solid-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-secondary-gradient-inverted-focus: var(--isport-iron-900);
  --color-surface-dropdown-default: var(--color-white-1000);
  --color-surface-dropdown-hover: var(--themes-neutral-subtle);
  --color-surface-dropdown-active: var(--isport-iron-100);
  --color-surface-dropdown-focus: var(--isport-iron-100);
  --color-surface-dropdown-disabled: var(--themes-disabled-subtle);
  --color-surface-megamenu-default: var(--isport-iron-950);
  --color-surface-search-field-normal-default: var(--isport-iron-50);
  --color-surface-search-field-normal-hover: var(--isport-iron-950-alfa-50);
  --color-surface-search-field-normal-focus: var(--color-white-1000);
  --color-surface-search-field-normal-filed: var(--color-white-1000);
  --color-surface-search-field-inverted-default: var(--isport-iron-950);
  --color-surface-search-field-inverted-hover: var(--color-white-50);
  --color-surface-search-field-inverted-focus: var(--color-surface-default-contrast);
  --color-surface-search-field-inverted-filled: var(--isport-iron-100);
  --color-surface-footer-normal-background: var(--isport-iron-950);
  --color-surface-footer-inverted-background: var(--themes-neutral-subtle);
  --color-surface-table-data-cell-subtle: var(--themes-action-primary-subtle);
  --color-surface-table-header-cell-default: var(--themes-brand-secondary-strong);
  --color-surface-table-subheader-cell-default: var(--isport-iron-50);
  --color-surface-gallery-strip-default: var(--color-surface-default-strong);
  --color-surface-author-card-list-background: var(--themes-neutral-subtle);
  --color-surface-modal-default: var(--color-white-1000);
  --color-surface-content-box-default: var(--themes-neutral-subtle);
  --color-surface-registration-lock-background: var(--isport-iron-100);
  --color-surface-registration-lock-lock-top: var(--isport-iron-900);
  --color-surface-registration-lock-lock-background: var(--isport-iron-300);
  --color-surface-registration-lock-lock-background-2: var(--isport-iron-500);
  --color-surface-registration-default-light: var(--color-white-1000);
  --color-surface-registration-default-subtle: var(--themes-neutral-lighter);
  --color-surface-button-betting-primary-normal-contained-default: var(--tipsport-tip-blue-700);
  --color-surface-button-betting-primary-normal-contained-hover: var(--tipsport-tip-blue-800);
  --color-surface-button-betting-primary-normal-contained-focus: var(--tipsport-tip-blue-600);
  --color-surface-button-betting-primary-normal-contained-active: var(--tipsport-tip-blue-600);
  --color-surface-button-betting-primary-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-betting-primary-normal-outlined-hover: var(--tipsport-tip-blue-200);
  --color-surface-button-betting-primary-normal-outlined-focus: var(--tipsport-tip-blue-200);
  --color-surface-button-betting-primary-normal-outlined-default: var(--color-white-1000);
  --color-surface-button-betting-primary-normal-outlined-disabled: var(--color-white-500);
  --color-surface-button-betting-primary-normal-text-hover: var(--color-white-1000);
  --color-surface-button-betting-primary-normal-text-focus: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-primary-normal-text-default: var(--color-white-1000);
  --color-surface-button-betting-primary-normal-text-disabled: var(--color-white-1000);
  --color-surface-button-betting-primary-normal-text-active: var(--color-white-1000);
  --color-surface-button-betting-primary-inverted-contained-default: var(--tipsport-tip-blue-700);
  --color-surface-button-betting-primary-inverted-contained-hover: var(--tipsport-tip-blue-800);
  --color-surface-button-betting-primary-inverted-contained-focus: var(--tipsport-tip-blue-600);
  --color-surface-button-betting-primary-inverted-contained-active: var(--tipsport-tip-blue-600);
  --color-surface-button-betting-primary-inverted-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-betting-primary-inverted-outlined-hover: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-primary-inverted-outlined-focus: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-primary-inverted-outlined-default: var(--color-white-1000);
  --color-surface-button-betting-primary-inverted-outlined-disabled: var(--color-white-500);
  --color-surface-button-betting-primary-inverted-text-hover: var(--tipsport-tip-blue-800);
  --color-surface-button-betting-primary-inverted-text-focus: var(--tipsport-tip-blue-900);
  --color-surface-button-betting-primary-inverted-text-default: var(--color-white-1000);
  --color-surface-button-betting-primary-inverted-text-disabled: var(--color-white-1000);
  --color-surface-button-betting-primary-inverted-text-active: var(--color-white-1000);
  --color-surface-button-betting-secondary-normal-contained-default: var(--tipsport-tip-blue-100);
  --color-surface-button-betting-secondary-normal-contained-hover: var(--tipsport-tip-blue-200);
  --color-surface-button-betting-secondary-normal-contained-focus: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-secondary-normal-contained-active: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-secondary-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-betting-secondary-normal-outlined-hover: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-secondary-normal-outlined-focus: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-secondary-normal-outlined-default: var(--color-white-1000);
  --color-surface-button-betting-secondary-normal-outlined-disabled: var(--color-white-1000);
  --color-surface-button-betting-secondary-normal-text-hover: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-secondary-normal-text-focus: var(--tipsport-tip-blue-50);
  --color-surface-button-betting-secondary-normal-text-default: var(--color-white-1000);
  --color-surface-results-default: var(--color-white-1000);
  --color-surface-results-hover: var(--isport-iron-50);
  --color-surface-title-section-normal: var(--themes-neutral-lighter);
  --color-surface-title-section-inverted: var(--isport-iron-950);
  --color-surface-rank-circle: var(--color-green-02-100);
  --color-surface-rank-square: var(--color-yellow-100);
  --color-surface-rank-hexagon: var(--color-purple-100);
  --color-surface-result-cells-row: var(--color-white-1000);
  --color-surface-result-cells-subheader: var(--isport-iron-50);
  --color-surface-goal-cells-row: var(--color-white-1000);
  --color-surface-goal-cells-subheader: var(--isport-iron-50);
  --color-surface-sticky-down-background: var(--isport-iron-50);
  --color-surface-discussion-box-background: var(--themes-neutral-strong);
  --color-surface-discussion-card-background: var(--color-white-1000);
  --color-surface-result-row-default: var(--color-white-1000);
  --color-surface-result-row-hover: var(--isport-iron-50);
  --color-surface-premium-box-background: var(--themes-neutral-strong);
  --color-surface-newsletter-box-background: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-default: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-inverted-default: var(--color-white-0);
  --color-surface-checkbox-default-unchecked-inverted-hover: var(--color-white-0);
  --color-surface-checkbox-default-unchecked-inverted-focus: var(--color-white-0);
  --color-surface-checkbox-default-unchecked-inverted-disabled: var(--color-white-0);
  --color-surface-checkbox-default-checked-normal-default: var(--isport-brick-500);
  --color-surface-checkbox-default-checked-normal-hover: var(--isport-brick-500);
  --color-surface-checkbox-default-checked-normal-focus: var(--isport-brick-500);
  --color-surface-checkbox-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-checkbox-default-checked-inverted-default: var(--isport-brick-600);
  --color-surface-checkbox-default-checked-inverted-hover: var(--isport-brick-600);
  --color-surface-checkbox-default-checked-inverted-focus: var(--isport-brick-600);
  --color-surface-checkbox-default-checked-inverted-disabled: var(--isport-iron-700);
  --color-surface-checkbox-error-unchecked-normal-default: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-inverted-default: var(--color-white-0);
  --color-surface-checkbox-error-unchecked-inverted-hover: var(--color-white-0);
  --color-surface-checkbox-error-unchecked-inverted-focus: var(--color-white-0);
  --color-surface-checkbox-error-unchecked-inverted-disabled: var(--color-white-0);
  --color-surface-checkbox-error-checked-normal-default: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-normal-hover: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-normal-focus: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-checkbox-error-checked-inverted-default: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-inverted-hover: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-inverted-focus: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-surface-commentary-box-background: var(--color-white-1000);
  --color-surface-commentary-box-subheader: var(--color-green-02-50);
  --color-surface-filter-normal-background: var(--isport-iron-50);
  --color-surface-filter-inverted-background: var(--isport-iron-950);
  --color-surface-top-event-default: var(--color-white-1000);
  --color-surface-input-normal-default: var(--color-white-1000);
  --color-surface-input-normal-hover: var(--color-white-1000);
  --color-surface-input-normal-focus: var(--color-white-1000);
  --color-surface-input-normal-active: var(--color-white-1000);
  --color-surface-input-normal-error: var(--color-white-1000);
  --color-surface-input-normal-disabled: var(--color-white-1000);
  --color-surface-input-inverted-default: var(--color-white-50);
  --color-surface-input-inverted-hover: var(--color-white-50);
  --color-surface-input-inverted-focus: var(--color-white-50);
  --color-surface-input-inverted-active: var(--color-white-50);
  --color-surface-input-inverted-error: var(--color-white-50);
  --color-surface-input-inverted-disabled: var(--color-white-50);
  --color-surface-scrollbar-background: var(--isport-iron-100);
  --color-surface-scrollbar-scrollbar: var(--themes-neutral-main);
  --color-surface-text-area-normal-default: var(--color-white-1000);
  --color-surface-text-area-normal-hover: var(--color-white-1000);
  --color-surface-text-area-normal-focus: var(--color-white-1000);
  --color-surface-text-area-normal-active: var(--color-white-1000);
  --color-surface-text-area-normal-error: var(--color-white-1000);
  --color-surface-text-area-normal-disabled: var(--color-white-1000);
  --color-surface-text-area-inverted-default: var(--color-white-1000);
  --color-surface-text-area-inverted-hover: var(--color-white-1000);
  --color-surface-text-area-inverted-focus: var(--color-white-1000);
  --color-surface-text-area-inverted-active: var(--color-white-1000);
  --color-surface-text-area-inverted-error: var(--color-white-1000);
  --color-surface-text-area-inverted-disabled: var(--color-white-1000);
  --color-surface-tipsport-bet-background: var(--tipsport-tip-blue-100);
  --color-surface-card-percent-progressbar-empty: var(--isport-iron-900);
  --color-surface-card-percent-progressbar-fill: var(--tipsport-tip-orange-500);
  --color-surface-card-percent-card-default: var(--color-white-1000);
  --color-surface-card-percent-card-hover: var(--color-white-800);
  --color-surface-card-ticket-card: var(--tipsport-tip-blue-100);
  --color-surface-card-ticket-background: var(--color-white-1000);
  --color-surface-ticketarena-header: var(--tipsport-tip-blue-700);
  --color-surface-ticketarena-background: var(--tipsport-tip-blue-100);
  --color-surface-flash-messages-info-normal-background: var(--themes-info-subtle);
  --color-surface-flash-messages-info-inverted-background: var(--themes-info-main);
  --color-surface-flash-messages-alert-normal-background: var(--themes-error-subtle);
  --color-surface-flash-messages-alert-inverted-background: var(--themes-error-main);
  --color-surface-flash-messages-warning-normal-background: var(--themes-warning-subtle);
  --color-surface-flash-messages-warning-inverted-background: var(--themes-warning-light);
  --color-surface-flash-messages-success-normal-background: var(--color-green-02-100);
  --color-surface-flash-messages-success-inverted-background: var(--themes-success-main);
  --color-surface-info-bar-video-background: var(--isport-iron-950);
  --color-surface-lineup-background: var(--isport-iron-50);
  --color-surface-lineup-playground: var(--color-green-300);
  --color-surface-lineup-player: var(--color-white-900);
  --color-surface-lineup-shadow: var(--isport-iron-950-alfa-500);
  --color-surface-radiobutton-default-unchecked-normal-default: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-default: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-hover: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-focus: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-disabled: var(--color-white-1000);
  --color-surface-radiobutton-default-checked-normal-default: var(--themes-brand-primary-main);
  --color-surface-radiobutton-default-checked-normal-hover: var(--themes-brand-primary-main);
  --color-surface-radiobutton-default-checked-normal-focus: var(--themes-brand-primary-main);
  --color-surface-radiobutton-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-radiobutton-default-checked-inverted-default: var(--themes-brand-primary-less-subtle);
  --color-surface-radiobutton-default-checked-inverted-hover: var(--themes-brand-primary-less-subtle);
  --color-surface-radiobutton-default-checked-inverted-focus: var(--themes-brand-primary-less-subtle);
  --color-surface-radiobutton-default-checked-inverted-disabled: var(--themes-disabled-main);
  --color-surface-radiobutton-error-unchecked-normal-default: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-default: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-hover: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-focus: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-disabled: var(--color-white-1000);
  --color-surface-radiobutton-error-checked-normal-default: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-normal-hover: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-normal-focus: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-radiobutton-error-checked-inverted-default: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-inverted-hover: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-inverted-focus: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-surface-stats-background: var(--isport-iron-950-alfa-200);
  --color-surface-stats-better: var(--isport-brick-500);
  --color-surface-stats-worse: var(--isport-iron-900);
  --color-surface-tipsport-table-header: var(--tipsport-tip-blue-700);
  --color-surface-tipsport-table-content: var(--tipsport-tip-blue-100);
  --color-surface-tipsport-table-background: var(--color-white-1000);
  --color-surface-results-card-default: var(--color-white-1000);
  --color-surface-results-card-hover: var(--color-white-800);
  --color-surface-schedule-default: var(--color-white-1000);
  --color-surface-schedule-hover: var(--isport-iron-50);
  --color-surface-result-module-card-default: var(--color-white-1000);
  --color-surface-result-module-card-hover: var(--isport-iron-50);
  --color-surface-result-module-default: var(--color-white-1000);
  --color-surface-team-card-default: var(--color-white-1000);
  --color-surface-team-card-hover: var(--color-white-800);
  --color-surface-videoplayer-background: var(--reflex-gray-950);
  --color-surface-tooltip-background: var(--themes-neutral-strong);
  --color-surface-funds-card-default: var(--color-white-1000);
  --color-surface-funds-card-hover: var(--color-white-800);
  --color-surface-segmented-controls-default: var(--color-white-1000);
  --color-surface-segmented-controls-hover: var(--themes-neutral-subtle);
  --color-surface-segmented-controls-focus: var(--themes-neutral-subtle);
  --color-surface-segmented-controls-active: var(--themes-brand-primary-main);
  --color-surface-segmented-controls-disbled: var(--color-white-1000);
  --color-text-video-card-dark-title: var(--themes-neutral-subtle);
  --color-text-video-card-light-title: var(--themes-neutral-strong);
  --color-text-video-box-dark-title: var(--color-text-video-card-dark-title);
  --color-text-video-box-light-title: var(--color-text-video-card-light-title);
  --color-text-button-primary-solid-inverted-contained-default: var(--isport-iron-950);
  --color-text-button-primary-solid-inverted-contained-hover: var(--isport-iron-950);
  --color-text-button-primary-solid-inverted-contained-focus: var(--isport-iron-950);
  --color-text-button-primary-solid-inverted-contained-active: var(--isport-iron-950);
  --color-text-button-primary-solid-inverted-contained-disabled: var(--isport-iron-500);
  --color-text-button-primary-solid-inverted-outlined-default: var(--isport-spring-400);
  --color-text-button-primary-solid-inverted-outlined-hover: var(--isport-spring-500);
  --color-text-button-primary-solid-inverted-outlined-focus: var(--isport-spring-600);
  --color-text-button-primary-solid-inverted-outlined-active: var(--isport-spring-400);
  --color-text-button-primary-solid-inverted-outlined-disabled: var(--isport-iron-700);
  --color-text-button-primary-solid-inverted-text-default: var(--isport-spring-500);
  --color-text-button-primary-solid-inverted-text-hover: var(--isport-spring-500);
  --color-text-button-primary-solid-inverted-text-focus: var(--isport-spring-600);
  --color-text-button-primary-solid-inverted-text-active: var(--isport-spring-400);
  --color-text-button-primary-solid-inverted-text-disabled: var(--isport-iron-700);
  --color-text-button-primary-solid-normal-contained-default: var(--isport-iron-950);
  --color-text-button-primary-solid-normal-contained-hover: var(--isport-iron-950);
  --color-text-button-primary-solid-normal-contained-focus: var(--isport-iron-950);
  --color-text-button-primary-solid-normal-contained-active: var(--isport-iron-950);
  --color-text-button-primary-solid-normal-contained-disabled: var(--isport-iron-500);
  --color-text-button-primary-solid-normal-outlined-default: var(--isport-spring-400);
  --color-text-button-primary-solid-normal-outlined-hover: var(--isport-spring-500);
  --color-text-button-primary-solid-normal-outlined-focus: var(--isport-spring-600);
  --color-text-button-primary-solid-normal-outlined-active: var(--isport-spring-400);
  --color-text-button-primary-solid-normal-outlined-disabled: var(--isport-iron-700);
  --color-text-button-primary-solid-normal-text-default: var(--isport-spring-500);
  --color-text-button-primary-solid-normal-text-hover: var(--isport-spring-500);
  --color-text-button-primary-solid-normal-text-focus: var(--isport-spring-600);
  --color-text-button-primary-solid-normal-text-active: var(--isport-spring-400);
  --color-text-button-primary-solid-normal-text-disabled: var(--isport-iron-700);
  --color-text-button-primary-gradient-inverted-contained-default: var(--isport-iron-950);
  --color-text-button-primary-gradient-inverted-contained-hover: var(--isport-iron-950);
  --color-text-button-primary-gradient-inverted-contained-focus: var(--isport-iron-950);
  --color-text-button-primary-gradient-inverted-contained-active: var(--isport-iron-950);
  --color-text-button-primary-gradient-inverted-contained-disabled: var(--isport-iron-500);
  --color-text-button-primary-gradient-inverted-outlined-default: var(--isport-spring-400);
  --color-text-button-primary-gradient-inverted-outlined-hover: var(--isport-spring-300);
  --color-text-button-primary-gradient-inverted-outlined-focus: var(--isport-spring-400);
  --color-text-button-primary-gradient-inverted-outlined-active: var(--isport-spring-400);
  --color-text-button-primary-gradient-inverted-outlined-disabled: var(--isport-iron-700);
  --color-text-button-primary-gradient-inverted-text-default: var(--isport-spring-500);
  --color-text-button-primary-gradient-inverted-text-hover: var(--isport-spring-400);
  --color-text-button-primary-gradient-inverted-text-focus: var(--isport-spring-400);
  --color-text-button-primary-gradient-inverted-text-active: var(--isport-spring-400);
  --color-text-button-primary-gradient-inverted-text-disabled: var(--isport-iron-700);
  --color-text-button-primary-gradient-normal-contained-default: var(--isport-iron-950);
  --color-text-button-primary-gradient-normal-contained-hover: var(--isport-iron-950);
  --color-text-button-primary-gradient-normal-contained-focus: var(--isport-iron-950);
  --color-text-button-primary-gradient-normal-contained-active: var(--isport-iron-950);
  --color-text-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-main);
  --color-text-button-primary-gradient-normal-outlined-default: var(--isport-spring-400);
  --color-text-button-primary-gradient-normal-outlined-hover: var(--isport-spring-500);
  --color-text-button-primary-gradient-normal-outlined-focus: var(--isport-spring-600);
  --color-text-button-primary-gradient-normal-outlined-active: var(--isport-spring-400);
  --color-text-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-main);
  --color-text-button-primary-gradient-normal-text-default: var(--isport-spring-500);
  --color-text-button-primary-gradient-normal-text-hover: var(--isport-spring-500);
  --color-text-button-primary-gradient-normal-text-focus: var(--isport-spring-600);
  --color-text-button-primary-gradient-normal-text-active: var(--isport-spring-400);
  --color-text-button-primary-gradient-normal-text-disabled: var(--themes-disabled-main);
  --color-text-button-secondary-contained-default: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-disabled: var(--themes-disabled-light);
  --color-text-button-secondary-outlined-default: var(--themes-action-secondary-darker);
  --color-text-button-secondary-outlined-hover: var(--themes-action-secondary-dark);
  --color-text-button-secondary-outlined-focus: var(--themes-action-secondary-dark);
  --color-text-button-secondary-outlined-active: var(--themes-action-secondary-main);
  --color-text-button-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-secondary-text-default: var(--themes-action-secondary-darker);
  --color-text-button-secondary-text-hover: var(--themes-action-secondary-dark);
  --color-text-button-secondary-text-focus: var(--themes-action-secondary-dark);
  --color-text-button-secondary-text-active: var(--themes-action-secondary-main);
  --color-text-button-secondary-text-disabled: var(--themes-disabled-light);
  --color-text-button-info-contained-default: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-disabled: var(--themes-disabled-light);
  --color-text-button-info-outlined-default: var(--themes-info-main);
  --color-text-button-info-outlined-hover: var(--themes-info-dark);
  --color-text-button-info-outlined-focus: var(--themes-info-dark);
  --color-text-button-info-outlined-active: var(--themes-info-light);
  --color-text-button-info-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-info-text-default: var(--themes-info-main);
  --color-text-button-info-text-hover: var(--themes-info-dark);
  --color-text-button-info-text-focus: var(--themes-info-dark);
  --color-text-button-info-text-active: var(--themes-info-light);
  --color-text-button-info-text-disabled: var(--themes-disabled-light);
  --color-text-button-error-contained-default: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-disabled: var(--themes-disabled-light);
  --color-text-button-error-outlined-default: var(--themes-error-main);
  --color-text-button-error-outlined-hover: var(--themes-error-dark);
  --color-text-button-error-outlined-focus: var(--themes-error-dark);
  --color-text-button-error-outlined-active: var(--themes-error-light);
  --color-text-button-error-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-error-text-default: var(--themes-error-main);
  --color-text-button-error-text-hover: var(--themes-error-dark);
  --color-text-button-error-text-focus: var(--themes-error-dark);
  --color-text-button-error-text-active: var(--themes-error-light);
  --color-text-button-error-text-disabled: var(--themes-disabled-light);
  --color-text-button-social-login-default: var(--isport-iron-950);
  --color-text-button-social-login-hover: var(--isport-iron-950);
  --color-text-button-social-login-focus: var(--isport-iron-950);
  --color-text-button-social-login-active: var(--themes-neutral-strong);
  --color-text-button-social-login-disabled: var(--themes-disabled-light);
  --color-text-default-title: var(--themes-neutral-strong);
  --color-text-default-subtitle: var(--themes-neutral-darker);
  --color-text-default-perex: var(--isport-iron-950);
  --color-text-default-body: var(--themes-neutral-strong);
  --color-text-default-meta: var(--themes-neutral-dark);
  --color-text-default-light: var(--themes-neutral-light);
  --color-text-default-inverted: var(--color-white-1000);
  --color-text-article-card-title: var(--color-text-default-title);
  --color-text-article-card-meta: var(--color-text-default-meta);
  --color-text-article-card-perex: var(--color-text-default-body);
  --color-text-article-title-default: var(--color-text-default-title);
  --color-text-article-title-hover: var(--isport-spring-400);
  --color-text-accordion-body-default: var(--themes-neutral-darker);
  --color-text-accordion-title-default: var(--themes-neutral-strong);
  --color-text-accordion-title-hover: var(--themes-brand-primary-main);
  --color-text-badge-success: var(--themes-success-on-success);
  --color-text-badge-warning: var(--themes-warning-on-warning);
  --color-text-badge-error: var(--themes-error-on-error);
  --color-text-badge-info: var(--themes-info-on-info);
  --color-text-badge-disabled: var(--themes-disabled-light);
  --color-text-badge-secondary: var(--themes-brand-secondary-on-secondary);
  --color-text-badge-primary: var(--isport-iron-950);
  --color-text-badgepill-soft-success: var(--themes-success-dark);
  --color-text-badgepill-soft-warning: var(--themes-warning-main);
  --color-text-badgepill-soft-error: var(--themes-error-main);
  --color-text-badgepill-soft-info: var(--themes-info-dark);
  --color-text-badgepill-soft-disabled: var(--themes-disabled-light);
  --color-text-badgepill-soft-secondary: var(--themes-brand-secondary-darker);
  --color-text-badgepill-soft-primary: var(--isport-spring-800);
  --color-text-badgepill-solid-success: var(--themes-success-on-success);
  --color-text-badgepill-solid-warning: var(--themes-warning-on-warning);
  --color-text-badgepill-solid-error: var(--themes-error-on-error);
  --color-text-badgepill-solid-info: var(--themes-info-on-info);
  --color-text-badgepill-solid-disabled: var(--themes-disabled-subtle);
  --color-text-badgepill-solid-secondary: var(--themes-brand-secondary-on-secondary);
  --color-text-badgepill-solid-primary: var(--isport-iron-950);
  --color-text-badgepill-outlined-success: var(--themes-success-main);
  --color-text-badgepill-outlined-warning: var(--themes-warning-dark);
  --color-text-badgepill-outlined-error: var(--themes-error-main);
  --color-text-badgepill-outlined-info: var(--themes-info-dark);
  --color-text-badgepill-outlined-disabled: var(--themes-disabled-light);
  --color-text-badgepill-outlined-secondary: var(--themes-brand-secondary-dark);
  --color-text-badgepill-outlined-primary: var(--isport-spring-500);
  --color-text-chip-inverted-contained-default: var(--color-white-1000);
  --color-text-chip-inverted-contained-hover: var(--color-white-1000);
  --color-text-chip-inverted-contained-focus: var(--color-white-1000);
  --color-text-chip-inverted-contained-active: var(--color-white-1000);
  --color-text-chip-inverted-contained-disabled: var(--isport-iron-600);
  --color-text-chip-primary-contained-default: var(--themes-neutral-darker);
  --color-text-chip-primary-contained-hover: var(--themes-neutral-dark);
  --color-text-chip-primary-contained-focus: var(--themes-action-primary-main);
  --color-text-chip-primary-contained-active: var(--themes-action-primary-main);
  --color-text-chip-primary-contained-disabled: var(--themes-disabled-light);
  --color-text-chip-primary-outlined-default: var(--themes-neutral-darker);
  --color-text-chip-primary-outlined-hover: var(--themes-action-primary-main);
  --color-text-chip-primary-outlined-focus: var(--themes-action-primary-on-primary);
  --color-text-chip-primary-outlined-active: var(--themes-action-primary-on-primary);
  --color-text-chip-primary-outlined-disabled: var(--themes-disabled-light);
  --color-text-chip-secondary-contained-default: var(--themes-neutral-darker);
  --color-text-chip-secondary-contained-hover: var(--themes-neutral-dark);
  --color-text-chip-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-contained-disabled: var(--themes-disabled-light);
  --color-text-chip-secondary-outlined-default: var(--themes-neutral-darker);
  --color-text-chip-secondary-outlined-hover: var(--themes-neutral-strong);
  --color-text-chip-secondary-outlined-focus: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-outlined-active: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-text-divider-label: var(--themes-neutral-main);
  --color-text-link-primary-default: var(--themes-action-primary-main);
  --color-text-link-primary-hover: var(--themes-action-primary-dark);
  --color-text-link-neutral-default: var(--themes-neutral-strong);
  --color-text-link-neutral-hover: var(--themes-neutral-dark);
  --color-text-list-default: var(--themes-neutral-strong);
  --color-text-list-inverted: var(--color-white-1000);
  --color-text-list-positive: var(--themes-neutral-strong);
  --color-text-list-negative: var(--themes-neutral-strong);
  --color-text-pagination-default: var(--themes-neutral-strong);
  --color-text-pagination-hover: var(--themes-neutral-strong);
  --color-text-pagination-active: var(--themes-brand-primary-main);
  --color-text-pagination-focus: var(--themes-neutral-strong);
  --color-text-pagination-disabled: var(--themes-neutral-lighter);
  --color-text-premium-card-normal-title: var(--themes-neutral-strong);
  --color-text-premium-card-normal-body: var(--themes-neutral-strong);
  --color-text-premium-card-normal-subtitle: var(--themes-neutral-dark);
  --color-text-premium-card-inverted-title: var(--themes-neutral-subtle);
  --color-text-premium-card-inverted-body: var(--themes-neutral-subtle);
  --color-text-premium-card-inverted-subtitle: var(--themes-action-primary-subtle);
  --color-text-tag-solid-normal-default: var(--themes-neutral-dark);
  --color-text-tag-solid-normal-hover: var(--themes-action-primary-light);
  --color-text-tag-solid-normal-focus: var(--themes-action-primary-light);
  --color-text-tag-solid-normal-active: var(--themes-action-primary-on-primary);
  --color-text-tag-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-tag-solid-inverted-default: var(--isport-iron-200);
  --color-text-tag-solid-inverted-hover: var(--isport-spring-400);
  --color-text-tag-solid-inverted-focus: var(--isport-iron-100);
  --color-text-tag-solid-inverted-active: var(--isport-iron-950);
  --color-text-tag-solid-inverted-disabled: var(--isport-iron-700);
  --color-text-tag-gradient-normal-default: var(--themes-neutral-dark);
  --color-text-tag-gradient-normal-hover: var(--themes-action-primary-light);
  --color-text-tag-gradient-normal-focus: var(--themes-action-primary-light);
  --color-text-tag-gradient-normal-active: var(--themes-action-primary-on-primary);
  --color-text-tag-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-tag-gradient-inverted-default: var(--isport-iron-200);
  --color-text-tag-gradient-inverted-hover: var(--isport-spring-400);
  --color-text-tag-gradient-inverted-focus: var(--isport-iron-100);
  --color-text-tag-gradient-inverted-active: var(--isport-iron-950);
  --color-text-tag-gradient-inverted-disabled: var(--isport-iron-700);
  --color-text-textlink-menu-item-default: var(--isport-iron-50);
  --color-text-textlink-menu-item-hover: var(--isport-spring-400);
  --color-text-textlink-menu-item-active: var(--isport-iron-50);
  --color-text-textlink-menu-item-focus: var(--isport-spring-300);
  --color-text-textlink-menu-item-disabled: var(--isport-iron-700);
  --color-text-textlink-menu-item-gradient-default: var(--isport-iron-50);
  --color-text-textlink-menu-item-gradient-hover: var(--isport-spring-400);
  --color-text-textlink-menu-item-gradient-active: var(--isport-iron-50);
  --color-text-textlink-menu-item-gradient-focus: var(--isport-spring-300);
  --color-text-textlink-menu-item-gradient-disabled: var(--isport-iron-700);
  --color-text-textlink-menu-item-solid-default: var(--isport-iron-50);
  --color-text-textlink-menu-item-solid-hover: var(--isport-spring-400);
  --color-text-textlink-menu-item-solid-active: var(--isport-iron-50);
  --color-text-textlink-menu-item-solid-focus: var(--isport-spring-300);
  --color-text-textlink-menu-item-solid-disabled: var(--isport-iron-700);
  --color-text-textlink-category-default: var(--isport-iron-50);
  --color-text-textlink-category-hover: var(--isport-iron-50);
  --color-text-textlink-category-active: var(--isport-iron-50);
  --color-text-textlink-category-focus: var(--isport-brick-500);
  --color-text-textlink-category-disabled: var(--themes-disabled-light);
  --color-text-textlink-category-gradient-default: var(--isport-iron-50);
  --color-text-textlink-category-gradient-hover: var(--isport-iron-50);
  --color-text-textlink-category-gradient-active: var(--isport-iron-50);
  --color-text-textlink-category-gradient-focus: var(--isport-brick-500);
  --color-text-textlink-category-gradient-disabled: var(--themes-disabled-light);
  --color-text-textlink-category-solid-default: var(--isport-iron-50);
  --color-text-textlink-category-solid-hover: var(--isport-iron-50);
  --color-text-textlink-category-solid-active: var(--isport-iron-50);
  --color-text-textlink-category-solid-focus: var(--isport-brick-500);
  --color-text-textlink-category-solid-disabled: var(--themes-disabled-light);
  --color-text-textlink-contained-default: var(--isport-iron-50);
  --color-text-textlink-contained-hover: var(--isport-iron-50);
  --color-text-textlink-contained-active: var(--isport-iron-50);
  --color-text-textlink-contained-focus: var(--isport-brick-500);
  --color-text-textlink-contained-disabled: var(--themes-disabled-light);
  --color-text-textlink-contained-gradient-default: var(--isport-iron-50);
  --color-text-textlink-contained-gradient-hover: var(--isport-iron-50);
  --color-text-textlink-contained-gradient-active: var(--isport-iron-50);
  --color-text-textlink-contained-gradient-focus: var(--isport-brick-500);
  --color-text-textlink-contained-gradient-disabled: var(--themes-disabled-light);
  --color-text-textlink-contained-solid-default: var(--isport-iron-50);
  --color-text-textlink-contained-solid-hover: var(--isport-iron-50);
  --color-text-textlink-contained-solid-active: var(--isport-iron-50);
  --color-text-textlink-contained-solid-focus: var(--isport-brick-500);
  --color-text-textlink-contained-solid-disabled: var(--themes-disabled-light);
  --color-text-textlink-megamenu-default: var(--isport-iron-50);
  --color-text-textlink-megamenu-hover: var(--isport-spring-400);
  --color-text-textlink-megamenu-active: var(--isport-iron-50);
  --color-text-textlink-megamenu-focus: var(--isport-spring-300);
  --color-text-textlink-megamenu-disabled: var(--isport-iron-700);
  --color-text-textlink-megamenu-gradient-default: var(--isport-iron-50);
  --color-text-textlink-megamenu-gradient-hover: var(--isport-spring-400);
  --color-text-textlink-megamenu-gradient-active: var(--isport-iron-50);
  --color-text-textlink-megamenu-gradient-focus: var(--isport-spring-300);
  --color-text-textlink-megamenu-gradient-disabled: var(--isport-iron-700);
  --color-text-textlink-megamenu-solid-default: var(--isport-iron-50);
  --color-text-textlink-megamenu-solid-hover: var(--isport-spring-400);
  --color-text-textlink-megamenu-solid-active: var(--isport-iron-50);
  --color-text-textlink-megamenu-solid-focus: var(--isport-spring-300);
  --color-text-textlink-megamenu-solid-disabled: var(--isport-iron-700);
  --color-text-textlink-stand-alone-default: var(--isport-iron-50);
  --color-text-textlink-stand-alone-hover: var(--isport-spring-400);
  --color-text-textlink-stand-alone-active: var(--isport-iron-50);
  --color-text-textlink-stand-alone-focus: var(--isport-spring-300);
  --color-text-textlink-stand-alone-disabled: var(--isport-iron-700);
  --color-text-textlink-stand-alone-gradient-default: var(--isport-iron-50);
  --color-text-textlink-stand-alone-gradient-hover: var(--isport-spring-400);
  --color-text-textlink-stand-alone-gradient-active: var(--isport-iron-50);
  --color-text-textlink-stand-alone-gradient-focus: var(--isport-spring-300);
  --color-text-textlink-stand-alone-gradient-disabled: var(--isport-iron-700);
  --color-text-textlink-stand-alone-solid-default: var(--isport-iron-50);
  --color-text-textlink-stand-alone-solid-hover: var(--isport-spring-400);
  --color-text-textlink-stand-alone-solid-active: var(--isport-iron-50);
  --color-text-textlink-stand-alone-solid-focus: var(--isport-spring-300);
  --color-text-textlink-stand-alone-solid-disabled: var(--isport-iron-700);
  --color-text-comments-card-name: var(--themes-gradient-01-front);
  --color-text-comments-card-body: var(--themes-neutral-strong);
  --color-text-comments-box-title: var(--isport-iron-50);
  --color-text-comments-box-body: var(--color-white-1000);
  --color-text-text-link-default: var(--themes-neutral-strong);
  --color-text-text-link-hover: var(--isport-brick-500);
  --color-text-text-link-focus: var(--themes-neutral-strong);
  --color-text-text-link-disabled: var(--themes-neutral-light);
  --color-text-text-link-stand-alone-primary-solid-normal-default: var(--isport-brick-500);
  --color-text-text-link-stand-alone-primary-solid-normal-hover: var(--isport-brick-600);
  --color-text-text-link-stand-alone-primary-solid-normal-focus: var(--isport-brick-500);
  --color-text-text-link-stand-alone-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-primary-solid-inverted-default: var(--color-white-1000);
  --color-text-text-link-stand-alone-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-text-text-link-stand-alone-primary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-stand-alone-primary-solid-inverted-disabled: var(--themes-disabled-strong);
  --color-text-text-link-stand-alone-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-primary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-secondary-solid-normal-default: var(--themes-neutral-strong);
  --color-text-text-link-stand-alone-secondary-solid-normal-hover: var(--isport-brick-500);
  --color-text-text-link-stand-alone-secondary-solid-normal-focus: var(--themes-neutral-strong);
  --color-text-text-link-stand-alone-secondary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-secondary-solid-inverted-default: var(--isport-spring-400);
  --color-text-text-link-stand-alone-secondary-solid-inverted-hover: var(--isport-spring-500);
  --color-text-text-link-stand-alone-secondary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-stand-alone-secondary-solid-inverted-disabled: var(--themes-disabled-strong);
  --color-text-text-link-stand-alone-secondary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-secondary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-primary-solid-normal-default: var(--isport-brick-500);
  --color-text-text-link-hyperlink-primary-solid-normal-hover: var(--isport-brick-600);
  --color-text-text-link-hyperlink-primary-solid-normal-focus: var(--isport-brick-500);
  --color-text-text-link-hyperlink-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-primary-solid-inverted-default: var(--color-white-1000);
  --color-text-text-link-hyperlink-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-text-text-link-hyperlink-primary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-hyperlink-primary-solid-inverted-disabled: var(--themes-disabled-strong);
  --color-text-text-link-hyperlink-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-primary-gradient-inverted-disabled: var(--themes-disabled-strong);
  --color-text-text-link-hyperlink-secondary-solid-normal-default: var(--themes-neutral-strong);
  --color-text-text-link-hyperlink-secondary-solid-normal-hover: var(--isport-brick-500);
  --color-text-text-link-hyperlink-secondary-solid-normal-focus: var(--themes-neutral-strong);
  --color-text-text-link-hyperlink-secondary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-secondary-solid-inverted-default: var(--isport-spring-400);
  --color-text-text-link-hyperlink-secondary-solid-inverted-hover: var(--isport-spring-500);
  --color-text-text-link-hyperlink-secondary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-hyperlink-secondary-solid-inverted-disabled: var(--themes-disabled-strong);
  --color-text-text-link-hyperlink-secondary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-secondary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-label-link-solid-text-default: var(--isport-brick-600);
  --color-text-label-link-solid-text-hover: var(--isport-brick-400);
  --color-text-label-link-solid-text-active: var(--themes-neutral-strong);
  --color-text-label-link-solid-text-focus: var(--themes-neutral-strong);
  --color-text-label-link-solid-text-disabled: var(--themes-neutral-light);
  --color-text-label-link-solid-contained-default: var(--themes-action-primary-subtle);
  --color-text-label-link-solid-contained-hover: var(--themes-action-primary-subtle);
  --color-text-label-link-solid-contained-active: var(--themes-neutral-lighter);
  --color-text-label-link-solid-contained-focus: var(--themes-accent-darker);
  --color-text-label-link-solid-contained-disabled: var(--themes-neutral-light);
  --color-text-label-link-gradient-text-default: var(--themes-action-primary-main);
  --color-text-label-link-gradient-text-hover: var(--isport-brick-400);
  --color-text-label-link-gradient-text-active: var(--themes-neutral-strong);
  --color-text-label-link-gradient-text-focus: var(--themes-neutral-strong);
  --color-text-label-link-gradient-text-disabled: var(--themes-neutral-light);
  --color-text-label-link-gradient-contained-default: var(--themes-action-primary-subtle);
  --color-text-label-link-gradient-contained-hover: var(--themes-action-primary-subtle);
  --color-text-label-link-gradient-contained-active: var(--themes-neutral-lighter);
  --color-text-label-link-gradient-contained-focus: var(--themes-accent-darker);
  --color-text-label-link-gradient-contained-disabled: var(--themes-neutral-light);
  --color-text-category-link-gradient-default: var(--themes-action-primary-light);
  --color-text-megamenu-link-title-default: var(--themes-neutral-strong);
  --color-text-megamenu-link-title-hover: var(--isport-brick-500);
  --color-text-megamenu-link-title-active: var(--isport-flame-600);
  --color-text-megamenu-link-title-focus: var(--themes-neutral-strong);
  --color-text-megamenu-link-text-link-default: var(--themes-neutral-strong);
  --color-text-megamenu-link-text-link-hover: var(--isport-brick-500);
  --color-text-megamenu-link-text-link-active: var(--isport-flame-600);
  --color-text-megamenu-link-text-link-focus: var(--themes-neutral-strong);
  --color-text-header-link-solid-normal-default: var(--isport-iron-50);
  --color-text-header-link-solid-normal-hover: var(--themes-action-primary-light);
  --color-text-header-link-solid-normal-selected: var(--isport-iron-50);
  --color-text-header-link-solid-normal-focus: var(--themes-neutral-strong);
  --color-text-header-link-solid-inverted-default: var(--isport-iron-50);
  --color-text-header-link-solid-inverted-hover: var(--themes-action-primary-light);
  --color-text-header-link-solid-inverted-active: var(--isport-iron-50);
  --color-text-header-link-solid-inverted-subtle: var(--isport-brick-50);
  --color-text-header-link-solid-inverted-focus: var(--themes-neutral-strong);
  --color-text-header-link-gradient-normal-default: var(--isport-iron-50);
  --color-text-header-link-gradient-normal-hover: var(--themes-action-primary-light);
  --color-text-header-link-gradient-normal-selected: var(--isport-iron-50);
  --color-text-header-link-gradient-normal-active: var(--themes-neutral-strong);
  --color-text-header-link-gradient-inverted-default: var(--isport-iron-50);
  --color-text-header-link-gradient-inverted-hover: var(--themes-action-primary-light);
  --color-text-header-link-gradient-inverted-active: var(--isport-iron-50);
  --color-text-header-link-gradient-inverted-focus: var(--themes-neutral-strong);
  --color-text-quotation-box-heading: var(--themes-neutral-strong);
  --color-text-dropdown-default: var(--themes-neutral-strong);
  --color-text-dropdown-disabled: var(--themes-disabled-main);
  --color-text-search-field-default-default: var(--themes-neutral-dark);
  --color-text-search-field-default-hover: var(--color-text-default-title);
  --color-text-search-field-default-focus: var(--color-text-default-title);
  --color-text-search-field-default-filled: var(--color-text-default-title);
  --color-text-search-field-inverted-default: var(--isport-iron-300);
  --color-text-search-field-inverted-hover: var(--isport-iron-100);
  --color-text-search-field-inverted-focus: var(--color-text-default-title);
  --color-text-search-field-inverted-filled: var(--color-text-default-title);
  --color-text-table-header-cells-heading: var(--color-white-1000);
  --color-text-table-subheader-cells-subheading: var(--themes-neutral-strong);
  --color-text-table-data-cells-body: var(--themes-neutral-strong);
  --color-text-table-data-cells-meta: var(--themes-neutral-dark);
  --color-text-breadcrumb-normal-default: var(--themes-neutral-dark);
  --color-text-breadcrumb-normal-active: var(--themes-neutral-strong);
  --color-text-breadcrumb-inverted-default: var(--themes-neutral-main);
  --color-text-breadcrumb-inverted-active: var(--color-white-1000);
  --color-text-author-card-list-subtitle: var(--themes-neutral-strong);
  --color-text-author-card-list-body: var(--themes-neutral-strong);
  --color-text-author-card-detail-subtitle: var(--themes-neutral-dark);
  --color-text-author-card-detail-body: var(--themes-neutral-strong);
  --color-text-modal-title: var(--themes-neutral-strong);
  --color-text-modal-body: var(--themes-neutral-strong);
  --color-text-content-box-title: var(--themes-neutral-strong);
  --color-text-content-box-body: var(--themes-neutral-strong);
  --color-text-registration-title: var(--themes-neutral-strong);
  --color-text-registration-body: var(--themes-neutral-strong);
  --color-text-application-card-title: var(--themes-neutral-strong);
  --color-text-application-card-body: var(--themes-neutral-strong);
  --color-text-avatar-name: var(--themes-neutral-dark);
  --color-text-button-betting-primary-normal-contained-default: var(--color-white-1000);
  --color-text-button-betting-primary-normal-contained-hover: var(--color-white-1000);
  --color-text-button-betting-primary-normal-contained-focus: var(--color-white-1000);
  --color-text-button-betting-primary-normal-contained-active: var(--color-white-1000);
  --color-text-button-betting-primary-normal-contained-disabled: var(--themes-disabled-main);
  --color-text-button-betting-primary-normal-outlined-default: var(--tipsport-tip-blue-700);
  --color-text-button-betting-primary-normal-outlined-hover: var(--tipsport-tip-blue-900);
  --color-text-button-betting-primary-normal-outlined-focus: var(--tipsport-tip-blue-900);
  --color-text-button-betting-primary-normal-outlined-active: var(--tipsport-tip-blue-600);
  --color-text-button-betting-primary-normal-outlined-disabled: var(--themes-disabled-main);
  --color-text-button-betting-primary-normal-text-default: var(--tipsport-tip-blue-700);
  --color-text-button-betting-primary-normal-text-hover: var(--tipsport-tip-blue-800);
  --color-text-button-betting-primary-normal-text-focus: var(--tipsport-tip-blue-800);
  --color-text-button-betting-primary-normal-text-active: var(--tipsport-tip-blue-600);
  --color-text-button-betting-primary-normal-text-disabled: var(--themes-disabled-main);
  --color-text-button-betting-primary-inverted-contained-default: var(--color-white-1000);
  --color-text-button-betting-primary-inverted-contained-hover: var(--color-white-1000);
  --color-text-button-betting-primary-inverted-contained-focus: var(--color-white-1000);
  --color-text-button-betting-primary-inverted-contained-active: var(--color-white-1000);
  --color-text-button-betting-primary-inverted-contained-disabled: var(--themes-disabled-main);
  --color-text-button-betting-primary-inverted-outlined-default: var(--tipsport-tip-blue-700);
  --color-text-button-betting-primary-inverted-outlined-hover: var(--tipsport-tip-blue-800);
  --color-text-button-betting-primary-inverted-outlined-focus: var(--tipsport-tip-blue-800);
  --color-text-button-betting-primary-inverted-outlined-active: var(--tipsport-tip-blue-600);
  --color-text-button-betting-primary-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-text-button-betting-primary-inverted-text-default: var(--color-white-1000);
  --color-text-button-betting-primary-inverted-text-hover: var(--color-white-1000);
  --color-text-button-betting-primary-inverted-text-focus: var(--color-white-1000);
  --color-text-button-betting-primary-inverted-text-active: var(--tipsport-tip-blue-200);
  --color-text-button-betting-primary-inverted-text-disabled: var(--themes-disabled-main);
  --color-text-button-betting-secondary-normal-contained-default: var(--tipsport-tip-blue-700);
  --color-text-button-betting-secondary-normal-contained-hover: var(--tipsport-tip-blue-900);
  --color-text-button-betting-secondary-normal-contained-focus: var(--tipsport-tip-blue-700);
  --color-text-button-betting-secondary-normal-contained-active: var(--tipsport-tip-blue-600);
  --color-text-button-betting-secondary-normal-contained-disabled: var(--themes-disabled-main);
  --color-text-button-betting-secondary-normal-outlined-default: var(--tipsport-tip-blue-700);
  --color-text-button-betting-secondary-normal-outlined-hover: var(--tipsport-tip-blue-800);
  --color-text-button-betting-secondary-normal-outlined-focus: var(--tipsport-tip-blue-800);
  --color-text-button-betting-secondary-normal-outlined-active: var(--tipsport-tip-blue-600);
  --color-text-button-betting-secondary-normal-outlined-disabled: var(--themes-disabled-main);
  --color-text-button-betting-secondary-normal-text-default: var(--tipsport-tip-blue-700);
  --color-text-button-betting-secondary-normal-text-hover: var(--tipsport-tip-blue-800);
  --color-text-button-betting-secondary-normal-text-focus: var(--tipsport-tip-blue-800);
  --color-text-button-betting-secondary-normal-text-active: var(--tipsport-tip-blue-600);
  --color-text-button-betting-secondary-normal-text-disabled: var(--themes-disabled-main);
  --color-text-results-live: var(--themes-success-main);
  --color-text-results-result: var(--themes-error-main);
  --color-text-results-body: var(--themes-neutral-strong);
  --color-text-results-meta: var(--themes-neutral-dark);
  --color-text-rank-circle: var(--themes-neutral-strong);
  --color-text-rank-square: var(--themes-neutral-strong);
  --color-text-rank-hexagon: var(--themes-neutral-strong);
  --color-text-result-cells-body: var(--themes-neutral-strong);
  --color-text-result-cells-points: var(--isport-brick-600);
  --color-text-result-cells-subheader: var(--themes-neutral-darker);
  --color-text-goal-cells-body: var(--themes-neutral-strong);
  --color-text-goal-cells-subheader: var(--themes-neutral-darker);
  --color-text-footer-normal-title: var(--themes-neutral-strong);
  --color-text-footer-normal-body: var(--themes-neutral-strong);
  --color-text-footer-inverted-title: var(--color-white-1000);
  --color-text-footer-inverted-body: var(--color-white-1000);
  --color-text-sticky-down-title: var(--themes-neutral-strong);
  --color-text-sticky-down-body: var(--themes-neutral-strong);
  --color-text-discussion-strip-title: var(--themes-neutral-strong);
  --color-text-discussion-strip-body: var(--themes-neutral-strong);
  --color-text-discussion-strip-meta: var(--themes-neutral-dark);
  --color-text-discussion-box-title: var(--color-white-1000);
  --color-text-error-title: var(--themes-brand-primary-main);
  --color-text-error-subtitle: var(--themes-neutral-strong);
  --color-text-error-body: var(--themes-neutral-darker);
  --color-text-article-meta-normal-data: var(--isport-iron-600);
  --color-text-article-meta-inverted-data: var(--isport-iron-400);
  --color-text-premium-box-title: var(--color-white-1000);
  --color-text-premium-box-body: var(--color-white-1000);
  --color-text-newsletter-box-title: var(--isport-brick-500);
  --color-text-newsletter-box-body: var(--themes-neutral-strong);
  --color-text-newsletter-box-meta: var(--themes-neutral-dark);
  --color-text-checkbox-default-unchecked-normal-default: var(--themes-neutral-strong);
  --color-text-checkbox-default-unchecked-normal-hover: var(--themes-neutral-strong);
  --color-text-checkbox-default-unchecked-normal-focus: var(--themes-neutral-strong);
  --color-text-checkbox-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-unchecked-normal-error: var(--themes-error-main);
  --color-text-checkbox-default-unchecked-inverted-default: var(--color-white-1000);
  --color-text-checkbox-default-unchecked-inverted-hover: var(--color-white-1000);
  --color-text-checkbox-default-unchecked-inverted-focus: var(--color-white-1000);
  --color-text-checkbox-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-unchecked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-default-checked-normal-default: var(--themes-neutral-strong);
  --color-text-checkbox-default-checked-normal-hover: var(--themes-neutral-strong);
  --color-text-checkbox-default-checked-normal-focus: var(--themes-neutral-strong);
  --color-text-checkbox-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-checked-normal-error: var(--themes-error-main);
  --color-text-checkbox-default-checked-inverted-default: var(--color-white-1000);
  --color-text-checkbox-default-checked-inverted-hover: var(--color-white-1000);
  --color-text-checkbox-default-checked-inverted-focus: var(--color-white-1000);
  --color-text-checkbox-default-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-checked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-default-all-normal-default: var(--themes-neutral-strong);
  --color-text-checkbox-default-all-normal-hover: var(--themes-neutral-strong);
  --color-text-checkbox-default-all-normal-focus: var(--themes-neutral-strong);
  --color-text-checkbox-default-all-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-all-normal-error: var(--themes-error-main);
  --color-text-checkbox-default-all-inverted-default: var(--color-white-1000);
  --color-text-checkbox-default-all-inverted-hover: var(--color-white-1000);
  --color-text-checkbox-default-all-inverted-focus: var(--color-white-1000);
  --color-text-checkbox-default-all-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-all-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-default: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-hover: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-focus: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-unchecked-normal-error: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-default: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-hover: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-focus: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-unchecked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-checked-normal-default: var(--themes-error-dark);
  --color-text-checkbox-error-checked-normal-hover: var(--themes-error-dark);
  --color-text-checkbox-error-checked-normal-focus: var(--themes-error-dark);
  --color-text-checkbox-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-checked-normal-error: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-default: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-hover: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-focus: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-checked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-all-normal-default: var(--themes-error-dark);
  --color-text-checkbox-error-all-normal-hover: var(--themes-error-dark);
  --color-text-checkbox-error-all-normal-focus: var(--themes-error-dark);
  --color-text-checkbox-error-all-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-all-normal-error: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-default: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-hover: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-focus: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-disabled: var(--themes-disabled-main);
  --color-text-commentary-box-title: var(--themes-neutral-strong);
  --color-text-commentary-box-body: var(--themes-neutral-strong);
  --color-text-commentary-box-meta: var(--themes-neutral-dark);
  --color-text-top-event-live: var(--themes-success-main);
  --color-text-top-event-result: var(--themes-error-main);
  --color-text-top-event-body: var(--themes-neutral-strong);
  --color-text-top-event-meta: var(--themes-neutral-dark);
  --color-text-top-event-info: var(--themes-info-main);
  --color-text-top-match-end: var(--isport-iron-700);
  --color-text-tab-normal-title: var(--color-text-default-title);
  --color-text-tab-inverted-title: var(--color-text-default-contrast);
  --color-text-tab-solid-normal-default: var(--color-text-default-title);
  --color-text-tab-solid-normal-hover: var(--themes-brand-primary-main);
  --color-text-tab-solid-inverted-default: var(--color-text-default-contrast);
  --color-text-tab-solid-inverted-hover: var(--themes-brand-primary-main);
  --color-text-input-normal-label-default: var(--themes-neutral-strong);
  --color-text-input-normal-label-hover: var(--themes-neutral-strong);
  --color-text-input-normal-label-focus: var(--themes-neutral-strong);
  --color-text-input-normal-label-active: var(--themes-neutral-strong);
  --color-text-input-normal-label-error: var(--themes-error-main);
  --color-text-input-normal-label-disabled: var(--themes-disabled-light);
  --color-text-input-normal-prefix-default: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-hover: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-focus: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-active: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-error: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-disabled: var(--themes-disabled-light);
  --color-text-input-normal-value-default: var(--isport-iron-400);
  --color-text-input-normal-value-hover: var(--themes-neutral-dark);
  --color-text-input-normal-value-focus: var(--themes-neutral-strong);
  --color-text-input-normal-value-active: var(--themes-neutral-strong);
  --color-text-input-normal-value-error: var(--themes-neutral-strong);
  --color-text-input-normal-value-disabled: var(--themes-disabled-light);
  --color-text-input-normal-sufix-default: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-hover: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-focus: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-active: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-error: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-disabled: var(--themes-disabled-light);
  --color-text-input-normal-description-default: var(--themes-neutral-dark);
  --color-text-input-normal-description-hover: var(--themes-neutral-dark);
  --color-text-input-normal-description-focus: var(--themes-neutral-dark);
  --color-text-input-normal-description-active: var(--themes-neutral-dark);
  --color-text-input-normal-description-error: var(--themes-error-main);
  --color-text-input-normal-description-disabled: var(--themes-disabled-light);
  --color-text-input-inverted-label-default: var(--themes-neutral-light);
  --color-text-input-inverted-label-hover: var(--themes-neutral-light);
  --color-text-input-inverted-label-focus: var(--themes-neutral-light);
  --color-text-input-inverted-label-active: var(--themes-neutral-light);
  --color-text-input-inverted-label-error: var(--themes-error-light);
  --color-text-input-inverted-label-disabled: var(--isport-iron-700);
  --color-text-input-inverted-prefix-default: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-hover: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-focus: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-active: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-error: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-disabled: var(--isport-iron-700);
  --color-text-input-inverted-value-default: var(--isport-iron-400);
  --color-text-input-inverted-value-hover: var(--themes-neutral-dark);
  --color-text-input-inverted-value-focus: var(--color-white-1000);
  --color-text-input-inverted-value-active: var(--color-white-1000);
  --color-text-input-inverted-value-error: var(--color-white-1000);
  --color-text-input-inverted-value-disabled: var(--isport-iron-700);
  --color-text-input-inverted-sufix-default: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-hover: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-focus: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-active: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-error: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-disabled: var(--isport-iron-700);
  --color-text-input-inverted-description-default: var(--themes-neutral-light);
  --color-text-input-inverted-description-hover: var(--themes-neutral-light);
  --color-text-input-inverted-description-focus: var(--themes-neutral-light);
  --color-text-input-inverted-description-active: var(--themes-neutral-light);
  --color-text-input-inverted-description-error: var(--themes-error-light);
  --color-text-input-inverted-description-disabled: var(--isport-iron-700);
  --color-text-text-area-normal-label-default: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-hover: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-focus: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-active: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-error: var(--themes-error-main);
  --color-text-text-area-normal-label-disabled: var(--themes-disabled-light);
  --color-text-text-area-normal-value-default: var(--themes-neutral-light);
  --color-text-text-area-normal-value-hover: var(--themes-neutral-dark);
  --color-text-text-area-normal-value-focus: var(--themes-neutral-strong);
  --color-text-text-area-normal-value-active: var(--themes-neutral-strong);
  --color-text-text-area-normal-value-error: var(--themes-neutral-strong);
  --color-text-text-area-normal-value-disabled: var(--themes-disabled-light);
  --color-text-text-area-normal-info-default: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-hover: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-focus: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-active: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-error: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-disabled: var(--themes-disabled-light);
  --color-text-text-area-normal-description-default: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-hover: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-focus: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-active: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-error: var(--themes-error-main);
  --color-text-text-area-normal-description-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-label-default: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-hover: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-focus: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-active: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-error: var(--themes-error-main);
  --color-text-text-area-inverted-label-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-value-default: var(--themes-neutral-light);
  --color-text-text-area-inverted-value-hover: var(--themes-neutral-dark);
  --color-text-text-area-inverted-value-focus: var(--themes-neutral-strong);
  --color-text-text-area-inverted-value-active: var(--themes-neutral-strong);
  --color-text-text-area-inverted-value-error: var(--themes-neutral-strong);
  --color-text-text-area-inverted-value-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-info-default: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-hover: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-focus: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-active: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-error: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-description-default: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-hover: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-focus: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-active: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-error: var(--themes-error-main);
  --color-text-text-area-inverted-description-disabled: var(--themes-disabled-light);
  --color-text-tipsport-bet-text: var(--color-text-default-title);
  --color-text-card-percent-text: var(--themes-neutral-strong);
  --color-text-card-ticket-text: var(--color-text-default-title);
  --color-text-card-ticket-description: var(--color-text-default-helper);
  --color-text-ticketarena-title: var(--color-text-default-title);
  --color-text-ticketarena-description: var(--color-text-default-subtitle);
  --color-text-flash-messages-info-normal-title: var(--themes-info-main);
  --color-text-flash-messages-info-inverted-title: var(--color-white-1000);
  --color-text-flash-messages-alert-normal-title: var(--themes-error-dark);
  --color-text-flash-messages-alert-inverted-title: var(--color-white-1000);
  --color-text-flash-messages-warning-normal-title: var(--isport-brick-500);
  --color-text-flash-messages-warning-inverted-title: var(--color-white-1000);
  --color-text-flash-messages-success-normal-title: var(--themes-success-dark);
  --color-text-flash-messages-success-inverted-title: var(--color-white-1000);
  --color-text-info-bar-video-text: var(--color-white-1000);
  --color-text-lineup-body: var(--themes-neutral-strong);
  --color-text-lineup-meta: var(--themes-neutral-dark);
  --color-text-radiobutton-default-unchecked-normal-default: var(--themes-neutral-strong);
  --color-text-radiobutton-default-unchecked-normal-hover: var(--themes-neutral-strong);
  --color-text-radiobutton-default-unchecked-normal-focus: var(--themes-neutral-strong);
  --color-text-radiobutton-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-default-unchecked-inverted-default: var(--color-white-1000);
  --color-text-radiobutton-default-unchecked-inverted-hover: var(--color-white-1000);
  --color-text-radiobutton-default-unchecked-inverted-focus: var(--color-white-1000);
  --color-text-radiobutton-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-default-checked-normal-default: var(--themes-neutral-strong);
  --color-text-radiobutton-default-checked-normal-hover: var(--themes-neutral-strong);
  --color-text-radiobutton-default-checked-normal-focus: var(--themes-neutral-strong);
  --color-text-radiobutton-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-default-checked-inverted-default: var(--color-white-1000);
  --color-text-radiobutton-default-checked-inverted-hover: var(--color-white-1000);
  --color-text-radiobutton-default-checked-inverted-focus: var(--color-white-1000);
  --color-text-radiobutton-default-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-unchecked-normal-default: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-normal-hover: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-normal-focus: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-unchecked-inverted-default: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-inverted-hover: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-inverted-focus: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-checked-normal-default: var(--themes-error-dark);
  --color-text-radiobutton-error-checked-normal-hover: var(--themes-error-dark);
  --color-text-radiobutton-error-checked-normal-focus: var(--themes-error-dark);
  --color-text-radiobutton-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-checked-inverted-default: var(--themes-error-main);
  --color-text-radiobutton-error-checked-inverted-hover: var(--themes-error-main);
  --color-text-radiobutton-error-checked-inverted-focus: var(--themes-error-main);
  --color-text-radiobutton-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-stats-body: var(--isport-iron-950);
  --color-text-stats-meta: var(--isport-iron-800);
  --color-text-tipsport-table-body: var(--color-text-default-title);
  --color-text-tipsport-table-description: var(--color-text-default-subtitle);
  --color-text-info-wrapper-default: var(--color-white-1000);
  --color-text-results-card-live: var(--themes-success-main);
  --color-text-results-card-result: var(--themes-error-main);
  --color-text-results-card-body: var(--themes-neutral-strong);
  --color-text-results-card-meta: var(--themes-neutral-dark);
  --color-text-results-card-info: var(--themes-info-main);
  --color-text-vote-strip-body: var(--themes-neutral-strong);
  --color-text-survey-title: var(--themes-neutral-strong);
  --color-text-survey-body: var(--themes-neutral-strong);
  --color-text-survey-meta: var(--themes-neutral-dark);
  --color-text-survey-confirm: var(--isport-brick-600);
  --color-text-schedule-live: var(--themes-success-main);
  --color-text-schedule-result: var(--themes-error-main);
  --color-text-schedule-body: var(--themes-neutral-strong);
  --color-text-schedule-meta: var(--themes-neutral-dark);
  --color-text-result-module-card-live: var(--themes-success-main);
  --color-text-result-module-card-result: var(--themes-error-main);
  --color-text-result-module-card-body: var(--themes-neutral-strong);
  --color-text-result-module-card-meta: var(--themes-neutral-dark);
  --color-text-online-box-body: var(--themes-neutral-strong);
  --color-text-title-section-normal-title: var(--themes-neutral-strong);
  --color-text-title-section-inverted-title: var(--color-white-1000);
  --color-text-team-card-body: var(--themes-neutral-strong);
  --color-text-videoplayer-body: var(--color-white-1000);
  --color-text-tooltip-body: var(--color-white-1000);
  --color-text-funds-card-title: var(--color-text-default-title);
  --color-text-funds-card-meta: var(--color-text-default-meta);
  --color-text-funds-card-percentages: var(--themes-success-main);
  --color-text-segmented-controls-default: var(--themes-neutral-strong);
  --color-text-segmented-controls-hover: var(--themes-neutral-strong);
  --color-text-segmented-controls-focus: var(--themes-neutral-strong);
  --color-text-segmented-controls-active: var(--themes-brand-primary-subtle);
  --color-text-segmented-controls-disabled: var(--themes-disabled-light);
  --color-text-stepper-inactive: var(--themes-disabled-main);
  --color-text-stepper-label: var(--themes-neutral-strong);
  --color-border-textlink-active-divider: var(--isport-spring-400);
  --color-border-textlink-active-category-focus: var(--themes-focus-main);
  --color-border-premium-card-normal: var(--themes-neutral-lighter);
  --color-border-button-primary-solid-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-border-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-border-button-primary-solid-inverted-outlined-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-border-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-primary-solid-inverted-contained-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-inverted-text-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-normal-contained-focus: var(--themes-focus-dark);
  --color-border-button-primary-solid-normal-outlined-default: var(--isport-spring-400);
  --color-border-button-primary-solid-normal-outlined-hover: var(--isport-spring-600);
  --color-border-button-primary-solid-normal-outlined-focus: var(--themes-focus-light);
  --color-border-button-primary-solid-normal-outlined-active: var(--isport-spring-300);
  --color-border-button-primary-solid-normal-outlined-disabled: var(--isport-iron-700);
  --color-border-button-primary-solid-normal-text-focus: var(--themes-focus-light);
  --color-border-button-primary-gradient-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-border-button-primary-gradient-inverted-outlined-hover: var(--isport-spring-300);
  --color-border-button-primary-gradient-inverted-outlined-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-inverted-outlined-active: var(--isport-spring-500);
  --color-border-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-primary-gradient-inverted-contained-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-inverted-text-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-normal-contained-focus: var(--themes-focus-dark);
  --color-border-button-primary-gradient-normal-outlined-default: var(--isport-spring-400);
  --color-border-button-primary-gradient-normal-outlined-hover: var(--isport-spring-500);
  --color-border-button-primary-gradient-normal-outlined-focus: var(--themes-focus-light);
  --color-border-button-primary-gradient-normal-outlined-active: var(--isport-spring-400);
  --color-border-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-primary-gradient-normal-text-focus: var(--themes-focus-light);
  --color-border-button-secondary-contained-focus: var(--themes-focus-main);
  --color-border-button-secondary-outlined-default: var(--themes-action-secondary-darker);
  --color-border-button-secondary-outlined-hover: var(--themes-action-secondary-dark);
  --color-border-button-secondary-outlined-focus: var(--themes-focus-main);
  --color-border-button-secondary-outlined-active: var(--themes-action-secondary-main);
  --color-border-button-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-secondary-text-focus: var(--themes-focus-main);
  --color-border-button-info-outlined-default: var(--themes-info-main);
  --color-border-button-info-outlined-hover: var(--themes-info-dark);
  --color-border-button-info-outlined-focus: var(--themes-focus-main);
  --color-border-button-info-outlined-active: var(--themes-info-light);
  --color-border-button-info-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-info-contained-focus: var(--themes-focus-main);
  --color-border-button-info-text-focus: var(--themes-focus-main);
  --color-border-button-error-outlined-default: var(--themes-error-main);
  --color-border-button-error-outlined-hover: var(--themes-error-dark);
  --color-border-button-error-outlined-focus: var(--themes-error-dark);
  --color-border-button-error-outlined-active: var(--themes-error-light);
  --color-border-button-error-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-error-contained-focus: var(--themes-focus-main);
  --color-border-button-error-text-focus: var(--themes-focus-main);
  --color-border-button-social-login-default: var(--themes-neutral-lighter);
  --color-border-button-social-login-hover: var(--themes-neutral-lighter);
  --color-border-button-social-login-focus: var(--themes-focus-main);
  --color-border-button-social-login-active: var(--themes-error-light);
  --color-border-button-social-login-disabled: var(--themes-disabled-light);
  --color-border-chip-inverted-contained-focus: var(--themes-focus-main);
  --color-border-chip-primary-outlined-default: var(--themes-neutral-main);
  --color-border-chip-primary-outlined-hover: var(--themes-action-primary-light);
  --color-border-chip-primary-outlined-focus: var(--themes-focus-main);
  --color-border-chip-primary-outlined-active: var(--themes-action-primary-light);
  --color-border-chip-primary-outlined-disabled: var(--themes-disabled-light);
  --color-border-chip-primary-contained-focus: var(--themes-focus-main);
  --color-border-chip-secondary-outlined-default: var(--themes-neutral-main);
  --color-border-chip-secondary-outlined-hover: var(--themes-neutral-main);
  --color-border-chip-secondary-outlined-focus: var(--themes-focus-main);
  --color-border-chip-secondary-outlined-active: var(--themes-neutral-main);
  --color-border-chip-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-border-chip-secondary-contained-focus: var(--themes-focus-main);
  --color-border-divider-default: var(--themes-neutral-light);
  --color-border-divider-label: var(--themes-neutral-light);
  --color-border-divider-chip: var(--themes-neutral-light);
  --color-border-divider-dark: var(--themes-neutral-strong);
  --color-border-divider-section: var(--themes-brand-primary-main);
  --color-border-divider-footer: var(--isport-spring-700);
  --color-border-icon-button-brand-solid-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-normal-outlined-default: var(--themes-action-primary-main);
  --color-border-icon-button-brand-solid-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-border-icon-button-brand-solid-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-normal-outlined-active: var(--themes-action-primary-light);
  --color-border-icon-button-brand-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-brand-solid-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-inverted-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-inverted-outlined-default: var(--themes-action-inverted-strong);
  --color-border-icon-button-brand-solid-inverted-outlined-hover: var(--themes-action-primary-dark);
  --color-border-icon-button-brand-solid-inverted-outlined-focus: var(--themes-focus-light);
  --color-border-icon-button-brand-solid-inverted-outlined-active: var(--themes-action-primary-light);
  --color-border-icon-button-brand-solid-inverted-outlined-disabled: var(--themes-action-inverted-darker);
  --color-border-icon-button-brand-solid-inverted-text-focus: var(--themes-focus-light);
  --color-border-icon-button-secondary-solid-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-secondary-solid-normal-outlined-default: var(--themes-action-secondary-darker);
  --color-border-icon-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-dark);
  --color-border-icon-button-secondary-solid-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-secondary-solid-normal-outlined-active: var(--themes-action-secondary-light);
  --color-border-icon-button-secondary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-secondary-solid-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-normal-outlined-default: var(--themes-action-secondary-main);
  --color-border-icon-button-primary-solid-normal-outlined-hover: var(--themes-action-secondary-dark);
  --color-border-icon-button-primary-solid-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-normal-outlined-active: var(--themes-action-secondary-light);
  --color-border-icon-button-primary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-primary-solid-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-inverted-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-inverted-outlined-default: var(--themes-action-secondary-on-secondary);
  --color-border-icon-button-primary-solid-inverted-outlined-hover: var(--isport-spring-500);
  --color-border-icon-button-primary-solid-inverted-outlined-focus: var(--themes-focus-light);
  --color-border-icon-button-primary-solid-inverted-outlined-active: var(--isport-spring-500);
  --color-border-icon-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-dark);
  --color-border-icon-button-primary-solid-inverted-text-focus: var(--themes-focus-light);
  --color-border-icon-button-primary-gradient-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-normal-outlined-default: var(--themes-action-secondary-main);
  --color-border-icon-button-primary-gradient-normal-outlined-hover: var(--themes-action-secondary-dark);
  --color-border-icon-button-primary-gradient-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-normal-outlined-active: var(--themes-action-secondary-light);
  --color-border-icon-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-primary-gradient-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-inverted-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-inverted-outlined-default: var(--themes-action-secondary-on-secondary);
  --color-border-icon-button-primary-gradient-inverted-outlined-hover: var(--isport-spring-500);
  --color-border-icon-button-primary-gradient-inverted-outlined-focus: var(--themes-focus-light);
  --color-border-icon-button-primary-gradient-inverted-outlined-active: var(--isport-spring-500);
  --color-border-icon-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-darker);
  --color-border-icon-button-primary-gradient-inverted-text-focus: var(--themes-focus-light);
  --color-border-pagination-default: var(--themes-neutral-light);
  --color-border-pagination-hover: var(--themes-neutral-light);
  --color-border-pagination-active: var(--themes-action-primary-main);
  --color-border-pagination-focus: var(--themes-focus-main);
  --color-border-pagination-disabled: var(--themes-neutral-lighter);
  --color-border-tag-solid-normal-default: var(--themes-neutral-light);
  --color-border-tag-solid-normal-hover: var(--themes-action-primary-light);
  --color-border-tag-solid-normal-focus: var(--themes-action-primary-light);
  --color-border-tag-solid-normal-active: var(--themes-action-primary-light);
  --color-border-tag-solid-normal-disabled: var(--themes-disabled-lighter);
  --color-border-tag-solid-inverted-default: var(--isport-iron-700);
  --color-border-tag-solid-inverted-hover: var(--isport-spring-500);
  --color-border-tag-solid-inverted-focus: var(--isport-spring-400);
  --color-border-tag-solid-inverted-disabled: var(--isport-iron-900);
  --color-border-tag-gradient-normal-default: var(--themes-neutral-light);
  --color-border-tag-gradient-normal-hover: var(--themes-action-primary-light);
  --color-border-tag-gradient-normal-focus: var(--themes-action-primary-light);
  --color-border-tag-gradient-normal-active: var(--themes-action-primary-light);
  --color-border-tag-gradient-normal-disabled: var(--themes-disabled-lighter);
  --color-border-tag-gradient-inverted-default: var(--isport-iron-700);
  --color-border-tag-gradient-inverted-hover: var(--isport-spring-500);
  --color-border-tag-gradient-inverted-focus: var(--isport-spring-400);
  --color-border-tag-gradient-inverted-disabled: var(--isport-iron-900);
  --color-border-badge-default: var(--color-white-1000);
  --color-border-badgepill-outlined-success: var(--themes-success-light);
  --color-border-badgepill-outlined-warning: var(--themes-warning-light);
  --color-border-badgepill-outlined-error: var(--themes-error-light);
  --color-border-badgepill-outlined-info: var(--themes-info-main);
  --color-border-badgepill-outlined-disabled: var(--themes-disabled-light);
  --color-border-badgepill-outlined-secondary: var(--themes-brand-secondary-main);
  --color-border-badgepill-outlined-primary: var(--isport-spring-400);
  --color-border-default-lighter: var(--themes-neutral-subtle);
  --color-border-default-subtle: var(--themes-neutral-subtle);
  --color-border-default-section: var(--themes-brand-primary-light);
  --color-border-default-dark: var(--themes-neutral-main);
  --color-border-header-link-solid-normal-active: var(--themes-neutral-strong);
  --color-border-header-link-solid-normal-focus: var(--themes-neutral-strong);
  --color-border-header-link-solid-inverted-active: var(--themes-neutral-strong);
  --color-border-header-link-solid-inverted-focus: var(--themes-neutral-strong);
  --color-border-avatar-default: var(--color-white-1000);
  --color-border-vote-strip-default: var(--themes-brand-primary-main);
  --color-border-vote-strip-hover: var(--themes-brand-primary-dark);
  --color-border-vote-strip-focus: var(--themes-focus-main);
  --color-border-dropdown-focus: var(--themes-focus-main);
  --color-border-search-field-normal-default: var(--themes-neutral-light);
  --color-border-search-field-normal-hover: var(--isport-iron-600);
  --color-border-search-field-normal-focus: var(--isport-spring-500);
  --color-border-search-field-normal-filled: var(--isport-iron-600);
  --color-border-search-field-inverted-default: var(--isport-iron-600);
  --color-border-search-field-inverted-hover: var(--isport-spring-400);
  --color-border-search-field-inverted-focus: var(--isport-spring-500);
  --color-border-search-field-inverted-filled: var(--isport-iron-600);
  --color-border-modal-default: var(--themes-neutral-lighter);
  --color-border-registration-subtle: var(--themes-neutral-subtle);
  --color-border-registration-lighter: var(--themes-neutral-lighter);
  --color-border-button-betting-primary-normal-contained-focus: var(--themes-focus-dark);
  --color-border-button-betting-primary-normal-outlined-default: var(--tipsport-tip-blue-700);
  --color-border-button-betting-primary-normal-outlined-hover: var(--tipsport-tip-blue-800);
  --color-border-button-betting-primary-normal-outlined-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-normal-outlined-active: var(--tipsport-tip-blue-600);
  --color-border-button-betting-primary-normal-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-betting-primary-normal-text-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-inverted-contained-focus: var(--themes-focus-dark);
  --color-border-button-betting-primary-inverted-outlined-default: var(--tipsport-tip-blue-700);
  --color-border-button-betting-primary-inverted-outlined-hover: var(--tipsport-tip-blue-800);
  --color-border-button-betting-primary-inverted-outlined-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-inverted-outlined-active: var(--tipsport-tip-blue-600);
  --color-border-button-betting-primary-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-betting-primary-inverted-text-focus: var(--themes-focus-light);
  --color-border-button-betting-secondary-contained-focus: var(--themes-focus-dark);
  --color-border-button-betting-secondary-outlined-default: var(--tipsport-tip-blue-700);
  --color-border-button-betting-secondary-outlined-hover: var(--tipsport-tip-blue-800);
  --color-border-button-betting-secondary-outlined-focus: var(--themes-focus-main);
  --color-border-button-betting-secondary-outlined-active: var(--tipsport-tip-blue-600);
  --color-border-button-betting-secondary-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-betting-secondary-text-focus: var(--themes-focus-main);
  --color-border-results-default: var(--isport-iron-950-alfa-200);
  --color-border-title-section-default: var(--themes-neutral-light);
  --color-border-result-cells-default: var(--isport-iron-950-alfa-200);
  --color-border-goal-cells-default: var(--isport-iron-950-alfa-200);
  --color-border-discussion-card-default: var(--themes-neutral-lighter);
  --color-border-newsletter-box-default: var(--themes-neutral-lighter);
  --color-border-checkbox-default-unchecked-normal-default: var(--themes-neutral-darker);
  --color-border-checkbox-default-unchecked-normal-hover: var(--themes-neutral-light);
  --color-border-checkbox-default-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-checkbox-default-unchecked-inverted-default: var(--themes-neutral-light);
  --color-border-checkbox-default-unchecked-inverted-hover: var(--isport-iron-900);
  --color-border-checkbox-default-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-checkbox-default-checked-normal-hover: var(--themes-neutral-light);
  --color-border-checkbox-default-checked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-default-checked-inverted-hover: var(--isport-iron-900);
  --color-border-checkbox-default-checked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-default-all-normal-hover: var(--themes-neutral-light);
  --color-border-checkbox-default-all-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-default-all-inverted-hover: var(--isport-iron-900);
  --color-border-checkbox-default-all-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-error-unchecked-normal-default: var(--themes-error-main);
  --color-border-checkbox-error-unchecked-normal-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-checkbox-error-unchecked-inverted-default: var(--themes-error-main);
  --color-border-checkbox-error-unchecked-inverted-hover: var(--themes-error-strong);
  --color-border-checkbox-error-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-checkbox-error-checked-normal-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-checked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-error-checked-inverted-hover: var(--themes-error-strong);
  --color-border-checkbox-error-checked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-error-all-normal-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-all-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-error-all-inverted-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-all-inverted-focus: var(--themes-focus-main);
  --color-border-commentary-box-default: var(--color-border-divider-default);
  --color-border-commentary-box-goal: var(--color-green-02-300);
  --color-border-input-normal-default: var(--isport-iron-300);
  --color-border-input-normal-hover: var(--themes-neutral-dark);
  --color-border-input-normal-focus: var(--themes-focus-main);
  --color-border-input-normal-active: var(--isport-iron-300);
  --color-border-input-normal-error: var(--themes-error-main);
  --color-border-input-normal-disabled: var(--themes-disabled-light);
  --color-border-input-inverted-default: var(--isport-iron-600);
  --color-border-input-inverted-hover: var(--isport-iron-200);
  --color-border-input-inverted-focus: var(--themes-focus-light);
  --color-border-input-inverted-active: var(--isport-iron-600);
  --color-border-input-inverted-error: var(--themes-error-light);
  --color-border-input-inverted-disabled: var(--themes-disabled-darker);
  --color-border-text-area-normal-default: var(--themes-neutral-light);
  --color-border-text-area-normal-hover: var(--themes-neutral-dark);
  --color-border-text-area-normal-focus: var(--themes-focus-main);
  --color-border-text-area-normal-active: var(--themes-neutral-dark);
  --color-border-text-area-normal-error: var(--themes-error-main);
  --color-border-text-area-normal-disabled: var(--themes-disabled-light);
  --color-border-text-area-inverted-default: var(--isport-iron-300);
  --color-border-text-area-inverted-hover: var(--themes-neutral-lighter);
  --color-border-text-area-inverted-focus: var(--themes-focus-main);
  --color-border-text-area-inverted-active: var(--themes-neutral-lighter);
  --color-border-text-area-inverted-error: var(--themes-error-main);
  --color-border-text-area-inverted-disabled: var(--themes-disabled-light);
  --color-border-lineup-playground: var(--color-white-1000);
  --color-border-radiobutton-default-unchecked-normal-default: var(--themes-neutral-darker);
  --color-border-radiobutton-default-unchecked-normal-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-default-unchecked-inverted-default: var(--themes-neutral-light);
  --color-border-radiobutton-default-unchecked-inverted-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-radiobutton-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-default-checked-normal-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-checked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-default-checked-inverted-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-checked-inverted-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-unchecked-normal-default: var(--themes-error-main);
  --color-border-radiobutton-error-unchecked-normal-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-error-unchecked-inverted-default: var(--themes-error-main);
  --color-border-radiobutton-error-unchecked-inverted-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-error-checked-normal-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-checked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-checked-inverted-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-checked-inverted-focus: var(--themes-focus-main);
  --color-border-ads-default: #d0d0d0;
  --color-border-tab-normal-default: var(--themes-neutral-light);
  --color-border-tab-inverted-default: var(--isport-iron-800);
  --color-border-tab-solid-inverted-default: var(--isport-iron-800);
  --color-border-tab-solid-inverted-hover: var(--themes-brand-primary-main);
  --color-border-tab-solid-normal-default: var(--themes-neutral-light);
  --color-border-tab-solid-normal-hover: var(--themes-brand-primary-main);
  --color-border-result-module-card-default: var(--isport-iron-950-alfa-200);
  --color-border-result-module-default: var(--isport-iron-950-alfa-200);
  --color-border-segmented-controls-default: var(--themes-neutral-light);
  --color-border-segmented-controls-focus: var(--themes-focus-main);
  --color-border-stepper-active: var(--themes-brand-primary-main);
  --color-border-stepper-hover: var(--themes-brand-primary-darker);
  --color-border-stepper-inactive: var(--themes-disabled-main);
  --color-border-spinner-background: var(--isport-spring-100);
  --color-border-spinner-spinner: var(--isport-spring-500);
  --color-icon-flash-messages-info-normal-icon: var(--themes-info-main);
  --color-icon-flash-messages-info-inverted-icon: var(--color-white-1000);
  --color-icon-flash-messages-alert-normal-icon: var(--themes-error-dark);
  --color-icon-flash-messages-alert-inverted-icon: var(--color-white-1000);
  --color-icon-flash-messages-warning-normal-icon: var(--isport-brick-500);
  --color-icon-flash-messages-warning-inverted-icon: var(--color-white-1000);
  --color-icon-flash-messages-success-normal-icon: var(--themes-success-dark);
  --color-icon-flash-messages-success-inverted-icon: var(--color-white-1000);
  --color-icon-avatar-default: var(--themes-neutral-dark);
  --color-icon-gallery-arrows-default: var(--themes-neutral-subtle);
  --color-icon-textlink-menu-item-default: var(--isport-iron-50);
  --color-icon-textlink-menu-item-hover: #fff;
  --color-icon-textlink-menu-item-active: #fff;
  --color-icon-textlink-menu-item-focus: var(--isport-spring-300);
  --color-icon-textlink-menu-item-disabled: var(--isport-iron-700);
  --color-icon-textlink-menu-item-solid-default: var(--isport-iron-50);
  --color-icon-textlink-menu-item-solid-hover: #fff;
  --color-icon-textlink-menu-item-solid-active: #fff;
  --color-icon-textlink-menu-item-solid-focus: var(--isport-spring-300);
  --color-icon-textlink-menu-item-solid-disabled: var(--isport-iron-700);
  --color-icon-textlink-menu-item-ctagory-default: var(--isport-iron-50);
  --color-icon-textlink-menu-item-ctagory-hover: #fff;
  --color-icon-textlink-menu-item-ctagory-active: #fff;
  --color-icon-textlink-menu-item-ctagory-focus: var(--isport-spring-300);
  --color-icon-textlink-menu-item-ctagory-disabled: var(--isport-iron-700);
  --color-icon-textlink-category-default: var(--isport-iron-50);
  --color-icon-textlink-category-solid-default: var(--isport-iron-50);
  --color-icon-textlink-category-solid-hover: var(--isport-iron-50);
  --color-icon-textlink-category-solid-active: var(--isport-iron-50);
  --color-icon-textlink-category-solid-focus: var(--isport-brick-500);
  --color-icon-textlink-category-solid-disabled: var(--themes-disabled-light);
  --color-icon-textlink-category-hover: var(--isport-iron-50);
  --color-icon-textlink-category-active: var(--isport-iron-50);
  --color-icon-textlink-category-focus: var(--isport-brick-500);
  --color-icon-textlink-category-disabled: var(--themes-disabled-light);
  --color-icon-textlink-category-gradient-default: var(--isport-iron-50);
  --color-icon-textlink-category-gradient-hover: var(--isport-iron-50);
  --color-icon-textlink-category-gradient-active: var(--isport-iron-50);
  --color-icon-textlink-category-gradient-focus: var(--isport-brick-500);
  --color-icon-textlink-category-gradient-disabled: var(--themes-disabled-light);
  --color-icon-textlink-contained-default: var(--isport-iron-50);
  --color-icon-textlink-contained-solid-default: var(--isport-iron-50);
  --color-icon-textlink-contained-solid-hover: var(--isport-iron-50);
  --color-icon-textlink-contained-solid-active: var(--isport-iron-50);
  --color-icon-textlink-contained-solid-focus: var(--isport-brick-500);
  --color-icon-textlink-contained-solid-disabled: var(--themes-disabled-light);
  --color-icon-textlink-contained-hover: var(--isport-iron-50);
  --color-icon-textlink-contained-active: var(--isport-iron-50);
  --color-icon-textlink-contained-focus: var(--isport-brick-500);
  --color-icon-textlink-contained-disabled: var(--themes-disabled-light);
  --color-icon-textlink-contained-gradient-default: var(--isport-iron-50);
  --color-icon-textlink-contained-gradient-hover: var(--isport-iron-50);
  --color-icon-textlink-contained-gradient-active: var(--isport-iron-50);
  --color-icon-textlink-contained-gradient-focus: var(--isport-brick-500);
  --color-icon-textlink-contained-gradient-disabled: var(--themes-disabled-light);
  --color-icon-textlink-stand-alone-default: var(--isport-iron-50);
  --color-icon-textlink-stand-alone-hover: var(--isport-iron-50);
  --color-icon-textlink-stand-alone-active: var(--isport-iron-50);
  --color-icon-textlink-stand-alone-focus: var(--isport-brick-500);
  --color-icon-textlink-stand-alone-disabled: var(--themes-disabled-light);
  --color-icon-textlink-megamenu-default: var(--isport-iron-50);
  --color-icon-textlink-megamenu-hover: var(--isport-iron-50);
  --color-icon-textlink-megamenu-active: var(--isport-iron-50);
  --color-icon-textlink-megamenu-focus: var(--isport-brick-500);
  --color-icon-textlink-megamenu-disabled: var(--themes-disabled-light);
  --color-icon-list-default: var(--themes-neutral-strong);
  --color-icon-list-brand: var(--isport-spring-500);
  --color-icon-list-inverted: var(--color-white-1000);
  --color-icon-list-positive: var(--color-green-02-500);
  --color-icon-list-negative: var(--color-surface-error-main);
  --color-icon-button-primary-solid-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-icon-button-primary-solid-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-solid-inverted-contained-default: var(--themes-action-primary-main);
  --color-icon-button-primary-solid-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-inverted-contained-active: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-inverted-contained-disabled: var(--themes-action-inverted-darker);
  --color-icon-button-primary-solid-inverted-text-default: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-text-hover: var(--themes-action-primary-main);
  --color-icon-button-primary-solid-inverted-text-focus: var(--themes-action-primary-light);
  --color-icon-button-primary-solid-inverted-text-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-text-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-solid-normal-contained-default: var(--isport-iron-950);
  --color-icon-button-primary-solid-normal-contained-hover: var(--isport-iron-950);
  --color-icon-button-primary-solid-normal-contained-focus: var(--isport-iron-950);
  --color-icon-button-primary-solid-normal-contained-active: var(--isport-iron-950);
  --color-icon-button-primary-solid-normal-contained-disabled: var(--isport-iron-500);
  --color-icon-button-primary-solid-normal-outlined-default: var(--isport-spring-400);
  --color-icon-button-primary-solid-normal-outlined-hover: var(--isport-spring-500);
  --color-icon-button-primary-solid-normal-outlined-focus: var(--isport-spring-600);
  --color-icon-button-primary-solid-normal-outlined-active: var(--isport-spring-400);
  --color-icon-button-primary-solid-normal-outlined-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-solid-normal-text-default: var(--isport-spring-400);
  --color-icon-button-primary-solid-normal-text-hover: var(--isport-spring-500);
  --color-icon-button-primary-solid-normal-text-focus: var(--isport-spring-600);
  --color-icon-button-primary-solid-normal-text-active: var(--isport-spring-400);
  --color-icon-button-primary-solid-normal-text-disabled: var(--isport-iron-700);
  --color-icon-button-primary-gradient-inverted-contained-default: var(--isport-iron-950);
  --color-icon-button-primary-gradient-inverted-contained-hover: var(--isport-iron-950);
  --color-icon-button-primary-gradient-inverted-contained-focus: var(--isport-iron-950);
  --color-icon-button-primary-gradient-inverted-contained-active: var(--isport-iron-950);
  --color-icon-button-primary-gradient-inverted-contained-disabled: var(--isport-iron-500);
  --color-icon-button-primary-gradient-inverted-outlined-default: var(--isport-spring-400);
  --color-icon-button-primary-gradient-inverted-outlined-hover: var(--isport-spring-300);
  --color-icon-button-primary-gradient-inverted-outlined-focus: var(--isport-spring-300);
  --color-icon-button-primary-gradient-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-gradient-inverted-text-default: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-text-hover: var(--isport-spring-400);
  --color-icon-button-primary-gradient-inverted-text-focus: var(--isport-spring-300);
  --color-icon-button-primary-gradient-inverted-text-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-text-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-gradient-normal-contained-default: var(--isport-iron-950);
  --color-icon-button-primary-gradient-normal-contained-hover: var(--isport-iron-950);
  --color-icon-button-primary-gradient-normal-contained-focus: var(--isport-iron-950);
  --color-icon-button-primary-gradient-normal-contained-active: var(--isport-iron-950);
  --color-icon-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-gradient-normal-outlined-default: var(--isport-spring-400);
  --color-icon-button-primary-gradient-normal-outlined-hover: var(--isport-spring-500);
  --color-icon-button-primary-gradient-normal-outlined-focus: var(--isport-spring-600);
  --color-icon-button-primary-gradient-normal-outlined-active: var(--isport-spring-400);
  --color-icon-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-gradient-normal-text-default: var(--isport-spring-400);
  --color-icon-button-primary-gradient-normal-text-hover: var(--isport-spring-500);
  --color-icon-button-primary-gradient-normal-text-focus: var(--isport-spring-600);
  --color-icon-button-primary-gradient-normal-text-active: var(--isport-spring-400);
  --color-icon-button-primary-gradient-normal-text-disabled: var(--themes-disabled-main);
  --color-icon-button-secondary-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-secondary-outlined-default: var(--themes-action-secondary-darker);
  --color-icon-button-secondary-outlined-hover: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-outlined-focus: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-outlined-active: var(--themes-action-secondary-main);
  --color-icon-button-secondary-outlined-disabled: var(--color-icon-default-disabled);
  --color-icon-button-secondary-text-default: var(--themes-action-secondary-darker);
  --color-icon-button-secondary-text-hover: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-text-focus: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-text-active: var(--themes-action-secondary-main);
  --color-icon-button-secondary-text-disabled: var(--themes-disabled-light);
  --color-icon-button-info-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-info-outlined-default: var(--themes-info-main);
  --color-icon-button-info-outlined-hover: var(--themes-info-dark);
  --color-icon-button-info-outlined-focus: var(--themes-info-dark);
  --color-icon-button-info-outlined-active: var(--themes-info-light);
  --color-icon-button-info-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-info-text-default: var(--themes-info-main);
  --color-icon-button-info-text-hover: var(--themes-info-dark);
  --color-icon-button-info-text-focus: var(--themes-info-dark);
  --color-icon-button-info-text-active: var(--themes-info-light);
  --color-icon-button-info-text-disabled: var(--themes-disabled-light);
  --color-icon-button-error-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-error-outlined-default: var(--themes-error-main);
  --color-icon-button-error-outlined-hover: var(--themes-error-dark);
  --color-icon-button-error-outlined-focus: var(--themes-error-dark);
  --color-icon-button-error-outlined-active: var(--themes-error-light);
  --color-icon-button-error-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-error-text-default: var(--themes-error-main);
  --color-icon-button-error-text-hover: var(--themes-error-dark);
  --color-icon-button-error-text-focus: var(--themes-error-dark);
  --color-icon-button-error-text-active: var(--themes-error-light);
  --color-icon-button-error-text-disabled: var(--themes-disabled-light);
  --color-icon-button-social-login-disabled: var(--themes-disabled-light);
  --color-icon-chip-inverted-contained-default: var(--color-white-1000);
  --color-icon-chip-inverted-contained-hover: var(--color-white-1000);
  --color-icon-chip-inverted-contained-focus: var(--color-white-1000);
  --color-icon-chip-inverted-contained-active: var(--color-white-1000);
  --color-icon-chip-inverted-contained-disabled: var(--isport-iron-600);
  --color-icon-chip-primary-contained-default: var(--themes-neutral-darker);
  --color-icon-chip-primary-contained-hover: var(--themes-neutral-dark);
  --color-icon-chip-primary-contained-focus: var(--themes-action-primary-main);
  --color-icon-chip-primary-contained-active: var(--themes-action-primary-main);
  --color-icon-chip-primary-contained-disabled: var(--themes-disabled-light);
  --color-icon-chip-primary-outlined-default: var(--themes-neutral-darker);
  --color-icon-chip-primary-outlined-hover: var(--themes-action-primary-main);
  --color-icon-chip-primary-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-chip-primary-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-chip-primary-outlined-disabled: var(--themes-disabled-light);
  --color-icon-chip-secondary-contained-default: var(--themes-neutral-darker);
  --color-icon-chip-secondary-contained-hover: var(--themes-neutral-dark);
  --color-icon-chip-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-contained-disabled: var(--themes-disabled-light);
  --color-icon-chip-secondary-outlined-default: var(--themes-neutral-darker);
  --color-icon-chip-secondary-outlined-hover: var(--themes-neutral-dark);
  --color-icon-chip-secondary-outlined-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-outlined-active: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-normal-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-normal-outlined-default: var(--themes-action-primary-main);
  --color-icon-icon-button-brand-solid-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-outlined-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-normal-text-default: var(--themes-action-primary-main);
  --color-icon-icon-button-brand-solid-normal-text-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-text-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-text-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-inverted-contained-default: var(--themes-action-primary-main);
  --color-icon-icon-button-brand-solid-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-contained-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-inverted-contained-disabled: var(--themes-action-inverted-darker);
  --color-icon-icon-button-brand-solid-inverted-outlined-default: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-outlined-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-outlined-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-inverted-outlined-disabled: var(--themes-action-inverted-darker);
  --color-icon-icon-button-brand-solid-inverted-text-default: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-hover: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-focus: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-active: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-disabled: var(--themes-action-inverted-darker);
  --color-icon-icon-button-secondary-solid-normal-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-secondary-solid-normal-outlined-default: var(--themes-action-secondary-darker);
  --color-icon-icon-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-outlined-focus: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-outlined-active: var(--themes-action-secondary-light);
  --color-icon-icon-button-secondary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-secondary-solid-normal-text-default: var(--themes-action-secondary-darker);
  --color-icon-icon-button-secondary-solid-normal-text-hover: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-text-focus: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-text-active: var(--themes-action-secondary-light);
  --color-icon-icon-button-secondary-solid-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-normal-contained-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-normal-contained-hover: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-normal-contained-focus: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-normal-contained-active: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-normal-contained-disabled: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-normal-outlined-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-normal-outlined-hover: var(--isport-spring-400);
  --color-icon-icon-button-primary-solid-normal-outlined-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-normal-outlined-active: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-normal-outlined-disabled: var(--isport-iron-800);
  --color-icon-icon-button-primary-solid-normal-text-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-normal-text-hover: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-normal-text-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-normal-text-active: var(--isport-spring-400);
  --color-icon-icon-button-primary-solid-normal-text-disabled: var(--isport-iron-800);
  --color-icon-icon-button-primary-solid-inverted-contained-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-inverted-contained-hover: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-inverted-contained-focus: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-inverted-contained-active: var(--isport-iron-950);
  --color-icon-icon-button-primary-solid-inverted-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-inverted-outlined-default: var(--isport-iron-50);
  --color-icon-icon-button-primary-solid-inverted-outlined-hover: var(--isport-spring-400);
  --color-icon-icon-button-primary-solid-inverted-outlined-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-inverted-outlined-active: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-inverted-outlined-disabled: var(--isport-iron-800);
  --color-icon-icon-button-primary-solid-inverted-text-default: var(--isport-iron-50);
  --color-icon-icon-button-primary-solid-inverted-text-hover: var(--isport-spring-400);
  --color-icon-icon-button-primary-solid-inverted-text-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-inverted-text-active: var(--isport-spring-500);
  --color-icon-icon-button-primary-solid-inverted-text-disabled: var(--isport-iron-800);
  --color-icon-icon-button-primary-gradient-normal-contained-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-normal-contained-hover: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-normal-contained-focus: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-normal-contained-active: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-main);
  --color-icon-icon-button-primary-gradient-normal-outlined-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-normal-outlined-hover: var(--isport-spring-400);
  --color-icon-icon-button-primary-gradient-normal-outlined-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-normal-outlined-active: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-main);
  --color-icon-icon-button-primary-gradient-normal-text-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-normal-text-hover: var(--isport-spring-400);
  --color-icon-icon-button-primary-gradient-normal-text-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-normal-text-active: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-normal-text-disabled: var(--themes-disabled-main);
  --color-icon-icon-button-primary-gradient-inverted-contained-default: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-inverted-contained-hover: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-inverted-contained-focus: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-inverted-contained-active: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-inverted-contained-disabled: var(--isport-iron-950);
  --color-icon-icon-button-primary-gradient-inverted-outlined-default: var(--isport-iron-50);
  --color-icon-icon-button-primary-gradient-inverted-outlined-hover: var(--isport-spring-400);
  --color-icon-icon-button-primary-gradient-inverted-outlined-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-inverted-outlined-active: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-inverted-outlined-disabled: var(--isport-iron-800);
  --color-icon-icon-button-primary-gradient-inverted-text-default: var(--isport-iron-50);
  --color-icon-icon-button-primary-gradient-inverted-text-hover: var(--isport-spring-400);
  --color-icon-icon-button-primary-gradient-inverted-text-focus: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-inverted-text-active: var(--isport-spring-500);
  --color-icon-icon-button-primary-gradient-inverted-text-disabled: var(--isport-iron-800);
  --color-icon-badge-success: var(--themes-success-on-success);
  --color-icon-badge-warning: var(--themes-warning-on-warning);
  --color-icon-badge-error: var(--themes-error-on-error);
  --color-icon-badge-info: var(--themes-info-on-info);
  --color-icon-badge-disabled: var(--themes-disabled-light);
  --color-icon-badge-secondary: var(--themes-brand-secondary-on-secondary);
  --color-icon-badge-primary: var(--isport-iron-950);
  --color-icon-badgepill-soft-success: var(--themes-success-dark);
  --color-icon-badgepill-soft-warning: var(--themes-warning-main);
  --color-icon-badgepill-soft-error: var(--themes-error-main);
  --color-icon-badgepill-soft-info: var(--themes-info-dark);
  --color-icon-badgepill-soft-disabled: var(--themes-disabled-light);
  --color-icon-badgepill-soft-secondary: var(--themes-brand-secondary-darker);
  --color-icon-badgepill-soft-primary: var(--isport-spring-800);
  --color-icon-badgepill-solid-success: var(--themes-success-on-success);
  --color-icon-badgepill-solid-warning: var(--themes-warning-on-warning);
  --color-icon-badgepill-solid-error: var(--themes-error-on-error);
  --color-icon-badgepill-solid-info: var(--themes-info-on-info);
  --color-icon-badgepill-solid-disabled: var(--themes-disabled-subtle);
  --color-icon-badgepill-solid-secondary: var(--themes-brand-secondary-on-secondary);
  --color-icon-badgepill-solid-primary: var(--isport-iron-950);
  --color-icon-badgepill-outlined-success: var(--themes-success-main);
  --color-icon-badgepill-outlined-warning: var(--themes-warning-dark);
  --color-icon-badgepill-outlined-error: var(--themes-error-main);
  --color-icon-badgepill-outlined-info: var(--themes-info-dark);
  --color-icon-badgepill-outlined-disabled: var(--themes-disabled-light);
  --color-icon-badgepill-outlined-secondary: var(--themes-brand-secondary-dark);
  --color-icon-badgepill-outlined-primary: var(--isport-spring-500);
  --color-icon-notification-default: var(--themes-neutral-dark);
  --color-icon-accordion-default: var(--themes-neutral-strong);
  --color-icon-accordion-hover: var(--isport-spring-500);
  --color-icon-play-button-default: var(--color-white-1000);
  --color-icon-category-link-solid-default: var(--themes-action-primary-main);
  --color-icon-category-link-solid-hover: var(--themes-action-primary-dark);
  --color-icon-category-link-solid-active: var(--themes-neutral-strong);
  --color-icon-category-link-solid-focus: var(--themes-neutral-strong);
  --color-icon-category-link-solid-disabled: var(--themes-neutral-light);
  --color-icon-category-link-gradient-default: var(--themes-action-primary-main);
  --color-icon-category-link-gradient-hover: var(--themes-action-primary-dark);
  --color-icon-category-link-gradient-active: var(--themes-neutral-strong);
  --color-icon-category-link-gradient-focus: var(--themes-neutral-strong);
  --color-icon-category-link-gradient-disabled: var(--themes-neutral-light);
  --color-icon-megamenu-link-default: var(--themes-neutral-strong);
  --color-icon-megamenu-link-title-default: var(--themes-neutral-strong);
  --color-icon-megamenu-link-title-hover: var(--themes-action-primary-main);
  --color-icon-megamenu-link-title-active: var(--themes-action-primary-lighter);
  --color-icon-megamenu-link-title-focus: var(--themes-neutral-strong);
  --color-icon-megamenu-link-title-disabled: var(--themes-neutral-light);
  --color-icon-megamenu-link-hover: var(--themes-action-primary-main);
  --color-icon-megamenu-link-active: var(--themes-action-primary-lighter);
  --color-icon-megamenu-link-focus: var(--themes-neutral-strong);
  --color-icon-megamenu-link-disabled: var(--themes-neutral-light);
  --color-icon-megamenu-link-text-link-default: var(--themes-neutral-strong);
  --color-icon-megamenu-link-text-link-hover: var(--themes-action-primary-main);
  --color-icon-megamenu-link-text-link-active: var(--themes-action-primary-lighter);
  --color-icon-megamenu-link-text-link-focus: var(--themes-neutral-strong);
  --color-icon-megamenu-link-text-link-disabled: var(--themes-neutral-light);
  --color-icon-header-link-default: var(--isport-iron-50);
  --color-icon-header-link-hover: var(--themes-action-primary-light);
  --color-icon-header-link-active: var(--themes-neutral-strong);
  --color-icon-header-link-focus: var(--themes-neutral-strong);
  --color-icon-header-link-disabled: var(--themes-neutral-light);
  --color-icon-quotation-box-default: var(--themes-neutral-strong);
  --color-icon-text-link-default: var(--themes-neutral-strong);
  --color-icon-text-link-hover: var(--isport-brick-500);
  --color-icon-text-link-visited: var(--isport-flame-600);
  --color-icon-text-link-focus: var(--themes-neutral-strong);
  --color-icon-text-link-disabled: var(--themes-neutral-light);
  --color-icon-text-link-stand-alone-primary-solid-normal-default: var(--isport-brick-500);
  --color-icon-text-link-stand-alone-primary-solid-normal-hover: var(--isport-brick-600);
  --color-icon-text-link-stand-alone-primary-solid-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-primary-solid-normal-focus: var(--isport-brick-500);
  --color-icon-text-link-stand-alone-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-primary-solid-inverted-default: var(--color-white-1000);
  --color-icon-text-link-stand-alone-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-icon-text-link-stand-alone-primary-solid-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-primary-solid-inverted-focus: var(--color-white-1000);
  --color-icon-text-link-stand-alone-primary-solid-inverted-disabled: var(--themes-disabled-strong);
  --color-icon-text-link-stand-alone-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-primary-gradient-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-primary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-primary-gradient-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-secondary-solid-default: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-solid-hover: var(--isport-brick-500);
  --color-icon-text-link-stand-alone-secondary-solid-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-secondary-solid-focus: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-solid-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-default: var(--isport-spring-400);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-hover: var(--isport-spring-500);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-focus: var(--color-white-1000);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-disabled: var(--themes-disabled-strong);
  --color-icon-text-link-stand-alone-secondary-inverted-gradient-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-secondary-inverted-gradient-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-secondary-gradient-default: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-gradient-hover: var(--isport-brick-500);
  --color-icon-text-link-stand-alone-secondary-gradient-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-secondary-gradient-focus: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-gradient-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-solid-inverted-default: var(--color-white-1000);
  --color-icon-text-link-hyperlink-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-icon-text-link-hyperlink-primary-solid-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-hyperlink-primary-solid-inverted-focus: var(--color-white-1000);
  --color-icon-text-link-hyperlink-primary-solid-inverted-disabled: var(--themes-disabled-strong);
  --color-icon-text-link-hyperlink-primary-solid-normal-default: var(--isport-brick-500);
  --color-icon-text-link-hyperlink-primary-solid-normal-hover: var(--isport-brick-600);
  --color-icon-text-link-hyperlink-primary-solid-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-primary-solid-normal-focus: var(--isport-brick-500);
  --color-icon-text-link-hyperlink-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-gradient-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-hyperlink-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-gradient-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-secondary-solid-normal-default: var(--themes-neutral-strong);
  --color-icon-text-link-hyperlink-secondary-solid-normal-hover: var(--isport-brick-500);
  --color-icon-text-link-hyperlink-secondary-solid-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-secondary-solid-normal-focus: var(--themes-neutral-strong);
  --color-icon-text-link-hyperlink-secondary-solid-normal-disabled: var(--themes-neutral-light);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-default: var(--isport-spring-400);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-hover: var(--isport-spring-500);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-focus: var(--color-white-1000);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-disabled: var(--themes-disabled-strong);
  --color-icon-text-link-hyperlink-secondary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-secondary-gradient-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-secondary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-secondary-gradient-inverted-visited: var(--themes-focus-light);
  --color-icon-dropdown-default: var(--themes-neutral-strong);
  --color-icon-dropdown-disabled: var(--themes-disabled-main);
  --color-icon-search-field-normal-search: var(--themes-action-secondary-darker);
  --color-icon-search-field-normal-closed: var(--themes-action-secondary-main);
  --color-icon-search-field-inverted-search: var(--isport-iron-400);
  --color-icon-search-field-inverted-closed: var(--themes-action-secondary-main);
  --color-icon-default-default: var(--themes-neutral-strong);
  --color-icon-default-light: var(--themes-neutral-darker);
  --color-icon-default-inverted: var(--themes-neutral-dark);
  --color-icon-breadcrumb-normal-chevron: var(--themes-neutral-main);
  --color-icon-breadcrumb-inverted-chevron: var(--themes-neutral-dark);
  --color-icon-application-card-default: var(--themes-neutral-strong);
  --color-icon-button-betting-primary-normal-left-icon-contained-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-normal-left-icon-contained-hover: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-normal-left-icon-contained-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-normal-left-icon-contained-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-normal-left-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-left-icon-outlined-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-normal-left-icon-outlined-hover: var(--tipsport-tip-orange-600);
  --color-icon-button-betting-primary-normal-left-icon-outlined-focus: var(--tipsport-tip-orange-600);
  --color-icon-button-betting-primary-normal-left-icon-outlined-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-normal-left-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-left-icon-text-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-normal-left-icon-text-hover: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-normal-left-icon-text-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-normal-left-icon-text-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-normal-left-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-right-icon-contained-default: var(--color-white-1000);
  --color-icon-button-betting-primary-normal-right-icon-contained-hover: var(--color-white-1000);
  --color-icon-button-betting-primary-normal-right-icon-contained-focus: var(--color-white-1000);
  --color-icon-button-betting-primary-normal-right-icon-contained-active: var(--color-white-1000);
  --color-icon-button-betting-primary-normal-right-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-right-icon-outlined-default: var(--tipsport-tip-blue-700);
  --color-icon-button-betting-primary-normal-right-icon-outlined-hover: var(--tipsport-tip-blue-900);
  --color-icon-button-betting-primary-normal-right-icon-outlined-focus: var(--tipsport-tip-blue-900);
  --color-icon-button-betting-primary-normal-right-icon-outlined-active: var(--tipsport-tip-blue-600);
  --color-icon-button-betting-primary-normal-right-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-right-icon-text-default: var(--tipsport-tip-blue-700);
  --color-icon-button-betting-primary-normal-right-icon-text-hover: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-primary-normal-right-icon-text-focus: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-primary-normal-right-icon-text-active: var(--tipsport-tip-blue-600);
  --color-icon-button-betting-primary-normal-right-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-left-icon-contained-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-inverted-left-icon-contained-hover: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-inverted-left-icon-contained-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-inverted-left-icon-contained-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-inverted-left-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-hover: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-left-icon-text-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-inverted-left-icon-text-hover: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-primary-inverted-left-icon-text-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-inverted-left-icon-text-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-primary-inverted-left-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-right-icon-contained-default: var(--color-white-1000);
  --color-icon-button-betting-primary-inverted-right-icon-contained-hover: var(--color-white-1000);
  --color-icon-button-betting-primary-inverted-right-icon-contained-focus: var(--color-white-1000);
  --color-icon-button-betting-primary-inverted-right-icon-contained-active: var(--color-white-1000);
  --color-icon-button-betting-primary-inverted-right-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-default: var(--tipsport-tip-blue-700);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-hover: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-focus: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-active: var(--tipsport-tip-blue-600);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-right-icon-text-default: var(--color-white-1000);
  --color-icon-button-betting-primary-inverted-right-icon-text-hover: var(--color-white-1000);
  --color-icon-button-betting-primary-inverted-right-icon-text-focus: var(--color-white-1000);
  --color-icon-button-betting-primary-inverted-right-icon-text-active: var(--tipsport-tip-blue-200);
  --color-icon-button-betting-primary-inverted-right-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-left-icon-contained-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-secondary-left-icon-contained-hover: var(--tipsport-tip-orange-600);
  --color-icon-button-betting-secondary-left-icon-contained-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-secondary-left-icon-contained-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-secondary-left-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-left-icon-outlined-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-secondary-left-icon-outlined-hover: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-secondary-left-icon-outlined-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-secondary-left-icon-outlined-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-secondary-left-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-left-icon-text-default: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-secondary-left-icon-text-hover: var(--tipsport-tip-orange-500);
  --color-icon-button-betting-secondary-left-icon-text-focus: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-secondary-left-icon-text-active: var(--tipsport-tip-orange-400);
  --color-icon-button-betting-secondary-left-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-right-icon-contained-default: var(--tipsport-tip-blue-700);
  --color-icon-button-betting-secondary-right-icon-contained-hover: var(--tipsport-tip-blue-900);
  --color-icon-button-betting-secondary-right-icon-contained-focus: var(--tipsport-tip-blue-700);
  --color-icon-button-betting-secondary-right-icon-contained-active: var(--tipsport-tip-blue-600);
  --color-icon-button-betting-secondary-right-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-right-icon-outlined-default: var(--tipsport-tip-blue-700);
  --color-icon-button-betting-secondary-right-icon-outlined-hover: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-secondary-right-icon-outlined-focus: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-secondary-right-icon-outlined-active: var(--tipsport-tip-blue-600);
  --color-icon-button-betting-secondary-right-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-right-icon-text-default: var(--tipsport-tip-blue-700);
  --color-icon-button-betting-secondary-right-icon-text-hover: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-secondary-right-icon-text-focus: var(--tipsport-tip-blue-800);
  --color-icon-button-betting-secondary-right-icon-text-active: var(--tipsport-tip-blue-600);
  --color-icon-button-betting-secondary-right-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-results-winner: var(--color-yellow-400);
  --color-icon-results-servis: var(--color-green-02-500);
  --color-icon-results-default: var(--themes-neutral-strong);
  --color-icon-error-background: var(--themes-action-primary-main);
  --color-icon-error-line: var(--isport-aquamarine-100);
  --color-icon-newsletter-box-default: var(--themes-neutral-main);
  --color-icon-checkbox-default-checked-normal-default: var(--color-white-1000);
  --color-icon-checkbox-default-checked-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-default-checked-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-default-checked-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-default-checked-inverted-default: var(--color-white-1000);
  --color-icon-checkbox-default-checked-inverted-hover: var(--color-white-1000);
  --color-icon-checkbox-default-checked-inverted-focus: var(--color-white-1000);
  --color-icon-checkbox-default-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-default: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-default-all-inverted-default: var(--color-white-1000);
  --color-icon-checkbox-default-all-inverted-hover: var(--color-white-1000);
  --color-icon-checkbox-default-all-inverted-focus: var(--color-white-1000);
  --color-icon-checkbox-default-all-inverted-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-default: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-default: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-hover: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-focus: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-default: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-default: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-hover: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-focus: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-disabled: var(--color-white-1000);
  --color-icon-input-normal-value-default: var(--themes-neutral-main);
  --color-icon-input-normal-value-hover: var(--themes-neutral-dark);
  --color-icon-input-normal-value-focus: var(--themes-neutral-strong);
  --color-icon-input-normal-value-active: var(--themes-neutral-strong);
  --color-icon-input-normal-value-error: var(--themes-neutral-strong);
  --color-icon-input-normal-value-disabled: var(--themes-disabled-light);
  --color-icon-input-normal-prefix-default: var(--isport-iron-800);
  --color-icon-input-normal-prefix-hover: var(--isport-iron-900);
  --color-icon-input-normal-prefix-focus: var(--themes-neutral-strong);
  --color-icon-input-normal-prefix-active: var(--themes-neutral-strong);
  --color-icon-input-normal-prefix-error: var(--themes-neutral-strong);
  --color-icon-input-normal-prefix-disabled: var(--themes-disabled-light);
  --color-icon-input-normal-sufix-default: var(--isport-iron-800);
  --color-icon-input-normal-sufix-hover: var(--isport-iron-900);
  --color-icon-input-normal-sufix-focus: var(--themes-neutral-strong);
  --color-icon-input-normal-sufix-active: var(--themes-neutral-strong);
  --color-icon-input-normal-sufix-error: var(--themes-neutral-strong);
  --color-icon-input-normal-sufix-disabled: var(--themes-disabled-light);
  --color-icon-input-inverted-value-default: var(--isport-iron-500);
  --color-icon-input-inverted-value-hover: var(--themes-neutral-dark);
  --color-icon-input-inverted-value-focus: var(--color-white-1000);
  --color-icon-input-inverted-value-active: var(--color-white-1000);
  --color-icon-input-inverted-value-error: var(--color-white-1000);
  --color-icon-input-inverted-value-disabled: var(--themes-disabled-darker);
  --color-icon-input-inverted-prefix-default: var(--isport-iron-200);
  --color-icon-input-inverted-prefix-hover: var(--isport-iron-200);
  --color-icon-input-inverted-prefix-focus: var(--isport-iron-200);
  --color-icon-input-inverted-prefix-active: var(--isport-iron-200);
  --color-icon-input-inverted-prefix-error: var(--isport-iron-200);
  --color-icon-input-inverted-prefix-disabled: var(--themes-disabled-darker);
  --color-icon-input-inverted-sufix-default: var(--isport-iron-200);
  --color-icon-input-inverted-sufix-hover: var(--isport-iron-200);
  --color-icon-input-inverted-sufix-focus: var(--isport-iron-200);
  --color-icon-input-inverted-sufix-active: var(--isport-iron-200);
  --color-icon-input-inverted-sufix-error: var(--isport-iron-200);
  --color-icon-input-inverted-sufix-disabled: var(--themes-disabled-darker);
  --color-icon-text-area-normal-default: var(--themes-neutral-light);
  --color-icon-text-area-normal-hover: var(--themes-neutral-dark);
  --color-icon-text-area-normal-focus: var(--themes-neutral-dark);
  --color-icon-text-area-normal-active: var(--themes-neutral-dark);
  --color-icon-text-area-normal-error: var(--themes-neutral-dark);
  --color-icon-text-area-normal-disabled: var(--themes-disabled-light);
  --color-icon-text-area-inverted-default: var(--themes-neutral-light);
  --color-icon-text-area-inverted-hover: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-focus: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-active: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-error: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-disabled: var(--themes-disabled-light);
  --color-icon-tipsport-bet-default: var(--tipsport-tip-orange-500);
  --color-icon-info-wrapper-default: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-default: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-hover: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-focus: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-disabled: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-inverted-default: var(--themes-brand-primary-strong);
  --color-icon-radiobutton-default-checked-inverted-hover: var(--themes-brand-primary-strong);
  --color-icon-radiobutton-default-checked-inverted-focus: var(--themes-brand-primary-strong);
  --color-icon-radiobutton-default-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-default: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-hover: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-focus: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-disabled: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-default: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-hover: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-focus: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-tab-normal-default: var(--themes-neutral-strong);
  --color-icon-tab-normal-hover: var(--isport-brick-600);
  --color-icon-tab-inverted-default: var(--color-white-1000);
  --color-icon-tab-inverted-hover: var(--isport-brick-600);
  --color-icon-top-match-podani: var(--color-green-02-500);
  --color-icon-top-event-winner: var(--color-yellow-400);
  --color-icon-top-event-servis: var(--color-green-02-500);
  --color-icon-top-event-default: var(--themes-neutral-strong);
  --color-icon-results-card-winner: var(--color-yellow-400);
  --color-icon-results-card-servis: var(--color-green-02-500);
  --color-icon-results-card-default: var(--themes-neutral-strong);
  --color-icon-commentary-box-yellow: var(--color-yellow-300);
  --color-icon-commentary-box-red: var(--themes-error-main);
  --color-icon-commentary-box-dark: var(--themes-neutral-strong);
  --color-icon-commentary-box-light: var(--isport-iron-300);
  --color-icon-commentary-box-substitution-in: var(--color-green-02-600);
  --color-icon-commentary-box-substitution-out: var(--themes-error-main);
  --color-icon-commentary-box-ball: var(--color-green-02-600);
  --color-icon-modal-success: var(--color-surface-brand-primary-main);
  --color-icon-schedule-winner: var(--color-yellow-400);
  --color-icon-schedule-servis: var(--color-green-02-500);
  --color-icon-schedule-default: var(--themes-neutral-strong);
  --color-icon-live-standings-up: var(--themes-success-main);
  --color-icon-live-standings-down: var(--themes-error-main);
  --color-icon-live-standings-remains: var(--isport-iron-500);
  --color-icon-result-module-card-winner: var(--color-yellow-400);
  --color-icon-result-module-card-servis: var(--color-green-02-500);
  --color-icon-result-module-card-default: var(--themes-neutral-strong);
  --color-icon-survey-success: var(--color-surface-brand-primary-main);
  --color-icon-title-section-default: var(--color-white-1000);
  --color-icon-videoplayer-default: var(--color-white-1000);
  --color-icon-segmented-controls-default: var(--themes-neutral-strong);
  --color-icon-segmented-controls-hover: var(--themes-neutral-strong);
  --color-icon-segmented-controls-focus: var(--themes-neutral-strong);
  --color-icon-segmented-controls-active: var(--themes-brand-primary-subtle);
  --color-icon-segmented-controls-disbaled: var(--themes-disabled-light);
  --color-icon-stepper-finished: var(--color-white-1000);
  --color-icon-stepper-hover: var(--color-white-1000);
  --color-icon-stepper-active: var(--themes-brand-primary-main);
  --spacing-spacer-half: var(--number-scale-4);
  --spacing-spacer-1: var(--number-scale-8);
  --spacing-spacer-2: var(--number-scale-16);
  --spacing-spacer-3: var(--number-scale-24);
  --spacing-spacer-4: var(--number-scale-32);
  --spacing-spacer-5: var(--number-scale-40);
  --spacing-spacer-6: var(--number-scale-48);
  --spacing-spacer-7: var(--number-scale-64);
  --spacing-button-radius: var(--number-scale-9999);
  --spacing-icon-button-radius: var(--number-scale-9999);
  --spacing-chip-radius: var(--number-scale-9999);
  --spacing-input-radius: var(--number-scale-8);
  --spacing-segmented-controls-radius: var(--number-scale-0);
  --spacing-avatar-radius: var(--number-scale-9999);
  --spacing-global-image-radius-global: var(--number-scale-32);
  --spacing-global-image-radius-1032x580: 32px;
  --spacing-global-image-radius-680x382: 32px;
  --spacing-global-image-radius-560x315: 24px;
  --spacing-global-image-radius-360x202: 20px;
  --spacing-global-image-radius-328x185: 20px;
  --spacing-global-image-radius-240x135: 16px;
  --spacing-global-image-radius-210x119: 12px;
  --spacing-global-image-radius-152x86: 12px;
  --spacing-global-image-radius-124x70: 8px;
  --spacing-global-image-radius-110x62: 8px;
  --spacing-global-image-radius-80x80: 8px;
  --spacing-status-radius: var(--number-scale-9999);
  --spacing-badge-pilled: var(--number-scale-4);
  --spacing-badge-rounded: var(--number-scale-2);
  --spacing-tag-radius: var(--number-scale-6);
  --spacing-tooltip-radius: var(--number-scale-4);
  --spacing-breaking-news-radius: var(--number-scale-0);
  --spacing-flash-message-radius: var(--number-scale-12);
  --spacing-author-card-radius: var(--number-scale-0);
  --spacing-comments-card-radius: var(--number-scale-10);
  --spacing-modal-radius: var(--number-scale-12);
  --spacing-topics-radius: var(--number-scale-0);
  --spacing-brand-height-2xs: 16px;
  --spacing-brand-height-xs: 20px;
  --spacing-brand-height-sm: 24px;
  --spacing-brand-height-md: 32px;
  --spacing-brand-height-lg: 36px;
  --spacing-brand-height-xl: 40px;
  --spacing-brand-height-2xl: 48px;
  --spacing-brand-height-3xl: 52px;
  --spacing-brand-height-4xl: 64px;
  --spacing-brand-height-5xl: 80px;
  --spacing-header-height: 120px;
  --spacing-header-v-gap: 16px;
  --spacing-header-item-v-padding: 12px;
  --spacing-header-brand-height: var(--spacing-brand-height-3xl);
  --spacing-header-sticky-brand-height: var(--spacing-brand-height-xl);
  --spacing-header-mobile-brand-height: var(--spacing-brand-height-xl);
  --spacing-header-bottom-left-radius: 0;
  --spacing-header-bottom-right-radius: 0;
  --spacing-header-top-left-radius: 0;
  --spacing-header-top-right-radius: 0;
  --spacing-page-content-left-h-padding-left: var(--number-scale-0);
  --spacing-page-content-left-h-padding-right: var(--number-scale-64);
  --spacing-premium-card-radius: 0;
  --spacing-textlink-v-padding: 0;
  --spacing-textlink-h-padding: 8px;
  --spacing-card-article-desktop-2xl-01-radius: 12px;
  --spacing-article-cards-desktop-2xl-v01-card-v-padding: 16px;
  --spacing-article-cards-desktop-2xl-v01-card-h-padding: 16px;
  --spacing-article-cards-desktop-2xl-v01-card-gap: 16px;
  --spacing-article-cards-desktop-2xl-v01-card-radius: 24px;
  --spacing-article-cards-desktop-2xl-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-content-gap: 16px;
  --spacing-article-cards-desktop-2xl-v01-content-radius: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-gap: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-radius: 0;
  --spacing-article-cards-desktop-2xl-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-title-radius: 0;
  --spacing-article-cards-desktop-xl-v01-card-v-padding: 16px;
  --spacing-article-cards-desktop-xl-v01-card-h-padding: 16px;
  --spacing-article-cards-desktop-xl-v01-card-gap: 16px;
  --spacing-article-cards-desktop-xl-v01-card-radius: 32px;
  --spacing-article-cards-desktop-xl-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-xl-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-xl-v01-content-gap: 20px;
  --spacing-article-cards-desktop-xl-v01-content-radius: 0;
  --spacing-article-cards-desktop-xl-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-xl-v01-elements-h-padding: 8px;
  --spacing-article-cards-desktop-xl-v01-elements-gap: 12px;
  --spacing-article-cards-desktop-xl-v01-elements-radius: 0;
  --spacing-article-cards-desktop-xl-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-xl-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-xl-v01-title-radius: 0;
  --spacing-article-cards-desktop-xl-v03-card-v-padding: 0;
  --spacing-article-cards-desktop-xl-v03-card-h-padding: 0;
  --spacing-article-cards-desktop-xl-v03-card-gap: 16px;
  --spacing-article-cards-desktop-xl-v03-card-radius: 0;
  --spacing-article-cards-desktop-xl-v03-content-v-padding: 0;
  --spacing-article-cards-desktop-xl-v03-content-h-padding: 0;
  --spacing-article-cards-desktop-xl-v03-content-gap: -168px;
  --spacing-article-cards-desktop-xl-v03-content-radius: 0;
  --spacing-article-cards-desktop-xl-v03-elements-v-padding-top: 150px;
  --spacing-article-cards-desktop-xl-v03-elements-v-padding-bottom: 32px;
  --spacing-article-cards-desktop-xl-v03-elements-h-padding: 24px;
  --spacing-article-cards-desktop-xl-v03-elements-gap: 16px;
  --spacing-article-cards-desktop-xl-v03-elements-radius-top: 0;
  --spacing-article-cards-desktop-xl-v03-elements-radius-bottom: var(--spacing-global-image-radius-680x382);
  --spacing-article-cards-desktop-xl-v03-title-v-padding: 0;
  --spacing-article-cards-desktop-xl-v03-title-h-padding: 0;
  --spacing-article-cards-desktop-xl-v03-title-radius: 0;
  --spacing-article-cards-desktop-xl-v02-card-v-padding: 16px;
  --spacing-article-cards-desktop-xl-v02-card-h-padding: 16px;
  --spacing-article-cards-desktop-xl-v02-card-gap: 16px;
  --spacing-article-cards-desktop-xl-v02-card-radius: 32px;
  --spacing-article-cards-desktop-xl-v02-content-v-padding: 0;
  --spacing-article-cards-desktop-xl-v02-content-h-padding: 0;
  --spacing-article-cards-desktop-xl-v02-content-gap: 20px;
  --spacing-article-cards-desktop-xl-v02-content-radius: 0;
  --spacing-article-cards-desktop-xl-v02-elements-v-padding: 0;
  --spacing-article-cards-desktop-xl-v02-elements-h-padding: 8px;
  --spacing-article-cards-desktop-xl-v02-elements-gap: 12px;
  --spacing-article-cards-desktop-xl-v02-elements-radius: 0;
  --spacing-article-cards-desktop-xl-v02-title-v-padding: 0;
  --spacing-article-cards-desktop-xl-v02-title-h-padding: 0;
  --spacing-article-cards-desktop-xl-v02-title-radius: 0;
  --spacing-article-cards-desktop-lg-v01-card-v-padding: 16px;
  --spacing-article-cards-desktop-lg-v01-card-h-padding: 16px;
  --spacing-article-cards-desktop-lg-v01-card-gap: 16px;
  --spacing-article-cards-desktop-lg-v01-card-radius: 20px;
  --spacing-article-cards-desktop-lg-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-lg-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-lg-v01-content-gap: 20px;
  --spacing-article-cards-desktop-lg-v01-content-radius: 0;
  --spacing-article-cards-desktop-lg-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-lg-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-lg-v01-elements-gap: 10px;
  --spacing-article-cards-desktop-lg-v01-elements-radius: 0;
  --spacing-article-cards-desktop-lg-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-lg-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-lg-v01-title-radius: 0;
  --spacing-article-cards-desktop-md-v01-card-v-padding: 16px;
  --spacing-article-cards-desktop-md-v01-card-h-padding: 16px;
  --spacing-article-cards-desktop-md-v01-card-gap: 16px;
  --spacing-article-cards-desktop-md-v01-card-radius: 12px;
  --spacing-article-cards-desktop-md-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-md-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-md-v01-content-gap: 20px;
  --spacing-article-cards-desktop-md-v01-content-radius: 0;
  --spacing-article-cards-desktop-md-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-v01-elements-gap: 8px;
  --spacing-article-cards-desktop-md-v01-elements-radius: 0;
  --spacing-article-cards-desktop-md-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-md-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-md-v01-title-radius: 0;
  --spacing-article-cards-desktop-md-v02-card-v-padding: 16px;
  --spacing-article-cards-desktop-md-v02-card-h-padding: 16px;
  --spacing-article-cards-desktop-md-v02-card-gap: 16px;
  --spacing-article-cards-desktop-md-v02-card-radius: 20px;
  --spacing-article-cards-desktop-md-v02-content-v-padding: 0;
  --spacing-article-cards-desktop-md-v02-content-h-padding: 0;
  --spacing-article-cards-desktop-md-v02-content-gap: 20px;
  --spacing-article-cards-desktop-md-v02-content-radius: 0;
  --spacing-article-cards-desktop-md-v02-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-v02-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-v02-elements-gap: 8px;
  --spacing-article-cards-desktop-md-v02-elements-radius: 0;
  --spacing-article-cards-desktop-md-v02-title-v-padding: 0;
  --spacing-article-cards-desktop-md-v02-title-h-padding: 0;
  --spacing-article-cards-desktop-md-v02-title-radius: 0;
  --spacing-article-cards-desktop-md-other-v01-card-v-padding: 16px;
  --spacing-article-cards-desktop-md-other-v01-card-h-padding: 16px;
  --spacing-article-cards-desktop-md-other-v01-card-gap: 16px;
  --spacing-article-cards-desktop-md-other-v01-card-radius: 20px;
  --spacing-article-cards-desktop-md-other-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-content-gap: 20px;
  --spacing-article-cards-desktop-md-other-v01-content-radius: 0;
  --spacing-article-cards-desktop-md-other-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-elements-gap: 8px;
  --spacing-article-cards-desktop-md-other-v01-elements-radius: 0;
  --spacing-article-cards-desktop-md-other-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-title-radius: 0;
  --spacing-article-cards-desktop-md-other-v02-card-v-padding: 16px;
  --spacing-article-cards-desktop-md-other-v02-card-h-padding: 16px;
  --spacing-article-cards-desktop-md-other-v02-card-gap: 16px;
  --spacing-article-cards-desktop-md-other-v02-card-radius: 20px;
  --spacing-article-cards-desktop-md-other-v02-content-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-content-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-content-gap: 20px;
  --spacing-article-cards-desktop-md-other-v02-content-radius: 0;
  --spacing-article-cards-desktop-md-other-v02-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-elements-gap: 8px;
  --spacing-article-cards-desktop-md-other-v02-elements-radius: 0;
  --spacing-article-cards-desktop-md-other-v02-title-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-title-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-title-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-card-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-card-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-card-gap: 16px;
  --spacing-article-cards-desktop-md-other-v03-card-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-content-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-content-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-content-gap: 20px;
  --spacing-article-cards-desktop-md-other-v03-content-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-elements-gap: 8px;
  --spacing-article-cards-desktop-md-other-v03-elements-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-title-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-title-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-title-radius: 0;
  --spacing-article-cards-desktop-sm-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-card-gap: 16px;
  --spacing-article-cards-desktop-sm-v01-card-radius: 0;
  --spacing-article-cards-desktop-sm-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-content-gap: 8px;
  --spacing-article-cards-desktop-sm-v01-content-radius: 0;
  --spacing-article-cards-desktop-sm-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-elements-gap: 8px;
  --spacing-article-cards-desktop-sm-v01-elements-radius: 0;
  --spacing-article-cards-desktop-sm-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-title-radius: 0;
  --spacing-article-cards-desktop-xs-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-card-gap: 16px;
  --spacing-article-cards-desktop-xs-v01-card-radius: 0;
  --spacing-article-cards-desktop-xs-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-content-gap: 20px;
  --spacing-article-cards-desktop-xs-v01-content-radius: 0;
  --spacing-article-cards-desktop-xs-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-elements-gap: 4px;
  --spacing-article-cards-desktop-xs-v01-elements-radius: 0;
  --spacing-article-cards-desktop-xs-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-title-radius: 0;
  --spacing-article-cards-mobile-xl-v01-card-v-padding: 16px;
  --spacing-article-cards-mobile-xl-v01-card-h-padding: 16px;
  --spacing-article-cards-mobile-xl-v01-card-gap: 12px;
  --spacing-article-cards-mobile-xl-v01-card-radius: 20px;
  --spacing-article-cards-mobile-xl-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-xl-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-xl-v01-content-gap: 12px;
  --spacing-article-cards-mobile-xl-v01-content-radius: 0;
  --spacing-article-cards-mobile-xl-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-xl-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-xl-v01-elements-gap: 8px;
  --spacing-article-cards-mobile-xl-v01-elements-radius: 0;
  --spacing-article-cards-mobile-xl-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-xl-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-xl-v01-title-radius: 0;
  --spacing-article-cards-mobile-xl-v02-card-v-padding: 16px;
  --spacing-article-cards-mobile-xl-v02-card-h-padding: 16px;
  --spacing-article-cards-mobile-xl-v02-card-gap: 12px;
  --spacing-article-cards-mobile-xl-v02-card-radius: 20px;
  --spacing-article-cards-mobile-xl-v02-content-v-padding: 0;
  --spacing-article-cards-mobile-xl-v02-content-h-padding: 0;
  --spacing-article-cards-mobile-xl-v02-content-gap: 12px;
  --spacing-article-cards-mobile-xl-v02-content-radius: 0;
  --spacing-article-cards-mobile-xl-v02-elements-v-padding: 0;
  --spacing-article-cards-mobile-xl-v02-elements-h-padding: 0;
  --spacing-article-cards-mobile-xl-v02-elements-gap: 8px;
  --spacing-article-cards-mobile-xl-v02-elements-radius: 0;
  --spacing-article-cards-mobile-xl-v02-title-v-padding: 0;
  --spacing-article-cards-mobile-xl-v02-title-h-padding: 0;
  --spacing-article-cards-mobile-xl-v02-title-radius: 0;
  --spacing-article-cards-mobile-xl-v03-card-v-padding: 0;
  --spacing-article-cards-mobile-xl-v03-card-h-padding: 0;
  --spacing-article-cards-mobile-xl-v03-card-gap: 0;
  --spacing-article-cards-mobile-xl-v03-card-radius: 20px;
  --spacing-article-cards-mobile-xl-v03-content-v-padding: 0;
  --spacing-article-cards-mobile-xl-v03-content-h-padding: 0;
  --spacing-article-cards-mobile-xl-v03-content-gap: -214px;
  --spacing-article-cards-mobile-xl-v03-content-radius: var(--spacing-global-image-radius-328x185);
  --spacing-article-cards-mobile-xl-v03-elements-v-padding-top: 110px;
  --spacing-article-cards-mobile-xl-v03-elements-v-padding-bottom: 24px;
  --spacing-article-cards-mobile-xl-v03-elements-h-padding: 24px;
  --spacing-article-cards-mobile-xl-v03-elements-gap: 16px;
  --spacing-article-cards-mobile-xl-v03-elements-radius-bottom: var(--spacing-global-image-radius-328x185);
  --spacing-article-cards-mobile-xl-v03-title-v-padding: 0;
  --spacing-article-cards-mobile-xl-v03-title-h-padding: 0;
  --spacing-article-cards-mobile-xl-v03-title-radius: 0;
  --spacing-article-cards-mobile-lg-v01-card-v-padding: 16px;
  --spacing-article-cards-mobile-lg-v01-card-h-padding: 16px;
  --spacing-article-cards-mobile-lg-v01-card-gap: 12px;
  --spacing-article-cards-mobile-lg-v01-card-radius: 20px;
  --spacing-article-cards-mobile-lg-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-lg-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-lg-v01-content-gap: 12px;
  --spacing-article-cards-mobile-lg-v01-content-radius: 0;
  --spacing-article-cards-mobile-lg-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-lg-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-lg-v01-elements-gap: 4px;
  --spacing-article-cards-mobile-lg-v01-elements-radius: 0;
  --spacing-article-cards-mobile-lg-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-lg-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-lg-v01-title-radius: 0;
  --spacing-article-cards-mobile-md-other-v01-card-v-padding: 12px;
  --spacing-article-cards-mobile-md-other-v01-card-h-padding: 12px;
  --spacing-article-cards-mobile-md-other-v01-card-gap: 12px;
  --spacing-article-cards-mobile-md-other-v01-card-radius: 8px;
  --spacing-article-cards-mobile-md-other-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-content-gap: 12px;
  --spacing-article-cards-mobile-md-other-v01-content-radius: 0;
  --spacing-article-cards-mobile-md-other-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-elements-gap: 4px;
  --spacing-article-cards-mobile-md-other-v01-elements-radius: 0;
  --spacing-article-cards-mobile-md-other-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-title-radius: 0;
  --spacing-article-cards-mobile-md-other-v02-card-v-padding: 12px;
  --spacing-article-cards-mobile-md-other-v02-card-h-padding: 12px;
  --spacing-article-cards-mobile-md-other-v02-card-gap: 12px;
  --spacing-article-cards-mobile-md-other-v02-card-radius: 8px;
  --spacing-article-cards-mobile-md-other-v02-content-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-content-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-content-gap: 12px;
  --spacing-article-cards-mobile-md-other-v02-content-radius: 0;
  --spacing-article-cards-mobile-md-other-v02-elements-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-elements-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-elements-gap: 4px;
  --spacing-article-cards-mobile-md-other-v02-elements-radius: 0;
  --spacing-article-cards-mobile-md-other-v02-title-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-title-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-title-radius: 0;
  --spacing-article-cards-mobile-xs-v01-card-v-padding: 16px;
  --spacing-article-cards-mobile-xs-v01-card-h-padding: 16px;
  --spacing-article-cards-mobile-xs-v01-card-gap: 12px;
  --spacing-article-cards-mobile-xs-v01-card-radius: 20px;
  --spacing-article-cards-mobile-xs-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-xs-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-xs-v01-content-gap: 12px;
  --spacing-article-cards-mobile-xs-v01-content-radius: 0;
  --spacing-article-cards-mobile-xs-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-xs-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-xs-v01-elements-gap: 4px;
  --spacing-article-cards-mobile-xs-v01-elements-radius: 0;
  --spacing-article-cards-mobile-xs-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-xs-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-xs-v01-title-radius: 0;
  --spacing-article-cards-mobile-m-other-radius: 8px;
  --spacing-vote-strip-radius: var(--number-scale-10);
  --spacing-discussion-box-radius: var(--number-scale-16);
  --spacing-discussion-card-radius: var(--number-scale-10);
  --spacing-info-bar-video-v-gap: 0;
  --spacing-info-bar-video-desktop-top-padding: 8px;
  --spacing-info-bar-video-desktop-bot-padding: 0;
  --spacing-info-bar-video-mobile-top-padding: 4px;
  --spacing-info-bar-video-mobile-bot-padding: 0;
  --spacing-gallery-box-radius: var(--number-scale-0);
  --spacing-gallery-box-v-gap: 0;
  --spacing-result-card-radius: var(--number-scale-12);
  --spacing-schedule-radius: var(--number-scale-12);
  --properties-premium-brand-size-36: lg;
  --properties-global-image-play-button-style: gradient;
  --properties-article-card-meta-section-purpose: contained;
  --themes-brand-primary-subtle: var(--isport-spring-50);
  --themes-brand-primary-less-subtle: var(--isport-spring-100);
  --themes-brand-primary-lighter: var(--isport-spring-300);
  --themes-brand-primary-light: var(--isport-spring-400);
  --themes-brand-primary-main: var(--isport-spring-500);
  --themes-brand-primary-dark: var(--isport-spring-700);
  --themes-brand-primary-darker: var(--isport-spring-900);
  --themes-brand-primary-strong: var(--isport-spring-950);
  --themes-brand-primary-on-primary: var(--color-white-1000);
  --themes-brand-secondary-subtle: var(--isport-iron-50);
  --themes-brand-secondary-lighter: var(--isport-iron-200);
  --themes-brand-secondary-light: var(--isport-iron-400);
  --themes-brand-secondary-main: var(--isport-iron-500);
  --themes-brand-secondary-dark: var(--isport-iron-600);
  --themes-brand-secondary-darker: var(--isport-iron-800);
  --themes-brand-secondary-strong: var(--isport-iron-950);
  --themes-brand-secondary-on-secondary: var(--color-white-1000);
  --themes-gradient-page-front: var(--isport-spring-600);
  --themes-gradient-page-back: var(--isport-aquamarine-600);
  --themes-gradient-01-front: var(--isport-brick-500);
  --themes-gradient-01-back: var(--isport-flame-500);
  --themes-gradient-02-front: var(--isport-spring-400);
  --themes-gradient-02-back: var(--isport-aquamarine-400);
  --themes-gradient-03-front: var(--isport-spring-600);
  --themes-gradient-03-back: var(--isport-aquamarine-600);
  --themes-gradient-04-front: var(--isport-iron-950);
  --themes-gradient-04-back: var(--isport-iron-900);
  --themes-gradient-05-back: var(--isport-iron-950);
  --themes-gradient-05-middle: var(--isport-iron-950-alfa-700);
  --themes-gradient-05-front: var(--isport-iron-950-alfa-50);
  --themes-gradient-06-front: var(--color-green-400);
  --themes-gradient-06-back: var(--color-green-300);
  --themes-gradient-07-front: var(--color-white-0);
  --themes-gradient-07-back: var(--color-white-1000);
  --themes-accent-subtle: var(--isport-brick-50);
  --themes-accent-lighter: var(--isport-brick-100);
  --themes-accent-light: var(--isport-brick-200);
  --themes-accent-main: var(--isport-brick-300);
  --themes-accent-dark: var(--isport-brick-400);
  --themes-accent-darker: var(--isport-brick-500);
  --themes-accent-strong: var(--isport-brick-600);
  --themes-accent-on-accent: var(--isport-brick-950);
  --themes-accent-secondary-subtle: var(--isport-iron-50);
  --themes-accent-secondary-lighter: var(--isport-iron-200);
  --themes-accent-secondary-light: var(--isport-iron-400);
  --themes-accent-secondary-main: var(--isport-iron-500);
  --themes-accent-secondary-dark: var(--isport-iron-600);
  --themes-accent-secondary-darker: var(--isport-iron-800);
  --themes-accent-secondary-strong: var(--isport-iron-950);
  --themes-accent-secondary-on-secondary: var(--color-white-1000);
  --themes-neutral-subtle: var(--isport-iron-50);
  --themes-neutral-lighter: var(--isport-iron-100);
  --themes-neutral-light: var(--isport-iron-200);
  --themes-neutral-main: var(--isport-iron-400);
  --themes-neutral-dark: var(--isport-iron-500);
  --themes-neutral-darker: var(--isport-iron-800);
  --themes-neutral-strong: var(--isport-iron-950);
  --themes-neutral-on-neutral: var(--isport-iron-950);
  --themes-action-primary-subtle: var(--isport-flame-50);
  --themes-action-primary-lighter: var(--isport-flame-300);
  --themes-action-primary-light: var(--isport-flame-500);
  --themes-action-primary-main: var(--isport-flame-700);
  --themes-action-primary-dark: var(--isport-flame-800);
  --themes-action-primary-darker: var(--isport-flame-900);
  --themes-action-primary-strong: var(--isport-flame-950);
  --themes-action-primary-on-primary: var(--color-white-1000);
  --themes-action-secondary-subtle: var(--isport-iron-50);
  --themes-action-secondary-lighter: var(--isport-iron-200);
  --themes-action-secondary-light: var(--isport-iron-400);
  --themes-action-secondary-main: var(--isport-iron-500);
  --themes-action-secondary-dark: var(--isport-iron-700);
  --themes-action-secondary-darker: var(--isport-iron-900);
  --themes-action-secondary-strong: var(--isport-iron-950);
  --themes-action-secondary-on-secondary: var(--color-white-1000);
  --themes-action-inverted-subtle: var(--color-white-1000);
  --themes-action-inverted-lighter: var(--color-white-1000);
  --themes-action-inverted-light: var(--color-white-1000);
  --themes-action-inverted-main: var(--color-white-1000);
  --themes-action-inverted-dark: var(--color-white-1000);
  --themes-action-inverted-darker: var(--color-white-1000);
  --themes-action-inverted-strong: var(--color-white-1000);
  --themes-action-inverted-on-inverted: var(--color-white-1000);
  --themes-info-subtle: var(--color-blue-50);
  --themes-info-lighter: var(--color-blue-200);
  --themes-info-light: var(--color-blue-400);
  --themes-info-main: var(--color-blue-600);
  --themes-info-dark: var(--color-blue-800);
  --themes-info-darker: var(--color-blue-900);
  --themes-info-strong: var(--color-blue-950);
  --themes-info-on-info: var(--color-white-1000);
  --themes-success-subtle: var(--color-green-50);
  --themes-success-lighter: var(--color-green-200);
  --themes-success-light: var(--color-green-400);
  --themes-success-main: var(--color-green-600);
  --themes-success-dark: var(--color-green-700);
  --themes-success-darker: var(--color-green-900);
  --themes-success-strong: var(--color-green-950);
  --themes-success-on-success: var(--color-white-1000);
  --themes-warning-subtle: var(--color-yellow-50);
  --themes-warning-lighter: var(--color-yellow-200);
  --themes-warning-light: var(--color-yellow-400);
  --themes-warning-main: var(--color-yellow-500);
  --themes-warning-dark: var(--color-yellow-600);
  --themes-warning-darker: var(--color-yellow-800);
  --themes-warning-strong: var(--color-yellow-950);
  --themes-warning-on-warning: var(--color-gray-950);
  --themes-error-subtle: var(--color-red-50);
  --themes-error-lighter: var(--color-red-02-200);
  --themes-error-light: var(--color-red-400);
  --themes-error-main: var(--color-red-600);
  --themes-error-dark: var(--color-red-700);
  --themes-error-darker: var(--color-red-900);
  --themes-error-strong: var(--color-red-950);
  --themes-error-on-error: var(--color-white-1000);
  --themes-focus-subtle: var(--color-violet-50);
  --themes-focus-lighter: var(--color-violet-200);
  --themes-focus-light: var(--color-violet-400);
  --themes-focus-main: var(--color-violet-600);
  --themes-focus-dark: var(--color-violet-700);
  --themes-focus-darker: var(--color-violet-900);
  --themes-focus-strong: var(--color-violet-950);
  --themes-disabled-subtle: var(--isport-iron-50);
  --themes-disabled-lighter: var(--isport-iron-100);
  --themes-disabled-light: var(--isport-iron-200);
  --themes-disabled-main: var(--isport-iron-300);
  --themes-disabled-dark: var(--isport-iron-400);
  --themes-disabled-darker: var(--isport-iron-500);
  --themes-disabled-strong: var(--isport-iron-600);
  --themes-shadows: #fff;
  --typography-desktop-heading-3xl-font-size: 40px;
  --typography-desktop-heading-3xl-line-height: 48px;
  --typography-desktop-heading-2xl-font-size: 42px;
  --typography-desktop-heading-2xl-line-height: 52px;
  --typography-desktop-heading-xl-font-size: 32px;
  --typography-desktop-heading-xl-line-height: 36px;
  --typography-desktop-heading-lg-font-size: 24px;
  --typography-desktop-heading-lg-line-height: 32px;
  --typography-desktop-heading-md-font-size: 20px;
  --typography-desktop-heading-md-line-height: 26px;
  --typography-desktop-heading-sm-font-size: 18px;
  --typography-desktop-heading-sm-line-height: 24px;
  --typography-desktop-heading-xs-font-size: 16px;
  --typography-desktop-heading-xs-line-height: 20px;
  --typography-desktop-heading-2xs-font-size: 14px;
  --typography-desktop-heading-2xs-line-height: 18px;
  --typography-desktop-inter-title-font-size: 26px;
  --typography-desktop-inter-title-line-height: 32px;
  --typography-desktop-section-title-font-size: 32px;
  --typography-desktop-section-title-line-height: 36px;
  --typography-desktop-subtitle-lg-font-size: 20px;
  --typography-desktop-subtitle-lg-line-height: 30px;
  --typography-desktop-subtitle-md-font-size: 18px;
  --typography-desktop-subtitle-md-line-height: 28px;
  --typography-desktop-body-lg-font-size: 18px;
  --typography-desktop-body-lg-line-height: 29px;
  --typography-desktop-body-md-font-size: 14px;
  --typography-desktop-body-md-line-height: 20px;
  --typography-desktop-body-sm-font-size: 13px;
  --typography-desktop-body-sm-line-height: 20px;
  --typography-desktop-body-xs-font-size: 12px;
  --typography-desktop-body-xs-line-height: 12px;
  --typography-desktop-button-lg-font-size: 18px;
  --typography-desktop-button-lg-line-height: 52px;
  --typography-desktop-button-md-font-size: 16px;
  --typography-desktop-button-md-line-height: 24px;
  --typography-desktop-button-sm-font-size: 14px;
  --typography-desktop-button-sm-line-height: 20px;
  --typography-desktop-button-xs-font-size: 12px;
  --typography-desktop-button-xs-line-height: 16px;
  --typography-desktop-menu-item-font-size: 14px;
  --typography-desktop-menu-item-line-height: 20px;
  --typography-desktop-label-font-size: 14px;
  --typography-desktop-label-line-height: 16px;
  --typography-desktop-helper-font-size: 12px;
  --typography-desktop-helper-line-height: 12px;
  --typography-mobile-heading-3xl-font-size: 34px;
  --typography-mobile-heading-3xl-line-height: 44px;
  --typography-mobile-heading-2xl-font-size: 32px;
  --typography-mobile-heading-2xl-line-height: 36px;
  --typography-mobile-heading-xl-font-size: 26px;
  --typography-mobile-heading-xl-line-height: 32px;
  --typography-mobile-heading-lg-font-size: 22px;
  --typography-mobile-heading-lg-line-height: 28px;
  --typography-mobile-heading-md-font-size: 18px;
  --typography-mobile-heading-md-line-height: 24px;
  --typography-mobile-heading-sm-font-size: 16px;
  --typography-mobile-heading-sm-line-height: 21px;
  --typography-mobile-heading-xs-font-size: 16px;
  --typography-mobile-heading-xs-line-height: 52px;
  --typography-mobile-heading-2xs-font-size: 16px;
  --typography-mobile-heading-2xs-line-height: 20px;
  --typography-mobile-inter-title-font-size: 26px;
  --typography-mobile-inter-title-line-height: 32px;
  --typography-mobile-section-title-font-size: 26px;
  --typography-mobile-section-title-line-height: 32px;
  --typography-mobile-subtitle-lg-font-size: 20px;
  --typography-mobile-subtitle-lg-line-height: 30px;
  --typography-mobile-subtitle-md-font-size: 18px;
  --typography-mobile-subtitle-md-line-height: 28px;
  --typography-mobile-body-lg-font-size: 18px;
  --typography-mobile-body-lg-line-height: 29px;
  --typography-mobile-body-md-font-size: 14px;
  --typography-mobile-body-md-line-height: 20px;
  --typography-mobile-body-sm-font-size: 13px;
  --typography-mobile-body-sm-line-height: 20px;
  --typography-mobile-body-xs-font-size: 12px;
  --typography-mobile-body-xs-line-height: 17px;
  --typography-mobile-button-lg-font-size: 18px;
  --typography-mobile-button-lg-line-height: 28px;
  --typography-mobile-button-md-font-size: 16px;
  --typography-mobile-button-md-line-height: 24px;
  --typography-mobile-button-sm-font-size: 14px;
  --typography-mobile-button-sm-line-height: 20px;
  --typography-mobile-button-xs-font-size: 12px;
  --typography-mobile-button-xs-line-height: 16px;
  --typography-mobile-menu-item-font-size: 14px;
  --typography-mobile-menu-item-line-height: 20px;
  --typography-mobile-label-font-size: 14px;
  --typography-mobile-label-line-height: 16px;
  --typography-mobile-helper-font-size: 12px;
  --typography-mobile-helper-line-height: 12px;
  --typography-mobile-table-font-size: 13px;
  --typography-mobile-table-line-height: 16px;
  --article-card-color-surface-content: var(--color-surface-default-contrast);
  --article-card-color-surface-bacground: var(--color-surface-default-contrast);
  --article-card-spacing-xl-d-v-gap: 20px;
  --article-card-spacing-xl-d-meta-h-gap: var(--number-scale-8);
  --article-card-spacing-xl-d-meta-v-gap: 0;
  --article-card-spacing-xl-d-content-h-padding: var(--number-scale-16);
  --article-card-spacing-xl-d-content-v-gap: var(--number-scale-16);
  --article-card-spacing-xl-d-content-title-h-padding: 0;
  --article-card-spacing-xl-d-content-title-v-padding: 0;
  --article-card-spacing-xl-m-v-gap: 12px;
  --article-card-spacing-xl-m-content-h-padding: 0;
  --article-card-spacing-xl-m-content-v-gap: 0;
  --article-card-spacing-xl-m-content-title-h-padding: 0;
  --article-card-spacing-xl-m-content-title-v-padding: 0;
  --article-card-spacing-xl-m-meta-h-gap: 0;
  --article-card-spacing-xl-m-meta-v-gap: 0;
  --article-card-spacing-xxl-v-gap: 20px;
  --article-card-spacing-xxl-content-r-padding: 0;
  --article-card-spacing-xxl-content-t-padding: 0;
  --article-card-spacing-xxl-content-v-gap: 0;
  --article-card-spacing-xxl-content-title-h-padding: 0;
  --article-card-spacing-xxl-content-title-v-padding: 0;
  --article-card-spacing-xxl-meta-h-gap: 0;
  --article-card-spacing-xxl-meta-v-gap: 0;
  --article-card-spacing-xs-m-v-gap: 12px;
  --article-card-spacing-xs-m-content-h-padding: 0;
  --article-card-spacing-xs-m-content-v-gap: 0;
  --article-card-spacing-xs-m-content-title-h-padding: 0;
  --article-card-spacing-xs-m-content-title-v-padding: 0;
  --article-card-spacing-xs-m-meta-h-gap: 0;
  --article-card-spacing-xs-m-meta-v-gap: 0;
  --article-card-radius-article-card-xxl: var(--number-scale-12);
  --article-card-radius-article-card-xl: var(--number-scale-12);
  --article-card-radius-article-card-l: var(--number-scale-12);
  --article-card-radius-article-card-m: var(--number-scale-12);
  --color-surface-default-light: var(--themes-neutral-lighter);
  --color-surface-default-main: var(--themes-neutral-light);
  --color-surface-default-dark: var(--themes-neutral-main);
  --color-surface-default-slightly-darker: var(--themes-neutral-dark);
  --color-surface-default-darker: var(--themes-neutral-darker);
  --color-surface-default-strong: var(--themes-neutral-strong);
  --color-surface-default-hover: var(--themes-neutral-subtle);
  --color-surface-default-pressed: var(--themes-brand-primary-subtle);
  --color-surface-default-active: var(--themes-brand-primary-subtle);
  --color-surface-default-focus: var(--themes-brand-primary-subtle);
  --color-surface-default-disabled: var(--themes-neutral-subtle);
  --color-surface-default-overlay: var(--color-black-700);
  --color-surface-default-contrast: var(--color-white-1000);
  --color-surface-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-surface-brand-primary-less-subtle: var(--themes-brand-primary-less-subtle);
  --color-surface-brand-primary-light: var(--themes-brand-primary-light);
  --color-surface-brand-primary-main: var(--themes-brand-primary-main);
  --color-surface-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-surface-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-surface-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-surface-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-surface-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-surface-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-surface-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-surface-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-surface-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-surface-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-surface-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-surface-accent-subtle: var(--themes-accent-subtle);
  --color-surface-accent-light: var(--themes-accent-lighter);
  --color-surface-accent-main: var(--themes-accent-light);
  --color-surface-accent-dark: var(--themes-accent-main);
  --color-surface-accent-strong: var(--themes-accent-strong);
  --color-surface-accent-hover: var(--themes-accent-main);
  --color-surface-accent-pressed: var(--themes-accent-lighter);
  --color-surface-neutral-subtle: var(--themes-neutral-subtle);
  --color-surface-neutral-light: var(--themes-neutral-lighter);
  --color-surface-neutral-main: var(--themes-neutral-light);
  --color-surface-neutral-dark: var(--themes-neutral-main);
  --color-surface-neutral-strong: var(--themes-neutral-strong);
  --color-surface-neutral-hover: var(--themes-neutral-main);
  --color-surface-neutral-pressed: var(--themes-neutral-lighter);
  --color-surface-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-surface-action-primary-light: var(--themes-action-primary-light);
  --color-surface-action-primary-main: var(--themes-action-primary-main);
  --color-surface-action-primary-dark: var(--themes-action-primary-dark);
  --color-surface-action-primary-strong: var(--themes-action-primary-strong);
  --color-surface-action-primary-hover: var(--themes-action-primary-dark);
  --color-surface-action-primary-pressed: var(--themes-action-primary-light);
  --color-surface-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-surface-action-secondary-light: var(--themes-action-secondary-light);
  --color-surface-action-secondary-main: var(--themes-action-secondary-main);
  --color-surface-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-surface-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-surface-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-surface-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-surface-info-subtle: var(--themes-info-subtle);
  --color-surface-info-light: var(--themes-info-light);
  --color-surface-info-main: var(--themes-info-main);
  --color-surface-info-dark: var(--themes-info-dark);
  --color-surface-info-strong: var(--themes-info-strong);
  --color-surface-info-hover: var(--themes-info-dark);
  --color-surface-info-pressed: var(--themes-info-light);
  --color-surface-succes-subtle: var(--themes-success-subtle);
  --color-surface-succes-light: var(--themes-success-light);
  --color-surface-succes-main: var(--themes-success-main);
  --color-surface-succes-dark: var(--themes-success-dark);
  --color-surface-succes-strong: var(--themes-success-strong);
  --color-surface-succes-hover: var(--themes-success-dark);
  --color-surface-succes-pressed: var(--themes-success-light);
  --color-surface-warning-subtle: var(--themes-warning-subtle);
  --color-surface-warning-light: var(--themes-warning-light);
  --color-surface-warning-main: var(--themes-warning-main);
  --color-surface-warning-dark: var(--themes-warning-dark);
  --color-surface-warning-strong: var(--themes-warning-strong);
  --color-surface-warning-hover: var(--themes-warning-dark);
  --color-surface-warning-pressed: var(--themes-warning-light);
  --color-surface-error-subtle: var(--themes-error-subtle);
  --color-surface-error-light: var(--themes-error-light);
  --color-surface-error-main: var(--themes-error-main);
  --color-surface-error-dark: var(--themes-error-dark);
  --color-surface-error-strong: var(--themes-error-strong);
  --color-surface-error-hover: var(--themes-error-dark);
  --color-surface-error-pressed: var(--themes-error-light);
  --color-text-default-title-hover: var(--themes-action-primary-main);
  --color-text-default-subtitle-hover: var(--themes-action-secondary-main);
  --color-text-default-helper: var(--themes-neutral-dark);
  --color-text-default-caption: var(--themes-neutral-main);
  --color-text-default-placeholder: var(--themes-neutral-main);
  --color-text-default-disabled: var(--themes-neutral-light);
  --color-text-default-contrast: var(--color-white-1000);
  --color-text-link-primary-pressed: var(--themes-action-primary-light);
  --color-text-link-primary-visited: var(--themes-action-primary-main);
  --color-text-link-primary-contrast: var(--themes-action-primary-on-primary);
  --color-text-link-primary-contrast-hover: var(--themes-action-primary-light);
  --color-text-link-primary-contrast-pressed: var(--themes-action-primary-subtle);
  --color-text-link-primary-contrast-visited: var(--themes-action-primary-on-primary);
  --color-text-link-secondary-default: var(--themes-action-secondary-main);
  --color-text-link-secondary-hover: var(--themes-action-secondary-dark);
  --color-text-link-secondary-pressed: var(--themes-action-secondary-light);
  --color-text-link-secondary-visited: var(--themes-action-secondary-main);
  --color-text-link-secondary-contrast: var(--themes-action-secondary-on-secondary);
  --color-text-link-secondary-contrast-hover: var(--themes-action-secondary-light);
  --color-text-link-secondary-contrast-pressed: var(--themes-action-secondary-subtle);
  --color-text-link-secondary-contrast-visited: var(--themes-action-secondary-on-secondary);
  --color-text-link-neutral-pressed: var(--themes-neutral-main);
  --color-text-link-neutral-visited: var(--themes-neutral-strong);
  --color-text-link-neutral-contrast: var(--themes-neutral-subtle);
  --color-text-link-neutral-contrast-hover: var(--themes-neutral-light);
  --color-text-link-neutral-contrast-pressed: var(--themes-neutral-main);
  --color-text-link-neutral-contrast-visited: var(--themes-neutral-subtle);
  --color-text-link-menu-default: var(--themes-neutral-strong);
  --color-text-link-menu-hover: var(--themes-action-primary-main);
  --color-text-link-menu-active: var(--themes-neutral-strong);
  --color-text-link-menu-focus: var(--themes-neutral-strong);
  --color-text-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-text-brand-primary-light: var(--themes-brand-primary-light);
  --color-text-brand-primary-main: var(--themes-brand-primary-main);
  --color-text-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-text-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-text-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-text-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-text-brand-primary-on-primary: var(--themes-brand-primary-on-primary);
  --color-text-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-text-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-text-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-text-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-text-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-text-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-text-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-text-brand-secondary-on-secondary: var(--themes-brand-secondary-on-secondary);
  --color-text-accent-subtle: var(--themes-accent-subtle);
  --color-text-accent-light: var(--themes-accent-light);
  --color-text-accent-main: var(--themes-accent-dark);
  --color-text-accent-dark: var(--themes-accent-darker);
  --color-text-accent-strong: var(--themes-accent-strong);
  --color-text-accent-hover: var(--themes-accent-darker);
  --color-text-accent-pressed: var(--themes-accent-light);
  --color-text-accent-on-accent: var(--themes-accent-on-accent);
  --color-text-neutral-subtle: var(--themes-neutral-subtle);
  --color-text-neutral-light: var(--themes-neutral-main);
  --color-text-neutral-main: var(--themes-neutral-dark);
  --color-text-neutral-dark: var(--themes-neutral-darker);
  --color-text-neutral-strong: var(--themes-neutral-strong);
  --color-text-neutral-hover: var(--themes-neutral-darker);
  --color-text-neutral-pressed: var(--themes-neutral-main);
  --color-text-neutral-on-neutral: var(--color-white-1000);
  --color-text-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-text-action-primary-light: var(--themes-action-primary-light);
  --color-text-action-primary-main: var(--themes-action-primary-main);
  --color-text-action-primary-dark: var(--themes-action-primary-dark);
  --color-text-action-primary-strong: var(--themes-action-primary-strong);
  --color-text-action-primary-hover: var(--themes-action-primary-dark);
  --color-text-action-primary-pressed: var(--themes-action-primary-light);
  --color-text-action-primary-on-primary: var(--themes-action-primary-on-primary);
  --color-text-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-text-action-secondary-light: var(--themes-action-secondary-light);
  --color-text-action-secondary-main: var(--themes-action-secondary-main);
  --color-text-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-text-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-text-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-text-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-text-action-secondary-on-secondary: var(--themes-action-secondary-on-secondary);
  --color-text-info-subtle: var(--themes-info-subtle);
  --color-text-info-light: var(--themes-info-light);
  --color-text-info-main: var(--themes-info-main);
  --color-text-info-dark: var(--themes-info-dark);
  --color-text-info-strong: var(--themes-info-strong);
  --color-text-info-hover: var(--themes-info-dark);
  --color-text-info-pressed: var(--themes-info-light);
  --color-text-info-on-info: var(--themes-info-on-info);
  --color-text-succes-subtle: var(--themes-success-subtle);
  --color-text-succes-light: var(--themes-success-light);
  --color-text-succes-main: var(--themes-success-main);
  --color-text-succes-dark: var(--themes-success-dark);
  --color-text-succes-strong: var(--themes-success-strong);
  --color-text-succes-hover: var(--themes-success-dark);
  --color-text-succes-pressed: var(--themes-success-light);
  --color-text-succes-on-succes: var(--themes-success-on-success);
  --color-text-warning-subtle: var(--themes-warning-subtle);
  --color-text-warning-light: var(--themes-warning-light);
  --color-text-warning-main: var(--themes-warning-main);
  --color-text-warning-dark: var(--themes-warning-dark);
  --color-text-warning-strong: var(--themes-warning-strong);
  --color-text-warning-hover: var(--themes-warning-dark);
  --color-text-warning-pressed: var(--themes-warning-light);
  --color-text-warning-on-warning: var(--themes-warning-on-warning);
  --color-text-error-subtle: var(--themes-error-subtle);
  --color-text-error-light: var(--themes-error-light);
  --color-text-error-main: var(--themes-error-main);
  --color-text-error-dark: var(--themes-error-dark);
  --color-text-error-strong: var(--themes-error-strong);
  --color-text-error-hover: var(--themes-error-dark);
  --color-text-error-pressed: var(--themes-error-light);
  --color-text-error-on-error: var(--themes-error-on-error);
  --color-border-default-light: var(--themes-neutral-lighter);
  --color-border-default-main: var(--themes-neutral-light);
  --color-border-default-strong: var(--themes-neutral-strong);
  --color-border-default-hover: var(--themes-neutral-dark);
  --color-border-default-pressed: var(--color-icon-action-primary-subtle);
  --color-border-default-active: var(--color-icon-action-primary-subtle);
  --color-border-default-focus: var(--color-icon-action-primary-light);
  --color-border-default-disabled: var(--themes-neutral-light);
  --color-border-default-contrast: var(--color-white-1000);
  --color-border-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-border-brand-primary-light: var(--themes-brand-primary-light);
  --color-border-brand-primary-main: var(--themes-brand-primary-main);
  --color-border-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-border-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-border-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-border-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-border-brand-primary-on-primary: var(--themes-brand-primary-on-primary);
  --color-border-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-border-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-border-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-border-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-border-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-border-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-border-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-border-brand-secondary-on-secondary: var(--themes-brand-secondary-on-secondary);
  --color-border-accent-subtle: var(--themes-accent-subtle);
  --color-border-accent-light: var(--themes-accent-light);
  --color-border-accent-main: var(--themes-accent-main);
  --color-border-accent-dark: var(--themes-accent-dark);
  --color-border-accent-strong: var(--themes-accent-strong);
  --color-border-accent-hover: var(--themes-accent-dark);
  --color-border-accent-pressed: var(--themes-accent-light);
  --color-border-accent-on-accent: var(--themes-accent-on-accent);
  --color-border-neutral-subtle: var(--themes-neutral-subtle);
  --color-border-neutral-light: var(--themes-neutral-lighter);
  --color-border-neutral-main: var(--themes-neutral-light);
  --color-border-neutral-dark: var(--themes-neutral-main);
  --color-border-neutral-strong: var(--themes-neutral-strong);
  --color-border-neutral-hover: var(--themes-neutral-dark);
  --color-border-neutral-pressed: var(--themes-neutral-light);
  --color-border-neutral-on-neutral: var(--color-white-1000);
  --color-border-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-border-action-primary-light: var(--themes-action-primary-light);
  --color-border-action-primary-main: var(--themes-action-primary-main);
  --color-border-action-primary-dark: var(--themes-action-primary-dark);
  --color-border-action-primary-strong: var(--themes-action-primary-strong);
  --color-border-action-primary-hover: var(--themes-action-primary-dark);
  --color-border-action-primary-pressed: var(--themes-action-primary-light);
  --color-border-action-primary-on-primary: var(--themes-action-primary-on-primary);
  --color-border-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-border-action-secondary-light: var(--themes-action-secondary-light);
  --color-border-action-secondary-main: var(--themes-action-secondary-main);
  --color-border-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-border-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-border-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-border-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-border-action-secondary-on-secondary: var(--themes-action-secondary-on-secondary);
  --color-border-info-subtle: var(--themes-info-subtle);
  --color-border-info-light: var(--themes-info-light);
  --color-border-info-main: var(--themes-info-main);
  --color-border-info-dark: var(--themes-info-dark);
  --color-border-info-strong: var(--themes-info-strong);
  --color-border-info-hover: var(--themes-info-dark);
  --color-border-info-pressed: var(--themes-info-light);
  --color-border-info-on-info: var(--themes-info-on-info);
  --color-border-succes-subtle: var(--themes-success-subtle);
  --color-border-succes-light: var(--themes-success-light);
  --color-border-succes-main: var(--themes-success-main);
  --color-border-succes-dark: var(--themes-success-dark);
  --color-border-succes-strong: var(--themes-success-strong);
  --color-border-succes-hover: var(--themes-success-dark);
  --color-border-succes-pressed: var(--themes-success-light);
  --color-border-succes-on-succes: var(--themes-success-on-success);
  --color-border-warning-subtle: var(--themes-warning-subtle);
  --color-border-warning-light: var(--themes-warning-light);
  --color-border-warning-main: var(--themes-warning-main);
  --color-border-warning-dark: var(--themes-warning-dark);
  --color-border-warning-strong: var(--themes-warning-strong);
  --color-border-warning-hover: var(--themes-warning-dark);
  --color-border-warning-pressed: var(--themes-warning-light);
  --color-border-warning-on-warning: var(--themes-warning-on-warning);
  --color-border-error-subtle: var(--themes-error-subtle);
  --color-border-error-light: var(--themes-error-light);
  --color-border-error-main: var(--themes-error-main);
  --color-border-error-dark: var(--themes-error-dark);
  --color-border-error-strong: var(--themes-error-strong);
  --color-border-error-hover: var(--themes-error-dark);
  --color-border-error-pressed: var(--themes-error-light);
  --color-border-error-on-error: var(--themes-error-on-error);
  --color-icon-default-subtle: var(--themes-neutral-subtle);
  --color-icon-default-main: var(--color-icon-action-primary-main);
  --color-icon-default-hover: var(--color-icon-action-primary-hover);
  --color-icon-default-pressed: var(--color-icon-action-primary-pressed);
  --color-icon-default-active: var(--color-icon-action-primary-subtle);
  --color-icon-default-focus: var(--color-icon-action-primary-light);
  --color-icon-default-neutral: var(--themes-neutral-main);
  --color-icon-default-strong: var(--themes-neutral-strong);
  --color-icon-default-disabled: var(--themes-neutral-light);
  --color-icon-default-contrast: var(--color-white-1000);
  --color-icon-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-icon-brand-primary-light: var(--themes-brand-primary-light);
  --color-icon-brand-primary-main: var(--themes-brand-primary-main);
  --color-icon-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-icon-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-icon-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-icon-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-icon-brand-primary-on-primary: var(--themes-brand-primary-on-primary);
  --color-icon-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-icon-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-icon-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-icon-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-icon-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-icon-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-icon-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-icon-brand-secondary-on-secondary: var(--themes-brand-secondary-on-secondary);
  --color-icon-accent-subtle: var(--themes-accent-subtle);
  --color-icon-accent-light: var(--themes-accent-light);
  --color-icon-accent-main: var(--themes-accent-dark);
  --color-icon-accent-dark: var(--themes-accent-darker);
  --color-icon-accent-strong: var(--themes-accent-strong);
  --color-icon-accent-hover: var(--themes-accent-darker);
  --color-icon-accent-pressed: var(--themes-accent-light);
  --color-icon-accent-on-accent: var(--themes-accent-on-accent);
  --color-icon-neutral-subtle: var(--themes-neutral-subtle);
  --color-icon-neutral-light: var(--themes-neutral-main);
  --color-icon-neutral-main: var(--themes-neutral-dark);
  --color-icon-neutral-dark: var(--themes-neutral-darker);
  --color-icon-neutral-strong: var(--themes-neutral-strong);
  --color-icon-neutral-hover: var(--themes-neutral-darker);
  --color-icon-neutral-pressed: var(--themes-neutral-main);
  --color-icon-neutral-on-neutral: var(--color-white-1000);
  --color-icon-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-icon-action-primary-light: var(--themes-action-primary-light);
  --color-icon-action-primary-main: var(--themes-action-primary-main);
  --color-icon-action-primary-dark: var(--themes-action-primary-dark);
  --color-icon-action-primary-strong: var(--themes-action-primary-strong);
  --color-icon-action-primary-hover: var(--themes-action-primary-dark);
  --color-icon-action-primary-pressed: var(--themes-action-primary-light);
  --color-icon-action-primary-on-primary: var(--themes-action-primary-on-primary);
  --color-icon-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-icon-action-secondary-light: var(--themes-action-secondary-light);
  --color-icon-action-secondary-main: var(--themes-action-secondary-main);
  --color-icon-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-icon-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-icon-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-icon-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-icon-action-secondary-on-secondary: var(--themes-action-secondary-on-secondary);
  --color-icon-info-subtle: var(--themes-info-subtle);
  --color-icon-info-light: var(--themes-info-light);
  --color-icon-info-main: var(--themes-info-main);
  --color-icon-info-dark: var(--themes-info-dark);
  --color-icon-info-strong: var(--themes-info-strong);
  --color-icon-info-hover: var(--themes-info-dark);
  --color-icon-info-pressed: var(--themes-info-light);
  --color-icon-info-on-info: var(--themes-info-on-info);
  --color-icon-succes-subtle: var(--themes-success-subtle);
  --color-icon-succes-light: var(--themes-success-light);
  --color-icon-succes-main: var(--themes-success-main);
  --color-icon-succes-dark: var(--themes-success-dark);
  --color-icon-succes-strong: var(--themes-success-strong);
  --color-icon-succes-hover: var(--themes-success-dark);
  --color-icon-succes-pressed: var(--themes-success-light);
  --color-icon-succes-on-succes: var(--themes-success-on-success);
  --color-icon-warning-subtle: var(--themes-warning-subtle);
  --color-icon-warning-light: var(--themes-warning-light);
  --color-icon-warning-main: var(--themes-warning-main);
  --color-icon-warning-dark: var(--themes-warning-dark);
  --color-icon-warning-strong: var(--themes-warning-strong);
  --color-icon-warning-hover: var(--themes-warning-dark);
  --color-icon-warning-pressed: var(--themes-warning-light);
  --color-icon-warning-on-warning: var(--themes-warning-on-warning);
  --color-icon-error-subtle: var(--themes-error-subtle);
  --color-icon-error-light: var(--themes-error-light);
  --color-icon-error-main: var(--themes-error-main);
  --color-icon-error-dark: var(--themes-error-dark);
  --color-icon-error-strong: var(--themes-error-strong);
  --color-icon-error-hover: var(--themes-error-dark);
  --color-icon-error-pressed: var(--themes-error-light);
  --color-icon-error-on-error: var(--themes-error-on-error);
}

.error-page {
  text-align: center;
  margin: 0 auto var(--spacing-32);
  grid-column: lend / rend;
}

.error-page__top {
  margin: 0 auto var(--spacing-8);
  max-width: 270px;
  height: 72px;
  position: relative;
}

.error-page__top[data-has-decor-line="true"]:after {
  content: "";
  background-image: url("/dist-isport/assets/default/icons/icon-error-line.svg");
  width: 271px;
  height: 71px;
  position: absolute;
  bottom: 7px;
  left: -29px;
}

@media screen and (max-width: 679px) {
  .error-page__top[data-has-decor-line="true"]:after {
    background-image: url("/dist-isport/assets/default/icons/icon-error-line-mobile.svg");
    width: 230px;
    height: 167px;
    bottom: -38px;
    left: 0;
  }
}

.error-page__decor-line {
  flex-shrink: 0;
  width: 269.912px;
  height: 71.607px;
  position: absolute;
  bottom: -3px;
  right: 24px;
}

.error-page__decor-line path {
  stroke: var(--color-border-error-subtle);
}

.error-page__number {
  text-align: center;
  background: var(--gradient-2, linear-gradient(90deg, #30e890 0%, #4ddfe6 100%));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 60px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 679px) {
  .error-page__number {
    font-size: 48px;
  }
}

.error-page__title {
  margin-bottom: var(--spacing-12);
  color: var(--color-text-error-subtitle);
  font: var(--font-desktop-h3);
}

.error-page__description {
  color: var(--color-text-error-body);
  margin-bottom: var(--spacing-24);
  font: var(--font-desktop-body2);
}

.fade-in {
  opacity: 0;
  transition: opacity .3s ease-out, transform .3s ease-out;
}

.fade-in--top {
  transform: translateY(-8px);
}

.fade-in--bottom {
  transform: translateY(8px);
}

.fade-in--is-visible {
  opacity: 1;
  transform: translateY(0);
}

.footer__themes {
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.footer__themes--top {
  padding-bottom: var(--spacing-16);
  border-bottom: 1px solid var(--color-border-divider-default);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__themes--bottom .footer__theme-link {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-default);
}

.footer__themes--bottom .footer__theme-separator {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-default);
  padding: var(--spacing-4);
}

.footer__categories {
  color: var(--primary-contrast);
  gap: var(--spacing-48);
  flex-direction: column;
  order: 1;
  display: flex;
}

.footer__categories--wrapper {
  justify-content: space-between;
  display: flex;
}

.footer__categories--wrapper .footer__category {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.footer__categories--wrapper .footer__category-main {
  padding-bottom: var(--spacing-12);
  border-bottom: 1px solid var(--color-border-default-light);
  margin-bottom: var(--spacing-12);
  font: var(--font-desktop-h4);
}

@media (max-width: 679px) {
  .footer__themes-logo img {
    display: none;
  }

  .footer__categories {
    order: 2;
  }

  .footer__categories--wrapper {
    gap: var(--spacing-48);
    flex-wrap: wrap;
  }

  .footer__categories--wrapper .footer__category {
    flex-basis: calc(50% - var(--spacing-24));
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .footer__themes-logo img {
    display: none;
  }

  .footer__categories {
    order: 2;
  }

  .footer__categories--wrapper {
    gap: var(--spacing-48);
    flex-wrap: wrap;
  }

  .footer__categories--wrapper .footer__category {
    flex-basis: calc(33.3333% - var(--spacing-48));
  }
}

.footer__contacts {
  gap: var(--spacing-40);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer__contacts .footer__contacts-links {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.footer__contacts .footer__contacts-links > a.footer__contacts-link {
  color: var(--primary-contrast);
  text-decoration: none;
}

.footer__contacts .footer__contacts-links > a.footer__contacts-link:hover {
  text-decoration: underline;
}

.footer__contacts .footer__contacts-divider {
  margin: 0 var(--spacing-16);
  height: var(--spacing-16);
}

.footer__applications-wrapper {
  display: flex;
}

.footer__applications {
  line-height: 0;
  display: flex;
}

.footer__social {
  gap: var(--spacing-12);
  display: flex;
}

.footer__social-link {
  background-color: #fff;
  border-radius: 51%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.footer__social-link img {
  display: block;
}

.footer__logo img {
  max-width: 104px;
  display: block;
}

@media (max-width: 679px) {
  .footer__contacts {
    order: 1;
  }

  .footer__contacts-links {
    font: var(--font-mobile-body2-bold);
    justify-content: center;
    gap: var(--spacing-16) 0;
  }

  .footer__contacts-link {
    padding: var(--spacing-8) 0;
    height: 20px;
  }

  .footer__applications-wrapper {
    gap: var(--spacing-40);
    flex-direction: column;
    align-items: center;
  }

  .footer__applications {
    gap: var(--spacing-24);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .footer__contacts {
    order: 1;
  }

  .footer__contacts-links {
    font: var(--font-mobile-body2-bold);
    justify-content: center;
  }

  .footer__contacts-link {
    padding: var(--spacing-8) 0;
  }

  .footer__applications-wrapper {
    gap: var(--spacing-40);
    flex-direction: column;
    align-items: center;
  }

  .footer__applications {
    gap: var(--spacing-24);
  }
}

.footer__copyright {
  text-align: center;
  justify-content: center;
  gap: var(--spacing-4);
  color: var(--background-paper-contrast);
  flex-wrap: wrap;
  order: 3;
  display: flex;
}

.footer__copyright a {
  color: var(--background-paper-contrast);
  text-decoration: none;
}

.footer__copyright a:hover {
  text-decoration: underline;
}

.footer__copyright a:not(:last-child):after {
  content: "|";
  margin-left: var(--spacing-4);
}

@media (max-width: 679px) {
  .footer__copyright {
    font: var(--font-mobile-meta-regular);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .footer__copyright {
    font: var(--font-mobile-meta-regular);
  }
}

body {
  background-color: var(--background-default);
}

body.body-hp {
  background: var(--color-surface-default-homepage, #f8fafc);
}

body.body-hp .container--main > main {
  background: var(--color-surface-default-homepage);
}

body.scrolling-disabled {
  height: 100%;
  overflow-y: hidden;
}

.container--main {
  grid-template-columns: auto 100% auto;
  grid-template-areas: "left-empty header-sticky right-empty"
                       "left-empty header-static right-empty"
                       "left-empty content right-empty"
                       "footer footer footer";
  gap: 0;
  display: grid;
}

@media (max-width: 679px) {
  .container--main {
    padding-top: var(--spacing-68);
    grid-template-rows: auto auto auto;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .container--main {
    padding-top: var(--spacing-68);
    grid-template-rows: auto auto auto;
  }
}

header {
  z-index: 800;
  background-color: var(--color-surface-header-inverted);
  grid-area: header-sticky;
  grid-template-columns: auto minmax(0, 1080px) auto;
  grid-template-areas: "header-left header-center header-right";
  transition: all 3ms;
  display: grid;
  position: fixed;
  top: 0;
}

header.is-pinned {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
}

header .header-wrapper {
  grid-area: header-center;
}

.header-static {
  background: var(--color-surface-default-homepage);
  padding: 0 var(--spacing-16);
  grid-area: header-static;
  grid-template-columns: [lend] 1fr[cdivider] 1fr[rend];
  display: grid;
  position: relative;
}

.wrapper {
  gap: var(--spacing-24);
  z-index: 1;
  text-align: left;
  background-color: var(--color-surface-default-page);
  grid-area: content;
  grid-template-columns: [lend] 1fr[cdivider] 1fr[rend];
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: grid;
  position: relative;
}

@media (max-width: 679px) {
  .wrapper {
    padding: 0 var(--spacing-16) var(--spacing-32) var(--spacing-16);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .wrapper {
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-48) var(--spacing-16);
  }
}

.wrapper--article-detail {
  margin: 0;
  display: block;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .wrapper--article-detail {
    margin-top: calc(-1 * var(--spacing-16));
    padding-top: 0;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .main--first-content, .main--second-content {
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
    padding: 0;
  }
}

.wrapper__aside {
  grid-area: 1 / 3 / 6;
  height: 100%;
  display: none;
}

.grid-113, .grid-233 {
  gap: var(--spacing-24);
  grid-column: lend / rend;
  grid-template-rows: auto;
  grid-auto-rows: min-content;
  display: grid;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .grid-113, .grid-233 {
    gap: var(--spacing-32);
  }
}

@media (max-width: 679px) {
  .grid-233 {
    grid-template-columns: [lend] 1fr[cdivider] 1fr[rend];
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .grid-233 {
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
  }
}

footer {
  z-index: 0;
  grid-area: 4 / footer;
  grid-template-columns: auto minmax(0, 1080px) auto;
  grid-template-areas: "footer-left footer-center footer-right";
  display: grid;
}

footer:not(.footer--blesksport) {
  background: var(--color-surface-default-strong);
}

footer > .footer-wrapper {
  padding: var(--spacing-40) var(--spacing-16);
  gap: var(--spacing-40);
  flex-direction: column;
  grid-area: footer-center;
  display: flex;
}

@media (min-width: 680px) and (max-width: 1079px) {
  footer > .footer-wrapper {
    padding: var(--spacing-40) var(--spacing-44);
  }
}

header {
  box-shadow: 0 4px 20px #0000001a;
}

body[data-header-nozindex="true"] .wrapper {
  z-index: 2 !important;
}

.header {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16);
  row-gap: var(--spacing-12);
  padding: var(--spacing-12) var(--spacing-16) var(--spacing-20);
  background-color: var(--color-surface-header-inverted);
  z-index: 4;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.header .flex-line-break {
  display: none;
}

@media (max-width: 679px) {
  .header {
    padding: var(--spacing-8) var(--spacing-16);
    z-index: 811;
  }

  .header .header__image {
    height: 36px;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .header {
    padding: var(--spacing-8) var(--spacing-24);
    row-gap: var(--spacing-12);
    z-index: 811;
  }
}

@media (max-width: 350px) {
  header .header-wrapper .header {
    padding: var(--spacing-8) var(--spacing-8);
    gap: var(--spacing-8);
  }
}

.header-search {
  display: none;
}

.header-search .header-search__magnifier {
  cursor: pointer;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .header-search {
    display: block;
    position: relative;
  }
}

.header-socials {
  display: none;
}

.header-user {
  position: relative;
}

.header-user .header-user-icon__icon {
  width: 24px;
  height: 24px;
}

.header-user .header-user-icon__avatar {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.header-user .header-user__link {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.header-user .header-user__link .header-user-icon__avatar {
  margin: var(--spacing-8);
}

.header-user .header-user__text {
  text-decoration: none;
  display: inline-block;
}

.header-user .header-user__text .header-user__link {
  color: var(--color-text-default-body);
  text-decoration: none;
}

.header-user .header-user__text:hover {
  text-decoration: underline;
}

.header-user:hover .header-user__menu {
  display: flex;
}

.header-user .header-user__menu {
  right: -10px;
  top: calc(24px + 2 * var(--spacing-12));
  background: var(--color-surface-default-contrast);
  z-index: 3;
  border-radius: 6px;
  flex-direction: column;
  width: 260px;
  display: none;
  position: absolute;
  box-shadow: 0 4px 15px #0000001a;
}

.header-user .header-user__menu:before {
  content: "";
  border-color: transparent transparent var(--color-surface-default-contrast);
  border-style: solid;
  border-width: 10px;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: -20px;
  right: 20px;
}

.header-user .header-user__menu:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 20px;
  display: block;
  position: absolute;
  top: -20px;
}

.header-user .header-user__menu > a {
  color: var(--color-text-default-body);
  text-decoration: none;
}

.header-user .header-user__menu > a:hover {
  background: var(--color-surface-default-subtle);
}

.header-user .header-user__menu > a:last-child:hover {
  border-radius: 0 0 6px 6px;
}

.header-user .header-user__info {
  align-items: center;
  gap: var(--spacing-16);
  padding: var(--spacing-16) 0;
  margin: 0 var(--spacing-16);
  border-bottom: 1px solid var(--color-border-default-light);
  border-radius: 6px 6px 0 0;
  flex-wrap: wrap;
  display: flex;
}

.header-user .header-user__subscription {
  text-transform: uppercase;
}

.header-user .header-user__detail {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.header-user .header-user__settings {
  gap: var(--spacing-16);
  padding: var(--spacing-16);
  align-items: center;
  display: flex;
}

.header-user .header-user__discussion {
  gap: var(--spacing-16);
  padding: var(--spacing-16);
  display: flex;
}

.header-user .header-user__logout {
  gap: var(--spacing-16);
  padding: var(--spacing-16) 0;
  margin: 0 var(--spacing-16);
  border-top: 1px solid var(--color-border-default-light);
  display: flex;
}

@media (max-width: 679px) {
  .header-user__settings-text, .header-user__discussion-text, .header-user__logout-text {
    padding-top: var(--spacing-4);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .header-user__settings-text, .header-user__discussion-text, .header-user__logout-text {
    padding-top: var(--spacing-2);
  }
}

.header-logo {
  justify-content: center;
  align-items: center;
  transition: all 2.5ms;
  display: flex;
}

.header-logo .logo__image {
  vertical-align: middle;
}

@media (max-width: 679px) {
  .header-logo {
    justify-content: flex-start;
    margin-right: auto;
  }

  .header-logo .logo__image {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 40px;
    margin-right: auto;
  }
}

@media (max-width: 350px) {
  .header-logo {
    margin-right: auto;
  }

  .header-logo .logo__image {
    width: 100%;
    height: auto;
    max-height: 36px;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .header-logo {
    margin-right: auto;
  }
}

.header-controls {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.header-subscription {
  margin-right: var(--spacing-8);
}

.header-desktop-navigation {
  flex-grow: 1;
  display: flex;
}

@media (max-width: 760px) {
  .header-desktop-navigation {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .header-desktop-navigation:not(:has(.overflow-menu.active)) {
    overflow: hidden;
  }

  .header-menu {
    max-width: 99%;
  }
}

.header-menu {
  width: 100%;
}

.header-menu__list {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.header-menu__item {
  padding-left: var(--spacing-16);
  padding-right: var(--spacing-16);
  font: var(--font-desktop-body1-bold);
  position: relative;
}

.header-menu__item:first-of-type {
  padding-left: 0;
}

.header-menu__link {
  color: var(--color-text-link-menu-default);
  font: var(--font-desktop-menu-item);
  flex-wrap: nowrap;
  align-items: center;
  width: max-content;
  text-decoration: none;
  display: flex;
}

.header-menu__link--active, .header-menu__link--active:hover {
  text-underline-offset: var(--spacing-4);
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.header-menu__link:hover {
  color: var(--color-text-link-menu-hover);
}

.header-menu__link:active {
  color: var(--color-text-link-menu-default);
}

.overflow-menu {
  padding-right: 0;
}

.overflow-menu__arrow {
  background: url("/dist-isport/assets/default/icon-chevron-down.svg") center / cover no-repeat;
  width: 16px;
  height: 16px;
  transition: transform .25s;
  display: block;
}

.active .overflow-menu__arrow {
  transform: rotate(-180deg);
}

.overflow-menu__list {
  z-index: 9000;
  padding: var(--spacing-8) 0;
  background-color: var(--color-surface-default-contrast);
  box-shadow: var(--shadow-m);
  border-radius: 4px;
  width: max-content;
  min-width: 130px;
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
}

.overflow-menu__list .header-menu__link {
  padding: var(--spacing-8) var(--spacing-16);
  text-align: left;
  color: var(--color-text-default-body);
  width: 100%;
  display: block;
}

.overflow-menu__list .header-menu__link .header-link__text {
  font-weight: 400;
  font-family: var(--body-md-font-family) !important;
}

.overflow-menu__list .header-menu__link .header-link__text:after {
  display: none;
}

.overflow-menu__list .header-menu__link:hover, .overflow-menu__list .header-menu__link:active, .overflow-menu__list .header-menu__link:focus {
  background-color: var(--color-surface-brand-primary-subtle);
}

.overflow-menu__list .header-menu__link:hover .header-link__text, .overflow-menu__list .header-menu__link:active .header-link__text, .overflow-menu__list .header-menu__link:focus .header-link__text {
  color: var(--color-text-default-body) !important;
  background-image: none !important;
}

.overflow-menu__list .header-menu__link[data-is-selected="true"] .header-link__text {
  font-family: var(--body-md-bold-font-family) !important;
}

.overflow-menu .header-menu__item {
  padding: 0;
}

.overflow-menu.active .overflow-menu__list {
  display: block;
}

.mobile-navigation {
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-16);
  grid-column: lend / rend;
  width: 100%;
  display: flex;
}

@media (min-width: 761px) {
  .mobile-navigation {
    display: none;
  }
}

.header-menu-hamburger {
  padding: var(--spacing-8);
  appearance: none;
  background-color: #0000;
  border: 0;
  align-items: center;
  display: flex;
  position: relative;
}

.header-menu-hamburger__icon {
  cursor: pointer;
  padding: var(--spacing-2);
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 25px;
  display: flex;
}

.header-menu-hamburger__icon .hamburger-line {
  background: var(--color-icon-icon-button-primary-gradient-inverted-text-default);
  pointer-events: none;
  transform-origin: 0;
  border-radius: 2px;
  width: 100%;
  height: 2px;
  transition: transform .4s ease-in-out;
}

.header-menu-hamburger__icon.opened .hamburger-line.line1 {
  transform-origin: 2%;
  transform: rotate(45deg);
}

.header-menu-hamburger__icon.opened .hamburger-line.line2 {
  transform: scaleY(0);
}

.header-menu-hamburger__icon.opened .hamburger-line.line3 {
  transform-origin: 2%;
  transform: rotate(-45deg);
}

.header-menu-hamburger:hover .header-menu-hamburger__icon .hamburger-line {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
}

.megamenu-wrapper {
  z-index: 810;
  background: var(--color-surface-megamenu-default);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.megamenu-wrapper.opened {
  width: 1080px;
  max-height: calc(100vh - 64px);
  display: grid;
  overflow: hidden auto;
}

@media (max-width: 679px) {
  .megamenu-wrapper {
    top: var(--spacing-64);
    padding: var(--spacing-24) var(--spacing-16);
    row-gap: var(--spacing-32);
    grid-template: [r1-start] "search" max-content [r1-end]
                   [r2-start] "categories" max-content [r2-end]
                   [r2-start] "other-categories" max-content [r2-end]
                   [r3-start] "stickers" max-content [r3-end]
                   [r4-start] "print" max-content [r4-end]
                   [r5-start] "divider" max-content [r5-end]
                   [r6-start] "applications" max-content [r6-end]
                   [r7-start] "social" max-content [r7-end]
                   [r8-start] "contact" max-content [r8-end]
                   [r9-start] "newsletter" max-content [r9-end]
                   / 1fr;
  }

  .megamenu-wrapper.opened {
    max-width: 100%;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-wrapper {
    top: var(--spacing-64);
    padding: var(--spacing-32) var(--spacing-44);
    gap: var(--spacing-32);
    grid-template: [r1-start] "search search" max-content [r1-end]
                   [r2-start] "stickers stickers" max-content [r2-end]
                   [r3-start] "categories categories" max-content [r3-end]
                   [r4-start] "other-categories other-categories" max-content [r4-end]
                   [r5-start] "divider divider" max-content [r5-end]
                   [r6-start] "social print" max-content [r6-end]
                   [r7-start] "newsletter print" max-content [r7-end]
                   [r8-start] "contact print" max-content [r8-end]
                   [r9-start] "applications print" max-content [r9-end]
                   / 1fr 1fr;
  }

  .megamenu-wrapper.opened {
    max-width: 100%;
  }
}

.megamenu-close {
  grid-area: close;
}

@media (max-width: 679px) {
  .megamenu-close {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-close {
    display: none;
  }
}

.megamenu-stickers {
  align-items: flex-start;
  gap: var(--spacing-8);
  flex-wrap: wrap;
  grid-area: stickers;
  display: flex;
}

.megamenu-search {
  grid-area: search;
}

.megamenu-search__form {
  position: relative;
}

@media (max-width: 679px) {
  .megamenu-search__form .search {
    padding: 0;
  }

  .megamenu-search__form .search__submit {
    right: var(--spacing-16);
  }
}

.megamenu-categories {
  grid-area: categories;
  align-items: flex-start;
  display: flex;
}

.megamenu-categories__item, .megamenu-categories .megamenu-subcategories {
  flex-direction: column;
  display: flex;
}

.megamenu-categories--other {
  grid-area: other-categories;
  display: block;
}

.megamenu-categories--other__item {
  display: block;
}

.megamenu-categories--other .megamenu-subcategories {
  justify-content: flex-start;
  gap: var(--spacing-10) var(--spacing-40);
  flex-flow: wrap;
}

@media (max-width: 679px) {
  .megamenu-categories {
    gap: var(--spacing-24);
    row-gap: var(--spacing-32);
    flex-flow: wrap;
  }

  .megamenu-categories__item {
    row-gap: var(--spacing-12);
    flex-basis: calc(50% - var(--spacing-12));
  }

  .megamenu-categories__item-title, .megamenu-categories__item-title > .text-link__text {
    font-size: var(--typography-mobile-heading-lg-font-size);
  }

  .megamenu-categories .megamenu-subcategories {
    gap: var(--spacing-8);
  }

  .megamenu-categories--other .megamenu-subcategories {
    gap: var(--spacing-10) var(--spacing-40);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-categories {
    gap: var(--spacing-24);
    flex-wrap: wrap;
  }

  .megamenu-categories__item {
    row-gap: var(--spacing-12);
    flex-basis: calc(25% - 20px);
  }

  .megamenu-categories .megamenu-subcategories {
    gap: var(--spacing-8);
  }

  .megamenu-categories--other .megamenu-subcategories {
    gap: var(--spacing-10) var(--spacing-40);
  }
}

.megamenu-print {
  flex-flow: column;
  grid-area: print;
  align-items: center;
  display: flex;
}

.megamenu-print__title {
  color: var(--text-primary);
  text-align: center;
}

.megamenu-print__subtitle {
  color: var(--text-primary);
  line-height: 20px;
  text-decoration: underline;
}

.megamenu-print__subtitle > a {
  color: var(--text-primary);
}

.megamenu-print__covers {
  justify-content: center;
  display: flex;
}

.megamenu-print__button {
  text-align: center;
  text-decoration: none;
}

@media (max-width: 679px) {
  .megamenu-print {
    gap: var(--spacing-16);
  }

  .megamenu-print__title {
    font: var(--font-mobile-h4);
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .megamenu-print__subtitle {
    font: var(--font-mobile-body1);
  }

  .megamenu-print__covers {
    gap: var(--spacing-24);
    flex-wrap: wrap;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-print {
    gap: var(--spacing-12);
  }

  .megamenu-print__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-print__subtitle {
    font: var(--font-desktop-body2);
  }

  .megamenu-print__covers {
    gap: var(--spacing-16);
    flex-direction: column;
  }
}

.megamenu-logo {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  height: 40px;
  transition: all 2.5ms;
  display: flex;
}

.megamenu-logo .megamenu-logo__image {
  vertical-align: middle;
  height: 40px;
}

@media (max-width: 679px) {
  .megamenu-logo {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-logo {
    display: none;
  }
}

.megamenu-divider {
  grid-area: divider;
}

.megamenu-applications {
  align-items: center;
  gap: var(--spacing-32);
  grid-area: applications;
  display: flex;
}

.megamenu-applications__wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.megamenu-applications__title {
  color: var(--color-white-1000);
}

.megamenu-applications__download {
  gap: var(--spacing-8);
  align-items: center;
  display: flex;
}

.megamenu-applications__download a {
  line-height: 0;
  display: block;
}

@media (max-width: 679px) {
  .megamenu-applications {
    flex-direction: column;
    align-items: center;
  }

  .megamenu-applications__wrapper {
    align-items: center;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-applications {
    flex-direction: column;
    align-items: flex-start;
  }

  .megamenu-applications__wrapper {
    align-items: flex-start;
  }
}

.megamenu-social {
  grid-area: social;
  align-items: center;
  display: flex;
}

@media (max-width: 679px) {
  .megamenu-social {
    justify-content: center;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-social {
    justify-content: flex-start;
  }
}

.megamenu-contact {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
  flex-flow: wrap;
  grid-area: contact;
  display: flex;
}

.megamenu-contact__title {
  color: var(--color-white-1000);
}

@media (max-width: 679px) {
  .megamenu-contact {
    justify-content: center;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-contact {
    justify-content: flex-start;
  }
}

.megamenu-backdrop--header {
  display: none;
}

.megamenu-backdrop {
  opacity: 0;
  pointer-events: none;
  background: #00000063;
  width: 100%;
  height: 0;
  transition: opacity .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.megamenu-backdrop--active {
  z-index: 2;
  opacity: 1;
  pointer-events: all;
  height: 100%;
}

.megamenu-newsletter {
  flex-direction: column;
  grid-area: newsletter;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.megamenu-newsletter__title {
  color: var(--color-white-1000);
}

@media (max-width: 679px) {
  .megamenu-newsletter {
    align-items: center;
  }

  .megamenu-newsletter__email {
    padding-bottom: 80px;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .megamenu-newsletter {
    align-items: flex-start;
  }
}

.section--columns-233 {
  grid-column: lend / rend;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .section--columns-233 {
    grid-column: 1 / 4;
  }
}

.section--columns-232 {
  grid-column: lend / rend;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .section--columns-232 {
    grid-column: 1 / 4;
  }
}

.article-header {
  color: var(--themes-action-inverted-on-inverted);
  gap: var(--spacing-24);
  background-color: var(--color-surface-default-content-top);
  flex-flow: column;
  grid-area: auto / lend / auto / rdivider;
  display: flex;
}

@media (max-width: 679px) {
  .article-header {
    margin: 0 calc(-1 * var(--spacing-16));
    padding: 0 var(--spacing-16);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .article-header {
    margin: 0 calc(-1 * var(--spacing-16));
    padding: var(--spacing-16) var(--spacing-16) 0 var(--spacing-16);
  }
}

.article-header--blesksport {
  background-color: #0000;
}

@media (max-width: 679px) {
  .article-header--blesksport {
    gap: 0;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .article-header--blesksport {
    margin: 0;
  }
}

.article-layout {
  gap: var(--spacing-24);
  padding: 0 var(--spacing-16);
  grid-area: auto / lend / auto / rdivider;
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.article-layout .grid-position-4 {
  display: none;
}

.article-layout .grid-position-3 {
  gap: var(--spacing-24);
  flex-flow: column;
  display: flex;
}

@media (max-width: 679px) {
  .article-layout {
    flex-direction: column;
    padding: 0;
    display: flex;
  }
}

.article-layout--blesksport {
  margin-bottom: var(--spacing-24);
}

@media (min-width: 680px) and (max-width: 1079px) {
  .article-layout--blesksport {
    grid-template-columns: minmax(0, 1fr);
  }
}

.progress-bar {
  z-index: 9999;
  width: 100%;
  height: 3px;
  transition: opacity .5s ease-out;
  position: fixed;
  top: 0;
  left: 0;
}

.progress-bar:before {
  content: "";
  opacity: .25;
  background-color: var(--themes-gradient-02-back);
  z-index: 0;
  display: block;
  position: absolute;
  inset: 0;
}

.progress-bar:after {
  content: "";
  opacity: .5;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 6px, var(--themes-gradient-02-front) 6px, var(--themes-gradient-02-back) 12px);
  z-index: 1;
  background-size: 200% 100%;
  animation: 24s linear infinite loading-animation;
  display: block;
  position: absolute;
  inset: 0;
}

.progress-bar--complete {
  opacity: 0;
}

.progress-bar__indicator {
  z-index: 2;
  background: var(--gradient-2);
  opacity: 1;
  width: 0;
  height: 100%;
  transition: width .3s ease-out;
  position: relative;
}

@keyframes loading-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.vp-player .vp-logo .vp-logo-bottom-left, .vp-player .vp-logo .vp-logo-bottom-right, .vp-player .vp-logo .vp-logo-top-left, .vp-player .vp-logo .vp-logo-top-right {
  width: auto;
}

.vp-player .vp-big-play-button .vp-icon-play:before {
  display: block;
}

.vp-player.vp-size-breakpoint-2 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-3 .vp-big-play-button .vp-icon-play:before {
  width: 30px;
}

.vp-player.vp-size-breakpoint-4 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-5 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-6 .vp-big-play-button .vp-icon-play:before {
  width: 60px;
}

.vp-player.vp-size-breakpoint-7 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-8 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-9 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-10 .vp-big-play-button .vp-icon-play:before {
  width: 90px;
}

.vp-player.vp-float-animation {
  z-index: 999 !important;
}

#vp-floating-container .vp-big-play-button .vp-icon-play:before {
  width: 30px;
}

.vp-player--loading {
  aspect-ratio: 16 / 9;
  color: #fff;
  background: #000;
  place-content: center;
  align-items: center;
  display: flex;
}

.vp-video-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}

.vp-video-wrapper .vp-video-wrapper--content .vp-display-container, .vp-video-wrapper .vp-video-wrapper--content .vp-reset, .vp-video-wrapper .vp-video-wrapper--content .vp-reset-text {
  vertical-align: middle;
}

.vp-video-wrapper .vp-skip {
  border: 1px solid #333;
  padding: .5em;
}

.vp-video-wrapper svg path {
  fill: #fff;
}

.vp-video-wrapper--content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vp-banner {
  width: auto !important;
  height: auto !important;
}

.vp-wrapper .vp-logo .vp-second-logo {
  width: auto;
  height: auto;
}

.vp-flag-floating .vp-wrapper {
  border: 5px solid #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px #00000040;
  max-width: 300px !important;
}

.vp-flag-floating .vp-wrapper .vp-overlays .vp-plugin-vast {
  display: none;
}

.vp-video-wrapper--poster {
  width: 100%;
  height: 100%;
}

.vp-video-access-dnd {
  background-size: 100%;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  inset: 0;
}

.vp-video-access-dnd--link {
  color: red;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
}

.vp-ended .vp-content-fade, .vp-prestart .vp-content-fade {
  background: none;
  opacity: 0 !important;
}

.online_report .report_item .media .vp-video-mobile-access-dnd a {
  float: none;
  width: auto;
  margin: auto;
}

.vp-video-access-dnd--link:hover {
  text-decoration: none;
}

.vp-video-access-dnd--link:after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  top: -5px;
  right: -25px;
}

.vp-video-access-dnd--overlayer {
  color: #fff;
  text-align: center;
  background: #000c;
  width: 100%;
  padding: 2% 0;
  position: absolute;
  bottom: 0;
}

.vp-video-access-dnd--logo {
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 20px;
  right: auto;
}

.vp-video-access-dnd--logo .logo {
  width: 27%;
  min-width: 90px;
  max-width: 170px;
  height: auto;
  padding: 20px;
}

.vp-video-access-dnd--button {
  color: #fff;
  background: red;
  border-radius: 2px;
  width: auto;
  margin: auto;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: table;
}

#article a.vp-video-access-dnd--button {
  text-decoration: none;
}

.vp-video-access-dnd--button:hover {
  color: #fff;
  text-decoration: underline;
}

.vp-video-access-dnd--overlayer-title {
  font-size: 20px;
  font-weight: 700;
}

.vp-video-access-dnd--overlayer-login {
  margin: 2%;
  font-size: 13px;
}

.vp-video-access-dnd--play {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 60px;
  height: 160px;
  position: absolute;
  top: 30%;
  left: calc(50% - 30px);
}

.vpplayer .vp-banner {
  width: auto;
  height: auto;
}

.vp-display-controls .vp-svg-icon-play path, .vp-display-controls .vp-svg-icon-puse path, .vp-display-controls .vp-svg-icon-replay path {
  display: none;
}

.vp-display-controls .vp-svg-icon-play, .vp-display-controls .vp-svg-icon-replay {
  background-repeat: no-repeat;
  background-size: contain;
}

.vp-video-mobile-access-dnd--overlayer {
  text-align: center;
  color: #fff;
  background: #000c;
  width: 100%;
  padding: 2% 0;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  bottom: 0;
  overflow: hidden;
}

.vp-video-mobile-access-dnd .vp-video-access-dnd--play {
  top: calc(50% - 28px);
}

.vp-mobile.vp-size-breakpoint-4 .vp-big-play-button .vp-icon-play:before, .vp-mobile.vp-size-breakpoint-5 .vp-big-play-button .vp-icon-play:before, .vp-mobile.vp-size-breakpoint-6 .vp-big-play-button .vp-icon-play:before {
  height: 120px;
}

.vp-overlay-video-locked-in-time {
  box-sizing: content-box;
  padding: 0 var(--spacing-48);
  width: calc(100% - 2 * var(--spacing-48));
  z-index: 20;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-16);
  visibility: hidden;
  background-size: contain;
  flex-direction: column;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  box-shadow: inset 0 0 0 2000px #000000b3;
  container-type: size;
}

.vp-overlay-video-locked-in-time__content {
  align-items: flex-start;
  gap: var(--spacing-32);
  text-align: left;
  flex-direction: column;
  display: flex;
}

.vp-overlay-video-locked-in-time__premium {
  align-items: center;
  gap: var(--spacing-16);
  display: flex;
}

.vp-overlay-video-locked-in-time__premium.logo-isport .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-reflex .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-e15 .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-auto .vp-overlay-video-locked-in-time__magazine {
  max-width: 110px;
  height: 40px;
}

.vp-overlay-video-locked-in-time__premium.logo-isport .vp-overlay-video-locked-in-time__magazine svg, .vp-overlay-video-locked-in-time__premium.logo-reflex .vp-overlay-video-locked-in-time__magazine svg, .vp-overlay-video-locked-in-time__premium.logo-e15 .vp-overlay-video-locked-in-time__magazine svg, .vp-overlay-video-locked-in-time__premium.logo-auto .vp-overlay-video-locked-in-time__magazine svg {
  width: 100%;
  height: 40px;
}

.vp-overlay-video-locked-in-time__premium.logo-premium .vp-overlay-video-locked-in-time__magazine {
  margin-bottom: var(--spacing-32);
  width: 150px;
  height: 20px;
}

.vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium-logo {
  line-height: 0;
}

.vp-overlay-video-locked-in-time__divider {
  height: var(--spacing-16);
  background: var(--other-divider);
  width: 1px;
}

.vp-overlay-video-locked-in-time__info {
  align-items: flex-start;
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.vp-overlay-video-locked-in-time__titles {
  align-items: flex-start;
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

.vp-overlay-video-locked-in-time__title {
  color: var(--background-paper-contrast);
  font: var(--font-desktop-h3);
}

.vp-overlay-video-locked-in-time__subtitle {
  color: var(--background-paper-contrast);
  font: var(--font-desktop-body1);
}

.vp-overlay-video-locked-in-time__button {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  border-radius: var(--spacing-button-radius);
  background: var(--color-surface-button-primary-solid-normal-contained-default);
  border: 2px solid var(--color-surface-button-primary-solid-normal-contained-default);
  color: var(--secondary-contrast);
  cursor: pointer;
  width: 150px;
  height: 52px;
  font-family: Lato, sans-serif;
  display: flex;
}

.vp-overlay-video-locked-in-time__button:hover {
  background: var(--color-surface-button-primary-solid-normal-contained-default);
  border: 2px solid var(--color-surface-button-primary-solid-normal-contained-default);
}

a.vp-overlay-video-locked-in-time__link {
  padding: var(--spacing-12) var(--spacing-24);
  letter-spacing: .18px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: var(--color-text-button-primary-solid-normal-contained-default) !important;
  text-decoration: none !important;
}

.vp-overlay-video-locked-in-time__login {
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.vp-overlay-video-locked-in-time__login-info {
  color: var(--background-paper-contrast);
  font: var(--font-desktop-body1);
}

.vp-overlay-video-locked-in-time__login-link {
  font-family: arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-decoration-line: underline;
  color: var(--secondary-contrast) !important;
}

.vp-overlay-video-locked-in-time__login-link:hover {
  text-decoration: none;
}

.animate-pulse-on-black-bg {
  animation: 2s infinite pulse-animation-on-black-bg;
}

@container (width <= 680px) {
  .vp-overlay-video-locked-in-time {
    padding: 0 var(--spacing-48);
    width: calc(100% - 2 * var(--spacing-48));
  }

  .vp-overlay-video-locked-in-time__content {
    gap: var(--spacing-24);
  }

  .vp-overlay-video-locked-in-time__info {
    gap: var(--spacing-16);
  }

  .vp-overlay-video-locked-in-time__titles {
    gap: 0;
  }

  a.vp-overlay-video-locked-in-time__link {
    padding: var(--spacing-8) var(--spacing-10);
  }

  .vp-overlay-video-locked-in-time__button {
    width: 103px;
    height: 40px;
  }
}

@container (width <= 560px) {
  .vp-overlay-video-locked-in-time {
    padding: 0 var(--spacing-24);
    width: calc(100% - 2 * var(--spacing-24));
  }

  .vp-overlay-video-locked-in-time__content {
    gap: var(--spacing-16);
  }

  .vp-overlay-video-locked-in-time__premium {
    display: none;
  }

  .vp-overlay-video-locked-in-time__info {
    gap: var(--spacing-8);
  }

  .vp-overlay-video-locked-in-time__titles {
    gap: 0;
  }

  .vp-overlay-video-locked-in-time__title {
    font: var(--font-desktop-h5);
  }

  .vp-overlay-video-locked-in-time__subtitle {
    font: var(--font-desktop-body2);
  }

  a.vp-overlay-video-locked-in-time__link {
    font-size: var(--typography-desktop-button-sm-font-size);
    line-height: var(--typography-desktop-button-sm-line-height);
  }

  .vp-overlay-video-locked-in-time__button {
    margin-top: var(--spacing-12);
    width: 81px;
    height: 32px;
  }

  .vp-overlay-video-locked-in-time__login-info {
    font: var(--font-desktop-body2);
  }

  .vp-overlay-video-locked-in-time__login-link {
    padding: var(--spacing-8);
    font: var(--font-desktop-body2);
  }
}

.avatar {
  border-radius: var(--spacing-avatar-radius);
  box-shadow: var(--shadow-m);
  background-color: var(--color-surface-avatar-background);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.avatar[href], .avatar[onclick], .avatar[role="button"] {
  cursor: pointer;
}

.avatar__icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatar svg {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.avatar path {
  fill: var(--color-icon-avatar-default);
}

.avatar__photo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.avatar--border {
  border: 2px solid var(--color-border-avatar-default);
}

.avatar--2xs {
  width: var(--avatar-size-2xs);
  height: var(--avatar-size-2xs);
  border-width: 1px;
}

.avatar--2xs .avatar__icon {
  width: var(--avatar-icon-size-2xs);
  height: var(--avatar-icon-size-2xs);
}

.avatar--xs {
  width: var(--avatar-size-xs);
  height: var(--avatar-size-xs);
  border-width: 1.5px;
}

.avatar--xs .avatar__icon {
  width: var(--avatar-icon-size-xs);
  height: var(--avatar-icon-size-xs);
}

.avatar--sm {
  width: var(--avatar-size-sm);
  height: var(--avatar-size-sm);
}

.avatar--sm .avatar__icon {
  width: var(--avatar-icon-size-sm);
  height: var(--avatar-icon-size-sm);
}

.avatar--md {
  width: var(--avatar-size-md);
  height: var(--avatar-size-md);
}

.avatar--md .avatar__icon {
  width: var(--avatar-icon-size-md);
  height: var(--avatar-icon-size-md);
}

.avatar--lg {
  width: var(--avatar-size-lg);
  height: var(--avatar-size-lg);
}

.avatar--lg .avatar__icon {
  width: var(--avatar-icon-size-lg);
  height: var(--avatar-icon-size-lg);
}

.avatar--xl {
  width: var(--avatar-size-xl);
  height: var(--avatar-size-xl);
}

.avatar--xl .avatar__icon {
  width: var(--avatar-icon-size-xl);
  height: var(--avatar-icon-size-xl);
}

.avatar--2xl {
  width: var(--avatar-size-2xl);
  height: var(--avatar-size-2xl);
}

.avatar--2xl .avatar__icon {
  width: var(--avatar-icon-size-2xl);
  height: var(--avatar-icon-size-2xl);
}

.avatar--3xl {
  width: var(--avatar-size-3xl);
  height: var(--avatar-size-3xl);
}

.avatar--3xl .avatar__icon {
  width: var(--avatar-icon-size-3xl);
  height: var(--avatar-icon-size-3xl);
}

.avatar--4xl {
  width: var(--avatar-size-4xl);
  height: var(--avatar-size-4xl);
}

.avatar--4xl .avatar__icon {
  width: var(--avatar-icon-size-4xl);
  height: var(--avatar-icon-size-4xl);
}

.badge-pill {
  padding: var(--spacing-2) var(--spacing-12);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);
  border-radius: var(--spacing-badge-pilled);
  border: 1px solid #0000;
  display: flex;
}

.badge-pill__icon {
  width: auto;
}

.badge-pill svg {
  width: inherit;
  height: inherit;
}

.badge-pill--sm {
  padding: var(--spacing-2) var(--spacing-4);
}

.badge-pill--sm .badge-pill__icon {
  height: 16px;
}

.badge-pill--md {
  padding: var(--spacing-2) var(--spacing-8);
}

.badge-pill--md .badge-pill__icon {
  height: 20px;
}

.badge-pill--lg {
  padding: var(--spacing-2) var(--spacing-12);
  height: 36px;
}

.badge-pill--lg .badge-pill__icon {
  height: 24px;
}

.badge-pill--solid.badge-pill--primary {
  background-color: var(--color-surface-badgepill-solid-primary);
}

.badge-pill--solid.badge-pill--primary svg, .badge-pill--solid.badge-pill--primary svg path {
  fill: var(--color-icon-badgepill-solid-primary);
}

.badge-pill--solid.badge-pill--primary .badge-pill__label {
  color: var(--color-text-badgepill-solid-primary);
}

.badge-pill--solid.badge-pill--secondary {
  background-color: var(--color-surface-badgepill-solid-secondary);
}

.badge-pill--solid.badge-pill--secondary svg, .badge-pill--solid.badge-pill--secondary svg path {
  fill: var(--color-icon-badgepill-solid-secondary);
}

.badge-pill--solid.badge-pill--secondary .badge-pill__label {
  color: var(--color-text-badgepill-solid-secondary);
}

.badge-pill--solid.badge-pill--info {
  background-color: var(--color-surface-badgepill-solid-info);
}

.badge-pill--solid.badge-pill--info svg, .badge-pill--solid.badge-pill--info svg path {
  fill: var(--color-icon-badgepill-solid-info);
}

.badge-pill--solid.badge-pill--info .badge-pill__label {
  color: var(--color-text-badgepill-solid-info);
}

.badge-pill--solid.badge-pill--success {
  background-color: var(--color-surface-badgepill-solid-success);
}

.badge-pill--solid.badge-pill--success svg, .badge-pill--solid.badge-pill--success svg path {
  fill: var(--color-icon-badgepill-solid-success);
}

.badge-pill--solid.badge-pill--success .badge-pill__label {
  color: var(--color-text-badgepill-solid-success);
}

.badge-pill--solid.badge-pill--warning {
  background-color: var(--color-surface-badgepill-solid-warning);
}

.badge-pill--solid.badge-pill--warning svg, .badge-pill--solid.badge-pill--warning svg path {
  fill: var(--color-icon-badgepill-solid-warning);
}

.badge-pill--solid.badge-pill--warning .badge-pill__label {
  color: var(--color-text-badgepill-solid-warning);
}

.badge-pill--solid.badge-pill--error {
  background-color: var(--color-surface-badgepill-solid-error);
}

.badge-pill--solid.badge-pill--error svg, .badge-pill--solid.badge-pill--error svg path {
  fill: var(--color-icon-badgepill-solid-error);
}

.badge-pill--solid.badge-pill--error .badge-pill__label {
  color: var(--color-text-badgepill-solid-error);
}

.badge-pill--solid.badge-pill--disabled {
  background-color: var(--color-surface-badgepill-solid-disabled);
}

.badge-pill--solid.badge-pill--disabled svg, .badge-pill--solid.badge-pill--disabled svg path {
  fill: var(--color-icon-badgepill-solid-disabled);
}

.badge-pill--solid.badge-pill--disabled .badge-pill__label {
  color: var(--color-text-badgepill-solid-disabled);
}

.badge-pill--soft.badge-pill--primary {
  background-color: var(--color-surface-badgepill-soft-primary);
}

.badge-pill--soft.badge-pill--primary svg, .badge-pill--soft.badge-pill--primary svg path {
  fill: var(--color-icon-badgepill-soft-primary);
}

.badge-pill--soft.badge-pill--primary .badge-pill__label {
  color: var(--color-text-badgepill-soft-primary);
}

.badge-pill--soft.badge-pill--secondary {
  background-color: var(--color-surface-badgepill-soft-secondary);
}

.badge-pill--soft.badge-pill--secondary svg, .badge-pill--soft.badge-pill--secondary svg path {
  fill: var(--color-icon-badgepill-soft-secondary);
}

.badge-pill--soft.badge-pill--secondary .badge-pill__label {
  color: var(--color-text-badgepill-soft-secondary);
}

.badge-pill--soft.badge-pill--info {
  background-color: var(--color-surface-badgepill-soft-info);
}

.badge-pill--soft.badge-pill--info svg, .badge-pill--soft.badge-pill--info svg path {
  fill: var(--color-icon-badgepill-soft-info);
}

.badge-pill--soft.badge-pill--info .badge-pill__label {
  color: var(--color-text-badgepill-soft-info);
}

.badge-pill--soft.badge-pill--success {
  background-color: var(--color-surface-badgepill-soft-success);
}

.badge-pill--soft.badge-pill--success svg, .badge-pill--soft.badge-pill--success svg path {
  fill: var(--color-icon-badgepill-soft-success);
}

.badge-pill--soft.badge-pill--success .badge-pill__label {
  color: var(--color-text-badgepill-soft-success);
}

.badge-pill--soft.badge-pill--warning {
  background-color: var(--color-surface-badgepill-soft-warning);
}

.badge-pill--soft.badge-pill--warning svg, .badge-pill--soft.badge-pill--warning svg path {
  fill: var(--color-icon-badgepill-soft-warning);
}

.badge-pill--soft.badge-pill--warning .badge-pill__label {
  color: var(--color-text-badgepill-soft-warning);
}

.badge-pill--soft.badge-pill--error {
  background-color: var(--color-surface-badgepill-soft-error);
}

.badge-pill--soft.badge-pill--error svg, .badge-pill--soft.badge-pill--error svg path {
  fill: var(--color-icon-badgepill-soft-error);
}

.badge-pill--soft.badge-pill--error .badge-pill__label {
  color: var(--color-text-badgepill-soft-error);
}

.badge-pill--soft.badge-pill--disabled {
  background-color: var(--color-surface-badgepill-soft-disabled);
}

.badge-pill--soft.badge-pill--disabled svg, .badge-pill--soft.badge-pill--disabled svg path {
  fill: var(--color-icon-badgepill-soft-disabled);
}

.badge-pill--soft.badge-pill--disabled .badge-pill__label {
  color: var(--color-text-badgepill-soft-disabled);
}

.badge-pill--outlined.badge-pill--primary {
  border-color: var(--color-border-badgepill-outlined-primary);
  background-color: var(--color-surface-badgepill-outlined-primary);
}

.badge-pill--outlined.badge-pill--primary svg, .badge-pill--outlined.badge-pill--primary svg path {
  fill: var(--color-icon-badgepill-outlined-primary);
}

.badge-pill--outlined.badge-pill--primary .badge-pill__label {
  color: var(--color-text-badgepill-outlined-primary);
}

.badge-pill--outlined.badge-pill--secondary {
  border-color: var(--color-border-badgepill-outlined-secondary);
  background-color: var(--color-surface-badgepill-outlined-secondary);
}

.badge-pill--outlined.badge-pill--secondary svg, .badge-pill--outlined.badge-pill--secondary svg path {
  fill: var(--color-icon-badgepill-outlined-secondary);
}

.badge-pill--outlined.badge-pill--secondary .badge-pill__label {
  color: var(--color-text-badgepill-outlined-secondary);
}

.badge-pill--outlined.badge-pill--info {
  border-color: var(--color-border-badgepill-outlined-info);
  background-color: var(--color-surface-badgepill-outlined-info);
}

.badge-pill--outlined.badge-pill--info svg, .badge-pill--outlined.badge-pill--info svg path {
  fill: var(--color-icon-badgepill-outlined-info);
}

.badge-pill--outlined.badge-pill--info .badge-pill__label {
  color: var(--color-text-badgepill-outlined-info);
}

.badge-pill--outlined.badge-pill--success {
  border-color: var(--color-border-badgepill-outlined-success);
  background-color: var(--color-surface-badgepill-outlined-success);
}

.badge-pill--outlined.badge-pill--success svg, .badge-pill--outlined.badge-pill--success svg path {
  fill: var(--color-icon-badgepill-outlined-success);
}

.badge-pill--outlined.badge-pill--success .badge-pill__label {
  color: var(--color-text-badgepill-outlined-success);
}

.badge-pill--outlined.badge-pill--warning {
  border-color: var(--color-border-badgepill-outlined-warning);
  background-color: var(--color-surface-badgepill-outlined-warning);
}

.badge-pill--outlined.badge-pill--warning svg, .badge-pill--outlined.badge-pill--warning svg path {
  fill: var(--color-icon-badgepill-outlined-warning);
}

.badge-pill--outlined.badge-pill--warning .badge-pill__label {
  color: var(--color-text-badgepill-outlined-warning);
}

.badge-pill--outlined.badge-pill--error {
  border-color: var(--color-border-badgepill-outlined-error);
  background-color: var(--color-surface-badgepill-outlined-error);
}

.badge-pill--outlined.badge-pill--error svg, .badge-pill--outlined.badge-pill--error svg path {
  fill: var(--color-icon-badgepill-outlined-error);
}

.badge-pill--outlined.badge-pill--error .badge-pill__label {
  color: var(--color-text-badgepill-outlined-error);
}

.badge-pill--outlined.badge-pill--disabled {
  border-color: var(--color-border-badgepill-outlined-disabled);
  background-color: var(--color-surface-badgepill-outlined-disabled);
}

.badge-pill--outlined.badge-pill--disabled svg, .badge-pill--outlined.badge-pill--disabled svg path {
  fill: var(--color-icon-badgepill-outlined-disabled);
}

.badge-pill--outlined.badge-pill--disabled .badge-pill__label {
  color: var(--color-text-badgepill-outlined-disabled);
}

.button--button.button--primary.button--solid.button--normal.button--contained {
  color: var(--color-text-button-primary-solid-normal-contained-default);
  background-color: var(--color-surface-button-primary-solid-normal-contained-default);
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--normal.button--contained .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--contained .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-contained-default);
}

.button--button.button--primary.button--solid.button--normal.button--contained:hover {
  color: var(--color-text-button-primary-solid-normal-contained-hover);
  background-color: var(--color-surface-button-primary-solid-normal-contained-hover);
}

.button--button.button--primary.button--solid.button--normal.button--contained:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--contained:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-contained-hover);
}

.button--button.button--primary.button--solid.button--normal.button--contained:focus-visible {
  border-color: var(--color-border-button-primary-solid-normal-contained-focus);
  color: var(--color-text-button-primary-solid-normal-contained-focus);
  background-color: var(--color-surface-button-primary-solid-normal-contained-focus);
}

.button--button.button--primary.button--solid.button--normal.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-contained-focus);
}

.button--button.button--primary.button--solid.button--normal.button--contained:active {
  color: var(--color-text-button-primary-solid-normal-contained-active);
  background-color: var(--color-surface-button-primary-solid-normal-contained-active);
}

.button--button.button--primary.button--solid.button--normal.button--contained:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--contained:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-contained-active);
}

.button--button.button--primary.button--solid.button--normal.button--contained[disabled] {
  color: var(--color-text-button-primary-solid-normal-contained-disabled);
  background-color: var(--color-surface-button-primary-solid-normal-contained-disabled);
}

.button--button.button--primary.button--solid.button--normal.button--contained[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-solid-normal-contained-disabled);
}

.button--button.button--primary.button--solid.button--normal.button--outlined {
  border-color: var(--color-border-button-primary-solid-normal-outlined-default);
  background-color: #0000;
}

.button--button.button--primary.button--solid.button--normal.button--outlined .button__text {
  color: var(--color-text-button-primary-solid-normal-outlined-default);
}

.button--button.button--primary.button--solid.button--normal.button--outlined .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--outlined .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-outlined-default);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:hover {
  border-color: var(--color-border-button-primary-solid-normal-outlined-hover);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-hover);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:hover .button__text {
  color: var(--color-text-button-primary-solid-normal-outlined-hover);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-outlined-hover);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:focus-visible {
  border-color: var(--color-border-button-primary-solid-normal-outlined-focus);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-focus);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:focus-visible .button__text {
  color: var(--color-text-button-primary-solid-normal-outlined-focus);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-outlined-focus);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:active {
  border-color: var(--color-border-button-primary-solid-normal-outlined-active);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-active);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:active .button__text {
  color: var(--color-text-button-primary-solid-normal-outlined-active);
}

.button--button.button--primary.button--solid.button--normal.button--outlined:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--outlined:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-normal-outlined-active);
}

.button--button.button--primary.button--solid.button--normal.button--outlined[disabled] {
  border-color: var(--color-border-button-primary-solid-normal-outlined-disabled);
  background-color: #0000;
}

.button--button.button--primary.button--solid.button--normal.button--outlined[disabled] .button__text {
  color: var(--color-text-button-primary-solid-normal-outlined-disabled);
}

.button--button.button--primary.button--solid.button--normal.button--outlined[disabled] .button__icon--left svg * {
  fill: var(--color-icon-button-primary-solid-normal-outlined-disabled);
}

.button--button.button--primary.button--solid.button--normal.button--text {
  color: var(--color-text-button-primary-text-default);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--normal.button--text .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--text .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-default);
}

.button--button.button--primary.button--solid.button--normal.button--text:hover {
  color: var(--color-text-button-primary-text-hover);
  background-color: var(--color-surface-button-primary-solid-normal-text-hover);
  border-color: #0000;
  text-decoration: none;
}

.button--button.button--primary.button--solid.button--normal.button--text:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--text:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-hover);
}

.button--button.button--primary.button--solid.button--normal.button--text:focus-visible {
  border-color: var(--color-border-button-primary-text-focus);
  color: var(--color-text-button-primary-text-focus);
  background-color: var(--color-surface-button-primary-solid-normal-text-focus);
}

.button--button.button--primary.button--solid.button--normal.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-focus);
}

.button--button.button--primary.button--solid.button--normal.button--text:active {
  color: var(--color-text-button-primary-text-active);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--normal.button--text:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--normal.button--text:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-active);
}

.button--button.button--primary.button--solid.button--normal.button--text[disabled] {
  color: var(--color-text-button-primary-text-disabled);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--normal.button--text[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-text-disabled);
}

.button--button.button--secondary.button--solid.button--normal.button--contained {
  color: var(--color-text-button-secondary-solid-normal-contained-default);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-default);
  border-color: #0000;
}

.button--button.button--secondary.button--solid.button--normal.button--contained .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--contained .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-contained-default);
}

.button--button.button--secondary.button--solid.button--normal.button--contained:hover {
  color: var(--color-text-button-secondary-solid-normal-contained-hover);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-hover);
}

.button--button.button--secondary.button--solid.button--normal.button--contained:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--contained:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-contained-hover);
}

.button--button.button--secondary.button--solid.button--normal.button--contained:focus-visible {
  border-color: var(--color-border-button-primary-solid-normal-contained-focus);
  color: var(--color-text-button-secondary-solid-normal-contained-focus);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-focus);
}

.button--button.button--secondary.button--solid.button--normal.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-contained-focus);
}

.button--button.button--secondary.button--solid.button--normal.button--contained:active {
  color: var(--color-text-button-secondary-solid-normal-contained-active);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-active);
}

.button--button.button--secondary.button--solid.button--normal.button--contained:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--contained:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-contained-active);
}

.button--button.button--secondary.button--solid.button--normal.button--contained[disabled] {
  color: var(--color-text-button-secondary-solid-normal-contained-disabled);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-disabled);
}

.button--button.button--secondary.button--solid.button--normal.button--contained[disabled] .button__icon svg * {
  fill: var(--color-icon-button-secondary-solid-normal-contained-disabled);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined {
  border-color: var(--color-border-button-secondary-solid-normal-outlined-default);
  background-color: #0000;
}

.button--button.button--secondary.button--solid.button--normal.button--outlined .button__text {
  color: var(--color-text-button-secondary-solid-normal-outlined-default);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--outlined .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-default);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:hover {
  border-color: var(--color-border-button-secondary-solid-normal-outlined-hover);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-hover);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:hover .button__text {
  color: var(--color-text-button-secondary-solid-normal-outlined-hover);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-hover);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:focus-visible {
  border-color: var(--color-border-button-secondary-solid-normal-outlined-focus);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-focus);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:focus-visible .button__text {
  color: var(--color-text-button-secondary-solid-normal-outlined-focus);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-focus);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:active {
  border-color: var(--color-border-button-secondary-solid-normal-outlined-active);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-active);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:active .button__text {
  color: var(--color-text-button-secondary-solid-normal-outlined-active);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--outlined:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-active);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined[disabled] {
  border-color: var(--color-border-button-secondary-solid-normal-outlined-disabled);
  background-color: #0000;
}

.button--button.button--secondary.button--solid.button--normal.button--outlined[disabled] .button__text {
  color: var(--color-text-button-secondary-solid-normal-outlined-disabled);
}

.button--button.button--secondary.button--solid.button--normal.button--outlined[disabled] .button__icon--left svg * {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-disabled);
}

.button--button.button--secondary.button--solid.button--normal.button--text {
  color: var(--color-text-button-secondary-text-default);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--secondary.button--solid.button--normal.button--text .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--text .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-text-default);
}

.button--button.button--secondary.button--solid.button--normal.button--text:hover {
  color: var(--color-text-button-secondary-text-hover);
  background-color: var(--color-surface-button-secondary-solid-normal-text-hover);
  border-color: #0000;
  text-decoration: none;
}

.button--button.button--secondary.button--solid.button--normal.button--text:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--text:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-text-hover);
}

.button--button.button--secondary.button--solid.button--normal.button--text:focus-visible {
  border-color: var(--color-border-button-secondary-text-focus);
  color: var(--color-text-button-secondary-text-focus);
  background-color: var(--color-surface-button-secondary-solid-normal-text-focus);
}

.button--button.button--secondary.button--solid.button--normal.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-text-focus);
}

.button--button.button--secondary.button--solid.button--normal.button--text:active {
  color: var(--color-text-button-secondary-text-active);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--secondary.button--solid.button--normal.button--text:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--secondary.button--solid.button--normal.button--text:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-secondary-text-active);
}

.button--button.button--secondary.button--solid.button--normal.button--text[disabled] {
  color: var(--color-text-button-secondary-text-disabled);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--secondary.button--solid.button--normal.button--text[disabled] .button__icon svg * {
  fill: var(--color-icon-button-secondary-text-disabled);
}

.button--button.button--error.button--solid.button--normal.button--contained {
  color: var(--color-text-button-error-solid-normal-contained-default);
  background-color: var(--color-surface-button-error-solid-normal-contained-default);
  border-color: #0000;
}

.button--button.button--error.button--solid.button--normal.button--contained .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--contained .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-contained-default);
}

.button--button.button--error.button--solid.button--normal.button--contained:hover {
  color: var(--color-text-button-error-solid-normal-contained-hover);
  background-color: var(--color-surface-button-error-solid-normal-contained-hover);
}

.button--button.button--error.button--solid.button--normal.button--contained:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--contained:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-contained-hover);
}

.button--button.button--error.button--solid.button--normal.button--contained:focus-visible {
  border-color: var(--color-border-button-primary-solid-normal-contained-focus);
  color: var(--color-text-button-error-solid-normal-contained-focus);
  background-color: var(--color-surface-button-error-solid-normal-contained-focus);
}

.button--button.button--error.button--solid.button--normal.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-contained-focus);
}

.button--button.button--error.button--solid.button--normal.button--contained:active {
  color: var(--color-text-button-error-solid-normal-contained-active);
  background-color: var(--color-surface-button-error-solid-normal-contained-active);
}

.button--button.button--error.button--solid.button--normal.button--contained:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--contained:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-contained-active);
}

.button--button.button--error.button--solid.button--normal.button--contained[disabled] {
  color: var(--color-text-button-error-solid-normal-contained-disabled);
  background-color: var(--color-surface-button-error-solid-normal-contained-disabled);
}

.button--button.button--error.button--solid.button--normal.button--contained[disabled] .button__icon svg * {
  fill: var(--color-icon-button-error-solid-normal-contained-disabled);
}

.button--button.button--error.button--solid.button--normal.button--outlined {
  border-color: var(--color-border-button-error-solid-normal-outlined-default);
  background-color: #0000;
}

.button--button.button--error.button--solid.button--normal.button--outlined .button__text {
  color: var(--color-text-button-error-solid-normal-outlined-default);
}

.button--button.button--error.button--solid.button--normal.button--outlined .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--outlined .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-outlined-default);
}

.button--button.button--error.button--solid.button--normal.button--outlined:hover {
  border-color: var(--color-border-button-error-solid-normal-outlined-hover);
  background-color: var(--color-surface-button-error-solid-normal-outlined-hover);
}

.button--button.button--error.button--solid.button--normal.button--outlined:hover .button__text {
  color: var(--color-text-button-error-solid-normal-outlined-hover);
}

.button--button.button--error.button--solid.button--normal.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-outlined-hover);
}

.button--button.button--error.button--solid.button--normal.button--outlined:focus-visible {
  border-color: var(--color-border-button-error-solid-normal-outlined-focus);
  background-color: var(--color-surface-button-error-solid-normal-outlined-focus);
}

.button--button.button--error.button--solid.button--normal.button--outlined:focus-visible .button__text {
  color: var(--color-text-button-error-solid-normal-outlined-focus);
}

.button--button.button--error.button--solid.button--normal.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-outlined-focus);
}

.button--button.button--error.button--solid.button--normal.button--outlined:active {
  border-color: var(--color-border-button-error-solid-normal-outlined-active);
  background-color: var(--color-surface-button-error-solid-normal-outlined-active);
}

.button--button.button--error.button--solid.button--normal.button--outlined:active .button__text {
  color: var(--color-text-button-error-solid-normal-outlined-active);
}

.button--button.button--error.button--solid.button--normal.button--outlined:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--outlined:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-solid-normal-outlined-active);
}

.button--button.button--error.button--solid.button--normal.button--outlined[disabled] {
  border-color: var(--color-border-button-error-solid-normal-outlined-disabled);
  background-color: #0000;
}

.button--button.button--error.button--solid.button--normal.button--outlined[disabled] .button__text {
  color: var(--color-text-button-error-solid-normal-outlined-disabled);
}

.button--button.button--error.button--solid.button--normal.button--outlined[disabled] .button__icon--left svg * {
  fill: var(--color-icon-button-error-solid-normal-outlined-disabled);
}

.button--button.button--error.button--solid.button--normal.button--text {
  color: var(--color-text-button-error-text-default);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--error.button--solid.button--normal.button--text .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--text .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-text-default);
}

.button--button.button--error.button--solid.button--normal.button--text:hover {
  color: var(--color-text-button-error-text-hover);
  background-color: var(--color-surface-button-error-solid-normal-text-hover);
  border-color: #0000;
  text-decoration: none;
}

.button--button.button--error.button--solid.button--normal.button--text:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--text:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-text-hover);
}

.button--button.button--error.button--solid.button--normal.button--text:focus-visible {
  border-color: var(--color-border-button-error-text-focus);
  color: var(--color-text-button-error-text-focus);
  background-color: var(--color-surface-button-error-solid-normal-text-focus);
}

.button--button.button--error.button--solid.button--normal.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-text-focus);
}

.button--button.button--error.button--solid.button--normal.button--text:active {
  color: var(--color-text-button-error-text-active);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--error.button--solid.button--normal.button--text:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--error.button--solid.button--normal.button--text:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-error-text-active);
}

.button--button.button--error.button--solid.button--normal.button--text[disabled] {
  color: var(--color-text-button-error-text-disabled);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--error.button--solid.button--normal.button--text[disabled] .button__icon svg * {
  fill: var(--color-icon-button-error-text-disabled);
}

.button--button.button--info.button--solid.button--normal.button--contained {
  color: var(--color-text-button-info-solid-normal-contained-default);
  background-color: var(--color-surface-button-info-solid-normal-contained-default);
  border-color: #0000;
}

.button--button.button--info.button--solid.button--normal.button--contained .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--contained .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-contained-default);
}

.button--button.button--info.button--solid.button--normal.button--contained:hover {
  color: var(--color-text-button-info-solid-normal-contained-hover);
  background-color: var(--color-surface-button-info-solid-normal-contained-hover);
}

.button--button.button--info.button--solid.button--normal.button--contained:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--contained:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-contained-hover);
}

.button--button.button--info.button--solid.button--normal.button--contained:focus-visible {
  border-color: var(--color-border-button-primary-solid-normal-contained-focus);
  color: var(--color-text-button-info-solid-normal-contained-focus);
  background-color: var(--color-surface-button-info-solid-normal-contained-focus);
}

.button--button.button--info.button--solid.button--normal.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-contained-focus);
}

.button--button.button--info.button--solid.button--normal.button--contained:active {
  color: var(--color-text-button-info-solid-normal-contained-active);
  background-color: var(--color-surface-button-info-solid-normal-contained-active);
}

.button--button.button--info.button--solid.button--normal.button--contained:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--contained:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-contained-active);
}

.button--button.button--info.button--solid.button--normal.button--contained[disabled] {
  color: var(--color-text-button-info-solid-normal-contained-disabled);
  background-color: var(--color-surface-button-info-solid-normal-contained-disabled);
}

.button--button.button--info.button--solid.button--normal.button--contained[disabled] .button__icon svg * {
  fill: var(--color-icon-button-info-solid-normal-contained-disabled);
}

.button--button.button--info.button--solid.button--normal.button--outlined {
  border-color: var(--color-border-button-info-solid-normal-outlined-default);
  background-color: #0000;
}

.button--button.button--info.button--solid.button--normal.button--outlined .button__text {
  color: var(--color-text-button-info-solid-normal-outlined-default);
}

.button--button.button--info.button--solid.button--normal.button--outlined .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--outlined .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-outlined-default);
}

.button--button.button--info.button--solid.button--normal.button--outlined:hover {
  border-color: var(--color-border-button-info-solid-normal-outlined-hover);
  background-color: var(--color-surface-button-info-solid-normal-outlined-hover);
}

.button--button.button--info.button--solid.button--normal.button--outlined:hover .button__text {
  color: var(--color-text-button-info-solid-normal-outlined-hover);
}

.button--button.button--info.button--solid.button--normal.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-outlined-hover);
}

.button--button.button--info.button--solid.button--normal.button--outlined:focus-visible {
  border-color: var(--color-border-button-info-solid-normal-outlined-focus);
  background-color: var(--color-surface-button-info-solid-normal-outlined-focus);
}

.button--button.button--info.button--solid.button--normal.button--outlined:focus-visible .button__text {
  color: var(--color-text-button-info-solid-normal-outlined-focus);
}

.button--button.button--info.button--solid.button--normal.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-outlined-focus);
}

.button--button.button--info.button--solid.button--normal.button--outlined:active {
  border-color: var(--color-border-button-info-solid-normal-outlined-active);
  background-color: var(--color-surface-button-info-solid-normal-outlined-active);
}

.button--button.button--info.button--solid.button--normal.button--outlined:active .button__text {
  color: var(--color-text-button-info-solid-normal-outlined-active);
}

.button--button.button--info.button--solid.button--normal.button--outlined:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--outlined:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-solid-normal-outlined-active);
}

.button--button.button--info.button--solid.button--normal.button--outlined[disabled] {
  border-color: var(--color-border-button-info-solid-normal-outlined-disabled);
  background-color: #0000;
}

.button--button.button--info.button--solid.button--normal.button--outlined[disabled] .button__text {
  color: var(--color-text-button-info-solid-normal-outlined-disabled);
}

.button--button.button--info.button--solid.button--normal.button--outlined[disabled] .button__icon--left svg * {
  fill: var(--color-icon-button-info-solid-normal-outlined-disabled);
}

.button--button.button--info.button--solid.button--normal.button--text {
  color: var(--color-text-button-info-text-default);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--info.button--solid.button--normal.button--text .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--text .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-text-default);
}

.button--button.button--info.button--solid.button--normal.button--text:hover {
  color: var(--color-text-button-info-text-hover);
  background-color: var(--color-surface-button-info-solid-normal-text-hover);
  border-color: #0000;
  text-decoration: none;
}

.button--button.button--info.button--solid.button--normal.button--text:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--text:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-text-hover);
}

.button--button.button--info.button--solid.button--normal.button--text:focus-visible {
  border-color: var(--color-border-button-info-text-focus);
  color: var(--color-text-button-info-text-focus);
  background-color: var(--color-surface-button-info-solid-normal-text-focus);
}

.button--button.button--info.button--solid.button--normal.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-text-focus);
}

.button--button.button--info.button--solid.button--normal.button--text:active {
  color: var(--color-text-button-info-text-active);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--info.button--solid.button--normal.button--text:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--info.button--solid.button--normal.button--text:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-info-text-active);
}

.button--button.button--info.button--solid.button--normal.button--text[disabled] {
  color: var(--color-text-button-info-text-disabled);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--info.button--solid.button--normal.button--text[disabled] .button__icon svg * {
  fill: var(--color-icon-button-info-text-disabled);
}

.button--button.button--primary.button--solid.button--inverted.button--contained {
  color: var(--color-text-button-primary-solid-inverted-contained-default);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-default);
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--inverted.button--contained .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--contained .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-contained-default);
}

.button--button.button--primary.button--solid.button--inverted.button--contained:hover {
  color: var(--color-text-button-primary-solid-inverted-contained-hover);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-hover);
}

.button--button.button--primary.button--solid.button--inverted.button--contained:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--contained:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-contained-hover);
}

.button--button.button--primary.button--solid.button--inverted.button--contained:focus-visible {
  border-color: var(--color-border-button-primary-solid-normal-contained-focus);
  color: var(--color-text-button-primary-solid-inverted-contained-focus);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-focus);
}

.button--button.button--primary.button--solid.button--inverted.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-contained-focus);
}

.button--button.button--primary.button--solid.button--inverted.button--contained:active {
  color: var(--color-text-button-primary-solid-inverted-contained-active);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-active);
}

.button--button.button--primary.button--solid.button--inverted.button--contained:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--contained:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-contained-active);
}

.button--button.button--primary.button--solid.button--inverted.button--contained[disabled] {
  color: var(--color-text-button-primary-solid-inverted-contained-disabled);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-disabled);
}

.button--button.button--primary.button--solid.button--inverted.button--contained[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-solid-inverted-contained-disabled);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined {
  border-color: var(--color-border-button-primary-solid-inverted-outlined-default);
  background-color: #0000;
}

.button--button.button--primary.button--solid.button--inverted.button--outlined .button__text {
  color: var(--color-text-button-primary-solid-inverted-outlined-default);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--outlined .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-default);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:hover {
  border-color: var(--color-border-button-primary-solid-inverted-outlined-hover);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-hover);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:hover .button__text {
  color: var(--color-text-button-primary-solid-inverted-outlined-hover);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-hover);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:focus-visible {
  border-color: var(--color-border-button-primary-solid-inverted-outlined-focus);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-focus);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:focus-visible .button__text {
  color: var(--color-text-button-primary-solid-inverted-outlined-focus);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-focus);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:active {
  border-color: var(--color-border-button-primary-solid-inverted-outlined-active);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-active);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:active .button__text {
  color: var(--color-text-button-primary-solid-inverted-outlined-active);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--outlined:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-active);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined[disabled] {
  border-color: var(--color-border-button-primary-solid-inverted-outlined-disabled);
  background-color: #0000;
}

.button--button.button--primary.button--solid.button--inverted.button--outlined[disabled] .button__text {
  color: var(--color-text-button-primary-solid-inverted-outlined-disabled);
}

.button--button.button--primary.button--solid.button--inverted.button--outlined[disabled] .button__icon--left svg * {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-disabled);
}

.button--button.button--primary.button--solid.button--inverted.button--text {
  color: var(--color-text-button-primary-text-default);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--inverted.button--text .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--text .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-default);
}

.button--button.button--primary.button--solid.button--inverted.button--text:hover {
  color: var(--color-text-button-primary-text-hover);
  background-color: var(--color-surface-button-primary-solid-inverted-text-hover);
  border-color: #0000;
  text-decoration: none;
}

.button--button.button--primary.button--solid.button--inverted.button--text:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--text:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-hover);
}

.button--button.button--primary.button--solid.button--inverted.button--text:focus-visible {
  border-color: var(--color-border-button-primary-text-focus);
  color: var(--color-text-button-primary-text-focus);
  background-color: var(--color-surface-button-primary-solid-inverted-text-focus);
}

.button--button.button--primary.button--solid.button--inverted.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-focus);
}

.button--button.button--primary.button--solid.button--inverted.button--text:active {
  color: var(--color-text-button-primary-text-active);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--inverted.button--text:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--solid.button--inverted.button--text:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-text-active);
}

.button--button.button--primary.button--solid.button--inverted.button--text[disabled] {
  color: var(--color-text-button-primary-text-disabled);
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--solid.button--inverted.button--text[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-text-disabled);
}

.button--button.button--primary.button--gradient.button--normal.button--contained {
  color: var(--color-text-button-primary-gradient-normal-contained-default);
  background-image: var(--gradient-2);
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--normal.button--contained .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--normal.button--contained .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-normal-contained-default);
}

.button--button.button--primary.button--gradient.button--normal.button--contained:hover, .button--button.button--primary.button--gradient.button--normal.button--contained:focus-visible, .button--button.button--primary.button--gradient.button--normal.button--contained:active, .button--button.button--primary.button--gradient.button--normal.button--contained[disabled] {
  background-image: unset;
}

.button--button.button--primary.button--gradient.button--normal.button--contained:hover {
  color: var(--color-text-button-primary-gradient-normal-contained-hover);
  background-color: var(--color-surface-button-primary-gradient-normal-contained-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--contained:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--normal.button--contained:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-normal-contained-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--contained:focus-visible {
  border-color: var(--color-border-button-primary-gradient-normal-text-focus);
  color: var(--color-text-button-primary-gradient-normal-contained-focus);
  background-color: var(--color-surface-button-primary-gradient-normal-contained-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) path, .button--button.button--primary.button--gradient.button--normal.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-primary-gradient-normal-contained-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--contained:active {
  color: var(--color-text-button-primary-gradient-normal-contained-active);
  background-color: var(--color-surface-button-primary-gradient-normal-contained-active);
}

.button--button.button--primary.button--gradient.button--normal.button--contained:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--normal.button--contained:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-normal-contained-active);
}

.button--button.button--primary.button--gradient.button--normal.button--contained[disabled] {
  color: var(--color-text-button-primary-gradient-normal-contained-disabled);
  background-color: var(--color-surface-button-primary-gradient-normal-contained-disabled);
}

.button--button.button--primary.button--gradient.button--normal.button--contained[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-gradient-normal-contained-disabled);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined {
  background-color: #0000;
  border-color: #0000;
  position: relative;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:before {
  content: "";
  border-radius: var(--spacing-button-radius);
  background: linear-gradient(90deg, var(--themes-gradient-03-front) 0%, var(--themes-gradient-03-back) 100%) border-box;
  opacity: 1;
  position: absolute;
  inset: -2px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined .button__text {
  background: var(--gradient-3);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-front);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-back);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:hover {
  border-color: var(--color-border-button-primary-gradient-normal-outlined-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:hover:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:hover .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-normal-outlined-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--normal.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-normal-outlined-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:focus-visible {
  border-color: var(--color-border-button-primary-gradient-normal-outlined-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:focus-visible:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:focus-visible .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-normal-outlined-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--normal.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-normal-outlined-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:active {
  border-color: var(--color-border-button-primary-gradient-normal-outlined-active);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:active:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:active .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-normal-outlined-active);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:active .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-front);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-back);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined[disabled] {
  border-color: var(--color-border-button-primary-gradient-normal-outlined-disabled);
  border-image-source: none;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined[disabled]:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--normal.button--outlined[disabled] .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-normal-outlined-disabled);
}

.button--button.button--primary.button--gradient.button--normal.button--outlined[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-gradient-normal-outlined-disabled);
}

.button--button.button--primary.button--gradient.button--normal.button--text {
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--normal.button--text .button__text {
  background: var(--gradient-3);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.button--button.button--primary.button--gradient.button--normal.button--text .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-front);
}

.button--button.button--primary.button--gradient.button--normal.button--text .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-back);
}

.button--button.button--primary.button--gradient.button--normal.button--text:hover {
  background-color: var(--color-surface-button-primary-gradient-normal-text-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--text:hover .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-normal-text-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--text:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--normal.button--text:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-normal-text-hover);
}

.button--button.button--primary.button--gradient.button--normal.button--text:focus-visible {
  border-color: var(--color-border-button-primary-gradient-normal-text-focus);
  background-color: var(--color-surface-button-primary-gradient-normal-text-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--text:focus-visible .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-normal-text-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--normal.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-normal-text-focus);
}

.button--button.button--primary.button--gradient.button--normal.button--text:active {
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--normal.button--text:active .button__text {
  background: var(--gradient-3);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.button--button.button--primary.button--gradient.button--normal.button--text:active .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-front);
}

.button--button.button--primary.button--gradient.button--normal.button--text:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-03-back);
}

.button--button.button--primary.button--gradient.button--normal.button--text[disabled] {
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--normal.button--text[disabled] .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-normal-text-disabled);
}

.button--button.button--primary.button--gradient.button--normal.button--text[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-gradient-normal-text-disabled);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained {
  color: var(--color-text-button-primary-gradient-inverted-contained-default);
  background-image: var(--gradient-2);
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--inverted.button--contained .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--inverted.button--contained .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-contained-default);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained:hover, .button--button.button--primary.button--gradient.button--inverted.button--contained:focus-visible, .button--button.button--primary.button--gradient.button--inverted.button--contained:active, .button--button.button--primary.button--gradient.button--inverted.button--contained[disabled] {
  background-image: unset;
}

.button--button.button--primary.button--gradient.button--inverted.button--contained:hover {
  color: var(--color-text-button-primary-gradient-inverted-contained-hover);
  background-color: var(--color-surface-button-primary-gradient-inverted-contained-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--inverted.button--contained:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-contained-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained:focus-visible {
  border-color: var(--color-border-button-primary-gradient-inverted-text-focus);
  color: var(--color-text-button-primary-gradient-inverted-contained-focus);
  background-color: var(--color-surface-button-primary-gradient-inverted-contained-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) path, .button--button.button--primary.button--gradient.button--inverted.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-primary-gradient-inverted-contained-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained:active {
  color: var(--color-text-button-primary-gradient-inverted-contained-active);
  background-color: var(--color-surface-button-primary-gradient-inverted-contained-active);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained:active .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--inverted.button--contained:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-contained-active);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained[disabled] {
  color: var(--color-text-button-primary-gradient-inverted-contained-disabled);
  background-color: var(--color-surface-button-primary-gradient-inverted-contained-disabled);
}

.button--button.button--primary.button--gradient.button--inverted.button--contained[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-contained-disabled);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined {
  background-color: #0000;
  border-color: #0000;
  position: relative;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:before {
  content: "";
  border-radius: var(--spacing-button-radius);
  background: linear-gradient(90deg, var(--themes-gradient-02-front) 0%, var(--themes-gradient-02-back) 100%) border-box;
  opacity: 1;
  position: absolute;
  inset: -2px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined .button__text {
  background: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-front);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-back);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:hover {
  border-color: var(--color-border-button-primary-gradient-inverted-outlined-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:hover:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:hover .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-inverted-outlined-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--inverted.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-outlined-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:focus-visible {
  border-color: var(--color-border-button-primary-gradient-inverted-outlined-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:focus-visible:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:focus-visible .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-inverted-outlined-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--inverted.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-outlined-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:active {
  border-color: var(--color-border-button-primary-gradient-inverted-outlined-active);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:active:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:active .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-inverted-outlined-active);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:active .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-front);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-back);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined[disabled] {
  border-color: var(--color-border-button-primary-gradient-inverted-outlined-disabled);
  border-image-source: none;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined[disabled]:before {
  opacity: 0;
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined[disabled] .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-inverted-outlined-disabled);
}

.button--button.button--primary.button--gradient.button--inverted.button--outlined[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-outlined-disabled);
}

.button--button.button--primary.button--gradient.button--inverted.button--text {
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--inverted.button--text .button__text {
  background: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.button--button.button--primary.button--gradient.button--inverted.button--text .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-front);
}

.button--button.button--primary.button--gradient.button--inverted.button--text .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-back);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:hover {
  background-color: var(--color-surface-button-primary-gradient-inverted-text-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:hover .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-inverted-text-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:hover .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--inverted.button--text:hover .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-text-hover);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:focus-visible {
  border-color: var(--color-border-button-primary-gradient-inverted-text-focus);
  background-color: var(--color-surface-button-primary-gradient-inverted-text-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:focus-visible .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-inverted-text-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) svg *, .button--button.button--primary.button--gradient.button--inverted.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-text-focus);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:active {
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--inverted.button--text:active .button__text {
  background: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.button--button.button--primary.button--gradient.button--inverted.button--text:active .button__icon--left:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-front);
}

.button--button.button--primary.button--gradient.button--inverted.button--text:active .button__icon--right:not(.button__icon--keep-color) svg * {
  fill: var(--themes-gradient-02-back);
}

.button--button.button--primary.button--gradient.button--inverted.button--text[disabled] {
  background-color: #0000;
  border-color: #0000;
}

.button--button.button--primary.button--gradient.button--inverted.button--text[disabled] .button__text {
  background: none;
  background-clip: unset;
  color: var(--color-text-button-primary-gradient-inverted-text-disabled);
}

.button--button.button--primary.button--gradient.button--inverted.button--text[disabled] .button__icon svg * {
  fill: var(--color-icon-button-primary-gradient-inverted-text-disabled);
}

.button--button-betting.button--primary.button--normal.button--contained {
  color: var(--color-text-button-betting-primary-normal-contained-default);
  background-color: var(--color-surface-button-betting-primary-normal-contained-default);
  border-color: #0000;
}

.button--button-betting.button--primary.button--normal.button--contained .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-contained-default);
}

.button--button-betting.button--primary.button--normal.button--contained .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-contained-default);
}

.button--button-betting.button--primary.button--normal.button--contained:hover {
  color: var(--color-text-button-betting-primary-normal-contained-hover);
  background-color: var(--color-surface-button-betting-primary-normal-contained-hover);
}

.button--button-betting.button--primary.button--normal.button--contained:hover .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-contained-hover);
}

.button--button-betting.button--primary.button--normal.button--contained:hover .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-contained-hover);
}

.button--button-betting.button--primary.button--normal.button--contained:focus-visible {
  border-color: var(--color-border-button-betting-primary-normal-contained-focus);
  color: var(--color-text-button-betting-primary-normal-contained-focus);
  background-color: var(--color-surface-button-betting-primary-normal-contained-focus);
}

.button--button-betting.button--primary.button--normal.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-contained-focus);
}

.button--button-betting.button--primary.button--normal.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-contained-focus);
}

.button--button-betting.button--primary.button--normal.button--contained:active {
  color: var(--color-text-button-betting-primary-normal-contained-active);
  background-color: var(--color-surface-button-betting-primary-normal-contained-active);
}

.button--button-betting.button--primary.button--normal.button--contained:active .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-contained-active);
}

.button--button-betting.button--primary.button--normal.button--contained:active .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-contained-active);
}

.button--button-betting.button--primary.button--normal.button--contained[disabled] {
  color: var(--color-text-button-betting-primary-normal-contained-disabled);
  background-color: var(--color-surface-button-betting-primary-normal-contained-disabled);
}

.button--button-betting.button--primary.button--normal.button--contained[disabled] .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-contained-disabled);
}

.button--button-betting.button--primary.button--normal.button--contained[disabled] .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-contained-disabled);
}

.button--button-betting.button--primary.button--normal.button--outlined {
  border-color: var(--color-border-button-betting-primary-normal-outlined-default);
  color: var(--color-text-button-betting-primary-normal-outlined-default);
  background-color: var(--color-surface-button-betting-primary-normal-outlined-default);
}

.button--button-betting.button--primary.button--normal.button--outlined .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-outlined-default);
}

.button--button-betting.button--primary.button--normal.button--outlined .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-outlined-default);
}

.button--button-betting.button--primary.button--normal.button--outlined:hover {
  border-color: var(--color-border-button-betting-primary-normal-outlined-hover);
  color: var(--color-text-button-betting-primary-normal-outlined-hover);
  background-color: var(--color-surface-button-betting-primary-normal-outlined-hover);
}

.button--button-betting.button--primary.button--normal.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-outlined-hover);
}

.button--button-betting.button--primary.button--normal.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-outlined-hover);
}

.button--button-betting.button--primary.button--normal.button--outlined:focus-visible {
  border-color: var(--color-border-button-betting-primary-normal-outlined-focus);
  color: var(--color-text-button-betting-primary-normal-outlined-focus);
  background-color: var(--color-surface-button-betting-primary-normal-outlined-focus);
}

.button--button-betting.button--primary.button--normal.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-outlined-focus);
}

.button--button-betting.button--primary.button--normal.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-outlined-focus);
}

.button--button-betting.button--primary.button--normal.button--outlined:active {
  border-color: var(--color-border-button-betting-primary-normal-outlined-active);
  color: var(--color-text-button-betting-primary-normal-outlined-active);
  background-color: #0000;
}

.button--button-betting.button--primary.button--normal.button--outlined:active .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-outlined-active);
}

.button--button-betting.button--primary.button--normal.button--outlined:active .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-outlined-active);
}

.button--button-betting.button--primary.button--normal.button--outlined[disabled] {
  border-color: var(--color-border-button-betting-primary-normal-outlined-disabled);
  color: var(--color-text-button-betting-primary-normal-outlined-disabled);
  background-color: var(--color-surface-button-betting-primary-normal-outlined-disabled);
}

.button--button-betting.button--primary.button--normal.button--outlined[disabled] .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-outlined-disabled);
}

.button--button-betting.button--primary.button--normal.button--outlined[disabled] .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-outlined-disabled);
}

.button--button-betting.button--primary.button--normal.button--text {
  color: var(--color-text-button-betting-primary-normal-text-default);
  background-color: #0000;
  border-color: #0000;
}

.button--button-betting.button--primary.button--normal.button--text .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-text-default);
}

.button--button-betting.button--primary.button--normal.button--text .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-text-default);
}

.button--button-betting.button--primary.button--normal.button--text:hover {
  color: var(--color-text-button-betting-primary-normal-text-hover);
  background-color: var(--color-surface-button-betting-primary-normal-text-hover);
}

.button--button-betting.button--primary.button--normal.button--text:hover .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-text-hover);
}

.button--button-betting.button--primary.button--normal.button--text:hover .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-text-hover);
}

.button--button-betting.button--primary.button--normal.button--text:focus-visible {
  border-color: var(--color-border-button-betting-primary-normal-text-focus);
  color: var(--color-text-button-betting-primary-normal-text-focus);
  background-color: var(--color-surface-button-betting-primary-normal-text-focus);
}

.button--button-betting.button--primary.button--normal.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-text-focus);
}

.button--button-betting.button--primary.button--normal.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-text-focus);
}

.button--button-betting.button--primary.button--normal.button--text:active {
  color: var(--color-text-button-betting-primary-normal-text-active);
  background-color: var(--color-surface-button-betting-primary-normal-text-active);
}

.button--button-betting.button--primary.button--normal.button--text:active .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-text-active);
}

.button--button-betting.button--primary.button--normal.button--text:active .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-text-active);
}

.button--button-betting.button--primary.button--normal.button--text[disabled] {
  color: var(--color-text-button-betting-primary-normal-text-disabled);
  background-color: var(--color-surface-button-betting-primary-normal-text-disabled);
}

.button--button-betting.button--primary.button--normal.button--text[disabled] .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-left-icon-text-disabled);
}

.button--button-betting.button--primary.button--normal.button--text[disabled] .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-normal-right-icon-text-disabled);
}

.button--button-betting.button--secondary.button--normal.button--contained {
  color: var(--color-text-button-betting-secondary-normal-contained-default);
  background-color: var(--color-surface-button-betting-secondary-normal-contained-default);
  border-color: #0000;
}

.button--button-betting.button--secondary.button--normal.button--contained .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-left-icon-contained-default);
}

.button--button-betting.button--secondary.button--normal.button--contained .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-right-icon-contained-default);
}

.button--button-betting.button--secondary.button--normal.button--contained:hover {
  color: var(--color-text-button-betting-secondary-normal-contained-hover);
  background-color: var(--color-surface-button-betting-secondary-normal-contained-hover);
}

.button--button-betting.button--secondary.button--normal.button--contained:hover .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-left-icon-contained-hover);
}

.button--button-betting.button--secondary.button--normal.button--contained:hover .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-right-icon-contained-hover);
}

.button--button-betting.button--secondary.button--normal.button--contained:focus-visible {
  border-color: var(--color-border-button-betting-secondary-normal-contained-focus);
  color: var(--color-text-button-betting-secondary-normal-contained-focus);
  background-color: var(--color-surface-button-betting-secondary-normal-contained-focus);
}

.button--button-betting.button--secondary.button--normal.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-left-icon-contained-focus);
}

.button--button-betting.button--secondary.button--normal.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-right-icon-contained-focus);
}

.button--button-betting.button--secondary.button--normal.button--contained:active {
  color: var(--color-text-button-betting-secondary-normal-contained-active);
  background-color: var(--color-surface-button-betting-secondary-normal-contained-active);
}

.button--button-betting.button--secondary.button--normal.button--contained:active .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-left-icon-contained-active);
}

.button--button-betting.button--secondary.button--normal.button--contained:active .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-right-icon-contained-active);
}

.button--button-betting.button--secondary.button--normal.button--contained[disabled] {
  color: var(--color-text-button-betting-secondary-normal-contained-disabled);
  background-color: var(--color-surface-button-betting-secondary-normal-contained-disabled);
}

.button--button-betting.button--secondary.button--normal.button--contained[disabled] .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-left-icon-contained-disabled);
}

.button--button-betting.button--secondary.button--normal.button--contained[disabled] .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-secondary-normal-right-icon-contained-disabled);
}

.button--button-betting.button--primary.button--inverted.button--contained {
  color: var(--color-text-button-betting-primary-inverted-contained-default);
  background-color: var(--color-surface-button-betting-primary-inverted-contained-default);
  border-color: #0000;
}

.button--button-betting.button--primary.button--inverted.button--contained .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-contained-default);
}

.button--button-betting.button--primary.button--inverted.button--contained .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-contained-default);
}

.button--button-betting.button--primary.button--inverted.button--contained:hover {
  color: var(--color-text-button-betting-primary-inverted-contained-hover);
  background-color: var(--color-surface-button-betting-primary-inverted-contained-hover);
}

.button--button-betting.button--primary.button--inverted.button--contained:hover .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-contained-hover);
}

.button--button-betting.button--primary.button--inverted.button--contained:hover .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-contained-hover);
}

.button--button-betting.button--primary.button--inverted.button--contained:focus-visible {
  border-color: var(--color-border-button-betting-primary-inverted-contained-focus);
  color: var(--color-text-button-betting-primary-inverted-contained-focus);
  background-color: var(--color-surface-button-betting-primary-inverted-contained-focus);
}

.button--button-betting.button--primary.button--inverted.button--contained:focus-visible .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-contained-focus);
}

.button--button-betting.button--primary.button--inverted.button--contained:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-contained-focus);
}

.button--button-betting.button--primary.button--inverted.button--contained:active {
  color: var(--color-text-button-betting-primary-inverted-contained-active);
  background-color: var(--color-surface-button-betting-primary-inverted-contained-active);
}

.button--button-betting.button--primary.button--inverted.button--contained:active .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-contained-active);
}

.button--button-betting.button--primary.button--inverted.button--contained:active .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-contained-active);
}

.button--button-betting.button--primary.button--inverted.button--contained[disabled] {
  color: var(--color-text-button-betting-primary-inverted-contained-disabled);
  background-color: var(--color-surface-button-betting-primary-inverted-contained-disabled);
}

.button--button-betting.button--primary.button--inverted.button--contained[disabled] .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-contained-disabled);
}

.button--button-betting.button--primary.button--inverted.button--contained[disabled] .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-contained-disabled);
}

.button--button-betting.button--primary.button--inverted.button--outlined {
  border-color: var(--color-border-button-betting-primary-inverted-outlined-default);
  color: var(--color-text-button-betting-primary-inverted-outlined-default);
  background-color: var(--color-surface-button-betting-primary-inverted-outlined-default);
}

.button--button-betting.button--primary.button--inverted.button--outlined .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-outlined-default);
}

.button--button-betting.button--primary.button--inverted.button--outlined .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-outlined-default);
}

.button--button-betting.button--primary.button--inverted.button--outlined:hover {
  border-color: var(--color-border-button-betting-primary-inverted-outlined-hover);
  color: var(--color-text-button-betting-primary-inverted-outlined-hover);
  background-color: var(--color-surface-button-betting-primary-inverted-outlined-hover);
}

.button--button-betting.button--primary.button--inverted.button--outlined:hover .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-outlined-hover);
}

.button--button-betting.button--primary.button--inverted.button--outlined:hover .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-outlined-hover);
}

.button--button-betting.button--primary.button--inverted.button--outlined:focus-visible {
  border-color: var(--color-border-button-betting-primary-inverted-outlined-focus);
  color: var(--color-text-button-betting-primary-inverted-outlined-focus);
  background-color: var(--color-surface-button-betting-primary-inverted-outlined-focus);
}

.button--button-betting.button--primary.button--inverted.button--outlined:focus-visible .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-outlined-focus);
}

.button--button-betting.button--primary.button--inverted.button--outlined:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-outlined-focus);
}

.button--button-betting.button--primary.button--inverted.button--outlined:active {
  border-color: var(--color-border-button-betting-primary-inverted-outlined-active);
  color: var(--color-text-button-betting-primary-inverted-outlined-active);
  background-color: #0000;
}

.button--button-betting.button--primary.button--inverted.button--outlined:active .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-outlined-active);
}

.button--button-betting.button--primary.button--inverted.button--outlined:active .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-outlined-active);
}

.button--button-betting.button--primary.button--inverted.button--outlined[disabled] {
  border-color: var(--color-border-button-betting-primary-inverted-outlined-disabled);
  color: var(--color-text-button-betting-primary-inverted-outlined-disabled);
  background-color: var(--color-surface-button-betting-primary-inverted-outlined-disabled);
}

.button--button-betting.button--primary.button--inverted.button--outlined[disabled] .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-outlined-disabled);
}

.button--button-betting.button--primary.button--inverted.button--outlined[disabled] .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-outlined-disabled);
}

.button--button-betting.button--primary.button--inverted.button--text {
  color: var(--color-text-button-betting-primary-inverted-text-default);
  background-color: #0000;
  border-color: #0000;
}

.button--button-betting.button--primary.button--inverted.button--text .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-text-default);
}

.button--button-betting.button--primary.button--inverted.button--text .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-text-default);
}

.button--button-betting.button--primary.button--inverted.button--text:hover {
  color: var(--color-text-button-betting-primary-inverted-text-hover);
  background-color: var(--color-surface-button-betting-primary-inverted-text-hover);
}

.button--button-betting.button--primary.button--inverted.button--text:hover .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-text-hover);
}

.button--button-betting.button--primary.button--inverted.button--text:hover .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-text-hover);
}

.button--button-betting.button--primary.button--inverted.button--text:focus-visible {
  border-color: var(--color-border-button-betting-primary-inverted-text-focus);
  color: var(--color-text-button-betting-primary-inverted-text-focus);
  background-color: var(--color-surface-button-betting-primary-inverted-text-focus);
}

.button--button-betting.button--primary.button--inverted.button--text:focus-visible .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-text-focus);
}

.button--button-betting.button--primary.button--inverted.button--text:focus-visible .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-text-focus);
}

.button--button-betting.button--primary.button--inverted.button--text:active {
  color: var(--color-text-button-betting-primary-inverted-text-active);
  background-color: var(--color-surface-button-betting-primary-inverted-text-active);
}

.button--button-betting.button--primary.button--inverted.button--text:active .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-text-active);
}

.button--button-betting.button--primary.button--inverted.button--text:active .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-text-active);
}

.button--button-betting.button--primary.button--inverted.button--text[disabled] {
  color: var(--color-text-button-betting-primary-inverted-text-disabled);
  background-color: var(--color-surface-button-betting-primary-inverted-text-disabled);
}

.button--button-betting.button--primary.button--inverted.button--text[disabled] .button__icon--left:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-left-icon-text-disabled);
}

.button--button-betting.button--primary.button--inverted.button--text[disabled] .button__icon--right:not(.button__icon--keep-color) path {
  fill: var(--color-icon-button-betting-primary-inverted-right-icon-text-disabled);
}

.button--button-social-login {
  border-color: var(--color-border-default-light);
  color: var(--color-text-default-body);
  background-color: var(--color-surface-button-social-login-default);
}

.button--button-social-login:hover {
  border-color: var(--color-border-button-social-login-hover);
  background-color: var(--color-surface-button-social-login-hover);
}

.button--button-social-login:focus-visible {
  border-color: var(--color-border-button-social-login-focus);
  background-color: var(--color-surface-default-contrast);
}

.button--button-social-login:active {
  border-color: var(--color-border-button-social-login-active);
  background-color: var(--color-surface-button-social-login-active);
}

.button--button-social-login[disabled] {
  color: var(--color-text-button-social-login-disabled);
  border-color: var(--color-border-button-social-login-disabled);
  background-color: var(--color-surface-button-social-login-disabled);
}

.button--button-social-login[disabled] .button__icon svg {
  filter: grayscale();
  opacity: .4;
}

.button {
  border-radius: var(--spacing-button-radius);
  cursor: pointer;
  border-style: solid;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.button:before {
  border-style: solid;
  border-color: #0000;
}

.button--animated {
  position: relative;
  overflow: hidden;
}

.button--animated:after {
  content: "";
  pointer-events: none;
  background: #fff3;
  width: 50%;
  height: 100%;
  animation: 1s ease-in-out 2s shiny-swipe;
  position: absolute;
  top: 0;
  left: -100%;
  transform: skewX(-30deg);
}

.button:hover, .button:hover .button__text {
  text-decoration: none;
}

.button:focus, .button:focus-visible {
  outline: none;
}

.button__text {
  color: inherit;
  pointer-events: none;
}

.button__icon {
  pointer-events: none;
  align-items: center;
  display: flex;
}

.button svg {
  width: inherit;
  height: inherit;
}

.button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.button--xs {
  gap: var(--spacing-4);
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
  border-width: 1.5px;
  height: 20px;
}

.button--xs .button__icon {
  width: auto;
  height: 12px;
}

.button--sm {
  gap: var(--spacing-4);
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
  border-width: 1.5px;
  height: 32px;
}

.button--sm:before {
  border-width: 1.5px;
}

.button--sm .button__icon {
  width: auto;
  height: 16px;
}

.button--button-social-login.button--sm {
  gap: var(--spacing-12);
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
  border-width: 1px;
}

.button--md {
  gap: var(--spacing-8);
  padding-left: var(--spacing-16);
  padding-right: var(--spacing-16);
  border-width: 2px;
  height: 40px;
}

.button--md:before {
  border-width: 2px;
}

.button--md .button__icon {
  width: auto;
  height: 20px;
}

.button--button-social-login.button--md {
  gap: var(--spacing-8);
  border-width: 1px;
}

.button--lg {
  gap: var(--spacing-8);
  padding-left: var(--spacing-24);
  padding-right: var(--spacing-24);
  border-width: 2px;
  height: 48px;
}

.button--lg .button__icon {
  width: auto;
  height: 24px;
}

.button--button-social-login.button--lg {
  gap: var(--spacing-12);
  border-width: 1px;
}

@keyframes shiny-swipe {
  0% {
    left: -100%;
  }

  100% {
    left: 120%;
  }
}

.chip {
  vertical-align: top;
  border-radius: var(--spacing-chip-radius);
  color: inherit;
  cursor: default;
  pointer-events: none;
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.chip--clickable {
  cursor: pointer;
  pointer-events: auto;
  transition: background-color .2s, border-color .2s;
}

.chip__icon {
  margin-left: var(--spacing-4);
  justify-content: center;
  align-items: center;
  display: flex;
}

.chip__icon svg {
  width: 20px;
  height: 20px;
}

.chip__icon svg * {
  fill: var(--color-icon-neutral-dark);
  transition: fill .2s;
}

.chip__avatar {
  margin: calc(var(--spacing-2) * -1) calc(var(--spacing-1) * -1);
}

.chip__label {
  padding: var(--spacing-2) var(--spacing-8);
  color: var(--color-text-neutral-dark);
  transition: color .2s;
}

.chip__close {
  margin-right: var(--spacing-4);
  justify-content: center;
  align-items: center;
  display: flex;
}

.chip__close svg {
  width: 16px;
  height: 16px;
}

.chip__close svg * {
  fill: var(--color-icon-neutral-main);
  transition: fill .2s;
}

.chip:hover .chip__label {
  text-decoration: none;
}

.chip[data-is-active] {
  cursor: default;
}

.chip[disabled] {
  cursor: not-allowed;
}

.chip--fill {
  background-color: var(--color-surface-chip-secondary-contained-default);
}

.chip--fill:hover {
  background-color: var(--color-surface-chip-secondary-contained-hover);
}

.chip--fill:hover .chip__icon svg * {
  fill: var(--color-text-chip-secondary-contained-hover);
}

.chip--fill:hover .chip__label {
  color: var(--color-text-chip-secondary-contained-hover);
}

.chip--fill:hover .chip__close svg * {
  fill: var(--color-text-chip-secondary-contained-hover);
}

.chip--fill[disabled] {
  background-color: var(--color-surface-chip-secondary-contained-disabled);
}

.chip--fill[data-is-active] {
  background-color: var(--color-surface-chip-secondary-contained-active);
}

.chip--fill[data-is-active] .chip__icon svg * {
  fill: var(--color-text-chip-secondary-contained-active);
}

.chip--fill[data-is-active] .chip__label {
  color: var(--color-text-chip-secondary-contained-active);
}

.chip--fill[data-is-active] .chip__close svg * {
  fill: var(--color-text-chip-secondary-contained-active);
}

.chip--outlined {
  border-color: var(--color-border-chip-secondary-outlined-default);
  background-color: var(--color-surface-chip-secondary-outlined-default);
}

.chip--outlined:hover {
  border-color: var(--color-border-chip-secondary-outlined-hover);
}

.chip--outlined:hover .chip__icon svg * {
  fill: var(--color-text-chip-secondary-outlined-hover);
}

.chip--outlined:hover .chip__label {
  color: var(--color-text-chip-secondary-outlined-hover);
}

.chip--outlined:hover .chip__close svg * {
  fill: var(--color-text-chip-secondary-outlined-hover);
}

.chip--outlined[disabled] {
  border-color: var(--color-border-chip-secondary-outlined-disabled);
}

.chip--outlined[disabled] .chip__icon svg * {
  fill: var(--color-text-chip-secondary-outlined-disabled);
}

.chip--outlined[disabled] .chip__label {
  color: var(--color-text-chip-secondary-outlined-disabled);
}

.chip--outlined[disabled] .chip__close svg * {
  fill: var(--color-text-chip-secondary-outlined-disabled);
}

.chip--outlined[data-is-active] {
  border-color: var(--color-border-chip-secondary-outlined-active);
  background-color: var(--color-surface-chip-secondary-outlined-active);
}

.chip--outlined[data-is-active] .chip__icon svg * {
  fill: var(--color-text-chip-secondary-outlined-active);
}

.chip--outlined[data-is-active] .chip__label {
  color: var(--color-text-chip-secondary-outlined-active);
}

.chip--outlined[data-is-active] .chip__close svg * {
  fill: var(--color-text-chip-secondary-outlined-active);
}

.chip--md {
  padding: var(--spacing-6);
}

.chip--sm {
  padding: var(--spacing-1) var(--spacing-4);
}

.datetime {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  color: var(--color-text-article-meta-normal-data);
  display: inline-flex;
}

.datetime:has(.datetime__separator) {
  gap: var(--spacing-4);
}

.datetime__date, .datetime__time, .datetime__separator {
  color: inherit;
}

.datetime__separator {
  justify-content: center;
  align-items: center;
  font-weight: 700;
  display: inline-flex;
}

.datetime__separator:before {
  content: "·";
}

.divider {
  background-color: var(--color-border-divider-label);
  margin: 0;
  margin-bottom: var(--spacing-16);
  width: 100%;
  height: 1px;
}

.divider--no-bottom-margin {
  margin-bottom: 0;
}

.divider--label, .divider--chip {
  text-align: center;
  grid-gap: 20px;
  background-color: #0000;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  align-items: center;
  height: auto;
  display: grid;
}

.divider--label:before, .divider--label:after, .divider--chip:before, .divider--chip:after {
  content: "";
  background-color: var(--color-border-divider-label);
  width: 100%;
  height: 1px;
}

.divider--label .divider__text {
  color: var(--color-text-divider-label);
}

.divider--section {
  background-color: var(--color-border-divider-section);
  height: 4px;
}

.divider--vertical {
  width: 1px;
  height: 100%;
  min-height: 1rem;
  margin: 0;
}

.divider--vertical.divider--chip, .divider--vertical.divider--label {
  grid-template-rows: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: auto;
  grid-auto-flow: row dense;
  width: auto;
  height: 100%;
  display: inline-grid;
}

.divider--vertical.divider--chip:before, .divider--vertical.divider--chip:after, .divider--vertical.divider--label:before, .divider--vertical.divider--label:after {
  width: 1px;
  height: 100%;
  margin: 0 auto;
}

.divider--vertical.divider--section {
  width: 4px;
}

.flag {
  box-shadow: var(--shadow-m);
  background-color: var(--color-surface-avatar-background);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flag[href], .flag[onclick], .flag[role="button"] {
  cursor: pointer;
}

.flag__photo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.flag--2xs {
  width: var(--spacing-12);
}

.flag--xs {
  width: var(--spacing-16);
}

.flag--sm {
  width: var(--spacing-20);
}

.flag--md {
  width: var(--spacing-32);
}

.flag--lg {
  width: var(--spacing-40);
}

.flag--xl {
  width: var(--spacing-60);
}

.flag--2xl {
  width: var(--spacing-80);
}

.header-link {
  align-items: center;
  gap: var(--spacing-4);
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  transition: color .3s, background-color .3s, background-image .3s;
  display: inline-flex;
}

.header-link .header-link__text {
  transition: color .3s, background-color .3s, background-image .3s;
  position: relative;
}

.header-link .header-link__text:hover {
  text-decoration: none;
}

.header-link .header-link__text:after {
  content: "";
  width: 0;
  height: 2px;
  transition: width .2s, background-color .3s;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}

.header-link__icon {
  line-height: 1;
}

.header-link__icon path {
  transition: fill .3s;
}

.header-link svg {
  width: auto;
  height: 1em;
  display: block;
}

.header-link:active .header-link__text:after, .header-link:focus .header-link__text:after, .header-link[data-is-selected] .header-link__text:after {
  width: 100%;
}

.header-link.header-link--solid.header-link--normal .header-link__text {
  color: var(--color-text-header-link-solid-normal-default);
}

.header-link.header-link--solid.header-link--normal .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-normal-default);
}

.header-link.header-link--solid.header-link--normal:hover .header-link__text {
  color: var(--color-text-header-link-solid-normal-hover);
}

.header-link.header-link--solid.header-link--normal:hover .header-link__text:after {
  background-color: var(--color-text-header-link-solid-normal-hover);
}

.header-link.header-link--solid.header-link--normal:hover .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-normal-hover);
}

.header-link.header-link--solid.header-link--normal[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--normal[data-is-selected] .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--normal[data-is-selected] .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--normal:focus, .header-link.header-link--solid.header-link--normal:active {
  background-color: var(--color-surface-text-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--normal:focus .header-link__text, .header-link.header-link--solid.header-link--normal:active .header-link__text {
  color: var(--color-text-header-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--normal:focus .header-link__text:after, .header-link.header-link--solid.header-link--normal:active .header-link__text:after {
  background-color: var(--color-text-header-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--normal:focus .header-link__icon svg *, .header-link.header-link--solid.header-link--normal:active .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--inverted .header-link__text {
  color: var(--color-text-header-link-solid-inverted-default);
}

.header-link.header-link--solid.header-link--inverted .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-default);
}

.header-link.header-link--solid.header-link--inverted .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-inverted-default);
}

.header-link.header-link--solid.header-link--inverted:hover .header-link__text {
  color: var(--color-text-header-link-solid-inverted-hover);
}

.header-link.header-link--solid.header-link--inverted:hover .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-hover);
}

.header-link.header-link--solid.header-link--inverted:hover .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-inverted-hover);
}

.header-link.header-link--solid.header-link--inverted[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--inverted[data-is-selected] .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--inverted[data-is-selected] .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--inverted:focus, .header-link.header-link--solid.header-link--inverted:active {
  background-color: var(--color-surface-text-link-solid-inverted-focus);
}

.header-link.header-link--solid.header-link--inverted:focus .header-link__text, .header-link.header-link--solid.header-link--inverted:active .header-link__text {
  color: var(--color-text-header-link-solid-inverted-focus);
}

.header-link.header-link--solid.header-link--inverted:focus .header-link__text:after, .header-link.header-link--solid.header-link--inverted:active .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-focus);
}

.header-link.header-link--solid.header-link--inverted:focus .header-link__icon svg *, .header-link.header-link--solid.header-link--inverted:active .header-link__icon svg * {
  fill: var(--color-text-header-link-solid-inverted-focus);
}

.header-link.header-link--gradient.header-link--normal .header-link__text {
  color: var(--color-text-default-body);
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
}

.header-link.header-link--gradient.header-link--normal .header-link__text:after {
  background-color: var(--themes-gradient-02-front);
  background-image: var(--gradient-2);
}

.header-link.header-link--gradient.header-link--normal .header-link__icon svg * {
  fill: var(--color-text-default-body);
}

.header-link.header-link--gradient.header-link--normal:hover .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
}

.header-link.header-link--gradient.header-link--normal:hover .header-link__icon svg * {
  fill: var(--themes-gradient-02-back);
}

.header-link.header-link--gradient.header-link--normal[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-gradient-normal-active);
  background: none;
  background-clip: unset;
}

.header-link.header-link--gradient.header-link--normal[data-is-selected] .header-link__icon svg * {
  fill: var(--color-text-header-link-gradient-normal-active);
}

.header-link.header-link--gradient.header-link--normal:focus .header-link__text, .header-link.header-link--gradient.header-link--normal:active .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.header-link.header-link--gradient.header-link--normal:focus .header-link__icon svg *, .header-link.header-link--gradient.header-link--normal:active .header-link__icon svg * {
  fill: var(--themes-gradient-02-back);
}

.header-link.header-link--gradient.header-link--inverted .header-link__text {
  color: var(--color-text-header-link-gradient-inverted-default);
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
}

.header-link.header-link--gradient.header-link--inverted .header-link__text:after {
  background-color: var(--themes-gradient-02-front);
  background-image: var(--gradient-2);
}

.header-link.header-link--gradient.header-link--inverted .header-link__icon svg * {
  fill: var(--color-text-header-link-gradient-inverted-default);
}

.header-link.header-link--gradient.header-link--inverted:hover .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
}

.header-link.header-link--gradient.header-link--inverted:hover .header-link__icon svg * {
  fill: var(--themes-gradient-02-back);
}

.header-link.header-link--gradient.header-link--inverted[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-gradient-inverted-active);
  background: none;
  background-clip: unset;
}

.header-link.header-link--gradient.header-link--inverted[data-is-selected] .header-link__icon svg * {
  fill: var(--color-text-header-link-gradient-inverted-active);
}

.header-link.header-link--gradient.header-link--inverted:focus .header-link__text, .header-link.header-link--gradient.header-link--inverted:active .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.header-link.header-link--gradient.header-link--inverted:focus .header-link__icon svg *, .header-link.header-link--gradient.header-link--inverted:active .header-link__icon svg * {
  fill: var(--themes-gradient-02-back);
}

.icon-button {
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
  --svg-fill-color: transparent;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 51%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.icon-button > svg path {
  fill: var(--svg-fill-color);
}

.icon-button:before {
  z-index: -1;
  border-radius: inherit;
  position: absolute;
}

.icon-button--xxs {
  width: 16px;
  height: 16px;
}

.icon-button--xxs svg {
  width: 12px;
  height: 12px;
}

.icon-button--xs {
  width: 24px;
  height: 24px;
}

.icon-button--xs svg {
  width: 16px;
  height: 16px;
}

.icon-button--sm {
  width: 32px;
  height: 32px;
}

.icon-button--sm svg {
  width: 20px;
  height: 20px;
}

.icon-button--md {
  width: 40px;
  height: 40px;
}

.icon-button--md svg {
  width: 24px;
  height: 24px;
}

.icon-button--lg {
  width: 48px;
  height: 48px;
}

.icon-button--lg svg {
  width: 24px;
  height: 24px;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained {
  background-color: var(--color-surface-icon-button-primary-gradient-normal-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-default);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained:focus-visible {
  border: 1px solid var(--color-border-icon-button-primary-gradient-normal-contained-focus);
  outline: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained:hover {
  background: var(--gradient-2);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-hover);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-primary-gradient-normal-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-primary-gradient-normal-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined {
  border-color: var(--color-border-icon-button-primary-gradient-normal-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:focus-visible {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-focus);
  border: 1px solid var(--color-border-icon-button-primary-gradient-normal-outlined-focus);
  outline: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:hover {
  --svg-fill-color: url("#gradient-3");
  background-color: #0000;
  border-color: #0000;
  position: relative;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:hover:before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(90deg, var(--themes-gradient-03-front) 0%, var(--themes-gradient-03-back) 100%) border-box;
  border: 1px solid #0000;
  position: absolute;
  inset: -1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-primary-gradient-normal-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:active:before {
  display: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-primary-gradient-normal-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text:focus-visible {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-focus);
  border: 1px solid var(--color-border-icon-button-primary-gradient-normal-text-focus);
  outline: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text:hover {
  --svg-fill-color: url("#gradient-3");
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained {
  background-color: var(--color-surface-icon-button-primary-gradient-inverted-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-default);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained:focus-visible {
  border: 1px solid var(--color-border-icon-button-primary-gradient-inverted-contained-focus);
  outline: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained:hover {
  background: var(--gradient-2);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-hover);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-primary-gradient-inverted-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-primary-gradient-inverted-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:focus-visible {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-focus);
  border: 1px solid var(--color-border-icon-button-primary-gradient-inverted-outlined-focus);
  outline: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:hover {
  --svg-fill-color: url("#gradient-2");
  background-color: #0000;
  border-color: #0000;
  position: relative;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:hover:before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(90deg, var(--themes-gradient-02-front) 0%, var(--themes-gradient-02-back) 100%) border-box;
  border: 1px solid #0000;
  position: absolute;
  inset: -1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:active:before {
  display: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text:focus-visible {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-focus);
  border: 1px solid var(--color-border-icon-button-primary-gradient-inverted-text-focus);
  outline: none;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text:hover {
  --svg-fill-color: url("#gradient-2");
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-default);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained:focus-visible {
  border: 1px solid var(--color-border-icon-button-secondary-solid-normal-contained-focus);
  outline: none;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained:hover {
  background: var(--color-surface-icon-button-secondary-solid-normal-contained-hover);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-active);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined {
  border: 1px solid var(--color-border-icon-button-secondary-solid-normal-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined:hover {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-hover);
  background-color: var(--color-surface-icon-button-secondary-solid-normal-outlined-hover);
  border-color: var(--color-border-icon-button-secondary-solid-normal-outlined-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined:focus-visible {
  border-color: var(--color-border-icon-button-secondary-solid-normal-outlined-focus);
  background-color: #0000;
  outline: none;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined:active {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-active);
  border: 1px solid var(--color-border-icon-button-secondary-solid-normal-outlined-active);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-disabled);
  border: 1px solid var(--color-border-icon-button-secondary-solid-normal-outlined-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-default);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text:focus-visible {
  border: 1px solid var(--color-border-icon-button-secondary-solid-normal-text-focus);
  outline: none;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text:hover {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-hover);
  background-color: var(--color-surface-icon-button-secondary-solid-normal-text-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-active);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-disabled);
}

.label-link {
  align-items: center;
  height: 20px;
  text-decoration: none;
  display: inline-flex;
}

.label-link:not([href]) {
  pointer-events: none;
}

.label-link.label-link--text.label-link--solid {
  color: var(--color-text-label-link-solid-text-default);
}

.label-link.label-link--text.label-link--solid[href]:hover {
  color: var(--color-text-label-link-solid-text-hover);
}

.label-link.label-link--text.label-link--solid[href]:hover:not(:active) {
  text-decoration: underline;
}

.label-link.label-link--text.label-link--solid:focus {
  color: var(--color-text-label-link-solid-text-focus);
  background-color: var(--color-surface-label-link-solid-text-focus);
}

.label-link.label-link--text.label-link--solid:active .label-link__text {
  color: var(--color-text-label-link-solid-text-active);
  text-decoration: none;
}

.label-link.label-link--text.label-link--solid:not([href]) {
  pointer-events: none;
}

.label-link.label-link--text.label-link--gradient .label-link__text {
  background: var(--gradient-1);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.label-link.label-link--text.label-link--gradient:hover .label-link__text, .label-link.label-link--text.label-link--gradient:focus .label-link__text, .label-link.label-link--text.label-link--gradient:active .label-link__text {
  background: none;
  background-clip: unset;
}

.label-link.label-link--text.label-link--gradient[href]:hover .label-link__text {
  color: var(--color-text-label-link-gradient-text-hover);
}

.label-link.label-link--text.label-link--gradient:focus {
  background-color: var(--color-surface-label-link-gradient-text-focus);
}

.label-link.label-link--text.label-link--gradient:focus .label-link__text {
  color: var(--color-text-label-link-gradient-text-focus);
}

.label-link.label-link--text.label-link--gradient:active .label-link__text {
  color: var(--color-text-label-link-gradient-text-active);
  text-decoration: none;
}

.label-link.label-link--contained {
  padding: 0 var(--spacing-8);
  border-radius: 80px;
}

.label-link.label-link--contained .label-link__text {
  text-decoration: none;
}

.label-link.label-link--contained.label-link--solid {
  color: var(--color-text-label-link-solid-contained-default);
  background-color: var(--color-surface-label-link-solid-contained-default);
}

.label-link.label-link--contained.label-link--solid[href]:hover {
  color: var(--color-text-label-link-solid-contained-hover);
  background-color: var(--color-surface-label-link-solid-contained-hover);
}

.label-link.label-link--contained.label-link--solid:focus {
  color: var(--color-text-label-link-solid-contained-focus);
  background-color: var(--color-surface-label-link-solid-contained-focus);
}

.label-link.label-link--contained.label-link--solid:active {
  color: var(--color-text-label-link-solid-contained-active);
  background-color: var(--color-surface-label-link-solid-contained-active);
  text-decoration: none;
}

.label-link.label-link--contained.label-link--gradient {
  color: var(--color-text-label-link-gradient-contained-default);
  background: var(--gradient-1);
}

.label-link.label-link--contained.label-link--gradient:focus {
  color: var(--color-text-label-link-gradient-contained-focus);
  background: var(--color-surface-label-link-gradient-contained-focus);
}

.label-link.label-link--contained.label-link--gradient[href]:hover {
  color: var(--color-text-label-link-gradient-contained-hover);
  background: var(--color-surface-label-link-gradient-contained-hover);
}

.label-link.label-link--contained.label-link--gradient:active {
  color: var(--color-text-label-link-gradient-contained-default);
  background: var(--color-surface-label-link-gradient-contained-active);
  text-decoration: none;
}

.logo {
  width: auto;
  display: flex;
}

.logo img {
  width: auto;
  height: 100%;
}

.logo--2xs {
  height: 16px;
}

.logo--xs {
  height: 20px;
}

.logo--sm {
  height: 24px;
}

.logo--md {
  height: 32px;
}

.logo--lg {
  height: 36px;
}

.logo--xl {
  height: 40px;
}

.logo--2xl {
  height: 48px;
}

.logo--3xl {
  height: 52px;
}

.logo--4xl {
  height: 64px;
}

.logo--6xl {
  height: 80px;
}

.premium-brand {
  width: auto;
  max-width: 100%;
}

.premium-brand svg {
  width: inherit;
  height: inherit;
  max-width: 100%;
}

.premium-brand--2xs {
  height: var(--spacing-brand-height-2xs);
}

.premium-brand--xs {
  height: var(--spacing-brand-height-xs);
}

.premium-brand--sm {
  height: var(--spacing-brand-height-sm);
}

.premium-brand--md {
  height: var(--spacing-brand-height-md);
}

.premium-brand--lg {
  height: var(--spacing-brand-height-lg);
}

.premium-brand--xl {
  height: var(--spacing-brand-height-xl);
}

.premium-brand--2xl {
  height: var(--spacing-brand-height-2xl);
}

.premium-brand--3xl {
  height: var(--spacing-brand-height-3xl);
}

.premium-brand--4xl {
  height: var(--spacing-brand-height-4xl);
}

.premium-brand--5xl {
  height: var(--spacing-brand-height-5xl);
}

.social-icon__wrapper {
  width: 30px;
  height: 30px;
  display: block;
}

.social-icon__wrapper svg {
  width: 100%;
  height: 100%;
}

.social-icon__wrapper svg:hover .circle {
  fill: var(--color-icon-brand-primary-main);
  transition: fill 2ms;
}

.social-icon[class$="-light"] .social-icon__wrapper svg .symbol {
  fill: var(--color-icon-default-strong);
}

.social-icon[class$="-light"] .social-icon__wrapper svg:hover .symbol {
  fill: var(--color-icon-brand-primary-main);
  transition: fill 2ms;
}

.social-icon[class$="-light"] .social-icon__wrapper svg:hover .circle {
  fill: inherit;
}

.store-button {
  cursor: pointer;
  background: #191919;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  align-items: center;
  gap: 0 8px;
  padding: 8px;
  display: flex;
}

.store-button__icon--top {
  height: 8px;
}

.store-button__icon--top > svg {
  margin-bottom: 8px;
}

.store-button__icon--bottom {
  height: 16px;
}

.store-button__icon--left {
  height: 24px;
}

.store-button > .right-side-icons {
  flex-flow: column;
  align-items: flex-start;
  gap: 4px 0;
  display: flex;
}

.store-button--light > .right-side-icons svg {
  filter: invert();
}

.store-button--dark {
  box-shadow: var(--shadow-xs) inset;
  background: #191919;
  border: 1px solid #d7d7d7;
}

.store-button--dark:focus {
  border: 1px solid var(--color-border-default-focus);
  box-shadow: var(--shadow-xs) inset;
  background: #575757;
}

.store-button--light {
  box-shadow: var(--shadow-xs) inset;
  background: #fff;
  border: 1px solid #d7d7d7;
}

.store-button--light:focus {
  border: 1px solid var(--color-border-default-focus);
  box-shadow: var(--shadow-xs) inset;
  background: #f8f8f8;
}

.store-button--app-store {
  align-items: flex-end;
}

.store-button--app-store.store-button--light > .store-button__icon--left {
  filter: invert();
}

.text-link {
  align-items: center;
  gap: var(--spacing-4);
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.text-link:after {
  content: "";
  border-radius: var(--spacing-4);
  opacity: 0;
  background-color: #0000;
  transition: opacity .2s, background-color .2s;
  position: absolute;
  inset: 0 -4px;
}

.text-link__text {
  z-index: 1;
  text-underline-offset: .15em;
  text-decoration: underline;
  transition: text-decoration-color .2s, color .2s;
  position: relative;
}

.text-link__text:focus-visible, .text-link:focus-visible {
  outline: none;
}

.text-link:focus-visible:after {
  opacity: 1;
}

.text-link__icon {
  z-index: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.text-link__icon svg * {
  transition: fill .2s;
}

.text-link svg {
  width: auto;
  height: 1em;
}

.text-link[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.text-link--stand-alone .text-link__text, .text-link--stand-alone[disabled] .text-link__text, .text-link--hyperlink:hover .text-link__text {
  text-decoration-color: #0000;
}

.text-link--stand-alone:hover .text-link__text, .text-link--stand-alone:visited .text-link__text, .text-link--stand-alone:focus-visible .text-link__text, .text-link--hyperlink .text-link__text, .text-link--hyperlink:visited .text-link__text, .text-link--hyperlink:focus-visible .text-link__text, .text-link--hyperlink[disabled] .text-link__text {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.text-link--stand-alone.text-link--primary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-default);
}

.text-link--stand-alone.text-link--primary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-default);
}

.text-link--stand-alone.text-link--primary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-hover);
}

.text-link--stand-alone.text-link--primary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-hover);
}

.text-link--stand-alone.text-link--primary.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-stand-alone-primary-solid-normal-focus);
}

.text-link--stand-alone.text-link--primary.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-focus);
}

.text-link--stand-alone.text-link--primary.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-focus);
}

.text-link--stand-alone.text-link--primary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--primary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--primary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-default);
}

.text-link--stand-alone.text-link--primary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-default);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:focus-visible:after {
  background-color: var(--color-surface-text-link-stand-alone-primary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:focus-visible .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--primary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-disabled);
}

.text-link--stand-alone.text-link--primary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-default);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-default);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:focus-visible:after {
  background-color: var(--color-surface-text-link-stand-alone-secondary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:focus-visible .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-disabled);
}

.text-link--stand-alone.text-link--neutral.text-link--normal .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--neutral.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--neutral.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--neutral.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--neutral.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--neutral.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--neutral.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--neutral.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--neutral.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--secondary-neutral.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
}

.text-link--hyperlink.text-link--primary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
}

.text-link--hyperlink.text-link--primary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-hover);
}

.text-link--hyperlink.text-link--primary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-hover);
}

.text-link--hyperlink.text-link--primary.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-hyperlink-primary-solid-normal-focus);
}

.text-link--hyperlink.text-link--primary.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-focus);
}

.text-link--hyperlink.text-link--primary.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-focus);
}

.text-link--hyperlink.text-link--primary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-default);
}

.text-link--hyperlink.text-link--primary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-default);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:focus-visible:after {
  background-color: var(--color-surface-text-link-hyperlink-primary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:focus-visible .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--primary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-default);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-default);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:focus-visible:after {
  background-color: var(--color-surface-text-link-hyperlink-secondary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:focus-visible .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--neutral.text-link--normal .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--neutral.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--neutral.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--neutral.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--neutral.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--neutral.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--neutral.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--neutral.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--neutral.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal:focus-visible:after {
  background-color: var(--color-surface-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal:focus-visible .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal:focus-visible .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--secondary-neutral.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.typography--underline {
  text-decoration: underline;
}

.typography--uppercase {
  text-transform: uppercase;
}

a > .typography:hover {
  text-decoration: underline;
}

a > .typography--underline:hover {
  text-decoration: none;
}

@media (max-width: 679px) {
  .typography--h1, .typography--heading-3xl {
    font: var(--typography-mobile-heading-3xl-font-size) / var(--typography-mobile-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .typography--heading-2xl {
    font: var(--typography-mobile-heading-2xl-font-size) / var(--typography-mobile-heading-2xl-line-height) var(--heading-2xl-font-family);
  }

  .typography--h2, .typography--heading-xl {
    font: var(--typography-mobile-heading-xl-font-size) / var(--typography-mobile-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .typography--h3, .typography--heading-lg {
    font: var(--typography-mobile-heading-lg-font-size) / var(--typography-mobile-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .typography--h4, .typography--heading-md {
    font: var(--typography-mobile-heading-md-font-size) / var(--typography-mobile-heading-md-line-height) var(--heading-md-font-family);
  }

  .typography--h5, .typography--heading-sm {
    font: var(--typography-mobile-heading-sm-font-size) / var(--typography-mobile-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .typography--heading-xs {
    font: var(--typography-mobile-heading-xs-font-size) / var(--typography-mobile-heading-xs-line-height) var(--heading-xs-font-family);
  }

  .typography--heading-2xs {
    font: var(--typography-mobile-heading-2xs-font-size) / var(--typography-mobile-heading-2xs-line-height) var(--heading-2xs-font-family);
  }

  .typography--inter-title {
    font: var(--typography-mobile-inter-title-font-size) / var(--typography-mobile-inter-title-line-height) var(--inter-title-font-family);
  }

  .typography--section-title {
    font: var(--typography-mobile-section-title-font-size) / var(--typography-mobile-section-title-line-height) var(--section-title-font-family);
  }

  .typography--subtitle1, .typography--subtitle-lg {
    font: var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  }

  .typography--subtitle1-bold, .typography--subtitle-lg-bold {
    font: 700 var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  }

  .typography--subtitle2, .typography--subtitle-md {
    font: var(--typography-mobile-subtitle-md-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-font-family);
  }

  .typography--subtitle2-bold, .typography--subtitle-md-bold {
    font: var(--typography-mobile-subtitle-md-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  }

  .typography--body1, .typography--body-lg {
    font: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-font-family);
  }

  .typography--body1-bold, .typography--body-lg-bold {
    font: 700 var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-bold-font-family);
  }

  .typography--body2, .typography--body-md {
    font: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-font-family);
  }

  .typography--body2-bold, .typography--body-md-bold {
    font: 700 var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-bold-font-family);
  }

  .typography--meta-regular, .typography--body-sm {
    font: var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-font-family);
  }

  .typography--meta-bold, .typography--body-sm-bold {
    font: 700 var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-bold-font-family);
  }

  .typography--body-xs {
    font: var(--typography-mobile-body-xs-font-size) / var(--typography-mobile-body-xs-line-height) var(--body-xs-font-family);
  }

  .typography--body-xs-bold {
    font: 700 var(--typography-mobile-body-xs-font-size) / var(--typography-mobile-body-xs-line-height) var(--body-xs-bold-font-family);
  }

  .typography--button-large, .typography--button-lg {
    font: var(--typography-mobile-button-lg-font-size) / var(--typography-mobile-button-lg-line-height) var(--button-lg-font-family);
  }

  .typography--button-medium, .typography--button-md {
    font: var(--typography-mobile-button-md-font-size) / var(--typography-mobile-button-md-line-height) var(--button-md-font-family);
  }

  .typography--button-small, .typography--button-sm {
    font: var(--typography-mobile-button-sm-font-size) / var(--typography-mobile-button-sm-line-height) var(--button-sm-font-family);
  }

  .typography--button-xs {
    font: var(--typography-mobile-button-xs-font-size) / var(--typography-mobile-button-xs-line-height) var(--button-xs-font-family);
  }

  .typography--menu-item {
    font: var(--typography-mobile-menu-item-font-size) / var(--typography-mobile-menu-item-line-height) var(--menu-item-font-family);
  }

  .typography--label {
    font: var(--typography-mobile-label-font-size) / var(--typography-mobile-label-line-height) var(--label-font-family);
  }

  .typography--helper-text {
    font: var(--typography-mobile-helper-font-size) / var(--typography-mobile-helper-line-height) var(--helper-font-family);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .typography--h1, .typography--heading-3xl {
    font: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .typography--heading-2xl {
    font: var(--typography-desktop-heading-2xl-font-size) / var(--typography-desktop-heading-2xl-line-height) var(--heading-2xl-font-family);
  }

  .typography--h2, .typography--heading-xl {
    font: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .typography--h3, .typography--heading-lg {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .typography--h4, .typography--heading-md {
    font: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
  }

  .typography--h5, .typography--heading-sm {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .typography--heading-xs {
    font: var(--typography-desktop-heading-xs-font-size) / var(--typography-desktop-heading-xs-line-height) var(--heading-xs-font-family);
  }

  .typography--heading-2xs {
    font: var(--typography-desktop-heading-2xs-font-size) / var(--typography-desktop-heading-2xs-line-height) var(--heading-2xs-font-family);
  }

  .typography--inter-title {
    font: var(--typography-desktop-inter-title-font-size) / var(--typography-desktop-inter-title-line-height) var(--inter-title-font-family);
  }

  .typography--section-title {
    font: var(--typography-desktop-section-title-font-size) / var(--typography-desktop-section-title-line-height) var(--section-title-font-family);
  }

  .typography--subtitle1, .typography--subtitle-lg {
    font: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  }

  .typography--subtitle1-bold, .typography--subtitle-lg-bold {
    font: 700 var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  }

  .typography--subtitle2, .typography--subtitle-md {
    font: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-font-family);
  }

  .typography--subtitle2-bold, .typography--subtitle-md-bold {
    font: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  }

  .typography--body1, .typography--body-lg {
    font: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
  }

  .typography--body1-bold, .typography--body-lg-bold {
    font: 700 var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
  }

  .typography--body2, .typography--body-md {
    font: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
  }

  .typography--body2-bold, .typography--body-md-bold {
    font: 700 var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
  }

  .typography--meta-regular, .typography--body-sm {
    font: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-font-family);
  }

  .typography--meta-bold, .typography--body-sm-bold {
    font: 700 var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  }

  .typography--body-xs {
    font: var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-font-family);
  }

  .typography--body-xs-bold {
    font: 700 var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-bold-font-family);
  }

  .typography--button-large, .typography--button-lg {
    font: var(--typography-desktop-button-lg-font-size) / var(--typography-desktop-button-lg-line-height) var(--button-lg-font-family);
  }

  .typography--button-medium, .typography--button-md {
    font: var(--typography-desktop-button-md-font-size) / var(--typography-desktop-button-md-line-height) var(--button-md-font-family);
  }

  .typography--button-small, .typography--button-sm {
    font: var(--typography-desktop-button-sm-font-size) / var(--typography-desktop-button-sm-line-height) var(--button-sm-font-family);
  }

  .typography--button-xs {
    font: var(--typography-desktop-button-xs-font-size) / var(--typography-desktop-button-xs-line-height) var(--button-xs-font-family);
  }

  .typography--menu-item {
    font: var(--typography-desktop-menu-item-font-size) / var(--typography-desktop-menu-item-line-height) var(--menu-item-font-family);
  }

  .typography--label {
    font: var(--typography-desktop-label-font-size) / var(--typography-desktop-label-line-height) var(--label-font-family);
  }

  .typography--helper-text {
    font: var(--typography-desktop-helper-font-size) / var(--typography-desktop-helper-line-height) var(--helper-font-family);
  }
}

.checkbox {
  --size: 20px;
}

.checkbox--sm {
  --size: 16px;
}

.checkbox__wrapper {
  align-items: flex-start;
  gap: 0 var(--spacing-8);
  display: inline-flex;
}

.checkbox .icon-wrapper {
  position: relative;
}

.checkbox .tooltip {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.checkbox--input {
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  border: 2px solid #0000;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  transition: border-color .2s ease-in-out;
  display: flex;
}

.checkbox--input .checkmark {
  width: var(--size);
  height: calc(var(--size)  - 4px);
  background: var(--color-surface-checkbox-default-checked-normal-default) url("/dist-isport/assets/default/icons/icon-checkbox-checked-white.svg") center center no-repeat;
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity .2s ease-in-out;
}

.checkbox .top-wrapper {
  align-items: center;
  gap: 0 var(--spacing-4);
  flex-flow: row;
  display: flex;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox input[type="checkbox"]:checked + .checkbox--input .checkmark {
  opacity: 1;
}

.checkbox__error {
  color: var(--color-text-input-normal-description-error);
}

.checkbox--normal .checkbox--controls-wrapper {
  color: var(--color-text-checkbox-default-unchecked-normal-default);
}

.checkbox--normal:has(input:checked) .checkbox--input {
  border-color: var(--color-surface-checkbox-default-checked-normal-default);
}

.checkbox--normal:has(input:checked) .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-default-checked-normal-hover);
}

.checkbox--normal:has(input:checked) .checkbox--input:focus {
  border-color: var(--color-border-checkbox-default-checked-normal-focus);
  outline: 2px solid var(--color-border-checkbox-default-checked-normal-hover);
}

.checkbox--normal:has(input:checked) .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-default-checked-normal-disabled);
}

.checkbox--normal .checkbox--input {
  border-color: var(--color-border-checkbox-default-unchecked-normal-default);
  background: var(--color-surface-checkbox-default-unchecked-normal-default);
}

.checkbox--normal .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-default-unchecked-normal-hover);
}

.checkbox--normal .checkbox--input:focus {
  border-color: var(--color-border-checkbox-default-unchecked-normal-focus);
  outline: 2px solid var(--color-border-checkbox-default-unchecked-normal-hover);
}

.checkbox--normal .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-default-unchecked-normal-disabled);
}

.checkbox--inverted .checkbox--controls-wrapper {
  color: var(--color-text-checkbox-default-unchecked-inverted-default);
}

.checkbox--inverted:has(input:checked) .checkbox--input {
  border-color: var(--color-surface-checkbox-default-checked-inverted-default);
}

.checkbox--inverted:has(input:checked) .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-default-checked-inverted-hover);
}

.checkbox--inverted:has(input:checked) .checkbox--input:focus {
  border-color: var(--color-border-checkbox-default-checked-inverted-focus);
  outline: 2px solid var(--color-border-checkbox-default-checked-inverted-hover);
}

.checkbox--inverted:has(input:checked) .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-default-checked-inverted-disabled);
}

.checkbox--inverted .checkbox--input {
  border-color: var(--color-border-checkbox-default-unchecked-inverted-default);
  background: var(--color-surface-checkbox-default-unchecked-inverted-default);
}

.checkbox--inverted .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-default-unchecked-inverted-hover);
}

.checkbox--inverted .checkbox--input:focus {
  border-color: var(--color-border-checkbox-default-unchecked-inverted-focus);
  outline: 2px solid var(--color-border-checkbox-default-unchecked-inverted-hover);
}

.checkbox--inverted .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-default-unchecked-inverted-disabled);
}

.checkbox--inverted svg {
  background: var(--color-surface-icon-button-primary-solid-normal-contained-default);
  border-radius: 51%;
  width: 16px;
  height: 16px;
  padding: 2px;
}

.checkbox--normal.checkbox--error .checkbox--controls-wrapper {
  color: var(--color-text-checkbox-error-unchecked-normal-default);
}

.checkbox--normal.checkbox--error:has(input:checked) .checkbox--input {
  border-color: var(--color-surface-checkbox-error-checked-normal-default);
}

.checkbox--normal.checkbox--error:has(input:checked) .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-error-checked-normal-hover);
}

.checkbox--normal.checkbox--error:has(input:checked) .checkbox--input:focus {
  border-color: var(--color-border-checkbox-error-checked-normal-focus);
  outline: 2px solid var(--color-border-checkbox-error-checked-normal-hover);
}

.checkbox--normal.checkbox--error:has(input:checked) .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-error-checked-normal-disabled);
}

.checkbox--normal.checkbox--error .checkbox--input {
  border-color: var(--color-border-checkbox-error-unchecked-normal-default);
  background: var(--color-surface-checkbox-error-unchecked-normal-default);
}

.checkbox--normal.checkbox--error .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-error-unchecked-normal-hover);
}

.checkbox--normal.checkbox--error .checkbox--input:focus {
  border-color: var(--color-border-checkbox-error-unchecked-normal-focus);
  outline: 2px solid var(--color-border-checkbox-error-unchecked-normal-hover);
}

.checkbox--normal.checkbox--error .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-error-unchecked-normal-disabled);
}

.checkbox--inverted.checkbox--error .checkbox--controls-wrapper {
  color: var(--color-text-checkbox-error-unchecked-inverted-default);
}

.checkbox--inverted.checkbox--error:has(input:checked) .checkbox--input {
  border-color: var(--color-surface-checkbox-error-checked-inverted-default);
}

.checkbox--inverted.checkbox--error:has(input:checked) .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-error-checked-inverted-hover);
}

.checkbox--inverted.checkbox--error:has(input:checked) .checkbox--input:focus {
  border-color: var(--color-border-checkbox-error-checked-inverted-focus);
  outline: 2px solid var(--color-border-checkbox-error-checked-inverted-hover);
}

.checkbox--inverted.checkbox--error:has(input:checked) .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-error-checked-inverted-disabled);
}

.checkbox--inverted.checkbox--error .checkbox--input {
  border-color: var(--color-border-checkbox-error-unchecked-inverted-default);
  background: var(--color-surface-checkbox-error-unchecked-inverted-default);
}

.checkbox--inverted.checkbox--error .checkbox--input:hover {
  outline: 2px solid var(--color-border-checkbox-error-unchecked-inverted-hover);
}

.checkbox--inverted.checkbox--error .checkbox--input:focus {
  border-color: var(--color-border-checkbox-error-unchecked-inverted-focus);
  outline: 2px solid var(--color-border-checkbox-error-unchecked-inverted-hover);
}

.checkbox--inverted.checkbox--error .checkbox--input:disabled {
  border-color: var(--color-border-checkbox-error-unchecked-inverted-disabled);
}

.input-field {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.input-field__label {
  color: var(--color-text-input-normal-label-default);
}

.input-field__input-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.input-field__input {
  font: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
  color: var(--color-text-input-normal-value-default);
  background: var(--color-surface-input-normal-default);
  border: 1px solid var(--color-border-input-normal-default);
  border-radius: 4px;
  width: 100%;
  height: 26px;
  padding: 8px 12px;
  transition: all .2s;
}

@media (max-width: 679px) {
  .input-field__input {
    font: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-font-family);
  }
}

.input-field__input::placeholder {
  color: var(--color-text-input-normal-value-default);
}

.input-field__input:focus-visible {
  border-color: var(--color-border-input-normal-active);
  color: var(--color-text-input-normal-value-active);
  outline: none;
}

.input-field__input:focus-visible ~ .input-field__icon path {
  fill: var(--color-text-input-normal-value-active);
}

.input-field__icon {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
}

.input-field__icon svg {
  width: 100%;
  height: auto;
}

.input-field__icon path {
  fill: var(--color-text-input-normal-value-default);
}

.input-field__icon--left {
  left: 12px;
}

.input-field__icon--right {
  right: 12px;
}

.input-field__error {
  color: var(--color-text-input-normal-description-error);
}

.input-field--disabled .input-field__input {
  background: var(--color-surface-input-normal-disabled);
  border-color: var(--color-border-input-normal-disabled);
  color: var(--color-text-input-normal-value-disabled);
}

.input-field--disabled .input-field__label {
  color: var(--color-text-input-normal-label-disabled);
}

.input-field--disabled .input-field__icon path {
  fill: var(--color-text-input-normal-value-disabled);
}

.input-field--icon-left .input-field__input {
  padding-left: 40px;
}

.input-field--icon-right .input-field__input {
  padding-right: 40px;
}

.input-field--error .input-field__input {
  border-color: var(--color-border-input-normal-error);
}

.input-field--error .input-field__label {
  color: var(--color-text-input-normal-description-error);
}

.article-card__forum a {
  align-items: center;
  gap: var(--spacing-4);
  font: var(--font-desktop-meta-regular);
  color: var(--secondary-main);
  text-decoration: none;
  display: flex;
}

.article-card__forum a:hover {
  text-decoration: underline;
}

.article-card__forum a:hover .article-card__forum-icon {
  background-color: #e7e7e7;
}

.article-card__forum a:hover .article-card__forum-icon svg path {
  fill: #191919;
}

.article-card__forum-icon {
  padding: var(--spacing-4);
  border-radius: 50%;
  line-height: 0;
}

.article-card {
  flex-direction: column;
  display: flex;
  position: relative;
}

.article-card__container {
  display: flex;
}

.article-card__container:hover {
  box-shadow: var(--shadow-s);
  transition: box-shadow .3s;
}

.article-card__content {
  width: 100%;
  position: relative;
}

.article-card__content-inner {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.article-card__rating .rating-bar__icons svg {
  width: 20px;
  height: 20px;
}

.article-card__title {
  color: var(--color-text-article-title-default);
  text-decoration: none;
}

.article-card__title-text {
  text-decoration: none;
}

.article-card__title:hover .article-card__title-text {
  text-decoration: none;
  transition: color .2s;
}

.article-card__title:hover .article-card__title-text--solid {
  color: var(--color-text-article-title-hover);
}

.article-card__title:hover .article-card__title-text--gradient {
  color: #0000;
  background: var(--gradient-3);
  -webkit-background-clip: text;
  background-clip: text;
}

.article-card__meta {
  gap: var(--spacing-4) var(--spacing-8);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.article-card__name, .article-card__date span, .article-card__partner, .article-card__forum {
  color: var(--color-text-article-meta-normal-data);
  line-height: 1;
}

.article-card--variant-1 .article-card__container {
  background-color: var(--color-surface-article-card-desktop-xl-v01-card-default);
}

.article-card--variant-1:has(.article-card__divider) .article-card__container {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.article-card--variant-1:has(.article-card__divider) .article-card__container:hover {
  box-shadow: none;
  transition: none;
}

.article-card--variant-2 .article-card__container {
  background-color: var(--color-surface-article-card-desktop-xl-v02-card-default);
}

.article-card--variant-3 .article-card__container {
  background-color: var(--themes-gradient-05-back);
  padding: 0;
  overflow: hidden;
}

.article-card--variant-3 .article-card__image {
  position: relative;
}

.article-card--variant-3 .article-card__image:after {
  content: "";
  background: linear-gradient(0deg, var(--themes-gradient-05-back) 0%, var(--themes-gradient-05-back) 20%, var(--themes-gradient-05-front) 100%);
  pointer-events: none;
  height: 38%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.article-card--variant-3 .article-card__content {
  background-color: var(--themes-gradient-05-back);
  margin-top: -1px;
}

.article-card--variant-3 .article-card__updated, .article-card--variant-3 .article-card__title {
  margin-top: -12px;
}

@media only screen and (max-width: 679px) {
  .article-card--variant-3 .article-card__updated, .article-card--variant-3 .article-card__title {
    margin-top: -2px;
  }
}

.article-card--variant-3 .article-card__updated + .article-card__title {
  margin-top: 0;
}

@media only screen and (max-width: 679px) {
  .article-card--variant-3 .article-card__updated + .article-card__title {
    margin-top: 0;
  }
}

.article-card--variant-3 .article-card__title-text {
  color: var(--color-text-default-inverted);
}

.article-card--promoted .article-card__container {
  border: 1px solid var(--color-border-ads-default);
  background-color: var(--color-surface-ads-background);
}

@media only screen and (min-width: 1080px) {
  .article-card--desktop-landscape-2xl .article-card__container {
    gap: var(--spacing-16);
    padding: var(--spacing-article-cards-desktop-2xl-v01-content-v-padding) var(--spacing-article-cards-desktop-2xl-v01-content-h-padding);
    border-radius: var(--spacing-article-cards-desktop-2xl-v01-content-radius);
    background-color: #0000;
  }

  .article-card--desktop-landscape-2xl .article-card__container:hover {
    box-shadow: none;
    transition: none;
  }

  .article-card--desktop-landscape-2xl .article-card__image {
    flex: 0 0 560px;
  }

  .article-card--desktop-landscape-2xl .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-2xl-v01-elements-gap);
  }

  .article-card--desktop-landscape-2xl .article-card__title-text {
    font: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .article-card--variant-1.article-card--desktop-portrait-xl .article-card__container, .article-card--variant-2.article-card--desktop-portrait-xl .article-card__container {
    gap: var(--spacing-16);
    padding: var(--spacing-article-cards-desktop-xl-v01-card-v-padding) var(--spacing-article-cards-desktop-xl-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-desktop-xl-v01-card-radius);
  }

  .article-card--variant-1.article-card--desktop-portrait-xl .article-card__content-inner, .article-card--variant-2.article-card--desktop-portrait-xl .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-xl-v01-elements-gap);
    padding: var(--spacing-article-cards-desktop-xl-v01-elements-v-padding) var(--spacing-article-cards-desktop-xl-v01-elements-h-padding);
  }

  .article-card--variant-1.article-card--desktop-portrait-xl .article-card__title-text, .article-card--variant-2.article-card--desktop-portrait-xl .article-card__title-text {
    font: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .article-card--variant-1.article-card--desktop-portrait-xl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--variant-3.article-card--desktop-portrait-xl .article-card__container {
    border-radius: var(--spacing-global-image-radius-210x119);
  }

  .article-card--variant-3.article-card--desktop-portrait-xl .article-card__content-inner {
    gap: var(--spacing-16);
    padding: 0 var(--spacing-16) var(--spacing-article-cards-desktop-xl-v03-elements-v-padding-bottom);
  }

  .article-card--variant-3.article-card--desktop-portrait-xl .article-card__title-text {
    font: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-2xl-font-family);
  }

  .article-card--desktop-landscape-lg .article-card__container {
    gap: var(--spacing-20);
    padding: var(--spacing-article-cards-desktop-lg-v01-card-v-padding) var(--spacing-article-cards-desktop-lg-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-desktop-lg-v01-card-radius);
  }

  .article-card--desktop-landscape-lg .article-card__image {
    flex: 0 0 328px;
  }

  .article-card--desktop-landscape-lg .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-lg-v01-elements-gap);
  }

  .article-card--desktop-landscape-lg .article-card__title-text {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .article-card--desktop-landscape-md .article-card__container {
    gap: var(--spacing-20);
    padding: var(--spacing-article-cards-desktop-md-v01-card-v-padding) var(--spacing-article-cards-desktop-md-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-desktop-md-v01-card-radius);
  }

  .article-card--desktop-landscape-md .article-card__image {
    flex: 0 0 210px;
  }

  .article-card--desktop-landscape-md .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-md-v01-elements-gap);
  }

  .article-card--desktop-landscape-md .article-card__title-text {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .article-card--desktop-portrait-sm .article-card__container {
    gap: var(--spacing-8);
    padding: var(--spacing-article-cards-desktop-sm-v01-content-v-padding) var(--spacing-article-cards-desktop-sm-v01-content-h-padding);
    border-radius: var(--spacing-article-cards-desktop-sm-v01-content-radius);
    background-color: #0000;
  }

  .article-card--desktop-portrait-sm .article-card__container:hover {
    box-shadow: none;
    transition: none;
  }

  .article-card--desktop-portrait-sm .article-card__image {
    max-width: 210px;
  }

  .article-card--desktop-portrait-sm .article-card__title-text {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .article-card--desktop-portrait-sm .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-sm-v01-elements-gap);
  }

  .article-card--desktop-landscape-xs .article-card__container {
    gap: var(--spacing-20);
    padding: var(--spacing-article-cards-desktop-xs-v01-content-v-padding) var(--spacing-article-cards-desktop-xs-v01-content-h-padding);
    border-radius: var(--spacing-article-cards-desktop-xs-v01-content-radius);
    background-color: #0000;
  }

  .article-card--desktop-landscape-xs .article-card__container:hover {
    box-shadow: none;
    transition: none;
  }

  .article-card--desktop-landscape-xs .article-card__image {
    flex: 0 0 152px;
    max-width: 50%;
  }

  .article-card--desktop-landscape-xs .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-xs-v01-elements-gap);
  }

  .article-card--desktop-landscape-xs .article-card__title-text {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .article-card.article-card--promoted .article-card__container {
    border-radius: 0;
  }

  .article-card--desktop-landscape-2xl .article-card__container, .article-card--desktop-landscape-xl .article-card__container, .article-card--desktop-landscape-lg .article-card__container, .article-card--desktop-landscape-md .article-card__container, .article-card--desktop-landscape-sm .article-card__container, .article-card--desktop-landscape-xs .article-card__container {
    flex-direction: row;
  }

  .article-card--desktop-portrait-2xl .article-card__container, .article-card--desktop-portrait-xl .article-card__container, .article-card--desktop-portrait-lg .article-card__container, .article-card--desktop-portrait-md .article-card__container, .article-card--desktop-portrait-sm .article-card__container, .article-card--desktop-portrait-xs .article-card__container {
    flex-direction: column;
  }
}

@media only screen and (min-width: 680px) and (max-width: 1079px) {
  .article-card--tablet-landscape-2xl .article-card__container {
    gap: var(--spacing-16);
    padding: var(--spacing-article-cards-desktop-2xl-v01-content-v-padding) var(--spacing-article-cards-desktop-2xl-v01-content-h-padding);
    border-radius: var(--spacing-article-cards-desktop-2xl-v01-content-radius);
    background-color: #0000;
  }

  .article-card--tablet-landscape-2xl .article-card__container:hover {
    box-shadow: none;
    transition: none;
  }

  .article-card--tablet-landscape-2xl .article-card__image {
    flex: 0 0 560px;
  }

  .article-card--tablet-landscape-2xl .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-2xl-v01-elements-gap);
  }

  .article-card--tablet-landscape-2xl .article-card__title-text {
    font: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .article-card--tablet-landscape-2xl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--variant-1.article-card--tablet-portrait-xl .article-card__container, .article-card--variant-2.article-card--tablet-portrait-xl .article-card__container {
    gap: var(--spacing-16);
    padding: var(--spacing-article-cards-desktop-xl-v01-card-v-padding) var(--spacing-article-cards-desktop-xl-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-desktop-xl-v01-card-radius);
  }

  .article-card--variant-1.article-card--tablet-portrait-xl .article-card__content-inner, .article-card--variant-2.article-card--tablet-portrait-xl .article-card__content-inner {
    gap: var(--spacing-12);
    padding: var(--spacing-article-cards-desktop-xl-v01-elements-v-padding) var(--spacing-article-cards-desktop-xl-v01-elements-h-padding);
  }

  .article-card--variant-1.article-card--tablet-portrait-xl .article-card__title-text, .article-card--variant-2.article-card--tablet-portrait-xl .article-card__title-text {
    font: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .article-card--variant-1.article-card--tablet-portrait-xl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--variant-3.article-card--tablet-portrait-xl .article-card__container {
    border-radius: var(--spacing-global-image-radius-210x119);
  }

  .article-card--variant-3.article-card--tablet-portrait-xl .article-card__content-inner {
    gap: var(--spacing-16);
    padding: 0 var(--spacing-16) var(--spacing-article-cards-desktop-xl-v03-elements-v-padding-bottom);
  }

  .article-card--variant-3.article-card--tablet-portrait-xl .article-card__title-text {
    font: var(--typography-desktop-heading-2xl-font-size) / var(--typography-desktop-heading-2xl-line-height) var(--heading-2xl-font-family);
  }

  .article-card--tablet-landscape-lg .article-card__container {
    gap: var(--spacing-20);
    padding: var(--spacing-article-cards-desktop-lg-v01-card-v-padding) var(--spacing-article-cards-desktop-lg-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-desktop-lg-v01-card-radius);
  }

  .article-card--tablet-landscape-lg .article-card__image {
    flex: 0 0 328px;
  }

  .article-card--tablet-landscape-lg .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-lg-v01-elements-gap);
  }

  .article-card--tablet-landscape-lg .article-card__title-text {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .article-card--tablet-landscape-md .article-card__container {
    gap: var(--spacing-20);
    padding: var(--spacing-article-cards-desktop-md-v01-card-v-padding) var(--spacing-article-cards-desktop-md-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-desktop-md-v01-card-radius);
  }

  .article-card--tablet-landscape-md .article-card__image {
    flex: 0 0 210px;
  }

  .article-card--tablet-landscape-md .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-md-v01-elements-gap);
  }

  .article-card--tablet-landscape-md .article-card__title-text {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .article-card--tablet-portrait-sm .article-card__container {
    gap: var(--spacing-8);
    padding: var(--spacing-article-cards-desktop-sm-v01-content-v-padding) var(--spacing-article-cards-desktop-sm-v01-content-h-padding);
    border-radius: var(--spacing-article-cards-desktop-sm-v01-content-radius);
    background-color: #0000;
  }

  .article-card--tablet-portrait-sm .article-card__container:hover {
    box-shadow: none;
    transition: none;
  }

  .article-card--tablet-portrait-sm .article-card__image {
    max-width: 210px;
  }

  .article-card--tablet-portrait-sm .article-card__title-text {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .article-card--tablet-portrait-sm .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-sm-v01-elements-gap);
  }

  .article-card--tablet-landscape-xs .article-card__container {
    gap: var(--spacing-20);
    padding: var(--spacing-article-cards-desktop-xs-v01-content-v-padding) var(--spacing-article-cards-desktop-xs-v01-content-h-padding);
    border-radius: var(--spacing-article-cards-desktop-xs-v01-content-radius);
    background-color: #0000;
  }

  .article-card--tablet-landscape-xs .article-card__container:hover {
    box-shadow: none;
    transition: none;
  }

  .article-card--tablet-landscape-xs .article-card__image {
    flex: 0 0 152px;
    max-width: 50%;
  }

  .article-card--tablet-landscape-xs .article-card__content-inner {
    gap: var(--spacing-article-cards-desktop-xs-v01-elements-gap);
  }

  .article-card--tablet-landscape-xs .article-card__title-text {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .article-card.article-card--promoted .article-card__container {
    border-radius: 0;
  }

  .article-card--tablet-landscape-2xl .article-card__container, .article-card--tablet-landscape-xl .article-card__container, .article-card--tablet-landscape-lg .article-card__container, .article-card--tablet-landscape-md .article-card__container, .article-card--tablet-landscape-sm .article-card__container, .article-card--tablet-landscape-xs .article-card__container {
    flex-direction: row;
  }

  .article-card--tablet-portrait-2xl .article-card__container, .article-card--tablet-portrait-xl .article-card__container, .article-card--tablet-portrait-lg .article-card__container, .article-card--tablet-portrait-md .article-card__container, .article-card--tablet-portrait-sm .article-card__container, .article-card--tablet-portrait-xs .article-card__container {
    flex-direction: column;
  }
}

@media (max-width: 679px) {
  .article-card--mobile-portrait-xl .article-card__title-text {
    font: var(--typography-mobile-heading-lg-font-size) / var(--typography-mobile-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .article-card--variant-1.article-card--mobile-portrait-xl .article-card__container, .article-card--variant-2.article-card--mobile-portrait-xl .article-card__container {
    gap: var(--spacing-12);
    padding: var(--spacing-article-cards-mobile-xl-v01-card-v-padding) var(--spacing-article-cards-mobile-xl-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-mobile-xl-v01-card-radius);
  }

  .article-card--variant-1.article-card--mobile-portrait-xl .article-card__content-inner, .article-card--variant-2.article-card--mobile-portrait-xl .article-card__content-inner {
    gap: var(--spacing-article-cards-mobile-xl-v01-content-gap);
    padding: var(--spacing-article-cards-mobile-xl-v01-elements-v-padding) var(--spacing-article-cards-mobile-xl-v01-elements-h-padding);
  }

  .article-card--variant-3.article-card--mobile-portrait-xl .article-card__container {
    border-radius: var(--spacing-global-image-radius-210x119);
  }

  .article-card--variant-3.article-card--mobile-portrait-xl .article-card__content-inner {
    gap: var(--spacing-article-cards-mobile-xl-v03-elements-gap);
    padding: 0 var(--spacing-article-cards-mobile-xl-v03-elements-h-padding) var(--spacing-article-cards-mobile-xl-v03-elements-v-padding-bottom) var(--spacing-article-cards-mobile-xl-v03-elements-h-padding);
  }

  .article-card--ContentTopArticle.article-card--mobile-portrait-xl .article-card__image:after {
    height: 15%;
    bottom: 0;
  }

  .article-card--mobile-portrait-lg .article-card__container {
    gap: var(--spacing-12);
    padding: var(--spacing-article-cards-mobile-lg-v01-card-v-padding) var(--spacing-article-cards-mobile-lg-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-mobile-lg-v01-card-radius);
  }

  .article-card--mobile-portrait-lg .article-card__content-inner {
    gap: var(--spacing-article-cards-mobile-lg-v01-elements-gap);
    padding: var(--spacing-article-cards-mobile-lg-v01-elements-v-padding) var(--spacing-article-cards-mobile-lg-v01-elements-h-padding);
  }

  .article-card--mobile-portrait-lg .article-card__title-text {
    font: var(--typography-mobile-heading-sm-font-size) / var(--typography-mobile-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .article-card--mobile-portrait-lg .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--mobile-landscape-md .article-card__container {
    gap: var(--spacing-12);
    padding: var(--spacing-article-cards-mobile-md-other-v01-card-v-padding) var(--spacing-article-cards-mobile-md-other-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-mobile-md-other-v01-card-radius);
  }

  .article-card--mobile-landscape-md .article-card__image {
    flex: 0 0 110px;
  }

  .article-card--mobile-landscape-md .article-card__content-inner {
    gap: var(--spacing-article-cards-mobile-md-other-v01-elements-gap);
  }

  .article-card--mobile-landscape-md .article-card__title-text {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .article-card--mobile-portrait-xs .article-card__container {
    gap: var(--spacing-12);
    padding: var(--spacing-article-cards-mobile-xs-v01-card-v-padding) var(--spacing-article-cards-mobile-xs-v01-card-h-padding);
    border-radius: var(--spacing-article-cards-mobile-xs-v01-card-radius);
  }

  .article-card--mobile-portrait-xs .article-card__content-inner {
    padding: var(--spacing-article-cards-mobile-xs-v01-elements-v-padding) var(--spacing-article-cards-mobile-xs-v01-elements-h-padding);
    gap: var(--spacing-article-cards-mobile-xs-v01-elements-gap);
  }

  .article-card--mobile-portrait-xs .article-card__title-text {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .article-card.article-card--promoted .article-card__container {
    border-radius: 0;
  }

  .article-card--mobile-landscape-2xl .article-card__container, .article-card--mobile-landscape-xl .article-card__container, .article-card--mobile-landscape-lg .article-card__container, .article-card--mobile-landscape-md .article-card__container, .article-card--mobile-landscape-sm .article-card__container, .article-card--mobile-landscape-xs .article-card__container {
    flex-direction: row;
  }

  .article-card--mobile-portrait-2xl .article-card__container, .article-card--mobile-portrait-xl .article-card__container, .article-card--mobile-portrait-lg .article-card__container, .article-card--mobile-portrait-md .article-card__container, .article-card--mobile-portrait-sm .article-card__container, .article-card--mobile-portrait-xs .article-card__container {
    flex-direction: column;
  }
}

.author-card--default {
  padding: var(--spacing-24);
  background: var(--color-surface-author-card-list-background);
  border-radius: var(--spacing-author-card-radius);
  min-height: 110px;
}

.author-card--default-big {
  grid-row: auto / span 2;
}

.author-card--default-big .author-card__image {
  margin-bottom: var(--spacing-24);
}

.author-card--default-small .author-card__image {
  margin-right: var(--spacing-16);
}

.author-card--default-big .author-card__name-wrapper {
  width: 100%;
}

.author-card--default-small .author-card__name-wrapper {
  margin-top: var(--spacing-6);
  align-items: flex-start;
  width: calc(100% - 76px);
}

.author-card--default-big .author-card__name {
  flex: calc(100% - 24px);
}

.author-card--default-small .author-card__name-wrapper--only-name {
  margin-top: 0;
}

.author-card__name-wrapper--only-name {
  height: 100%;
  align-items: center !important;
}

.author-card__name-wrapper--only-name > h2, .author-card__name-wrapper--only-name > span {
  align-items: center;
  display: flex;
}

.author-card--default-small .author-card__role {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  width: calc(100% - 76px);
  margin-left: 76px;
  line-height: 1.43;
  display: -webkit-box;
  overflow: hidden;
}

.author-card--author-detail {
  border-bottom: 1px solid var(--other-divider);
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto;
  display: grid;
}

.author-card--author-detail .author-card__image {
  margin-right: var(--spacing-16);
  grid-row: 2 span;
}

.author-card--author-detail .author-card__role {
  color: var(--color-text-author-card-detail-subtitle);
}

.author-card--author-detail .author-card__mail-link {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
  overflow-wrap: anywhere;
  margin-top: var(--spacing-4);
  grid-area: 2 / 3;
}

.author-card--author-detail .author-card__description {
  margin-top: var(--spacing-24);
  grid-column: 3 span;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .author-card--author-detail .author-card__role {
    grid-area: 2 / 2;
  }
}

@media (max-width: 679px) {
  .author-detail-wrapper {
    grid-column: lend / rend;
  }

  .author-card--author-detail {
    padding-bottom: var(--spacing-32);
  }

  .author-card--author-detail .author-card__name-wrapper, .author-card--author-detail .author-card__role {
    grid-column: 2 span;
  }

  .author-card--author-detail .social-media {
    margin-top: var(--spacing-24);
    grid-area: 4 / 3 span;
  }

  .author-card--author-detail .author-card__mail-link {
    margin-top: var(--spacing-8);
    grid-area: 5 / 3 span;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .author-detail-wrapper {
    grid-column: lend / rend;
  }

  .author-card--author-detail {
    padding-bottom: var(--spacing-32);
  }

  .author-card--author-detail .social-media {
    grid-area: 1 / 3;
    justify-self: end;
  }

  .author-card--author-detail .author-card__mail-link {
    justify-self: end;
  }
}

.author-card__image {
  float: left;
}

.author-card__name-wrapper {
  color: initial;
  -webkit-text-decoration: initial;
  text-decoration: initial;
  flex-direction: row;
  display: flex;
}

.author-card__arrow-wrapper {
  flex: 0 24px;
  min-width: 24px;
  margin-left: auto;
}

.author-card__arrow-wrapper svg {
  width: 100%;
  height: 100%;
}

.author-card__role {
  margin-top: var(--spacing-2);
  color: var(--color-text-author-card-list-subtitle);
  align-self: center;
  width: 100%;
}

.badge--pill {
  align-items: center;
  gap: var(--spacing-4);
  padding: var(--spacing-4);
  border-radius: var(--spacing-badge-radius-pilled, 6px);
  font-family: var(--typography-desktop-body-sm-font-family, Roboto);
  font-size: var(--typography-desktop-body-sm-font-size, 13px);
  font-style: normal;
  font-weight: 700;
  line-height: var(--typography-desktop-body-sm-line-height, 20px);
  letter-spacing: var(--typography-desktop-body-sm-letter-spacing, 0);
  text-transform: uppercase;
  border: 1px solid #0000;
  flex-grow: 0;
  height: 16px;
  display: inline-flex;
}

.badge--pill .badge__text {
  color: var(--primary-contrast);
  font: var(--font-desktop-meta-bold);
  text-transform: uppercase;
  white-space: nowrap;
}

.badge--fill-primary {
  background-color: var(--primary-main);
}

.badge--outlined-primary {
  border: 1px solid var(--color-surface-brand-primary-light);
}

.badge--outlined-primary .badge__text {
  color: var(--color-surface-brand-primary-light);
}

.badge--outlined-primary-dark {
  border: 1px solid var(--primary-dark);
}

.badge--outlined-primary-dark .badge__text {
  color: var(--primary-dark);
}

.badge--success {
  background-color: #f3faf3;
}

.badge--success .badge__text {
  color: #388e3b;
}

.breadcrumbs {
  margin: var(--spacing-24) 0;
  padding: 0 var(--spacing-16);
  grid-area: 1;
}

.breadcrumbs ol {
  margin: 0;
  padding: 0;
}

.breadcrumbs ol li {
  font: var(--font-desktop-meta-regular);
  color: var(--color-text-secondary);
  list-style-type: none;
  display: inline-block;
}

.breadcrumbs ol li a {
  color: var(--color-text-secondary);
  font-weight: 500;
}

.breadcrumbs ol li:last-of-type a {
  color: var(--color-text-primary);
}

.breadcrumbs ol .arrow-icon {
  width: 5px;
  height: 9px;
  margin: 0 10px;
  display: inline-block;
  position: relative;
}

.breadcrumbs ol .arrow-icon path {
  fill: var(--color-text-secondary);
}

@media (max-width: 679px) {
  .breadcrumbs {
    margin: var(--spacing-16) 0;
  }

  .breadcrumbs ol {
    width: 100%;
    padding: 0 5px;
    position: relative;
  }
}

.breadcrumb {
  grid-column: lend / rend;
}

.breadcrumb li {
  color: var(--color-text-breadcrumb-normal-default);
  list-style-type: none;
  display: inline-block;
}

.breadcrumb li a.text-link, .breadcrumb li span, .breadcrumb li .text-link--hyperlink.text-link--normal .text-link__text {
  color: var(--color-text-breadcrumb-normal-default);
  font-weight: 500;
  text-decoration: none !important;
}

.breadcrumb li a.text-link:focus, .breadcrumb li span:focus, .breadcrumb li .text-link--hyperlink.text-link--normal .text-link__text:focus {
  background-color: #0000 !important;
}

.breadcrumb li:last-of-type a, .breadcrumb li:last-of-type span {
  color: var(--color-text-breadcrumb-normal-active) !important;
}

.breadcrumb .arrow-icon {
  vertical-align: middle;
  margin: 0 5px;
  display: inline-block;
  position: relative;
}

.breadcrumb .arrow-icon path {
  fill: var(--color-text-breadcrumb-normal-default);
}

.breadcrumb--inverted li, .breadcrumb--inverted li a.text-link, .breadcrumb--inverted li .text-link--hyperlink.text-link--inverted .text-link__text {
  color: var(--color-text-breadcrumb-inverted-default);
}

.breadcrumb--inverted li:last-of-type a, .breadcrumb--inverted li:last-of-type span {
  color: var(--color-text-breadcrumb-inverted-active) !important;
}

.breadcrumb--inverted--single-item li, .breadcrumb--inverted--single-item li a, .breadcrumb--inverted--single-item li span {
  color: var(--color-text-textlink-stand-alone-secondary-solid-inverted-default) !important;
}

.breadcrumb--inverted .arrow-icon path {
  fill: var(--color-text-breadcrumb-inverted-default);
}

@media (max-width: 679px) {
  .breadcrumb {
    margin: var(--spacing-16) 0;
  }

  ol.breadcrumb {
    width: 100%;
    padding: 0 5px;
    position: relative;
  }
}

.content-top {
  padding: var(--spacing-16);
  border-radius: 12px;
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.content-top--theme-a {
  background-color: var(--color-surface-content-top-background);
}

.content-top--theme-b {
  background-color: var(--isport-iron-100);
}

@media screen and (max-width: 679px) {
  .content-top {
    background-color: #0000;
    padding: 0;
  }
}

@media screen and (min-width: 680px) and (max-width: 1079px), (min-width: 1080px) {
  .content-top {
    flex-direction: row;
  }
}

.content-top__main-article {
  flex: 1;
}

@media screen and (min-width: 680px) and (max-width: 1079px) {
  .content-top__main-article {
    flex: 2;
  }
}

@media screen and (max-width: 679px) {
  .content-top__main-article {
    background-color: #0000;
  }

  .content-top__main-article--theme-a {
    background-color: var(--color-surface-content-top-background);
  }

  .content-top__main-article--theme-b {
    background-color: var(--isport-iron-100);
  }
}

.content-top__secondary-articles {
  flex-direction: column;
  flex: 1;
  gap: 24px;
  display: flex;
}

@media screen and (min-width: 1080px) {
  .content-top__secondary-articles {
    flex: 0 0 296px;
  }
}

.content-top__secondary-articles .article-card__category {
  display: inline-flex;
}

@media screen and (max-width: 679px) {
  .content-top__secondary-articles .article-card__category {
    display: flex;
  }
}

@media screen and (max-width: 679px) {
  .content-top__divider {
    display: none;
  }
}

.dropdown {
  z-index: 1;
  box-shadow: var(--shadow-m);
  padding: var(--spacing-8) 0;
  background-color: var(--themes-shadows);
  border-radius: 4px;
  flex-direction: column;
  width: max-content;
  display: flex;
  position: absolute;
}

.dropdown--md .dropdown__item {
  padding: 0 var(--spacing-16);
  background-color: var(--color-surface-default-contrast);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  display: flex;
}

.dropdown--sm .dropdown__item {
  height: 32px;
}

.filters {
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.filters__header {
  background: var(--color-surface-default-homepage);
  padding: var(--spacing-16);
  margin: 0 calc(-1 * var(--spacing-16));
  align-items: center;
  display: flex;
}

.filters__default-message {
  color: var(--color-text-default-title);
  font: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
  line-height: var(--typography-desktop-body-lg-line-height);
  text-align: center;
  background-image: url("/dist-isport/assets/default/icons/icon-trophy.svg");
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 60px;
}

.info-toggle {
  --toggle-icon-size: 24px;
  align-items: center;
  gap: var(--spacing-8);
  background: var(--color-surface-default-overlay);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: var(--spacing-4);
  height: calc(var(--toggle-icon-size)  + var(--spacing-4) * 2);
  width: calc(var(--toggle-icon-size)  + var(--spacing-4) * 2);
  border-radius: 16px;
  max-width: 100%;
  transition: all .3s ease-in-out;
  display: inline-flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.info-toggle[data-info-toggle-active] {
  padding-right: var(--spacing-16);
  width: auto;
  height: auto;
}

.info-toggle[data-info-toggle-active] .info-toggle__content {
  animation: .3s ease-in-out forwards fade-slide-in;
}

.info-toggle[data-info-toggle-active] .info-toggle__icon {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlN1YnRyYWN0IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMTJDMiA2LjQ4IDYuNDggMiAxMiAyQzE3LjUyIDIgMjIgNi40OCAyMiAxMkMyMiAxNy41MiAxNy41MiAyMiAxMiAyMkM2LjQ4IDIyIDIgMTcuNTIgMiAxMlpNMTEgOVY3SDEzVjlIMTFaTTExIDE3VjExSDEzVjE3SDExWiIgZmlsbD0iI0ZGRkZGRSIvPgo8L3N2Zz4K");
}

.info-toggle__trigger {
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  padding: 0;
  display: flex;
}

.info-toggle__icon {
  width: var(--toggle-icon-size);
  height: var(--toggle-icon-size);
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMSA3SDEzVjlIMTFWN1pNMTEgMTFIMTNWMTdIMTFWMTFaTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyQzIgMTcuNTIgNi40OCAyMiAxMiAyMkMxNy41MiAyMiAyMiAxNy41MiAyMiAxMkMyMiA2LjQ4IDE3LjUyIDIgMTIgMlpNMTIgMjBDNy41OSAyMCA0IDE2LjQxIDQgMTJDNCA3LjU5IDcuNTkgNCAxMiA0QzE2LjQxIDQgMjAgNy41OSAyMCAxMkMyMCAxNi40MSAxNi40MSAyMCAxMiAyMFoiIGZpbGw9IiNGRkZGRkUiLz4KPC9zdmc+Cg==") center / 100% no-repeat;
  transition: background-image .3s ease-in-out;
  display: block;
}

.info-toggle__icon:hover {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlN1YnRyYWN0IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMTJDMiA2LjQ4IDYuNDggMiAxMiAyQzE3LjUyIDIgMjIgNi40OCAyMiAxMkMyMiAxNy41MiAxNy41MiAyMiAxMiAyMkM2LjQ4IDIyIDIgMTcuNTIgMiAxMlpNMTEgOVY3SDEzVjlIMTFaTTExIDE3VjExSDEzVjE3SDExWiIgZmlsbD0iI0ZGRkZGRSIvPgo8L3N2Zz4K");
}

.info-toggle__content {
  opacity: 0;
  flex-grow: 1;
  transition: all .3s ease-in-out;
  overflow: hidden;
  transform: translateX(-10px);
}

.info-toggle__description {
  display: inline;
}

.info-toggle__text {
  color: var(--color-text-default-contrast);
  display: inline;
}

.info-toggle__separator, .info-toggle__source {
  color: var(--color-text-default-contrast);
  vertical-align: baseline;
  display: inline-block;
}

.info-toggle__separator {
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
}

.info-box {
  pointer-events: all;
  padding: var(--spacing-24);
  background-color: var(--color-surface-flash-messages-info-normal-background);
  color: var(--color-text-flash-messages-info-normal-title);
  box-shadow: var(--shadow-m);
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-16);
  border-radius: 12px;
  flex-direction: row;
  display: none;
  position: relative;
}

@media (max-width: 679px) {
  .info-box {
    padding: var(--spacing-8) var(--spacing-12);
    gap: var(--spacing-8);
  }
}

.info-box--transition {
  transition: opacity .6s ease-out;
}

.info-box--visible {
  opacity: 1;
  display: flex;
}

.info-box--fade-out {
  opacity: 0;
}

.info-box--info {
  background-color: var(--color-surface-flash-messages-info-normal-background);
  color: var(--color-text-flash-messages-info-normal-title);
}

.info-box--info svg * {
  fill: var(--color-text-flash-messages-info-normal-title);
}

.info-box--error {
  background-color: var(--color-surface-flash-messages-alert-normal-background);
  color: var(--color-text-flash-messages-alert-normal-title);
}

.info-box--error svg * {
  fill: var(--color-text-flash-messages-alert-normal-title);
}

.info-box--warning {
  background-color: var(--color-surface-flash-messages-warning-normal-background);
  color: var(--color-text-flash-messages-warning-normal-title);
}

.info-box--warning svg * {
  fill: var(--color-text-flash-messages-warning-normal-title);
}

.info-box--success {
  background-color: var(--color-surface-flash-messages-success-normal-background);
  color: var(--color-text-flash-messages-success-normal-title);
}

.info-box--success svg * {
  fill: var(--color-text-flash-messages-success-normal-title);
}

.info-box__icon {
  position: relative;
  top: 2px;
}

.info-box__text {
  width: calc(100% - var(--spacing-24)  - var(--spacing-24));
  display: inline-block;
}

.info-box__close {
  cursor: pointer;
  height: var(--spacing-24);
  width: var(--spacing-24);
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
  top: 2px;
}

.info-box__close svg {
  width: 16px;
  height: 16px;
  transition: transform .2s;
}

.info-box__close svg * {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.info-box__close:hover svg {
  transform: rotate(90deg);
}

.global-image {
  width: 100%;
  position: relative;
  overflow: hidden;
  container: image-root-element / inline-size;
}

.global-image__container {
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.global-image__container--default-rounding {
  border-radius: var(--spacing-global-image-radius-210x119);
}

@container image-root-element (width >= 80px) and (width < 110px) {
  .global-image__container--default-rounding {
    border-radius: var(--spacing-global-image-radius-80x80);
  }
}

@container image-root-element (width >= 110px) and (width < 124px) {
  .global-image__container--default-rounding {
    border-radius: var(--spacing-global-image-radius-110x62);
  }
}

@container image-root-element (width >= 124px) and (width < 152px) {
  .global-image__container--default-rounding {
    border-radius: var(--spacing-global-image-radius-124x70);
  }
}

@container image-root-element (width >= 152px) and (width < 210px) {
  .global-image__container--default-rounding {
    border-radius: var(--spacing-global-image-radius-152x86);
  }
}

@container image-root-element (width >= 210px) {
  .global-image__container--default-rounding {
    border-radius: var(--spacing-global-image-radius-210x119);
  }
}

.global-image__container:hover .global-image__play-icon path {
  fill: var(--color-surface-play-button-hover);
}

.global-image__container:hover .global-image__img--animate {
  transform: scale(1.05);
}

.global-image__container:focus .global-image__play-icon path {
  fill: var(--color-surface-play-button-focus);
}

.global-image__container:active .global-image__play-icon path {
  fill: var(--color-surface-play-button-active);
}

.global-image--16-9 .global-image__container {
  padding-top: 56.25%;
}

.global-image--4-3 .global-image__container {
  padding-top: 75%;
}

.global-image--1-1 .global-image__container {
  padding-top: 100%;
}

.global-image--21-9 .global-image__container {
  padding-top: 42.86%;
}

.global-image--ratio-none {
  width: fit-content;
  max-width: 100%;
  height: auto;
}

.global-image--ratio-none .global-image__container {
  width: auto;
  height: auto;
  padding-top: 0;
}

.global-image--ratio-none .global-image__container picture, .global-image--ratio-none .global-image__container img {
  max-width: 100%;
  height: auto;
  position: static;
}

.global-image--ratio-none .global-image__container--default-rounding {
  overflow: hidden;
}

.global-image--ratio-none .global-image__container--default-rounding picture, .global-image--ratio-none .global-image__container--default-rounding img {
  border-radius: inherit;
}

.global-image--ratio-none .global-image__picture {
  display: block;
  position: static;
}

.global-image--ratio-none .global-image__img {
  width: auto;
  max-width: 100%;
  display: block;
  position: static;
}

.global-image__placeholder {
  background-color: #dadada;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.global-image__placeholder-icon {
  width: auto;
}

.global-image__placeholder-icon svg {
  width: 100%;
  height: 100%;
}

.global-image__placeholder-icon--magazine {
  height: 24px;
}

@container image-root-element (width < 226px) {
  .global-image__placeholder-icon--magazine {
    height: 18px;
  }
}

.global-image__placeholder-icon--default {
  height: 100%;
}

.global-image__img {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
  transition: all .25s;
  position: absolute;
  top: 0;
  left: 0;
}

.global-image__credit-info {
  max-width: calc(100% - 24px);
  position: absolute;
  bottom: 12px;
  left: 12px;
}

@container image-root-element (width < 240px) {
  .global-image__credit-info {
    display: none;
  }
}

@container image-root-element (width < 328px) {
  .global-image__credit-info {
    bottom: 8px;
    left: 8px;
  }
}

@container image-root-element (width >= 328px) and (width < 560px) {
  .global-image__credit-info {
    bottom: 12px;
    left: 12px;
  }
}

@container image-root-element (width >= 560px) {
  .global-image__credit-info {
    bottom: 16px;
    left: 16px;
  }
}

.global-image__play-icon {
  aspect-ratio: 1;
  pointer-events: none;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: auto;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.global-image__play-icon svg {
  width: 28px;
  height: 28px;
}

@container image-root-element (width < 210px) {
  .global-image__play-icon {
    width: 24px;
  }

  .global-image__play-icon svg {
    width: 20px;
    height: 20px;
  }
}

@container image-root-element (width < 240px) {
  .global-image__play-icon {
    inset: auto 8px 8px auto;
    transform: translate(0);
  }
}

@container image-root-element (width >= 210px) and (width < 328px) {
  .global-image__play-icon {
    width: 32px;
  }

  .global-image__play-icon svg {
    width: 26px;
    height: 26px;
  }
}

@container image-root-element (width >= 328px) and (width < 680px) {
  .global-image__play-icon {
    width: 48px;
  }

  .global-image__play-icon svg {
    width: 40px;
    height: 40px;
  }
}

@container image-root-element (width >= 680px) {
  .global-image__play-icon {
    width: 64px;
  }

  .global-image__play-icon svg {
    width: 54px;
    height: 54px;
  }
}

.global-image__play-icon--solid path {
  fill: var(--color-icon-play-button-default);
}

.global-image__play-icon--gradient path {
  fill: url("#gradient-1");
}

.global-image__description {
  color: var(--color-text-default-helper);
  padding-top: var(--spacing-12);
  display: block;
}

@container image-root-element (width < 240px) {
  .global-image__description {
    display: none;
  }
}

.list {
  gap: var(--spacing-12) 0;
  counter-reset: order;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.list[data-right-icon="true"] span {
  flex: unset;
}

.list--circle .list__item, .list--number .list__item, .list--icon .list__item {
  gap: var(--spacing-4);
}

.list--circle .list__item:before {
  content: "•";
  width: 1em;
  display: inline-block;
}

.list--icon svg {
  flex: none;
}

.list--number .list__item:before {
  content: counter(order) ".";
  width: 1em;
  display: inline-block;
}

.list__item {
  counter-increment: order;
  color: inherit;
  align-items: flex-start;
  display: flex;
}

.list__item-text {
  color: inherit;
  flex: 1;
}

.list__link {
  color: inherit;
}

.list__link:hover {
  text-decoration: none;
}

.list--no-bullet {
  color: var(--color-text-list-negative);
}

.list--no-bullet svg * {
  fill: var(--color-icon-list-negative);
}

.list--no-bullet .list__item:before {
  content: none;
  display: none;
}

.list--default {
  color: var(--color-text-list-default);
}

.list--default svg * {
  fill: var(--color-icon-list-default);
}

.list--inverted {
  color: var(--color-text-list-inverted);
}

.list--inverted svg * {
  fill: var(--color-icon-list-inverted);
}

.list--brand {
  color: var(--color-text-list-brand);
}

.list--brand svg * {
  fill: var(--color-icon-list-brand);
}

.list--negative {
  color: var(--color-text-list-negative);
}

.list--negative svg * {
  fill: var(--color-icon-list-negative);
}

.author {
  gap: var(--spacing-16);
  margin-top: var(--spacing-12);
  flex-direction: row;
  display: flex;
}

@media (max-width: 679px) {
  .author {
    margin-top: var(--spacing-16);
  }
}

.author__info {
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

@media (max-width: 679px) {
  .author__names {
    flex-direction: column;
    display: flex;
  }

  .author__separator {
    display: none;
  }
}

.author__name {
  font: var(--font-mobile-body2-bold);
}

@media (max-width: 679px) {
  .author__name {
    font: var(--font-mobile-meta-bold);
  }
}

.author__name--link {
  text-underline-offset: 4px;
}

.author__photos {
  display: inline-flex;
  position: relative;
}

.author__photo:not(:first-child) {
  margin-left: -16px;
}

.author__date-and-category {
  gap: var(--spacing-8);
  flex-wrap: wrap;
  display: flex;
}

[data-collapser-target] {
  transition: max-height .15s ease-out, visibility .3s, opacity .3s;
  position: relative;
  overflow: hidden;
}

[data-collapser-target]:after {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--color-surface-default-contrast) 100%);
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

[data-collapser-target].is-collapsed {
  transition: max-height .15s ease-in, visibility .3s, opacity .3s;
}

[data-collapser-target].is-collapsed:after {
  visibility: visible;
  opacity: 1;
}

[data-collapser-target][aria-expanded="true"] {
  max-height: 100%;
}

[data-collapser-target][aria-expanded="true"]:after {
  display: none;
}

[data-collapser] {
  cursor: pointer;
  color: var(--color-text-action-primary-main);
  margin-top: var(--spacing-16);
  margin-bottom: var(--spacing-16);
  background: none;
  border: 0;
  align-items: center;
  text-decoration: none;
  display: flex;
}

[data-collapser-arrow] {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

[data-collapser-arrow] path {
  fill: var(--color-text-action-primary-main);
}

[data-collapser-arrow][aria-expanded="true"] {
  transform: rotate(180deg);
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity .2s;
}

.loading-placeholder:not(.loading-placeholder--loaded, .loading-placeholder--error) {
  background-color: #f8f8f8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.loading-placeholder--loaded, .loading-placeholder--error):after {
  content: " ";
  filter: opacity(.5);
  background-color: #f8f8f8;
  background-image: linear-gradient(to right, #f8f8f8 0%, #eee 33%, #f8f8f8 67%);
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  animation: 1s linear infinite gradient-animation;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.loading-placeholder--loaded, .loading-placeholder--error) img {
  text-indent: -9999px;
  color: #0000;
  position: absolute;
}

.loading-placeholder--loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder--loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder--error {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.loading-placeholder--error:before {
  content: "";
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.flag--update {
  gap: var(--spacing-4);
  padding: 0 var(--spacing-8);
  height: var(--spacing-24);
  color: var(--color-success-states-contained-hover-background);
  background-color: var(--color-success-alert-background);
  margin-bottom: var(--spacing-24);
  text-transform: uppercase;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: inline-flex;
}

.wiki-form {
  padding: var(--spacing-8) var(--spacing-12);
  margin: var(--spacing-16) 0;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
  background: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  flex-direction: row;
  width: 100%;
  height: 48px;
  display: flex;
}

.wiki-form__input {
  padding: var(--spacing-8) var(--spacing-4);
  font-size: var(--font-size-16);
  border: none;
  width: 90%;
  height: 16px;
}

.wiki-form__input:focus {
  outline: none;
}

.wiki-form__button {
  cursor: pointer;
  padding: var(--spacing-4);
  background: none;
  border: none;
}

.wiki-form__button svg {
  width: 32px;
  height: 32px;
}

.load-more-wrapper, .uncover-wrapper {
  text-align: center;
  padding-bottom: var(--spacing-16);
  position: relative;
}

.load-more-wrapper > a, .uncover-wrapper > a {
  text-decoration: none;
}

.load-more-wrapper--error, .uncover-wrapper--error {
  padding: var(--spacing-12);
}

.load-more-wrapper--gradient, .uncover-wrapper--gradient {
  text-align: center;
  padding-bottom: var(--spacing-16);
  position: relative;
}

.load-more-wrapper--gradient:before, .uncover-wrapper--gradient:before {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--background-default), var(--background-default) 140%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
}

.load-more-wrapper--hidden, .uncover-wrapper--hidden {
  display: none;
}

.load-more-disabled {
  gap: var(--spacing-12);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.load-more-disabled__icon {
  line-height: 0;
}

.load-more-disabled__text {
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

.load-more-disabled__title {
  text-align: center;
}

.load-more-disabled__subtitle {
  color: var(--text-secondary);
  text-align: center;
}

@media (max-width: 679px) {
  .load-more-disabled__title {
    font: var(--font-mobile-h4);
  }

  .load-more-disabled__subtitle {
    font: var(--font-mobile-body2);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .load-more-disabled__title {
    font: var(--font-desktop-h4);
  }

  .load-more-disabled__subtitle {
    font: var(--font-desktop-body2);
  }
}

.match-summary {
  gap: var(--spacing-32);
  flex-direction: column;
  display: flex;
}

.match-summary__section {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.match-summary__title {
  color: var(--color-text-default-meta);
}

.match-summary-events {
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex-flow: column wrap;
  display: flex;
}

@media (min-width: 1080px), (min-width: 680px) and (max-width: 1079px) {
  .match-summary-events {
    flex-direction: row;
  }
}

.match-summary-events__team {
  flex: 0 0 calc(50% - var(--spacing-32) / 2);
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 1080px), (min-width: 680px) and (max-width: 1079px) {
  .match-summary-events__team:nth-child(2n) {
    text-align: right;
    align-items: flex-end;
  }

  .match-summary-events__team:nth-child(2n) .match-summary-events__item {
    flex-flow: row-reverse wrap;
  }
}

.match-summary-events__team-name {
  margin-bottom: var(--spacing-8);
}

.match-summary-events__list {
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

.match-summary-events__item {
  justify-content: flex-start;
  gap: var(--spacing-8);
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.match-summary-events__item-icon svg {
  width: 20px;
  height: 20px;
}

.match-summary-events__item-text {
  gap: var(--spacing-4);
  flex-wrap: wrap;
  display: flex;
}

.match-summary-events__item-additional {
  color: var(--isport-iron-600);
}

.menubar {
  border-bottom: 1px solid var(--color-border-tab-normal-default);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.menubar__item {
  gap: var(--spacing-8);
  justify-content: center;
  align-items: center;
  height: 44px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .menubar__item {
    gap: var(--spacing-4);
  }
}

.menubar__item-title {
  transition: color .2s;
}

.menubar__item:hover, .menubar__item:hover .menubar__item-title {
  text-decoration: none;
}

.menubar__item:not(.menubar__item--active):hover {
  color: var(--themes-gradient-01-front);
}

.menubar__item:not(.menubar__item--active):hover .menubar__item-icon--right path {
  fill: var(--themes-gradient-01-front);
}

.menubar__item--main-category {
  margin-right: var(--spacing-12);
  margin-left: var(--spacing-24);
}

@media (max-width: 679px) {
  .menubar__item--main-category {
    margin-left: var(--spacing-16);
  }
}

.menubar__item-icon {
  width: auto;
  height: 1em;
}

.menubar__item-icon svg {
  width: inherit;
  height: inherit;
}

.menubar__item-icon svg * {
  transition: fill .2s;
}

.menubar__item-icon--main-category.menubar__item-icon--left {
  height: 24px;
}

@media (max-width: 679px) {
  .menubar__item-icon--main-category.menubar__item-icon--left {
    height: 20px;
  }
}

.menubar__item-icon--main-category.menubar__item-icon--left svg path {
  fill: url("#gradient-1");
}

.menubar__item-icon--main-category.menubar__item-icon--right {
  height: 20px;
}

.menubar__item--active {
  cursor: default;
}

.menubar__item--active:after {
  content: "";
  background: var(--themes-gradient-01-front);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.menubar__item--light {
  color: var(--color-text-tab-normal-title);
}

.menubar__item--light .menubar__item-icon path {
  fill: var(--color-text-tab-normal-title);
}

.menubar__item--dark {
  color: var(--color-text-tab-inverted-title);
}

.menubar__item--dark .menubar__item-icon svg * {
  fill: var(--color-text-tab-inverted-title);
}

.pagination {
  margin: var(--spacing-32) 0;
  position: relative;
}

.pagination > ul.pagination__wrapper {
  justify-content: center;
  gap: var(--spacing-8);
  flex-direction: row;
  width: 100%;
  margin: 0;
  display: flex;
}

.pagination__item {
  border: 2px var(--color-border-pagination-default) solid;
  font: var(--font-desktop-button-medium);
  text-align: center;
  color: var(--color-text-pagination-default);
  background-color: var(--color-surface-pagination-default);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  transition: all .4s ease-in-out;
  display: block;
}

.pagination__item:hover {
  color: var(--color-text-pagination-hover);
  border-color: var(--color-border-pagination-hover);
  background-color: var(--color-surface-pagination-hover);
}

.pagination__item:focus {
  background-color: var(--color-surface-pagination-focus);
  border-color: var(--color-border-pagination-focus);
  color: var(--color-text-pagination-focus);
}

.pagination .pagination__link {
  color: var(--color-text-pagination-default);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.pagination--active {
  pointer-events: none;
  color: var(--color-text-pagination-active);
  background-color: #0000;
  border-width: 2px;
  border-color: #0000;
  position: relative;
}

.pagination--active:before {
  content: "";
  background: linear-gradient(90deg, var(--themes-gradient-02-front) 0%, var(--themes-gradient-02-back) 100%) border-box;
  opacity: 1;
  border-style: solid;
  border-color: #0000;
  border-radius: 4px;
  position: absolute;
  inset: -2px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.pagination--active .pagination__link {
  color: var(--color-text-pagination-active);
}

.pagination__arrow-button {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
}

.pagination__arrow-button:first-child {
  left: 0;
}

.pagination__arrow-button:last-child {
  right: 0;
}

.pagination--disabled {
  pointer-events: none;
}

.params-list {
  --title-width: 111px;
  gap: var(--spacing-8);
  text-align: left;
  flex-flow: wrap;
  align-items: flex-start;
  display: flex;
}

.params-list__title {
  gap: var(--spacing-8);
  flex-flow: row;
  flex: 0 0 111px;
  align-items: center;
  display: flex;
}

.params-list__title span:after {
  content: ":";
}

.params-list__value {
  flex: 1 1 calc(100% - var(--title-width)  - var(--spacing-8));
}

.params-list svg {
  width: 20px;
  height: 20px;
}

.partner-promo {
  flex-flow: row;
  align-items: center;
  display: flex;
}

.partner-promo__logo {
  flex-flow: column;
  justify-content: center;
  max-width: 144px;
  height: 48px;
  display: flex;
}

.partner-promo__logo img {
  width: auto;
  max-width: 144px;
  max-height: 48px;
}

.partner-promo__name {
  color: var(--color-text-neutral-light);
  font: var(--font-desktop-meta-bold);
}

.partner-promo__name a {
  color: var(--color-surface-brand-primary-main);
  text-decoration: underline;
}

.partner-promo__name a:hover {
  text-decoration: none;
}

.partner-promo__link + .partner-promo__name {
  margin-left: var(--spacing-20);
}

@media (max-width: 679px) {
  .partner-promo__partner {
    font: var(--font-mobile-meta-bold);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .partner-promo__partner {
    font: var(--font-mobile-meta-bold);
  }
}

.partner-promo--theme-dark .partner-promo__name {
  color: var(--text-disabled);
}

.premium-box {
  padding: var(--spacing-48);
  background-color: var(--color-surface-premium-box-background);
  position: relative;
}

@media (max-width: 679px) {
  .premium-box {
    padding: var(--spacing-32) var(--spacing-16);
  }
}

.premium-box--fade-out:before {
  content: " ";
  background: linear-gradient(#fff0 0% 2%, #fff 70%, #fff);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.premium-box__logo {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-12);
  margin-bottom: var(--spacing-32);
  display: flex;
}

.premium-box__logo-premium svg {
  width: auto;
  height: 12px;
}

.premium-box__title {
  margin-bottom: var(--spacing-12);
  color: var(--color-text-premium-box-title);
}

.premium-box__description {
  margin-bottom: var(--spacing-24);
  color: var(--color-text-premium-box-body);
}

.premium-box__login {
  padding-top: var(--spacing-32);
}

.premium-box__login-text {
  color: var(--color-text-premium-box-body);
}

.promobox-main {
  background: var(--other-common-black, #000);
  padding-right: var(--spacing-16);
  align-items: flex-start;
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-40);
  grid-column: 1 / 4;
  grid-template-columns: 2fr 1fr;
  display: grid;
  color: #fff !important;
}

.promobox-main .article-card--theme-light .article-card__title {
  color: #fff !important;
}

.promobox-main > .promobox-article-main {
  box-sizing: border-box;
  padding-bottom: var(--spacing-40);
}

.promobox-main > .promobox-article-main .article-card .author__photo {
  border: 2px solid #fff;
  border-radius: 51%;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 8px 2px #1919191a, 0 2px 4px -2px #19191912;
}

.promobox-main > .promobox-article-main .article-card .meta__authors {
  font: var(--font-desktop-body1-bold);
  color: var(--color-text-disabled);
}

.promobox-main > .promobox-article-main .article-card__title {
  font: var(--font-desktop-h2);
}

@media (max-width: 679px) {
  .promobox-main > .promobox-article-main .article-card__title {
    font: var(--font-mobile-h3);
  }
}

.promobox-main > .promobox-article-main .article-card__content {
  margin-left: var(--spacing-32);
}

.promobox-main > .promobox-article-list {
  box-sizing: border-box;
  padding-top: var(--spacing-40);
  padding-bottom: var(--spacing-64);
  grid-auto-rows: 1fr 48px;
  align-items: center;
  height: 100%;
  display: grid;
}

.promobox-main > .promobox-article-list .article-card {
  justify-content: flex-start !important;
}

.promobox-main > .promobox-article-list .article-card__title {
  font: var(--font-mobile-h5);
}

.promobox-main > .promobox-article-list .article-card__container {
  flex-direction: row-reverse;
}

.promobox-main > .promobox-article-list .article-card__content {
  width: 100%;
}

.promobox-main > .promobox-article-list .article-card__image {
  object-fit: cover;
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
}

.promobox-main > .promobox-article-list .article-card__image picture, .promobox-main > .promobox-article-list .article-card__image img {
  object-fit: cover;
  aspect-ratio: 1 !important;
}

.promobox-main > .promobox-article-list > .divider {
  background: var(--secondary-main, #393939);
  flex: 1 0 0;
  justify-self: center;
  width: 100%;
  height: 1px;
}

.promobox-main > .promobox-article-list > .divider:first-of-type {
  display: none;
}

@media (max-width: 679px) {
  .promobox-main > .promobox-article-list > .divider:first-of-type {
    display: block;
  }
}

@media (max-width: 679px) {
  .promobox-main {
    padding-right: 0;
    padding-bottom: var(--spacing-16);
    flex-flow: column;
    grid-column: 1 / 4;
    display: flex;
  }

  .promobox-main > .promobox-article-main {
    padding-bottom: 0;
  }

  .promobox-main > .promobox-article-main .article-card__content {
    padding: 0 var(--spacing-16);
    gap: var(--spacing-16);
    margin-left: 0;
  }

  .promobox-main > .promobox-article-main .article-card .meta__date, .promobox-main > .promobox-article-main .article-card .meta__time {
    display: none;
  }

  .promobox-main > .promobox-article-list {
    padding-top: 0;
    padding: 0 var(--spacing-16);
    gap: var(--spacing-16);
    grid-auto-rows: unset;
  }

  .promobox-main > .promobox-article-list .article-card__container {
    flex-direction: row;
  }

  .promobox-main > .promobox-article-list .article-card__content {
    gap: var(--spacing-8) var(--spacing-12);
  }
}

.piano-position {
  grid-column: lend / rend;
  margin-inline: auto;
}

.piano-position:empty {
  display: none;
}

.piano-position--article-detail-top {
  margin-top: var(--spacing-32);
}

@media screen and (max-width: 679px) {
  .piano-position--article-detail-top {
    margin: var(--spacing-24) calc(-1 * var(--spacing-16)) 0;
  }
}

.piano-position--article-detail-perex {
  margin-bottom: var(--spacing-24);
}

@media screen and (max-width: 679px) {
  .piano-position--article-detail-perex {
    margin-bottom: var(--spacing-16);
  }
}

.registration {
  background-color: var(--color-surface-registration-default-light);
  box-shadow: var(--shadow-l);
}

@media (max-width: 679px) {
  .registration {
    box-shadow: none;
  }
}

.registration__top, .registration__bottom {
  padding: var(--spacing-32) var(--spacing-24) var(--spacing-40);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.registration__top {
  border: 1px solid var(--color-border-registration-subtle);
  gap: var(--spacing-32);
  border-bottom: none;
}

@media (max-width: 679px) {
  .registration__top {
    gap: var(--spacing-24);
    padding: var(--spacing-24) var(--spacing-16);
    border: none;
  }
}

.registration__bottom {
  border: 1px solid var(--color-border-registration-lighter);
  background-color: var(--color-surface-registration-default-subtle);
}

@media (max-width: 679px) {
  .registration__bottom {
    padding: var(--spacing-24) var(--spacing-16) var(--spacing-32);
    border: none;
  }
}

.registration__header {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.registration__perex {
  text-align: center;
  text-wrap: balanced;
  max-width: 655px;
}

.registration__benefits {
  align-items: center;
}

.registration__benefits svg path {
  fill: var(--color-icon-list-brand);
}

.registration__icon {
  margin-bottom: var(--spacing-12);
  background-color: var(--color-surface-registration-lock-background);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  display: flex;
}

.registration__login {
  align-items: center;
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.registration__button {
  width: 296px;
  max-width: 100%;
}

.registration__form {
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.svg-loader {
  margin: 0 auto;
  display: block;
}

.chevron_right:after {
  content: "";
  background: url("/dist-isport/assets/default/chevron_right.svg") center no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.enquiry-bars {
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.enquiry-bars__title {
  border-top: var(--spacing-4) solid var(--color-border-divider-section);
  padding-top: var(--spacing-8);
  font: var(--font-desktop-body2-bold);
  color: var(--color-text-survey-meta);
}

.enquiry-bars__question {
  font: var(--font-desktop-h2);
  color: var(--color-text-survey-title);
}

.enquiry-bars__answers {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.enquiry-bars-answers__answer {
  font: var(--font-desktop-body1);
  border-radius: var(--spacing-vote-strip-radius);
  border-left: var(--spacing-4) solid var(--color-border-vote-strip-default);
  background: var(--color-surface-vote-strip-default);
  position: relative;
}

.enquiry-bars-answers__answer--hover:hover {
  border-style: solid;
  border-width: 1px 1px 1px 4px;
  border-color: var(--color-border-vote-strip-hover);
  background: var(--color-surface-vote-strip-hover);
  cursor: pointer;
}

.enquiry-bars-answers__answer--hover:hover .enquiry-bars-answer__statement {
  padding: 7px 15px 7px 16px;
}

.enquiry-bars-answer__indicator {
  background-color: var(--color-surface-vote-strip-answer);
  width: 0;
  height: 100%;
  transition: width 1s linear;
  position: absolute;
}

.enquiry-bars-answer__statement {
  padding: var(--spacing-8) var(--spacing-16);
  justify-content: space-between;
  display: flex;
  position: relative;
}

.enquiry-bars-answer__statement--selected {
  font: var(--font-desktop-body1-bold);
}

.enquiry-bars__result-notice {
  padding: var(--spacing-24) var(--spacing-32);
  background-color: var(--color-surface-survey-background);
  gap: var(--spacing-24);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.enquiry-bars__result-notice .button {
  width: max-content;
  max-height: fit-content;
}

.enquiry-bars-result-notice__text {
  text-align: center;
  font: var(--font-desktop-body1-bold);
  color: var(--color-text-survey-body);
}

.enquiry-bars-result-notice__link {
  color: var(--white);
  width: 114px;
  text-decoration: none;
  display: block;
}

.enquiry-bars-result-notice__link:hover {
  text-decoration: underline;
}

.enquiry-bars__footer {
  border-bottom: 1px solid var(--color-border-divider-default);
  padding-bottom: var(--spacing-4);
  justify-content: space-between;
  display: flex;
}

.enquiry-bars-footer__vote-notice {
  color: var(--color-text-survey-confirm);
  font: var(--font-desktop-body1-bold);
}

.enquiry-bars-footer__total-votes {
  text-align: right;
  color: var(--color-text-survey-meta);
  font: var(--font-desktop-body1);
  margin-left: auto;
}

@media (max-width: 679px) {
  .enquiry-bars__title {
    font: var(--font-mobile-body2-bold);
  }

  .enquiry-bars__question {
    font: var(--font-mobile-h2);
  }

  .enquiry-bars-answers__answer {
    font: var(--font-mobile-body1);
  }

  .enquiry-bars-footer__vote-notice {
    font: var(--font-mobile-body1-bold);
  }

  .enquiry-bars-footer__total-votes {
    font: var(--font-mobile-body1);
  }
}

.survey {
  position: relative;
}

.survey:before {
  content: "";
  z-index: -1;
  background: var(--gradient-1);
  width: 100%;
  height: 4px;
  position: absolute;
  top: -4px;
  left: 0;
}

.survey[data-vote-disabled="true"] .survey__answers {
  pointer-events: none;
}

.survey__title {
  font: var(--font-desktop-body2-bold);
  color: var(--color-text-survey-meta);
  margin-bottom: 24px;
  padding-top: 8px;
}

.survey__question {
  color: var(--color-text-survey-title);
  font: var(--font-desktop-h2);
  margin-bottom: 24px;
}

.survey-answers__answer {
  border-radius: var(--spacing-vote-strip-radius);
  border: 1px solid #0000;
  border-left: var(--spacing-info-bar-video-mobile-top-padding) solid var(--color-border-vote-strip-default);
  background: var(--color-surface-vote-strip-default);
  font: var(--font-desktop-body1);
  margin-bottom: 8px;
  position: relative;
}

.survey-answers__answer:hover {
  background: var(--color-surface-vote-strip-hover);
  border: 1px solid var(--color-border-vote-strip-hover);
  border-left-width: var(--spacing-info-bar-video-mobile-top-padding);
  font-weight: 700;
}

.survey-answers__answer:focus {
  border: 1px solid var(--color-border-vote-strip-focus);
  border-left-width: var(--spacing-info-bar-video-mobile-top-padding);
  background: var(--color-surface-vote-strip-default);
  border-color: var(--color-border-vote-strip-focus);
}

.survey-answers__answer[data-is-user-answer="true"] {
  background: var(--color-surface-vote-strip-default);
  border-color: var(--color-border-vote-strip-default);
}

.survey-answers__answer:last-child {
  margin-bottom: 24px;
}

.survey-answers__answer--hover {
  cursor: pointer;
}

.survey-answer__indicator {
  background: var(--color-surface-vote-strip-answer);
  width: 0;
  height: 100%;
  transition: width 1s linear;
  position: absolute;
}

.survey-answer__statement {
  justify-content: space-between;
  padding: 8px 16px;
  display: flex;
  position: relative;
}

.survey-answer__statement--selected {
  font-weight: 700;
}

.survey-answer__statement--selected span {
  font-weight: 700 !important;
}

.survey__result-notice {
  background: var(--color-surface-survey-background);
  margin-bottom: 24px;
  padding: 24px 32px;
  display: none;
}

.survey__result-notice .button {
  padding: var(--spacing-8) var(--spacing-24);
  box-sizing: border-box;
  font: var(--typography-desktop-button-md-font-size) / var(--typography-desktop-button-md-line-height) var(--button-md-font-family);
  width: max-content;
  max-height: fit-content;
  display: flex;
}

.survey-result-notice__text {
  color: var(--color-text-survey-body);
  text-align: center;
  font-size: var(--font-size-18);
  font-weight: 700;
  line-height: 28px;
  font: var(--font-desktop-body1-bold);
}

.survey-result-notice__link {
  color: var(--white);
  width: 114px;
  margin: 24px auto 0;
  text-decoration: none;
  display: block;
}

.survey__footer {
  border-bottom: 1px solid var(--grey-200);
  justify-content: space-between;
  padding-bottom: 4px;
  display: flex;
}

.survey-footer__vote-notice {
  color: var(--color-text-survey-confirm);
  font: var(--font-desktop-body1-bold);
}

.survey-footer__total-votes {
  text-align: right;
  color: var(--grey-600);
  font: var(--font-desktop-body1);
  margin-left: auto;
}

@media (max-width: 679px) {
  .survey__title {
    font: var(--font-mobile-body2-bold);
  }

  .survey__question {
    font: var(--font-mobile-h2);
  }

  .survey-answers__answer {
    font: var(--font-mobile-body1);
  }

  .survey-footer__vote-notice {
    font: var(--font-mobile-body1-bold);
  }

  .survey-footer__total-votes {
    font: var(--font-mobile-body1);
    color: var(--color-text-survey-meta);
  }
}

.print__cover {
  flex-direction: column;
  display: flex;
}

.print__cover > a {
  text-decoration: none;
  display: flex;
}

@media (max-width: 679px) {
  .print__cover {
    gap: var(--spacing-16);
  }

  .print__cover > a > img {
    width: 106px;
    height: auto;
  }

  .print__cover .button {
    width: 106px;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .print__cover {
    gap: var(--spacing-12);
  }

  .print__cover > a > img {
    width: 128px;
    height: auto;
  }

  .print__cover .button {
    width: 128px;
  }
}

.field {
  box-sizing: border-box;
  background: var(--color-surface-lineup-playground) url("/dist-isport/assets/default/detail-match/hriste.svg") 50% 50% no-repeat;
  margin-bottom: var(--spacing-32);
  background-size: contain;
  height: 0;
  padding-bottom: 62%;
  position: relative;
}

@media (max-width: 679px) {
  .field {
    background-image: url("/dist-isport/assets/default/detail-match/hriste-mobil.svg");
    padding-bottom: 161%;
  }
}

.field__line {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

@media (max-width: 679px) {
  .field__line {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.field__player {
  text-align: center;
  gap: var(--spacing-16);
  margin-bottom: var(--spacing-16);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  flex-direction: column;
  height: 60px;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .field__player {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .field__player {
    gap: var(--spacing-8);
    height: 44px;
  }
}

.field__player-number {
  color: var(--color-text-lineup-body);
  top: var(--spacing-12);
  text-shadow: 0 0 10px #fff, 0 0 8px #fff, 0 0 6px #fff;
  display: block;
  position: relative;
}

@media (max-width: 480px) {
  .field__player-number {
    top: var(--spacing-8);
  }
}

.field__player-name {
  border-radius: var(--spacing-4);
  background: var(--color-surface-lineup-player);
  color: var(--color-text-lineup-body);
  padding: var(--spacing-4);
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0 -6px 18px -6px var(--color-surface-lineup-shadow);
  max-width: 68px;
  display: block;
  overflow: hidden;
}

@media (max-width: 480px) {
  .field__player-name {
    padding: var(--spacing-2);
  }
}

.field__player-icons {
  margin-top: calc(-1 * var(--spacing-2));
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2);
  width: 100%;
  display: flex;
  position: absolute;
  top: 100%;
}

.field__player-icons svg {
  width: var(--spacing-16);
  height: var(--spacing-16);
}

@media (max-width: 679px) {
  .field__player-icons {
    bottom: var(--spacing-24);
    flex-flow: wrap;
    justify-content: flex-start;
    width: 34px;
    top: auto;
    left: 82%;
  }
}

@media (max-width: 480px) {
  .field__player-icons {
    gap: 0;
    width: 26px;
    padding: 0;
    bottom: 14px;
  }

  .field__player-icons svg {
    width: var(--spacing-12);
    height: var(--spacing-12);
  }
}

@media (max-width: 380px) {
  .field__player-icons {
    display: none;
  }
}

.field__player-icons-text {
  display: none;
}

.field__home, .field__away {
  gap: var(--spacing-4);
  padding: var(--spacing-8);
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 100%;
  display: flex;
  position: absolute;
}

@media (max-width: 679px) {
  .field__home, .field__away {
    padding: var(--spacing-4);
    align-items: stretch;
    width: 100%;
    height: 50%;
    left: 0;
    right: 0;
  }
}

.field__home {
  flex-direction: row-reverse;
  left: 0;
}

@media (max-width: 679px) {
  .field__home {
    flex-direction: column-reverse;
    top: 0;
  }
}

.field__home .field__player {
  background-image: url("/dist-isport/assets/default/detail-match/dres-red.svg");
}

.field__away {
  flex-direction: row;
  right: 0;
}

@media (max-width: 679px) {
  .field__away {
    flex-direction: column;
    bottom: 0;
  }
}

.field__away .field__player {
  background-image: url("/dist-isport/assets/default/detail-match/dres-blue.svg");
}

.line-up-table {
  border-collapse: collapse;
  width: 100%;
}

@media (max-width: 679px) {
  .line-up-table {
    width: calc(100% + 2 * var(--spacing-16));
    margin: 0 calc(-1 * var(--spacing-16));
  }
}

.line-up-table__group-title {
  background: var(--color-surface-title-section-inverted);
  color: var(--color-text-title-section-inverted-title);
  border: 0;
}

.line-up-table__group-title th, .line-up-table__group-title td {
  padding: var(--spacing-12) var(--spacing-16);
}

@media (max-width: 679px) {
  .line-up-table__group-title th, .line-up-table__group-title td {
    padding: var(--spacing-8) var(--spacing-12);
  }
}

.line-up-table__group-title-text {
  color: var(--color-text-title-section-inverted-title);
}

.line-up-table__team, .line-up-table__player, .line-up-table__player-detail {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-2) var(--spacing-8);
  flex-flow: wrap;
  display: flex;
}

.line-up-table__team--away, .line-up-table__player--away, .line-up-table__player-detail--away {
  flex-direction: row-reverse;
}

.line-up-table__player {
  width: 100%;
  display: flex;
}

.line-up-table__player-number {
  min-width: var(--spacing-16);
  text-align: right;
  display: inline-block;
}

.line-up-table__player-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 48px);
  overflow: hidden;
}

.line-up-table__player-icons {
  align-items: center;
  gap: var(--spacing-2);
  flex-wrap: wrap;
  display: inline-flex;
}

@media (max-width: 679px) {
  .line-up-table__player-icons {
    padding: 0 var(--spacing-20);
    width: 100%;
  }

  .line-up-table__player--away .line-up-table__player-icons {
    justify-content: flex-end;
  }
}

.line-up-table__player-icons-text {
  display: none;
}

.line-up-table__player-icons-item {
  align-items: center;
  display: flex;
}

.line-up-table__player-icons-item[data-type="goal"] svg *, .line-up-table__player-icons-item[data-type="assist"] svg * {
  fill: #60a562;
}

.line-up-table__player-icons-item[data-type="substitution"] {
  gap: var(--spacing-4);
  padding: 0 var(--spacing-20);
  width: 100%;
  display: flex;
}

.line-up-table__player--away .line-up-table__player-icons-item[data-type="substitution"] {
  text-align: right;
  flex-direction: row-reverse;
}

.line-up-table__player-icons-item[data-type="substitution"] .line-up-table__player-icons-text {
  color: var(--color-text-lineup-meta);
  display: inline-block;
}

.line-up-table__player-icons-item svg {
  width: var(--spacing-16);
  height: var(--spacing-16);
}

.line-up-table__player-icons:empty {
  display: none;
}

.line-up-table__player-detail {
  padding: 0 var(--spacing-24);
  margin-bottom: calc(-1 * var(--spacing-4));
}

.line-up-table__player-detail-text {
  color: var(--color-text-lineup-meta);
}

.line-up-table thead {
  background: var(--color-surface-lineup-background);
}

.line-up-table thead th, .line-up-table thead td {
  padding: var(--spacing-12) var(--spacing-16);
  vertical-align: middle;
}

@media (max-width: 679px) {
  .line-up-table thead th, .line-up-table thead td {
    padding: var(--spacing-8) var(--spacing-12);
  }
}

.line-up-table tr {
  border-bottom: 1px solid var(--color-border-divider-default);
}

.line-up-table td, .line-up-table th {
  padding: var(--spacing-8) var(--spacing-16);
  vertical-align: top;
  width: 50%;
}

@media (max-width: 679px) {
  .line-up-table td, .line-up-table th {
    padding: var(--spacing-8) var(--spacing-12);
  }
}

.link-list {
  align-items: center;
  gap: 0 var(--spacing-12);
  flex-wrap: wrap;
  display: flex;
}

.link-list__item {
  color: var(--color-text-default-body);
}

.link-list__link {
  color: inherit;
}

.link-list__link:hover {
  text-decoration: none;
}

.promo-subscription {
  gap: var(--spacing-16);
  padding: var(--spacing-40);
  background-color: var(--color-surface-neutral-strong);
  border-radius: var(--spacing-8);
  background-image: var(--gradient-4);
  flex-direction: column;
  flex: 1 0 0;
  grid-area: print;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .promo-subscription {
    width: 100%;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .promo-subscription {
    align-self: flex-start;
  }
}

.promo-subscription:before {
  content: "";
  background-color: var(--themes-gradient-02-front);
  background-image: var(--gradient-2);
  border-radius: var(--spacing-8);
  height: var(--spacing-16);
  z-index: -1;
  width: 100%;
  position: absolute;
  top: -4px;
  left: 0;
}

.promo-subscription__header {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-16);
  display: flex;
}

.promo-subscription__divider {
  min-height: var(--spacing-16);
}

.promo-subscription__benefits {
  color: var(--color-text-default-contrast);
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.promo-subscription__benefits-content {
  color: var(--isport-brick-300);
}

.select {
  visibility: hidden;
  opacity: 0;
}

.choices {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.choices:focus {
  outline: none;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
  background-color: var(--color-surface-default-disabled);
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices__inner {
  vertical-align: top;
  background-color: var(--color-surface-default-contrast);
  padding: var(--spacing-8) var(--spacing-12);
  border: 1px solid var(--color-text-default-body);
  border-radius: var(--spacing-input-radius);
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  padding-right: var(--spacing-24);
  width: 100%;
  display: inline-block;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__item {
  font: var(--font-mobile-body2);
  color: var(--color-text-default-body);
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .5;
}

.choices__input {
  vertical-align: baseline;
  background-color: var(--color-surface-default-contrast);
  font: var(--font-mobile-body2);
  color: var(--color-text-default-body);
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-4) 0 var(--spacing-4) var(--spacing-2);
  border: 0;
  border-radius: 0;
  max-width: 100%;
  display: inline-block;
}

.choices__input:focus {
  outline: 0;
}

.choices__input::-webkit-search-decoration {
  display: none;
}

.choices__input::-webkit-search-cancel-button {
  display: none;
}

.choices__input::-webkit-search-results-button {
  display: none;
}

.choices__input::-webkit-search-results-decoration {
  display: none;
}

.choices__input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.choices__input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

.choices__placeholder {
  color: var(--color-text-default-placeholder);
  font: var(--font-mobile-body2);
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"]:after {
  content: "";
  right: var(--spacing-12);
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M13.2291%207.50004L9.9958%2010.7334L6.76247%207.50004C6.43747%207.17504%205.91247%207.17504%205.58747%207.50004C5.26247%207.82504%205.26247%208.35004%205.58747%208.67504L9.41247%2012.5C9.73747%2012.825%2010.2625%2012.825%2010.5875%2012.5L14.4125%208.67504C14.7375%208.35004%2014.7375%207.82504%2014.4125%207.50004C14.0875%207.18337%2013.5541%207.17504%2013.2291%207.50004Z%22%20fill%3D%22%23DF0F19%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.choices[data-type*="select-one"].is-open:after {
  transform: translateY(-50%)rotate(180deg);
}

.choices[data-type*="select-one"] .choices__input {
  padding: var(--spacing-8) var(--spacing-12);
  border-bottom: 1px solid var(--color-border-default-subtle);
  background-color: var(--color-surface-default-contrast);
  width: 100%;
  margin: 0;
  display: block;
}

.choices__list[aria-expanded], .choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  background-color: var(--color-surface-default-contrast);
  margin-top: var(--spacing-4);
  border-radius: var(--spacing-input-radius);
  will-change: visibility;
  box-shadow: var(--shadow-m);
  width: 100%;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.is-active.choices__list[aria-expanded], .is-active.choices__list--dropdown {
  visibility: visible;
}

.is-flipped .choices__list[aria-expanded], .is-flipped .choices__list--dropdown {
  border-radius: .25rem .25rem 0 0;
  margin-top: 0;
  margin-bottom: -1px;
  top: auto;
  bottom: 100%;
}

.choices__list[aria-expanded] .choices__list, .choices__list--dropdown .choices__list {
  margin: var(--spacing-8) 0;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  max-height: 216px;
  position: relative;
  overflow: auto;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar {
  width: 14px;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar {
  width: 14px;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar-track {
  background-clip: padding-box;
  background-color: var(--color-surface-default-disabled);
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar-track {
  background-clip: padding-box;
  background-color: var(--color-surface-default-disabled);
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar-thumb {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar-thumb {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar-thumb:hover {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar-thumb:hover {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
}

.choices__list[aria-expanded] .choices__item, .choices__list--dropdown .choices__item {
  padding: var(--spacing-8) var(--spacing-16);
  color: var(--color-text-default-body);
  font: var(--font-desktop-body2);
  position: relative;
}

.choices__list[aria-expanded] .choices__item:hover, .choices__list--dropdown .choices__item:hover {
  background-color: var(--color-surface-default-hover);
}

.choices__list[aria-expanded] .choices__placeholder, .choices__list--dropdown .choices__placeholder {
  display: none;
}

.scroll-menu .is-mouse-drag {
  -webkit-user-select: none;
  user-select: none;
  cursor: ew-resize;
}

.scroll-menu__item {
  text-decoration: none;
  display: block;
}

.scroll-menu__item:hover {
  color: var(--primary-main);
  text-decoration: none;
}

.scroll-menu__item:hover .typography {
  text-decoration: none;
}

.scroll-menu--theme-light .scroll-menu__item {
  color: var(--text-primary);
}

.scroll-menu--theme-light .scroll-menu__item:hover {
  color: var(--primary-dark);
}

.scroll-menu--theme-dark .scroll-menu__item {
  color: var(--color-text-default-contrast);
}

.scroll-menu--theme-dark .scroll-menu__item:hover {
  color: var(--primary-main);
}

.social-icon-list, .social-share {
  gap: var(--spacing-12);
  flex-direction: row;
  display: flex;
}

.social-media {
  gap: var(--spacing-12);
  flex-flow: wrap;
  display: flex;
}

.social-media:empty {
  display: none;
}

.premium-sticker {
  background-image: url("/dist-isport/assets/default/premium-sticker-black.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 88px;
  height: 20px;
}

.swiper {
  position: relative;
  container: swiper / inline-size;
}

.swiper__container {
  position: relative;
}

.swiper__container.end-fade:after {
  content: "";
  pointer-events: none;
  width: 120px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
}

.swiper__list {
  gap: var(--spacing-24);
  white-space: nowrap;
  scroll-snap-type: x proximity;
  cursor: grab;
  transition: all .3s ease-in-out;
  display: flex;
  overflow: auto;
}

.swiper__list:active {
  cursor: grabbing;
}

.swiper__item {
  white-space: normal;
  scroll-snap-align: start;
  flex-shrink: 0;
}

.swiper__item:has(.tabs__item--hidden) {
  display: none;
}

.swiper__navigation {
  padding-top: var(--spacing-12);
}

@media (min-width: 576px) {
  .swiper__navigation {
    display: none;
  }
}

.swiper__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.swiper__arrow--prev {
  left: var(--spacing-20);
}

.swiper__arrow--next {
  right: var(--spacing-20);
}

.swiper--article .swiper__item {
  width: calc((100% - var(--spacing-24)) / 1.5);
}

@media (min-width: 480px) {
  .swiper--article .swiper__item {
    width: 50%;
  }
}

@media (min-width: 576px) {
  .swiper--article .swiper__item {
    flex-shrink: 1;
  }
}

.swiper--article .swiper__item:nth-of-type(5), .swiper--article .swiper__item:nth-of-type(6) {
  display: block;
}

@media (min-width: 576px) {
  .swiper--article .swiper__item:nth-of-type(5), .swiper--article .swiper__item:nth-of-type(6) {
    display: none;
  }
}

@container swiper (width >= 768px) {
  .swiper--article .swiper__item:nth-of-type(5) {
    display: block;
  }
}

@container swiper (width >= 850px) {
  .swiper--article .swiper__item:nth-of-type(6) {
    display: block;
  }
}

.swiper--mobile-navigation {
  width: 100%;
}

.swiper--mobile-navigation .swiper__list {
  gap: var(--spacing-8);
}

.swiper--filters {
  width: 100%;
}

.swiper--filters .swiper__list {
  gap: var(--spacing-8);
}

.swiper--filters .swiper__arrow--prev {
  left: 0;
}

.swiper--filters .swiper__arrow--next {
  right: 0;
}

@media (max-width: 679px) {
  .swiper--filters .swiper__arrow {
    display: none;
  }
}

.swiper--video .swiper__list {
  padding-right: var(--spacing-16);
}

@media (max-width: 679px) {
  .swiper--video .swiper__list {
    gap: var(--spacing-16);
  }
}

.swiper--video .swiper__arrow {
  opacity: .8;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 135px;
  display: flex;
  position: absolute;
  top: 0;
  transform: translateY(0);
}

@media (max-width: 679px) {
  .swiper--video .swiper__arrow {
    display: none;
  }
}

.swiper--video .swiper__arrow svg {
  width: 18px;
  height: 18px;
}

.swiper--video .swiper__arrow--prev {
  left: 0;
}

.swiper--video .swiper__arrow--next {
  right: 16px;
}

.swiper--author-articles .swiper__list {
  padding-right: var(--spacing-16);
  align-items: stretch;
  display: flex;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .swiper--author-articles .swiper__list {
    max-width: 100%;
  }
}

@media (max-width: 679px) {
  .swiper--author-articles .swiper__list {
    gap: var(--spacing-16);
  }
}

.swiper--author-articles .swiper__item {
  cursor: auto;
  align-items: stretch;
  height: auto;
  display: flex;
}

.swiper--author-articles .swiper__arrow {
  z-index: 50;
  opacity: .8;
  justify-content: center;
  align-items: center;
  width: 50px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateY(0);
}

@media (max-width: 679px) {
  .swiper--author-articles .swiper__arrow {
    display: none;
  }
}

.swiper--author-articles .swiper__arrow svg {
  width: 18px;
  height: 18px;
}

.swiper--author-articles .swiper__arrow--prev {
  left: 0;
}

.swiper--author-articles .swiper__arrow--next {
  right: 16px;
}

.swiper--tickets .swiper__list {
  gap: 0;
  max-width: 100%;
  margin: 0 auto;
  display: inline-flex;
}

.swiper--tickets .swiper__item {
  padding-left: var(--spacing-16);
}

.swiper--tickets .swiper__item:last-of-type {
  padding-right: var(--spacing-16);
}

.swiper--tabs .swiper__container, .swiper--menubar .swiper__container {
  position: relative;
}

.swiper--tabs .swiper__container.end-fade:after, .swiper--menubar .swiper__container.end-fade:after {
  right: 0;
}

.swiper--tabs .swiper__list, .swiper--menubar .swiper__list {
  gap: var(--spacing-24);
  padding-right: var(--spacing-12);
}

.swiper--tabs .swiper__list {
  border-bottom: 1px solid var(--color-border-tab-normal-default);
}

.swiper--tabs .swiper__arrow--prev {
  left: var(--spacing-8);
}

.swiper--tabs .swiper__arrow--next {
  right: var(--spacing-8);
}

@media (max-width: 679px) {
  .swiper--tabs .swiper__arrow {
    display: none;
  }
}

.swiper--menubar {
  width: 100%;
}

@media (max-width: 679px) {
  .swiper--menubar .swiper__arrow {
    display: none;
  }
}

.swiper--menubar .swiper__arrow--prev {
  left: 0;
}

.swiper--menubar .swiper__arrow--next {
  right: var(--spacing-8);
}

@media (min-width: 680px) and (max-width: 1079px) {
  .swiper--menubar .swiper__arrow--next {
    right: var(--spacing-16);
  }
}

.swiper--menubar .swiper__list {
  gap: var(--spacing-16);
}

.swiper--menubar .swiper.end-fade:after {
  width: 50px;
}

.swiper--theme-white .swiper__container.end-fade:after, .swiper--theme-light .swiper__container.end-fade:after {
  background: linear-gradient(90deg, #0000 0%, #ffffff80 50%, #fff 100%);
}

.swiper--theme-iron .swiper__container.end-fade:after {
  background: linear-gradient(90deg, transparent 0%, var(--isport-iron-100) 100%);
}

.swiper--theme-dark .swiper__container.end-fade:after {
  background: linear-gradient(90deg, transparent 0%, var(--color-surface-neutral-strong) 100%);
}

.swiper-navigation {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.swiper-navigation__item {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.swiper-navigation__item:before {
  content: "";
  pointer-events: none;
  border-radius: 2px;
  display: block;
}

.swiper-navigation--barred .swiper-navigation__item {
  width: 40px;
  height: 30px;
}

.swiper-navigation--barred .swiper-navigation__item:before {
  background-color: var(--color-surface-neutral-main);
  width: 100%;
  height: 6px;
}

.swiper-navigation--barred .swiper-navigation__item.is-active:before {
  background-color: var(--color-surface-brand-primary-main);
}

* {
  --tag-gradient-active-normal-bg: var(--gradient-1);
  --tag-gradient-active-inverted-bg: var(--gradient-2);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal {
  border: 1px solid var(--color-border-tag-solid-normal-default);
  background: var(--color-surface-tag-solid-normal-default);
  color: var(--color-text-tag-solid-normal-default);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal:hover {
  border: 1px solid var(--color-border-tag-solid-normal-hover);
  background: var(--color-surface-tag-solid-normal-hover);
  color: var(--color-text-tag-solid-normal-hover);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal:focus {
  border: 1px solid var(--color-border-tag-solid-normal-focus);
  background: var(--color-surface-tag-solid-normal-focus);
  color: var(--color-text-tag-solid-normal-focus);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal:active {
  background: var(--color-surface-tag-solid-normal-active);
  color: var(--color-text-tag-solid-normal-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal[disabled] {
  border: 1px solid var(--color-border-tag-solid-normal-disabled);
  background: var(--color-surface-tag-solid-normal-disabled);
  color: var(--color-text-tag-solid-normal-disabled);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal {
  border: 1px solid var(--color-border-tag-gradient-normal-default);
  background: var(--color-surface-tag-gradient-normal-default);
  color: var(--color-text-tag-gradient-normal-default);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:hover {
  background: var(--color-surface-tag-gradient-normal-hover);
  color: var(--color-text-tag-gradient-normal-hover);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:focus {
  background: var(--color-surface-tag-gradient-normal-focus);
  color: var(--color-text-tag-gradient-normal-focus);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:active {
  background: var(--tag-gradient-active-normal-bg);
  color: var(--color-text-tag-gradient-normal-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:active span {
  background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal[disabled] {
  border: 1px solid var(--color-border-tag-gradient-normal-disabled);
  background: var(--color-surface-tag-gradient-normal-disabled);
  color: var(--color-text-tag-gradient-normal-disabled);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted {
  border: 1px solid var(--color-border-tag-solid-inverted-default);
  background: var(--color-surface-tag-solid-inverted-default);
  color: var(--color-text-tag-solid-inverted-default);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted:hover {
  border: 1px solid var(--color-border-tag-solid-inverted-hover);
  background: var(--color-surface-tag-solid-inverted-hover);
  color: var(--color-text-tag-solid-inverted-hover);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted:focus {
  border: 1px solid var(--color-border-tag-solid-inverted-focus);
  background: var(--color-surface-tag-solid-inverted-focus);
  color: var(--color-text-tag-solid-inverted-focus);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted:active {
  background: var(--color-surface-tag-solid-inverted-active);
  color: var(--color-text-tag-solid-inverted-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted[disabled] {
  border: 1px solid var(--color-border-tag-solid-inverted-disabled);
  background: var(--color-surface-tag-solid-inverted-disabled);
  color: var(--color-text-tag-solid-inverted-disabled);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted {
  border: 1px solid var(--color-border-tag-gradient-inverted-default);
  background: var(--color-surface-tag-gradient-inverted-default);
  color: var(--color-text-tag-gradient-inverted-default);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:hover {
  background: var(--color-surface-tag-gradient-inverted-hover);
  color: var(--color-text-tag-gradient-inverted-hover);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:focus {
  background: var(--color-surface-tag-gradient-inverted-focus);
  color: var(--color-text-tag-gradient-inverted-focus);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:active {
  background: var(--tag-gradient-active-inverted-bg);
  color: var(--color-text-tag-gradient-inverted-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:active span {
  background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted[disabled] {
  border: 1px solid var(--color-border-tag-gradient-inverted-disabled);
  background: var(--color-surface-tag-gradient-inverted-disabled);
  color: var(--color-text-tag-gradient-inverted-disabled);
}

.tag-wrapper {
  padding: var(--spacing-2) var(--spacing-12);
  border-radius: var(--spacing-tag-radius);
  border: 1px solid var(--color-border-tag-default-default);
  background: var(--color-surface-tag-primary-default);
  outline: none;
  text-decoration: none;
  transition: color .2s, background-color .2s, border-color .2s;
  display: inline-flex;
  position: relative;
}

.tag-wrapper:before {
  border-radius: calc(var(--spacing-tag-radius)  + 1px);
}

.tag-wrapper > .tag {
  text-decoration: none;
  position: relative;
}

.tag-wrapper > .tag:hover {
  text-decoration: none;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:before {
  content: "";
  z-index: -1;
  border-radius: calc(var(--spacing-tag-radius)  + 1px);
  background: var(--gradient-1);
  opacity: 0;
  margin: -2px;
  transition: opacity .2s;
  position: absolute;
  inset: 0;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:hover:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:hover > .tag {
  background: var(--gradient-1);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:focus:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:focus > .tag {
  background: var(--gradient-1);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:before {
  content: "";
  z-index: -1;
  border-radius: calc(var(--spacing-tag-radius)  + 1px);
  background: var(--gradient-2);
  opacity: 0;
  margin: -2px;
  transition: opacity .2s;
  position: absolute;
  inset: 0;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:hover:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:hover > .tag {
  background: var(--gradient-2);
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:focus:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:focus > .tag {
  background: var(--gradient-2);
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:active > .tag {
  border-color: #0000;
}

.tag-list {
  margin: var(--spacing-32) 0;
  gap: var(--spacing-8);
  flex-wrap: wrap;
  display: flex;
}

.tag-list__item {
  display: inline-flex;
}

.title-section {
  padding: var(--spacing-12) var(--spacing-16);
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.title-section--dark {
  color: var(--color-text-title-section-inverted-title);
  background: var(--color-surface-title-section-inverted);
}

.title-section--dark .title-section__icon path {
  fill: var(--color-text-title-section-inverted-title);
}

.title-section--light {
  border-top: 1px solid var(--color-border-title-section-default);
  color: var(--color-text-title-section-normal-title);
  background: var(--color-surface-title-section-normal);
}

.topic {
  padding: var(--spacing-32) var(--spacing-24);
  background-color: var(--surface-default-disabled);
  container: topic / inline-size;
}

.topic__image {
  margin-bottom: var(--spacing-24);
  overflow: hidden;
}

.topic__image img {
  object-fit: cover;
  width: 100%;
  height: 128px;
  display: block;
}

@media (max-width: 679px) {
  .topic__image img {
    max-height: 68px;
  }
}

.topic .scroll-menu__item {
  padding-top: 6px;
}

.topic__top {
  justify-content: space-between;
  gap: var(--spacing-16);
  padding-bottom: var(--spacing-24);
  align-items: center;
  display: flex;
}

@media (max-width: 679px) {
  .topic__top {
    flex-direction: column;
    align-items: flex-start;
  }
}

.topic__heading {
  align-items: flex-start;
  gap: var(--spacing-8) var(--spacing-24);
  flex-direction: column;
  display: flex;
}

@container topic (width >= 680px) {
  .topic__heading {
    flex-direction: row;
  }
}

.topic__badge {
  padding-bottom: var(--spacing-4);
  flex: none;
}

.topic__link {
  color: inherit;
  text-decoration: none;
}

.topic__unimenu {
  padding-bottom: var(--spacing-12);
  padding-top: var(--spacing-12);
  margin-top: calc(-1 * var(--spacing-24));
  margin-bottom: var(--spacing-24);
  position: relative;
}

.topic__unimenu:after {
  content: "";
  background-color: var(--secondary-outlined-resting-border);
  opacity: .3;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.topic__partner {
  max-width: 230px;
}

.topic__main-article {
  margin-bottom: var(--spacing-24);
}

.topic__top-articles {
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-24);
  margin-top: var(--spacing-24);
  display: flex;
}

@media (max-width: 679px) {
  .topic__top-articles {
    flex-direction: column;
  }
}

.topic__top-articles__item {
  width: 50%;
}

@container topic (width >= 768px) {
  .topic__top-articles__item {
    width: 33.3%;
  }
}

@media (max-width: 679px) {
  .topic__top-articles__item {
    width: 100%;
  }
}

@media (max-width: 679px) {
  .topic__top-articles__item:not(:first-of-type) .article-card__image {
    display: none;
  }
}

.topic__top-articles__item:nth-of-type(3) {
  display: none;
}

@container topic (width >= 768px) {
  .topic__top-articles__item:nth-of-type(3) {
    display: block;
  }
}

@media (max-width: 679px) {
  .topic__top-articles__item:nth-of-type(3) {
    display: block;
  }
}

.topic__custom-article html, .topic__custom-article body, .topic__custom-article .font-body {
  font: var(--font-mobile-body1);
  color: var(--text-primary);
}

.topic__custom-article h1, .topic__custom-article .heading-1 {
  font: var(--font-mobile-h1);
}

.topic__custom-article h2, .topic__custom-article .heading-2 {
  font: var(--font-mobile-h2);
}

.topic__custom-article h3, .topic__custom-article .heading-3 {
  font: var(--font-mobile-h3);
}

.topic__custom-article h4, .topic__custom-article .heading-4 {
  font: var(--font-mobile-h4);
}

.topic__custom-article h5, .topic__custom-article .heading-5 {
  font: var(--font-mobile-h5);
}

.topic__custom-article .button {
  text-decoration: none;
}

.topic__custom-article em, .topic__custom-article em strong {
  font-style: italic;
}

.topic__custom-article sub, .topic__custom-article sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.topic__custom-article sup {
  top: -.5em;
}

.topic__custom-article sub {
  bottom: -.25em;
}

.topic__custom-article ul {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: disc;
}

.topic__custom-article ul ul {
  margin: 0 0 0 var(--spacing-24);
  list-style-type: circle;
}

.topic__custom-article ol {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: decimal;
}

.topic__custom-article ol ol {
  margin: 0 0 0 var(--spacing-24);
}

.topic__custom-article hr {
  border: 0;
  border-top: 1px solid var(--other-divider);
}

.topic__custom-article blockquote:not(.tiktok-embed) {
  background-color: var(--other-divider);
  border-left: 5px solid var(--other-backdrop-overlay);
  padding: var(--spacing-20);
}

@media (max-width: 679px) {
  .topic__custom-article p {
    font: var(--font-mobile-body1);
  }

  .topic__custom-article strong {
    font: var(--font-mobile-body1-bold);
  }

  .topic__custom-article table thead {
    font: var(--font-mobile-body2-bold);
  }

  .topic__custom-article table tbody {
    font: var(--font-mobile-body2);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .topic__custom-article p {
    font: var(--font-desktop-body1);
  }

  .topic__custom-article strong {
    font: var(--font-desktop-body1-bold);
  }

  .topic__custom-article table thead {
    font: var(--font-desktop-body2-bold);
  }

  .topic__custom-article table tbody {
    font: var(--font-desktop-body2);
  }
}

.topic__custom-article-body .uncover-wrapper:before {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--action-disabled-background), var(--action-disabled-background) 140%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
}

.topic__bottom-articles {
  margin-bottom: var(--spacing-24);
  padding-top: var(--spacing-24);
  position: relative;
}

.topic__bottom-articles:after {
  content: "";
  background-color: var(--secondary-outlined-resting-border);
  opacity: .3;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.topic__cta {
  padding-top: var(--spacing-8);
  justify-content: center;
  display: flex;
}

.topic--miniSpecialDetailClanku {
  padding: var(--spacing-16);
}

.topic--miniSpecialDetailClanku .topic__top {
  padding-bottom: 0;
}

.topic--theme-dark {
  background-color: var(--color-surface-neutral-strong);
}

.topic--theme-dark .topic__name {
  color: var(--color-text-default-contrast);
}

.topic--theme-dark .topic__bottom-articles:after, .topic--theme-dark .topic__unimenu:after {
  background-color: var(--color-border-divider-default);
}

.topic--theme-dark .topic__custom-article, .topic--theme-dark .topic__custom-article a {
  color: var(--color-text-default-contrast);
}

.topic--theme-dark .topic__custom-article a.category__link {
  color: var(--primary-main);
}

.topic--theme-dark .topic__custom-article-body .uncover-wrapper:before {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--surface-neutral-darker), var(--surface-neutral-darker) 140%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
}

.tabs {
  scroll-margin-top: 68px;
}

.tabs--hidden {
  display: none;
}

.tabs__item {
  cursor: pointer;
  appearance: none;
  gap: var(--spacing-8);
  background: none;
  border: 0;
  outline: none;
  justify-content: center;
  align-items: center;
  height: 44px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
  position: relative;
}

.tabs__item:after {
  content: "";
  background: var(--themes-gradient-01-front);
  height: 2px;
  transition: left .2s ease-out, right .2s ease-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
}

.tabs__item--hidden {
  display: none;
}

.tabs__item:not(.tabs__item--active):hover {
  color: var(--themes-gradient-01-front);
}

.tabs__item:not(.tabs__item--active):hover .tabs__item-icon svg * {
  fill: var(--themes-gradient-01-front);
}

.tabs__item:focus {
  outline: none;
}

.tabs__item span:hover {
  text-decoration: none;
}

.tabs__item-title {
  color: inherit;
  transition: color .2s;
}

.tabs__item-icon {
  width: auto;
  height: 1em;
}

.tabs__item-icon svg {
  width: inherit;
  height: inherit;
}

.tabs__item-icon svg * {
  transition: fill .2s;
}

.tabs__item--active {
  cursor: default;
}

.tabs__item--active:after {
  background: var(--themes-gradient-01-front);
  left: 0;
  right: 0;
}

.tabs__item--light {
  color: var(--color-text-tab-normal-title);
}

.tabs__item--light .tabs__item-icon path {
  fill: var(--color-text-tab-normal-title);
}

.tabs__content {
  padding-top: var(--spacing-24);
  position: relative;
}

.tabs__panel {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  position: absolute;
  inset: 0;
}

.tabs__panel--align-center {
  max-width: 690px;
  margin: auto;
}

.tabs__panel--bg-light {
  background: var(--isport-iron-100);
  padding: var(--spacing-24);
}

.tabs__panel--active {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  position: relative;
}

.tabs__item--dark {
  color: var(--color-text-tab-inverted-title);
}

.tabs__item--dark .tabs__item-icon path {
  fill: var(--color-text-tab-inverted-title);
}

.accordion {
  flex-flow: column;
  display: flex;
}

.accordion__item {
  border-bottom: 1px solid var(--color-border-divider-default);
  list-style-type: none;
}

.accordion__question {
  padding: var(--spacing-16) var(--spacing-40) var(--spacing-16) 0;
  cursor: pointer;
  color: var(--color-text-accordion-title-default);
  position: relative;
}

.accordion__question * {
  font-style: normal;
  font-weight: 700;
  line-height: var(--typography-desktop-body-lg-line-height);
  letter-spacing: var(--typography-desktop-body-lg-letter-spacing, 0);
  color: inherit;
}

@media (max-width: 679px) {
  .accordion__question * {
    line-height: var(--typography-mobile-body-lg-line-height);
    letter-spacing: var(--typography-mobile-body-lg-letter-spacing);
  }
}

.accordion__question:hover {
  color: var(--color-text-accordion-title-hover);
}

.accordion__question:hover svg > path {
  fill: var(--color-text-accordion-title-hover);
}

.accordion__icon {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: all .4s;
  display: flex;
  position: absolute;
  top: 20px;
  right: 8px;
}

.item--opened .accordion__icon {
  transform-origin: center;
  transition: all .4s;
  transform: rotate(180deg);
}

.accordion__icon svg {
  width: 12px;
  height: 8px;
}

.accordion__answer {
  color: var(--color-text-accordion-body-default);
  font: var(--font-desktop-body2);
  display: none;
}

.item--opened .accordion__answer {
  padding-bottom: var(--spacing-16);
  display: block;
}

.accordion__answer, .accordion__answer * {
  color: var(--color-text-accordion-body-default);
  font-weight: 400;
}

.accordion__answer:last-child, .accordion__answer :last-child {
  margin-bottom: 0;
}

.accordion__answer p {
  margin-bottom: var(--spacing-16);
}

.accordion__answer ol, .accordion__answer ul {
  padding-left: var(--spacing-16);
  margin: 0 0 var(--spacing-16) var(--spacing-16);
}

.accordion__answer ol li, .accordion__answer ul li {
  margin-bottom: var(--spacing-8);
}

.accordion__answer ol {
  list-style-type: decimal;
}

.accordion__answer a:hover {
  text-decoration: none;
}

.accordion__answer h3, .accordion__answer h4 {
  font: var(--font-desktop-body2-bold);
  margin-bottom: var(--spacing-4);
}

@media (max-width: 679px) {
  .accordion__answer h3, .accordion__answer h4 {
    font: var(--font-mobile-body2-bold);
  }
}

.premium-card {
  border-radius: var(--spacing-premium-card-radius);
  padding: var(--spacing-48) var(--spacing-32);
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  max-width: 329px;
  display: flex;
  position: relative;
}

.premium-card .badge-pill {
  border-radius: 6px;
}

@media (max-width: 679px) {
  .premium-card {
    padding: var(--spacing-32) var(--spacing-24);
  }
}

.premium-card--normal {
  border: 2px solid var(--color-border-premium-card-normal);
}

@media (max-width: 679px) {
  .premium-card--best {
    order: -1;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .premium-card {
    height: 499px;
  }
}

.premium-card__badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.premium-card__header {
  text-align: center;
  margin-bottom: var(--spacing-32);
  flex-shrink: 0;
}

.premium-card__content {
  flex-grow: 1;
  flex-shrink: 0;
}

.premium-card__benefits {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-12);
}

.premium-card__benefits-divider, .premium-card__benefits-title {
  margin-bottom: var(--spacing-8);
}

.premium-card__footer {
  justify-content: center;
  align-items: flex-end;
  margin-top: auto;
  display: flex;
}

.premium-card__cta {
  min-width: 204px;
}

.premium-card--normal.premium-card--normal {
  background: var(--color-surface-premium-card-normal-normal);
}

.premium-card--normal.premium-card--normal .premium-card__title, .premium-card--normal.premium-card--normal .premium-card__price, .premium-card--normal.premium-card--normal .premium-card__interval {
  color: var(--color-text-premium-card-normal-title);
}

.premium-card--normal.premium-card--normal .premium-card__benefits-title {
  color: var(--color-text-premium-card-normal-body);
}

.premium-card--normal.premium-card--normal .premium-card__price-discount {
  color: var(--color-text-premium-card-normal-subtitle);
  text-decoration: line-through;
}

.premium-card--normal.premium-card--normal .premium-card__price-info {
  color: var(--color-text-premium-card-normal-subtitle);
}

.premium-card--normal.premium-card--best {
  background: var(--color-surface-premium-card-normal-best);
}

.premium-card--normal.premium-card--best .premium-card__title, .premium-card--normal.premium-card--best .premium-card__price, .premium-card--normal.premium-card--best .premium-card__interval {
  color: var(--color-text-premium-card-normal-title);
}

.premium-card--normal.premium-card--best .premium-card__benefits-title {
  color: var(--color-text-premium-card-normal-body);
}

.premium-card--normal.premium-card--best .premium-card__price-discount {
  color: var(--color-text-premium-card-normal-subtitle);
  text-decoration: line-through;
}

.premium-card--normal.premium-card--best .premium-card__price-info {
  color: var(--color-text-premium-card-normal-subtitle);
}

.premium-card--normal.premium-card--vip {
  background: var(--color-surface-premium-card-normal-VIP);
}

.premium-card--normal.premium-card--vip .premium-card__title, .premium-card--normal.premium-card--vip .premium-card__price, .premium-card--normal.premium-card--vip .premium-card__interval {
  color: var(--color-text-premium-card-normal-title);
}

.premium-card--normal.premium-card--vip .premium-card__benefits-title {
  color: var(--color-text-premium-card-normal-body);
}

.premium-card--normal.premium-card--vip .premium-card__price-discount {
  color: var(--color-text-premium-card-normal-subtitle);
  text-decoration: line-through;
}

.premium-card--normal.premium-card--vip .premium-card__price-info {
  color: var(--color-text-premium-card-normal-subtitle);
}

.premium-card--inverted.premium-card--normal {
  background: var(--color-surface-premium-card-inverted-normal);
}

.premium-card--inverted.premium-card--normal .premium-card__title, .premium-card--inverted.premium-card--normal .premium-card__price, .premium-card--inverted.premium-card--normal .premium-card__interval {
  color: var(--color-text-premium-card-inverted-title);
}

.premium-card--inverted.premium-card--normal .premium-card__benefits-title {
  color: var(--color-text-premium-card-inverted-body);
}

.premium-card--inverted.premium-card--normal .premium-card__price-discount {
  color: var(--color-text-premium-card-inverted-subtitle);
  text-decoration: line-through;
}

.premium-card--inverted.premium-card--normal .premium-card__price-info {
  color: var(--color-text-premium-card-inverted-subtitle);
}

.premium-card--inverted.premium-card--best {
  background: var(--color-surface-premium-card-inverted-best);
}

.premium-card--inverted.premium-card--best .premium-card__title, .premium-card--inverted.premium-card--best .premium-card__price, .premium-card--inverted.premium-card--best .premium-card__interval {
  color: var(--color-text-premium-card-inverted-title);
}

.premium-card--inverted.premium-card--best .premium-card__benefits-title {
  color: var(--color-text-premium-card-inverted-body);
}

.premium-card--inverted.premium-card--best .premium-card__price-discount {
  color: var(--color-text-premium-card-inverted-subtitle);
  text-decoration: line-through;
}

.premium-card--inverted.premium-card--best .premium-card__price-info {
  color: var(--color-text-premium-card-inverted-subtitle);
}

.premium-card--inverted.premium-card--vip {
  background: var(--color-surface-premium-card-inverted-VIP);
}

.premium-card--inverted.premium-card--vip .premium-card__title, .premium-card--inverted.premium-card--vip .premium-card__price, .premium-card--inverted.premium-card--vip .premium-card__interval {
  color: var(--color-text-premium-card-inverted-title);
}

.premium-card--inverted.premium-card--vip .premium-card__benefits-title {
  color: var(--color-text-premium-card-inverted-body);
}

.premium-card--inverted.premium-card--vip .premium-card__price-discount {
  color: var(--color-text-premium-card-inverted-subtitle);
  text-decoration: line-through;
}

.premium-card--inverted.premium-card--vip .premium-card__price-info {
  color: var(--color-text-premium-card-inverted-subtitle);
}

.search {
  --default-icon: transparent url("/dist-isport/assets/default/icon-magnifier-gray.svg") center center no-repeat;
  --light-icon: transparent url("/dist-isport/assets/default/icons/icon-magnifier-gray-light.svg") center center no-repeat;
  grid-area: search;
  grid-template-columns: 1fr;
  grid-template-areas: "form";
  align-items: center;
  gap: 0 56px;
  display: grid;
}

@media (max-width: 679px) {
  .search {
    z-index: 1;
    padding: var(--spacing-16);
    position: sticky;
    top: 0;
  }
}

.search__form {
  margin: 0;
  margin-top: var(--spacing-24);
  flex: 1;
  grid-area: form;
  align-items: center;
  display: flex;
  position: relative;
}

.search .remove-hint {
  cursor: pointer;
  margin-right: var(--spacing-8);
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  right: 40px;
  display: none !important;
}

.search .remove-hint > svg {
  height: 16px;
}

.search .remove-hint > svg > path {
  fill: #b8b8b8;
}

.search__input {
  padding: var(--spacing-12) var(--spacing-48) var(--spacing-12) var(--spacing-24);
  background: var(--color-surface-search-field-normal-default);
  font: var(--font-desktop-body1);
  border: 1px solid var(--color-border-search-field-normal-default);
  box-sizing: border-box;
  border-radius: 4px;
  outline-width: 1px;
  outline-color: #0000;
  width: 100%;
  transition: background-color .2s, border-color .2s, color .2s;
}

.search__input::placeholder {
  transition: all .2s;
}

.search__input:hover {
  color: var(--color-text-search-field-default-hover);
  background: var(--color-surface-search-field-normal-hover);
  border: 1px solid var(--color-border-search-field-normal-hover);
}

.search__input:focus {
  border: 1px solid var(--color-border-search-field-normal-focus);
  outline-width: 0;
}

.search__input:not(:placeholder-shown) {
  border: 1px solid var(--color-border-search-field-normal-filled);
  background: var(--color-surface-search-field-normal-filed);
}

.search__submit {
  right: var(--spacing-16);
  background: var(--default-icon);
  text-indent: -999px;
  cursor: pointer;
  background-position: 100%;
  border: 0;
  border-left: 1px solid #0000;
  width: 28px;
  height: 20px;
  position: absolute;
}

@media (max-width: 679px) {
  .search__submit {
    right: var(--spacing-32);
  }
}

.search--inverted .search__submit {
  background: var(--light-icon);
}

.search--inverted .search__input {
  border: 1px solid var(--color-border-search-field-inverted-default);
  background: var(--color-surface-search-field-inverted-default);
  color: var(--color-text-search-field-inverted-default);
}

.search--inverted .search__input:hover {
  color: var(--color-text-search-field-inverted-hover);
  border: 1px solid var(--color-border-search-field-inverted-hover);
  background: var(--color-surface-search-field-inverted-hover);
}

.search--inverted .search__input:hover::placeholder {
  color: var(--color-text-search-field-inverted-hover);
}

.search--inverted .search__input:focus {
  color: var(--color-text-search-field-inverted-focus);
  border: 1px solid var(--color-border-search-field-inverted-focus);
  background: var(--color-surface-search-field-inverted-focus);
  outline-width: 0;
}

.search--inverted .search__input:focus::placeholder {
  color: var(--color-text-search-field-inverted-focus);
}

.search--inverted .search__input:focus ~ .search__submit {
  background: var(--default-icon);
}

.search--inverted .search__input:not(:placeholder-shown) {
  color: var(--color-text-search-field-inverted-filled);
  border: 1px solid var(--color-border-search-field-inverted-filled);
  background: var(--color-surface-search-field-inverted-filled);
}

.search--inverted .search__input:not(:placeholder-shown) ~ .search__submit {
  background: var(--default-icon);
}

.stepper {
  padding-bottom: var(--spacing-44);
  align-items: center;
  display: flex;
}

.stepper__item {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.stepper__item:last-child {
  flex: 0;
}

.stepper__content {
  z-index: 1;
  position: relative;
}

.stepper__circle {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: flex;
}

@media (max-width: 679px) {
  .stepper__circle {
    width: 32px;
    height: 32px;
  }
}

.stepper__circle svg {
  width: 24px;
  height: auto;
}

@media (max-width: 679px) {
  .stepper__circle svg {
    width: 16px;
  }
}

.stepper__circle path {
  fill: var(--color-icon-stepper-finished);
}

.stepper__dot {
  background: var(--gradient-2);
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

@media (max-width: 679px) {
  .stepper__dot {
    width: 14px;
    height: 14px;
  }
}

.stepper__number {
  color: var(--color-text-stepper-inactive);
  font: var(--typography-desktop-button-lg-font-size) / var(--typography-desktop-button-lg-line-height) var(--button-lg-font-family);
}

@media (max-width: 679px) {
  .stepper__number {
    font: var(--typography-mobile-button-md-font-size) / var(--typography-mobile-button-md-line-height) var(--button-md-font-family);
  }
}

.stepper__label {
  color: var(--color-text-stepper-label);
  white-space: nowrap;
  text-align: center;
  font: 700 var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 679px) {
  .stepper__label {
    font: 700 var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-font-family);
  }
}

.stepper__connector {
  background-color: var(--color-border-stepper-inactive);
  flex: 1;
  height: 2px;
  margin: 0;
}

.stepper__item--completed .stepper__circle {
  background: var(--gradient-2);
  border: none;
}

.stepper__item--completed .stepper__connector {
  background: linear-gradient(-90deg, var(--themes-gradient-02-front) 0%, var(--themes-gradient-02-back) 100%);
}

.stepper__item--active .stepper__circle {
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, var(--themes-gradient-02-front) 0%, var(--themes-gradient-02-back) 100%) border-box;
  border: 2px solid #0000;
}

.stepper__item--active .stepper__label {
  font: 700 var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
}

@media (max-width: 679px) {
  .stepper__item--active .stepper__label {
    font: 700 var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-bold-font-family);
  }
}

.stepper__item--upcoming .stepper__circle {
  border: 2px solid var(--color-border-stepper-inactive);
  background-color: #fff;
}

.stepper--activation {
  margin-bottom: var(--spacing-32);
  width: 270px;
}

@media (max-width: 679px) {
  .stepper--activation {
    width: 200px;
  }
}

.video-card {
  gap: var(--spacing-12);
  flex-direction: column;
  width: 240px;
  display: flex;
}

@media (max-width: 679px) {
  .video-card {
    width: 210px;
  }
}

.video-card__content {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.video-card__title {
  text-decoration: none;
}

.video-card__title:hover .video-card__title-text {
  text-decoration: none;
  transition: color .2s;
}

.video-card__title:hover .video-card__title-text--solid {
  color: var(--color-text-article-title-hover);
}

.video-card__title:hover .video-card__title-text--gradient {
  color: #0000;
  background: var(--gradient-3);
  -webkit-background-clip: text;
  background-clip: text;
}

.video-card--theme-dark .video-card__title {
  color: var(--color-text-video-card-dark-title);
}

.video-card--theme-light .video-card__title {
  color: var(--color-text-video-card-light-title);
}

.video-strip {
  padding: var(--spacing-16) 0 var(--spacing-16) var(--spacing-16);
  overflow: hidden;
}

.video-strip--theme-dark {
  background-color: var(--color-surface-video-strip-inverted-background);
}

.video-strip--theme-light {
  background-color: var(--color-surface-video-strip-normal-background);
}

[data-tooltip]:not(.vp-controls [data-tooltip]) {
  cursor: help;
  position: relative;
}

[data-tooltip]:not(.vp-controls [data-tooltip]):before, [data-tooltip]:not(.vp-controls [data-tooltip]):after {
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
  position: absolute;
}

[data-tooltip]:not(.vp-controls [data-tooltip]):before {
  content: attr(data-tooltip);
  background: var(--color-surface-tooltip-background);
  color: #fff;
  border-radius: var(--spacing-tooltip-radius);
  font: var(--typography-desktop-helper-font-size) / var(--typography-desktop-helper-line-height) var(--helper-font-family);
  z-index: 20;
  text-align: left;
  min-width: 150px;
  padding: .5rem 1rem;
}

@media (max-width: 679px) {
  [data-tooltip]:not(.vp-controls [data-tooltip]):before {
    font: var(--typography-mobile-helper-font-size) / var(--typography-mobile-helper-line-height) var(--helper-font-family);
  }
}

[data-tooltip]:not(.vp-controls [data-tooltip]):after {
  content: "";
  z-index: 2;
  border: 4px solid #0000;
}

[data-tooltip]:not(.vp-controls [data-tooltip]):not([data-position]):before {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%)translateY(-8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip]):not([data-position]):after {
  border-top-color: var(--color-surface-tooltip-background);
  bottom: calc(100% - 4px);
  left: 50%;
  transform: translateX(-50%)translateY(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip]):not([data-position]):hover:before, [data-tooltip]:not(.vp-controls [data-tooltip]):not([data-position]):hover:after {
  opacity: 1;
  transform: translateX(-50%)translateY(-8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="left"]:before {
  bottom: 100%;
  left: -10px;
  transform: translateY(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="left"]:after {
  border-top-color: var(--color-surface-tooltip-background);
  bottom: calc(100% - 8px);
  left: 0;
  transform: translateY(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="left"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="left"]:hover:after {
  opacity: 1;
  transform: translateY(-8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="center"]:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"]:not([data-arrow]):before {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%)translateY(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="center"]:after, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"]:not([data-arrow]):after {
  border-top-color: var(--color-surface-tooltip-background);
  bottom: calc(100% - 8px);
  left: 50%;
  transform: translateX(-50%)translateY(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="center"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="center"]:hover:after, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"]:not([data-arrow]):hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"]:not([data-arrow]):hover:after {
  opacity: 1;
  transform: translateX(-50%)translateY(-8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="right"]:before {
  bottom: 100%;
  right: -10px;
  transform: translateY(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="right"]:after {
  border-top-color: var(--color-surface-tooltip-background);
  bottom: calc(100% - 8px);
  right: 0;
  transform: translateY(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="right"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="top"][data-arrow="right"]:hover:after {
  opacity: 1;
  transform: translateY(-8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="left"]:before {
  top: 100%;
  left: -10px;
  transform: translateY(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="left"]:after {
  border-bottom-color: var(--color-surface-tooltip-background);
  top: calc(100% - 8px);
  left: 0;
  transform: translateY(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="left"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="left"]:hover:after {
  opacity: 1;
  transform: translateY(8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="center"]:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"]:not([data-arrow]):before {
  top: 100%;
  left: 50%;
  transform: translateX(-50%)translateY(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="center"]:after, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"]:not([data-arrow]):after {
  border-bottom-color: var(--color-surface-tooltip-background);
  top: calc(100% - 8px);
  left: 50%;
  transform: translateX(-50%)translateY(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="center"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="center"]:hover:after, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"]:not([data-arrow]):hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"]:not([data-arrow]):hover:after {
  opacity: 1;
  transform: translateX(-50%)translateY(8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="right"]:before {
  top: 100%;
  right: -10px;
  transform: translateY(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="right"]:after {
  border-bottom-color: var(--color-surface-tooltip-background);
  top: calc(100% - 8px);
  right: 0;
  transform: translateY(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="right"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="bottom"][data-arrow="right"]:hover:after {
  opacity: 1;
  transform: translateY(8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="left"]:before {
  top: 50%;
  right: 100%;
  transform: translateY(-50%)translateX(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="left"]:after {
  border-left-color: var(--color-surface-tooltip-background);
  top: 50%;
  right: calc(100% - 8px);
  transform: translateY(-50%)translateX(-4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="left"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="left"]:hover:after {
  opacity: 1;
  transform: translateY(-50%)translateX(-8px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="right"]:before {
  top: 50%;
  left: 100%;
  transform: translateY(-50%)translateX(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="right"]:after {
  border-right-color: var(--color-surface-tooltip-background);
  top: 50%;
  left: calc(100% - 8px);
  transform: translateY(-50%)translateX(4px);
}

[data-tooltip]:not(.vp-controls [data-tooltip])[data-position="right"]:hover:before, [data-tooltip]:not(.vp-controls [data-tooltip])[data-position="right"]:hover:after {
  opacity: 1;
  transform: translateY(-50%)translateX(8px);
}

.wysiwyg {
  color: var(--color-text-default-body);
  font: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
}

@media (max-width: 679px) {
  .wysiwyg {
    font: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-font-family);
  }
}

.wysiwyg h1:not([class]), .wysiwyg h2:not([class]), .wysiwyg h3:not([class]), .wysiwyg h4:not([class]), .wysiwyg h5:not([class]), .wysiwyg h6:not([class]) {
  color: var(--color-text-default-subtitle);
  font: var(--typography-desktop-inter-title-font-size) / var(--typography-desktop-inter-title-line-height) var(--inter-title-font-family);
}

@media (max-width: 679px) {
  .wysiwyg h1:not([class]), .wysiwyg h2:not([class]), .wysiwyg h3:not([class]), .wysiwyg h4:not([class]), .wysiwyg h5:not([class]), .wysiwyg h6:not([class]) {
    font: var(--typography-mobile-inter-title-font-size) / var(--typography-mobile-inter-title-line-height) var(--inter-title-font-family);
  }
}

.wysiwyg p:not([class]), .wysiwyg span:not([class]), .wysiwyg i:not([class]), .wysiwyg em:not([class]) {
  font: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
}

@media (max-width: 679px) {
  .wysiwyg p:not([class]), .wysiwyg span:not([class]), .wysiwyg i:not([class]), .wysiwyg em:not([class]) {
    font: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-font-family);
  }
}

.wysiwyg strong:not([class]), .wysiwyg b:not([class]) {
  font: 700 var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
}

@media (max-width: 679px) {
  .wysiwyg strong:not([class]), .wysiwyg b:not([class]) {
    font: 700 var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-bold-font-family);
  }
}

.wysiwyg a:not([class]) {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
  text-underline-offset: .2em;
  text-decoration: underline;
  transition: color .2s;
}

.wysiwyg a:not([class]):hover {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-hover);
  text-decoration: none;
}

.wysiwyg a[target="_blank"]:not([class]) {
  padding-right: 1.1em;
  position: relative;
}

.wysiwyg a[target="_blank"]:not([class]):after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z' fill='%23FD4506'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  width: .8em;
  height: .8em;
  transition: background-image .2s;
  display: inline-block;
}

.wysiwyg a[target="_blank"]:not([class]):hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z' fill='%23EE3308'/%3E%3C/svg%3E");
}

.wysiwyg ol:not([class]), .wysiwyg ul:not([class]) {
  color: var(--color-text-list-default);
  margin: var(--spacing-32) 0;
  padding: 0;
  list-style: none;
}

.wysiwyg ol:not([class]) li, .wysiwyg ul:not([class]) li {
  padding: 0 0 var(--spacing-16) var(--spacing-16);
  list-style: none;
  position: relative;
}

.wysiwyg ol:not([class]) li:before, .wysiwyg ul:not([class]) li:before {
  color: var(--color-text-list-default);
  position: absolute;
  left: 0;
}

.wysiwyg ol:not([class]) li:last-child, .wysiwyg ul:not([class]) li:last-child {
  padding-bottom: 0;
}

.wysiwyg ol:not([class]) {
  counter-reset: item;
}

.wysiwyg ol:not([class]) li {
  counter-increment: item;
  list-style: none;
}

.wysiwyg ol:not([class]) li:before {
  content: counter(item) " ";
}

.wysiwyg ul:not([class]) li:before {
  content: "•";
}

.wysiwyg .table-wrapper {
  margin-bottom: var(--spacing-20);
  padding-bottom: var(--spacing-12);
  max-width: 100%;
  overflow-x: auto;
}

.wysiwyg .table-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.wysiwyg .table-wrapper::-webkit-scrollbar:horizontal {
  border-radius: var(--spacing-8);
  background-color: var(--action-disabled-background);
  height: 4px;
}

.wysiwyg .table-wrapper::-webkit-scrollbar-thumb {
  border-radius: var(--spacing-8);
  background-color: var(--action-disabled);
  cursor: pointer;
  height: 4px;
}

.wysiwyg table:not([class]) {
  table-layout: fixed;
  border-collapse: separate;
  max-width: 100%;
  transition: all .2s ease-in;
  overflow: hidden;
}

.wysiwyg th:not([class]), .wysiwyg td:not([class]) {
  vertical-align: middle;
  color: var(--text-primary);
}

.wysiwyg thead {
  text-align: left;
}

.wysiwyg thead th:not([class]), .wysiwyg thead td:not([class]) {
  color: var(--color-text-table-header-cells-heading);
  padding: var(--spacing-12) var(--spacing-8);
  font: 700 var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
  background-color: var(--color-surface-table-header-cell-default);
}

@media (max-width: 679px) {
  .wysiwyg thead th:not([class]), .wysiwyg thead td:not([class]) {
    font: 700 var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-bold-font-family);
  }
}

.wysiwyg tbody th:not([class]), .wysiwyg tbody td:not([class]) {
  padding: var(--spacing-12) var(--spacing-8);
  border-bottom: 1px solid var(--other-divider);
  font: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
}

@media (max-width: 679px) {
  .wysiwyg tbody th:not([class]), .wysiwyg tbody td:not([class]) {
    font: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-font-family);
  }
}

.wysiwyg tbody th:not([class]) {
  background: var(--color-surface-table-subheader-cell-default);
}

.wysiwyg tbody td:not([class]) {
  background: var(--secondary-contrast);
}

.wysiwyg tbody * {
  font: inherit;
}

.wysiwyg tbody strong {
  font: 700 var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
}

@media (max-width: 679px) {
  .wysiwyg tbody strong {
    font: 700 var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-bold-font-family);
  }
}

.wysiwyg img:not([class]) {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.status-box {
  padding: 0 var(--spacing-24);
  flex-direction: column;
  display: flex;
}

@media (max-width: 679px) {
  .status-box {
    padding: 0;
  }
}

.status-box__title {
  margin-bottom: var(--spacing-8);
}

.status-box__content {
  margin-bottom: var(--mobile-size);
}

@media (max-width: 679px) {
  .status-box__content {
    flex-direction: column;
    display: flex;
  }
}

.status-box__body-text {
  margin-bottom: var(--spacing-24);
}

.status-box__body-text .text-link {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-default);
}

.status-box__body-text .text-link:hover {
  text-decoration: underline;
}

.status-box__image {
  float: right;
  width: 380px;
  max-width: 50%;
  height: auto;
}

@media (max-width: 679px) {
  .status-box__image {
    float: none;
    margin: auto;
    margin-bottom: var(--spacing-24);
    order: 1;
    min-width: 240px;
    display: flex;
  }
}

.status-box__signature {
  margin-bottom: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

@media (max-width: 679px) {
  .status-box__signature {
    order: 1;
  }
}

@media (max-width: 679px) {
  .status-box__cta {
    margin: auto;
  }
}

.spinner {
  width: var(--spacing-20);
  aspect-ratio: 1;
  background-image: url("data:image/svg+xml,<svg width=\"56\" height=\"56\" viewBox=\"0 0 56 56\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M36.639 5.60901C39.5795 6.74346 42.2677 8.44598 44.5502 10.6193C46.8327 12.7927 48.6648 15.3944 49.9419 18.2758C51.2189 21.1572 51.9159 24.2619 51.9931 27.4127C52.0703 30.5635 51.5261 33.6986 50.3916 36.6391C49.2572 39.5796 47.5547 42.2678 45.3813 44.5503C43.2079 46.8328 40.6063 48.6649 37.7249 49.9419C34.8435 51.219 31.7387 51.916 28.5879 51.9932C25.4372 52.0703 22.302 51.5262 19.3616 50.3917C16.4211 49.2573 13.7329 47.5547 11.4503 45.3814C9.16784 43.208 7.33576 40.6063 6.05871 37.7249C4.78166 34.8435 4.08465 31.7388 4.00748 28.588C3.93031 25.4372 4.47448 22.3021 5.60894 19.3616C6.74339 16.4212 8.4459 13.7329 10.6193 11.4504C12.7926 9.16792 15.3943 7.33584 18.2757 6.05879C21.1571 4.78174 24.2618 4.08473 27.4126 4.00756C30.5634 3.93038 33.6985 4.47456 36.639 5.60901L36.639 5.60901Z\" stroke=\"%23D9FFEC\" stroke-width=\"8\"/><path d=\"M36.639 5.60901C39.5795 6.74346 42.2677 8.44598 44.5502 10.6193C46.8327 12.7927 48.6648 15.3944 49.9419 18.2758C51.2189 21.1572 51.9159 24.2619 51.9931 27.4127C52.0703 30.5635 51.5261 33.6986 50.3916 36.6391C49.2572 39.5796 47.5547 42.2678 45.3813 44.5503C43.2079 46.8328 40.6063 48.6649 37.7249 49.9419C34.8435 51.219 31.7387 51.916 28.5879 51.9932C25.4372 52.0703 22.302 51.5262 19.3616 50.3917\" stroke=\"%2315D279\" stroke-width=\"8\" stroke-linecap=\"round\"/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ad-branding-wrapper {
  z-index: -1;
  position: absolute;
  top: 55px;
}

.ad-bottom {
  grid-column: 1 / -1;
  grid-template-columns: auto minmax(0, 1080px) auto;
}

.ad-category--bottom {
  grid-column: 1 / -1;
}

.ad-category--left-column {
  grid-column: 1 / 3;
}

.ad-position--desktop, .ad-position--tablet, .ad-position--mobile {
  display: none;
}

@media (max-width: 679px) {
  .ad-position--mobile {
    display: block;
  }

  .ad-category--left-column {
    grid-column: 1 / -1;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .ad-position--tablet {
    display: block;
  }

  .ad-category--left-column {
    grid-column: 1 / -1;
  }
}

.ad-list-322, .ad-list-353 {
  grid-column: 1 / -1;
}

.ad-list-325 {
  grid-column: 1 / -1;
}

:root {
  --ad-top: calc(64px + 15px + 20px + 5px);
}

.editorial-promo {
  position: relative;
}

.editorial-promo__agree, .editorial-promo__noads {
  position: absolute;
}

.editorial-promo--position-311 {
  width: 328px;
  height: 600px;
}

.editorial-promo--position-311 .editorial-promo__agree, .editorial-promo--position-311 .editorial-promo__noads {
  width: 196px;
  left: 66px;
}

.editorial-promo--position-311 .editorial-promo__agree {
  bottom: 86px;
}

.editorial-promo--position-311 .editorial-promo__noads {
  bottom: 34px;
}

.editorial-promo--position-322-desktop {
  width: 680px;
  height: 300px;
}

.editorial-promo--position-322-desktop .editorial-promo__agree, .editorial-promo--position-322-desktop .editorial-promo__noads {
  width: 165px;
  left: 100px;
}

.editorial-promo--position-322-desktop .editorial-promo__agree {
  bottom: 86px;
}

.editorial-promo--position-322-desktop .editorial-promo__noads {
  bottom: 42px;
}

.editorial-promo--position-322-mobile {
  width: 330px;
  height: 300px;
}

.editorial-promo--position-322-mobile .editorial-promo__agree, .editorial-promo--position-322-mobile .editorial-promo__noads {
  width: 145px;
  left: 21px;
}

.editorial-promo--position-322-mobile .editorial-promo__agree {
  bottom: 105px;
}

.editorial-promo--position-322-mobile .editorial-promo__noads {
  bottom: 61px;
}

.editorial-promo__noads {
  color: var(--primary-contrast);
  border-color: var(--primary-contrast);
  background: none;
}

.editorial-promo__noads:hover {
  color: var(--primary-contrast);
  border-color: var(--primary-contrast);
  background: #fafafa33;
}

.editorial-promo--magazine-e15:not(.editorial-promo--position-311) .editorial-promo__noads {
  color: #264961;
  background: none;
  border-color: #264961;
}

.editorial-promo--magazine-e15:not(.editorial-promo--position-311) .editorial-promo__noads:hover {
  color: #264961;
  background: #26496133;
  border-color: #264961;
}

.editorial-promo--magazine-zeny.editorial-promo--position-311 .editorial-promo__agree {
  bottom: 66px;
}

.editorial-promo--magazine-zeny.editorial-promo--position-322-mobile .editorial-promo__agree {
  bottom: 74px;
  left: 23px;
}

.editorial-promo--magazine-zeny.editorial-promo--position-322-desktop .editorial-promo__agree {
  bottom: 76px;
}

.miniplayer {
  display: none;
}

.modal {
  z-index: 10;
  background-color: #191919b3;
  width: 100%;
  height: 100%;
  padding-top: 140px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal__wrapper {
  background-color: var(--background-default);
  padding: var(--spacing-24);
  border: 1px solid var(--other-outline-border);
  gap: var(--spacing-24);
  color: var(--text-primary);
  border-radius: 12px;
  flex-direction: column;
  width: 688px;
  margin: auto;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .modal__wrapper {
    padding: var(--spacing-16);
    width: calc(100% - var(--spacing-32));
    gap: var(--spacing-16);
  }

  .modal__wrapper .icon-button > svg {
    min-width: 20px;
  }
}

.modal__header {
  border-bottom: 1px solid var(--other-divider);
  padding-bottom: var(--spacing-16);
  width: 100%;
  margin: auto;
}

.modal__close {
  top: var(--spacing-24);
  right: var(--spacing-24);
  position: absolute;
}

@media (max-width: 679px) {
  .modal__close {
    top: var(--spacing-16);
    right: var(--spacing-16);
  }
}

.modal__body {
  width: 100%;
  margin: auto;
  position: relative;
}

.modal__footer {
  gap: var(--spacing-24);
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  display: flex;
}

.modal__footer:empty {
  display: none;
}

@media (max-width: 679px) {
  .modal__footer {
    justify-content: initial;
    align-items: center;
    gap: var(--spacing-16);
  }

  .modal__footer a, .modal__footer button {
    width: 100%;
  }
}

.modal--video .modal__wrapper {
  background-color: var(--color-surface-videoplayer-background);
  border: none;
  border-radius: 0;
  width: 1092px;
  max-width: 100%;
  padding-top: 0;
}

@media screen and (orientation: landscape) and (max-height: 768px) {
  .modal--video .modal__wrapper {
    width: 100vh;
  }
}

.modal--video .modal__header {
  padding: var(--spacing-8) 0;
  border-bottom-color: var(--color-border-divider-default);
}

.modal--video .modal__close {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  top: var(--spacing-8);
  right: var(--spacing-24);
  cursor: pointer;
  display: flex;
}

@media screen and (max-width: 679px) {
  .modal--video .modal__close {
    right: var(--spacing-16);
  }
}

.modal--video .modal__close-text {
  color: var(--color-text-videoplayer-body);
}

.modal--video .modal__close-icon {
  width: 24px;
  height: 24px;
}

.modal--video .modal__close-icon svg {
  width: 100%;
  height: auto;
}

.modal--video .modal__close-icon path {
  fill: var(--color-text-videoplayer-body);
}

.box__headline {
  color: var(--text-primary);
  font: var(--font-desktop-section-title);
  letter-spacing: .03em;
  text-transform: uppercase;
  background: none;
  padding: 0;
}

.box__headline a:after {
  display: none;
}

.wiki-search__title {
  margin-bottom: var(--spacing-32);
  display: block;
}

.wiki-search__description {
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-32);
}

.wiki-search__description > strong {
  margin-left: var(--spacing-12);
  font-weight: 700;
}

.wiki-search__paging {
  margin-top: var(--spacing-32);
  justify-content: space-between;
  display: flex;
}

.wiki-search > .search {
  padding: 0 !important;
}

.wiki-search .remove-hint {
  display: none !important;
}

.wiki-search .search__input:not(:placeholder-shown) + .remove-hint + .search__submit {
  border-left: none;
}

@media (max-width: 679px) {
  .wiki-search {
    grid-column: lend / rend;
  }

  .wiki-search__title, .wiki-search__description {
    margin-bottom: 0;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .wiki-search {
    grid-column: lend / rend;
  }
}

.author-card-group__title {
  padding-top: var(--spacing-32);
  padding-bottom: var(--spacing-24);
}

@media (max-width: 679px) {
  .author-card-group__title {
    padding-top: var(--spacing-24);
    padding-bottom: var(--spacing-16);
  }
}

.author-card-list {
  gap: var(--spacing-16);
  width: 100%;
}

.author-card-list__bottom {
  margin-top: var(--spacing-24);
  justify-content: center;
  display: flex;
}

.category-info {
  grid-column: lend / rend;
}

.category-info picture, .category-info img {
  max-width: 100%;
}

.category-info__description html, .category-info__description body, .category-info__description .font-body {
  font: var(--font-mobile-body1);
  color: var(--text-primary);
}

.category-info__description h1, .category-info__description .heading-1 {
  font: var(--font-mobile-h1);
}

.category-info__description h2, .category-info__description .heading-2 {
  font: var(--font-mobile-h2);
}

.category-info__description h3, .category-info__description .heading-3 {
  font: var(--font-mobile-h3);
}

.category-info__description h4, .category-info__description .heading-4 {
  font: var(--font-mobile-h4);
}

.category-info__description h5, .category-info__description .heading-5 {
  font: var(--font-mobile-h5);
}

.category-info__description .button {
  text-decoration: none;
}

.category-info__description em, .category-info__description em strong {
  font-style: italic;
}

.category-info__description sub, .category-info__description sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.category-info__description sup {
  top: -.5em;
}

.category-info__description sub {
  bottom: -.25em;
}

.category-info__description ul {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: disc;
}

.category-info__description ul ul {
  margin: 0 0 0 var(--spacing-24);
  list-style-type: circle;
}

.category-info__description ol {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: decimal;
}

.category-info__description ol ol {
  margin: 0 0 0 var(--spacing-24);
}

.category-info__description hr {
  border: 0;
  border-top: 1px solid var(--other-divider);
}

.category-info__description blockquote:not(.tiktok-embed) {
  background-color: var(--other-divider);
  border-left: 5px solid var(--other-backdrop-overlay);
  padding: var(--spacing-20);
}

@media (max-width: 679px) {
  .category-info__description p {
    font: var(--font-mobile-body1);
  }

  .category-info__description strong {
    font: var(--font-mobile-body1-bold);
  }

  .category-info__description table thead {
    font: var(--font-mobile-body2-bold);
  }

  .category-info__description table tbody {
    font: var(--font-mobile-body2);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .category-info__description p {
    font: var(--font-desktop-body1);
  }

  .category-info__description strong {
    font: var(--font-desktop-body1-bold);
  }

  .category-info__description table thead {
    font: var(--font-desktop-body2-bold);
  }

  .category-info__description table tbody {
    font: var(--font-desktop-body2);
  }
}

@media (max-width: 679px) {
  .category-info {
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu {
    margin: var(--spacing-32) 0 var(--spacing-24);
    font: var(--font-desktop-body2);
    color: var(--text-primary);
    width: 100%;
    height: 44px;
    position: relative;
    overflow: visible;
  }

  .category-info__menu > ul {
    z-index: 2;
    padding: var(--spacing-12) var(--spacing-48) var(--spacing-12) var(--spacing-16);
    border: 1px solid var(--other-outline-border);
    background: var(--background-paper-contrast);
    border-radius: 4px;
    width: 100%;
    min-height: 44px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .category-info__menu > ul:before {
    content: "Menu";
    top: var(--spacing-12);
    left: var(--spacing-16);
    position: absolute;
  }

  .category-info__menu > ul:after {
    content: "";
    top: 18px;
    right: calc(var(--spacing-48) / 2 - 6px);
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxLjQxIDEwLjU5IDAgNiA0LjU4IDEuNDEgMCAwIDEuNDFsNiA2IDYtNloiIGZpbGw9IiMwMDAiLz48L3N2Zz4=") no-repeat;
    width: 12px;
    height: 8px;
    transition: transform .2s ease-in-out;
    display: block;
    position: absolute;
    transform: rotateX(0);
  }

  .category-info__menu > ul li {
    display: none;
  }

  .category-info__menu--opened > ul {
    border: 2px solid var(--primary-main);
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu--opened > ul:after {
    transition: transform .2s ease-in-out;
    transform: rotateX(180deg);
  }

  .category-info__menu--opened > ul li {
    background: var(--background-paper-contrast);
    flex: 1;
    display: block;
    position: relative;
  }

  .category-info__menu--opened > ul li:hover {
    background: var(--action-hover);
  }

  .category-info__menu--opened > ul li:hover > a {
    color: var(--primary-main);
  }

  .category-info__menu--opened > ul > li:first-child {
    margin-top: var(--spacing-32);
  }

  .category-info__menu-item--active {
    border-left: 2px solid var(--primary-main);
  }

  .category-info__menu-link {
    padding: var(--spacing-12) 0 var(--spacing-12) var(--spacing-16);
    color: var(--text-primary);
    width: 100%;
    text-decoration: none;
    display: block;
  }

  .category-info span.category-info__menu-link {
    display: none;
  }

  .category-info h1 {
    font: var(--font-mobile-h2);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .category-info {
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu {
    margin: var(--spacing-32) 0 var(--spacing-24);
    font: var(--font-desktop-body2);
    color: var(--text-primary);
    width: 100%;
    height: 44px;
    position: relative;
    overflow: visible;
  }

  .category-info__menu > ul {
    z-index: 2;
    padding: var(--spacing-12) var(--spacing-48) var(--spacing-12) var(--spacing-16);
    border: 1px solid var(--other-outline-border);
    background: var(--background-paper-contrast);
    border-radius: 4px;
    width: 100%;
    min-height: 44px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .category-info__menu > ul:before {
    content: "Menu";
    top: var(--spacing-12);
    left: var(--spacing-16);
    position: absolute;
  }

  .category-info__menu > ul:after {
    content: "";
    top: 18px;
    right: calc(var(--spacing-48) / 2 - 6px);
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxLjQxIDEwLjU5IDAgNiA0LjU4IDEuNDEgMCAwIDEuNDFsNiA2IDYtNloiIGZpbGw9IiMwMDAiLz48L3N2Zz4=") no-repeat;
    width: 12px;
    height: 8px;
    transition: transform .2s ease-in-out;
    display: block;
    position: absolute;
    transform: rotateX(0);
  }

  .category-info__menu > ul li {
    display: none;
  }

  .category-info__menu--opened > ul {
    border: 2px solid var(--primary-main);
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu--opened > ul:after {
    transition: transform .2s ease-in-out;
    transform: rotateX(180deg);
  }

  .category-info__menu--opened > ul li {
    background: var(--background-paper-contrast);
    flex: 1;
    display: block;
    position: relative;
  }

  .category-info__menu--opened > ul li:hover {
    background: var(--action-hover);
  }

  .category-info__menu--opened > ul li:hover > a {
    color: var(--primary-main);
  }

  .category-info__menu--opened > ul > li:first-child {
    margin-top: var(--spacing-32);
  }

  .category-info__menu-item--active {
    border-left: 2px solid var(--primary-main);
  }

  .category-info__menu-link {
    padding: var(--spacing-12) 0 var(--spacing-12) var(--spacing-16);
    color: var(--text-primary);
    width: 100%;
    text-decoration: none;
    display: block;
  }

  .category-info span.category-info__menu-link {
    display: none;
  }

  .category-info h1 {
    font: var(--font-mobile-h2);
  }
}

.category-menu {
  margin: calc(var(--spacing-12) * -1) calc(var(--spacing-24) * -1) 0;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .category-menu {
    margin: 0 calc(var(--spacing-16) * -1);
  }
}

@media (max-width: 679px) {
  .category-menu {
    margin: 0 calc(var(--spacing-16) * -1);
  }
}

.forum__comment-head {
  gap: var(--spacing-12);
  flex-flow: row;
  align-items: center;
  display: flex;
}

.forum__comment-head-row {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (max-width: 419.999px) {
  .forum__comment-head-row:has(.forum__comment-head-tip) {
    flex-flow: column;
    align-items: flex-start;
  }
}

.forum__comment-head-controls {
  gap: var(--spacing-8);
  display: flex;
}

@media (max-width: 419.999px) {
  .forum__comment-head-controls:has(.forum__comment-head-tip) {
    margin-bottom: var(--spacing-4);
    order: -1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.forum__comment-head-side {
  gap: var(--spacing-12);
  align-items: center;
  display: flex;
}

.forum__comment-head-side--right {
  flex-flow: column;
  align-items: flex-start;
  gap: 0;
  width: 100%;
}

.forum__comment-head-side--left {
  word-break: break-all;
  max-width: 55%;
}

.forum__comment-head-icon {
  z-index: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
}

.forum__comment-head-icon img, .forum__comment-head-icon svg {
  object-fit: cover;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.forum__comment-head-icon .forum__comment-head-badge {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: -2px;
  left: 28px;
}

.forum__comment-head-icon.author-editor-badge {
  width: 0;
  height: 16px;
  left: -22px;
}

.forum__comment-head-icon.author-editor-badge svg {
  width: 16px;
  height: 16px;
}

.forum__comment-head-icon-sm {
  z-index: 1;
  width: 24px;
  height: 24px;
}

.forum__comment-head-info {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  display: flex;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .forum__comment-head-info {
    gap: var(--spacing-12);
    flex-direction: row;
    align-items: center;
  }
}

.forum__comment-head-nick {
  color: var(--text-primary);
  font: var(--font-desktop-h4);
}

@media (max-width: 679px) {
  .forum__comment-head-nick {
    word-break: break-word;
    font: var(--font-mobile-h4);
  }
}

.forum__comment-head-date {
  color: var(--text-secondary);
  font: var(--font-desktop-body2);
  line-height: 1.4;
}

.forum__comment-head-tip {
  gap: var(--spacing-8);
  color: var(--text-secondary);
  font: var(--font-desktop-body2);
  align-items: center;
  display: inline-flex;
}

.forum__comment-head-tip--rated {
  color: var(--white);
  background-color: var(--primary-main);
}

.forum__comment-head-tip--rated:hover {
  color: var(--white);
}

@media (max-width: 679px) {
  .forum__comment-head-tip {
    font: var(--font-mobile-body2);
  }

  .forum__comment-head-tip .forum__comment-head-tip-text {
    display: none;
  }
}

.forum__comment-head-tip img {
  width: 51px;
  height: 16px;
  margin-top: -2px;
}

.forum__comment-head-likes-counter {
  color: var(--secondary-light);
  font: var(--font-desktop-body1);
  padding: 0;
}

@media (max-width: 679px) {
  .forum__comment-head-likes-counter {
    font: var(--font-mobile-body1);
  }
}

.forum__comment-head-likes-counter svg path {
  fill: var(--white);
}

.forum__comment-head-likes-counter--counted {
  color: var(--secondary-light);
}

.forum__comment-head-likes-counter--counted svg path {
  fill: var(--white);
  stroke: var(--color-border-brand-primary-main);
}

.forum__comment-head-likes-counter:hover {
  color: var(--color-border-brand-primary-main);
}

.forum__comment-head-likes-counter:hover svg path {
  fill: var(--white);
  stroke: var(--color-border-brand-primary-main);
}

.forum__comment-head-likes-counter > span {
  padding-top: var(--spacing-2);
  color: var(--color-text-default-subtitle);
}

.likes-counter {
  align-items: center;
  display: flex;
}

.likes-counter__icon-button {
  transition: transform .25s cubic-bezier(.175, .885, .32, 1.275);
  position: relative;
}

@media (max-width: 679px) {
  .likes-counter__icon-button > svg {
    min-width: 20px;
  }
}

.likes-counter__icon-button--counted svg path {
  fill: var(--isport-spring-600);
  transition: fill .25s cubic-bezier(.175, .885, .32, 1.275);
}

.likes-counter__icon-button.unlike-animation {
  animation: .5s cubic-bezier(.175, .885, .32, 1.275) forwards unlike-pulse;
}

.likes-counter__icon-button.unlike-animation svg path {
  animation: .5s cubic-bezier(.175, .885, .32, 1.275) forwards unlike-fade;
}

.likes-counter__icon-button.just-liked {
  transform: scale(1.25);
}

.likes-counter__float-icon {
  pointer-events: none;
  opacity: 0;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.likes-counter__float-icon svg {
  fill: #f03e3e;
  width: 100%;
  height: 100%;
}

.likes-counter__float-icon--sub {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.likes-counter__float-icon--sub svg {
  fill: #f03e3e;
  width: 100%;
  height: 100%;
}

.likes-counter__float-icon--1 {
  animation: 1s cubic-bezier(.175, .885, .32, 1.275) forwards float-up-1;
}

.likes-counter__float-icon--1 .sub-1 {
  opacity: .6;
  width: 12px;
  height: 12px;
  animation: 1s cubic-bezier(.175, .885, .32, 1.275) .25s forwards float-up-sub-1;
}

.likes-counter__float-icon--1 .sub-2 {
  opacity: .75;
  width: 12px;
  height: 12px;
  animation: 1s cubic-bezier(.175, .885, .32, 1.275) .15s forwards float-up-sub-2;
}

.likes-counter__float-icon--2 {
  animation: 1s cubic-bezier(.175, .885, .32, 1.275) forwards float-up-2;
}

.likes-counter__float-icon--2 .sub-1 {
  opacity: .25;
  width: 10px;
  height: 10px;
  animation: 1s cubic-bezier(.175, .885, .32, 1.275) .25s forwards float-up-sub-3;
}

.likes-counter__float-icon--2 .sub-2 {
  opacity: .4;
  width: 10px;
  height: 10px;
  animation: 1s cubic-bezier(.175, .885, .32, 1.275) .15s forwards float-up-sub-4;
}

@keyframes float-up-1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%)rotate(0);
  }

  40% {
    opacity: .9;
    transform: translate(0, -20px)rotate(-8deg);
  }

  70% {
    opacity: .7;
    transform: translate(2px, -35px)rotate(-12deg);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%)rotate(0);
  }
}

@keyframes float-up-2 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%)rotate(0)scale(0);
  }

  40% {
    opacity: .85;
    transform: translate(-8px, -25px)rotate(8deg)scale(1);
  }

  70% {
    opacity: .65;
    transform: translate(-12px, -40px)rotate(12deg)scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%)rotate(0)scale(.5);
  }
}

@keyframes float-up-sub-1 {
  0% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }

  40% {
    opacity: .75;
    transform: translate(8px, -12px)rotate(10deg);
  }

  70% {
    opacity: .5;
    transform: translate(12px, -25px)rotate(15deg);
  }

  100% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }
}

@keyframes float-up-sub-2 {
  0% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }

  40% {
    opacity: .8;
    transform: translate(10px, -8px)rotate(15deg);
  }

  70% {
    opacity: .6;
    transform: translate(14px, -15px)rotate(20deg);
  }

  100% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }
}

@keyframes float-up-sub-3 {
  0% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }

  40% {
    opacity: .65;
    transform: translate(-6px, -8px)rotate(-12deg);
  }

  70% {
    opacity: .4;
    transform: translate(-10px, -12px)rotate(-15deg);
  }

  100% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }
}

@keyframes float-up-sub-4 {
  0% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }

  40% {
    opacity: .7;
    transform: translate(3px, -15px)rotate(-8deg);
  }

  70% {
    opacity: .5;
    transform: translate(6px, -28px)rotate(-12deg);
  }

  100% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }
}

@keyframes unlike-pulse {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes unlike-fade {
  0% {
    fill-opacity: 1;
  }

  40% {
    fill-opacity: .5;
  }

  100% {
    fill-opacity: 1;
  }
}

.forum__comment-replay-net-vertical-line {
  border-left: 1px solid #ededec;
  height: 164px;
  position: absolute;
  top: -155px;
  left: -13px;
}

.forum__comment-replay-net {
  position: absolute;
}

.forum__comment-replay-net svg {
  z-index: 0;
  width: 25px;
  height: 15px;
  position: relative;
  top: 8px;
  left: -13px;
}

.forum__comment-content {
  padding: var(--spacing-20) 0 var(--spacing-20) var(--spacing-48);
  position: relative;
  overflow: hidden;
}

.forum__comment-content--deleted {
  gap: var(--spacing-12);
  color: var(--primary-main);
  align-items: center;
  display: flex;
}

.forum__comment-content--deleted .dataimg-attention {
  width: 24px;
  height: 24px;
}

.forum__comment-content img {
  width: 16px;
  height: 16px;
}

.forum__comment-content-notify-reason {
  color: var(--grey-600);
  margin-top: var(--spacing-8);
  border: 1px solid var(--other-divider);
  padding: var(--spacing-8);
  background-color: var(--grey-100);
}

.discussion-box {
  gap: var(--spacing-24);
  border-radius: var(--spacing-discussion-box-radius);
  padding: var(--spacing-32);
  background: var(--gradient-4);
  margin-bottom: var(--spacing-32);
  flex-direction: column;
  grid-column: 1 / 3;
  display: flex;
  position: relative;
}

@media screen and (max-width: 679px) {
  .discussion-box {
    padding: var(--spacing-32) var(--spacing-16);
    margin-bottom: var(--spacing-24);
  }
}

.discussion-box:before {
  content: "";
  z-index: -1;
  border-radius: calc(var(--spacing-discussion-box-radius)  + 2px) calc(var(--spacing-discussion-box-radius)  + 2px) 0 0;
  background: var(--gradient-2);
  width: 100%;
  height: 25px;
  position: absolute;
  top: -4px;
  left: 0;
}

.discussion-box .likes-counter__icon-button {
  cursor: default;
  pointer-events: none;
}

.discussion-box__title {
  color: var(--color-text-discussion-box-title);
}

.discussion-box__content {
  padding: var(--spacing-24);
  background: var(--color-surface-discussion-card-background);
}

@media screen and (max-width: 679px) {
  .discussion-box__content {
    padding: var(--spacing-16);
    border-radius: var(--spacing-discussion-card-radius);
  }
}

.discussion-box__content .forum__comment-content {
  padding-top: var(--spacing-8);
  padding-bottom: 0;
}

@media screen and (max-width: 679px) {
  .discussion-box__cta {
    width: 100%;
  }
}

.profile-user {
  flex-direction: column;
  grid-column: lend / rend;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.profile-user a {
  color: var(--text-primary);
}

.profile-user a:hover {
  text-decoration: none;
}

.profile-user__title {
  justify-content: space-between;
  display: flex;
}

.profile-user__title h1.title-hidden {
  visibility: hidden;
}

.profile-user__title span {
  align-self: center;
}

.profile-user__name-email {
  text-align: right;
}

.profile-user__brand {
  justify-content: center;
  display: flex;
}

.profile-user__subscription {
  justify-content: space-between;
  display: flex;
}

.profile-user__subscription p {
  flex: 0 0 150px;
}

.profile-user__subscription ~ .profile-user__subscription {
  padding-top: var(--spacing-12);
  border-top: none;
  margin-top: 0;
}

.profile-user__cancel-reccuring, .profile-user__cancel-newsletter {
  padding-top: var(--spacing-12);
  justify-content: space-between;
  display: flex;
}

.profile-user__cancel-reccuring strong, .profile-user__cancel-newsletter strong {
  text-align: right;
  color: var(--green);
  position: relative;
}

.profile-user__cancel-reccuring strong a, .profile-user__cancel-newsletter strong a {
  color: var(--text-primary);
  font-weight: normal;
}

.profile-user__cancel-reccuring strong span, .profile-user__cancel-newsletter strong span {
  color: var(--primary-main);
  cursor: pointer;
}

.profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
  background: var(--primary-contrast);
  border: 1px solid var(--grey-300);
  padding: var(--spacing-12);
  color: var(--text-primary);
  text-align: center;
  z-index: 2;
  width: 160px;
  line-height: 17px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

.profile-user__remaining-days {
  padding-top: var(--spacing-12);
  text-align: right;
}

.profile-user__remaining-days strong {
  color: var(--green);
}

.profile-user__subscription, .profile-user__setting-links, .profile-user__condition-links, .profile-user__order, .profile-user__benefits, .profile-user__activate {
  border-top: 1px solid var(--grey-300);
  padding-top: var(--spacing-32);
  margin-top: var(--spacing-32);
  line-height: 35px;
}

.profile-user__voucher {
  gap: var(--spacing-32);
  flex-direction: column;
  align-items: center;
  margin: 0;
  display: flex;
}

.profile-user__voucher-preview {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.profile-user__voucher-image {
  max-width: 100%;
}

.profile-user__voucher-image img {
  width: 100%;
}

.profile-user__condition-prefix {
  margin-top: var(--spacing-24);
}

.profile-user__condition-links a {
  padding-left: var(--spacing-20);
  position: relative;
}

.profile-user__condition-links a:before {
  content: "";
  background: var(--grey-300);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.profile-user .error-message {
  color: var(--red);
}

.profile-user__form-centered {
  justify-content: center;
  display: flex;
}

.profile-user .activation-steps {
  margin: var(--spacing-32) auto 0;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  display: flex;
}

.profile-user .activation-steps__item {
  padding: var(--spacing-8) var(--spacing-20) var(--spacing-8);
  background: var(--grey-200);
  color: var(--text-primary);
  width: calc(50% - 30px);
  margin-right: 30px;
  position: relative;
}

.profile-user .activation-steps__item:last-of-type {
  text-indent: 25px;
  left: -30px;
}

.profile-user .activation-steps__item:before {
  content: "";
  border: 35px solid #0000;
  border-left: 30px solid var(--grey-200);
  z-index: 1;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.profile-user .activation-steps__item.active-step {
  color: var(--red);
  background: var(--grey-50);
}

.profile-user .activation-steps__item.active-step:before {
  border-left: 30px solid var(--grey-50);
}

.profile-user__form {
  gap: var(--spacing-32);
  box-shadow: var(--shadow-l);
  margin: var(--spacing-32) 0;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.profile-user__form-top {
  padding: var(--spacing-40) var(--spacing-24) 0;
  gap: var(--spacing-32);
  border: 2px solid var(--color-border-registration-subtle);
  background: var(--color-surface-registration-default-light);
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (max-width: 679px) {
  .profile-user__form-top {
    padding: var(--spacing-40) var(--spacing-16) 0;
    gap: var(--spacing-24);
  }
}

.profile-user__form .profile-user__form-bottom {
  padding: var(--spacing-32) var(--spacing-24);
  gap: var(--spacing-32);
  background: var(--color-surface-registration-default-subtle);
  border-top: 1px solid var(--color-border-registration-lighter);
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (max-width: 679px) {
  .profile-user__form .profile-user__form-bottom {
    padding: var(--spacing-32) var(--spacing-16);
    gap: var(--spacing-24);
  }

  .profile-user__form .profile-user__form-bottom .form-order__field {
    flex-direction: row;
  }
}

.profile-user__form .profile-user__order {
  gap: var(--spacing-16);
  border: none;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.profile-user__form .form-order__divider {
  background-color: var(--color-border-divider-default);
  border: none;
  width: 100%;
  height: 1px;
  margin: 0;
}

.profile-user__form .form-order__icons {
  gap: var(--spacing-16);
  align-items: center;
  display: flex;
}

.profile-user__form .form-order__icons span {
  margin: 0;
}

.profile-user__form .form-order__payment {
  gap: var(--spacing-8);
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

@media (max-width: 679px) {
  .profile-user__form .form-order__payment {
    align-items: flex-start;
  }
}

.profile-user__form .form-order__field {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 679px) {
  .profile-user__form .form-order__field {
    gap: var(--spacing-8);
    flex-direction: column;
  }
}

.profile-user__form .form-order__info-text {
  gap: var(--spacing-16);
  padding: var(--spacing-24);
  margin: var(--spacing-16) 0;
  border-radius: var(--spacing-flash-message-radius);
  background: var(--color-surface-flash-messages-info-normal-background);
  box-shadow: var(--shadow-m);
  color: var(--color-text-flash-messages-info-normal-title);
  display: flex;
}

.profile-user__form .form-order__info-text svg {
  flex-shrink: 0;
}

.profile-user__form .form-order__info-text svg path {
  fill: var(--color-text-flash-messages-info-normal-title);
}

.profile-user__form .profile-user__agreements {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.profile-user__form .profile-user__agreement {
  align-items: flex-start;
  gap: var(--spacing-8);
  display: flex;
}

.loader {
  border: 16px solid var(--primary-outlined-hover-background);
  border-top: 16px solid var(--primary-main);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  animation: 2s linear infinite spin;
}

.form-review__field {
  margin: var(--spacing-8) 0;
}

.form-review__field input {
  display: none;
}

.form-review__field label {
  background-color: var(--grey-100);
  cursor: pointer;
  padding: 14px 16px 10px 42px;
  display: block;
  position: relative;
}

.form-review__field label:after {
  content: "";
  border: solid 1px var(--grey-300);
  background-color: var(--white);
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 11px;
  left: 12px;
}

.form-review__field label[for="textarea"] {
  display: none;
}

.form-review__field input:checked + label {
  background-color: var(--grey-200);
}

.form-review__field input:checked + label:after {
  content: "✔";
  font-size: var(--font-size-16);
  text-align: center;
  color: var(--primary-main);
  background-color: var(--pink);
  border-color: var(--primary-main);
}

.form-review__field textarea {
  padding: var(--spacing-12);
  box-sizing: border-box;
  border: solid 1px var(--grey-100);
  width: 100%;
  height: 130px;
  min-height: 100px;
  display: none;
}

.form-review__field textarea:focus-visible {
  border: solid 1px var(--grey-200);
  outline: none;
}

.form-review__field input[id="choice8"]:checked ~ textarea {
  display: block;
}

.discount-code__info {
  color: var(--primary-main);
}

.profile-user__discounted {
  gap: var(--spacing-16);
  color: var(--color-text-button-error-text-default);
  display: flex;
}

.form-order__field {
  justify-content: space-between;
  display: flex;
}

.form-order__field p {
  white-space: nowrap;
  flex: 0 0 150px;
}

.form-order__field.discount-code {
  justify-content: left;
  gap: var(--spacing-8);
}

@media (max-width: 679px) {
  .form-order__field.discount-code {
    flex-flow: wrap;
  }
}

.form-order__field.discount-code .discount-code__input {
  border-radius: var(--spacing-input-radius);
  border: 1px solid var(--color-border-input-normal-default);
  background: var(--color-surface-input-normal-default);
  color: var(--color-text-input-normal-value-default);
  padding: 10px 12px;
}

.form-order__info-text {
  background-color: var(--grey-100);
  padding: var(--spacing-8) var(--spacing-24);
}

.form-order__payment-choose input, .form-order__payment-choose label, .form-order__agreement input, .form-order__agreement label {
  cursor: pointer;
}

.form-order__icons span {
  margin: 0 0 0 var(--spacing-8);
  background-repeat: no-repeat;
  background-size: cover;
  height: 23px;
  display: inline-block;
}

.form-order__icons span.master-card {
  background-image: url("/dist/assets/default/icons/icon-premium-master-card.svg");
  width: 30px;
}

.form-order__icons span.visa {
  background-image: url("/dist/assets/default/icons/icon-premium-visa.svg");
  background-position: 0 -3px;
  width: 39px;
}

.form-order__icons span.gopay {
  background-image: url("/dist/assets/default/icons/icon-premium-gopay.svg");
  width: 50px;
}

.form-order__icons span.google-pay {
  background-image: url("/dist/assets/default/icons/icon-google-pay.svg");
  background-position: -7px -11px;
  background-size: 60px 45px;
  width: 44px;
}

.form-order__icons span.apple-pay {
  background-image: url("/dist/assets/default/icons/icon-apple-pay.svg");
  width: 36px;
}

@media (max-width: 679px) {
  .profile-user {
    width: 100%;
  }

  .profile-user strong {
    font: var(--font-mobile-body1-bold);
  }

  .profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
    font: var(--font-mobile-meta-regular);
  }

  .profile-user__title h3 {
    margin-top: 5px;
  }

  .profile-user .activation-steps__info {
    display: none;
  }

  .profile-user .activation-steps__item:before {
    border: 21px solid #0000;
    border-left: 21px solid var(--grey-200);
  }

  .profile-user .activation-steps__item.active-step:before {
    border-left: 21px solid var(--grey-50);
  }

  .profile-user .block__body-content .premium-offer {
    display: block;
  }

  .profile-user .block__body-content__center {
    align-self: initial;
    flex-direction: column-reverse;
  }

  .profile-user__voucher-title {
    font: var(--font-mobile-body1-bold);
  }

  .form-review__field label, .form-review__field textarea {
    font: var(--font-mobile-meta-regular);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .profile-user {
    min-width: 680px;
  }

  .profile-user strong {
    font: var(--font-desktop-body1-bold);
  }

  .profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
    font: var(--font-desktop-meta-regular);
  }

  .profile-user__voucher-title {
    font: var(--font-desktop-body1-bold);
  }

  .form-review__field label, .form-review__field textarea {
    font: var(--font-desktop-meta-regular);
  }
}

.premium-odmeny {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-32);
  flex-direction: column;
  width: 100%;
  display: inline-flex;
}

.block__header {
  padding-left: var(--spacing-24);
  padding-right: var(--spacing-24);
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
}

.block__body {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.block__body-content {
  padding: var(--spacing-24);
  border-radius: var(--spacing-15);
  border: 1px var(--grey-200) solid;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
  box-shadow: 0 2px 4px -2px #19191912;
}

.block__body-content__top {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
}

.block__body-content .premium-offer {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex: 1 1 0;
  display: flex;
}

.block__body-content .premium-offer__text {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  display: flex;
}

.block__body-content .premium-offer__title {
  color: var(--grey-900);
  font-size: var(--font-size-22);
  font-weight: 400;
  line-height: var(--spacing-28);
  word-wrap: break-word;
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.block__body-content__center {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
}

.block__body-content__right {
  color: var(--grey-600);
  font: var(--font-desktop-meta-regular);
}

.thanks {
  gap: var(--spacing-8);
  grid-column: 1 / 4;
  display: flex;
}

.thanks__content {
  padding-right: var(--spacing-24);
  align-items: flex-start;
  gap: var(--spacing-8) 0;
  flex-flow: column;
  flex: 1;
  display: flex;
}

.thanks__content .thanks__image-wrapper {
  display: none;
}

@media (max-width: 679px) {
  .thanks {
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding-right: 0;
  }

  .thanks__content {
    padding-right: 0;
  }

  .thanks__content > .thanks__image-wrapper {
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    display: flex !important;
  }

  .thanks .button {
    align-self: center;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .thanks {
    grid-column: 1 / 4;
  }
}

@media (max-width: 679px) {
  .thanks__title {
    margin-bottom: var(--spacing-16);
  }
}

.thanks__text, .thanks__publisher {
  margin-bottom: var(--spacing-16);
}

.thanks__image-wrapper {
  flex: none;
  width: 302px;
}

@media (max-width: 679px) {
  .thanks__image-wrapper {
    margin-bottom: var(--spacing-16);
    height: 106px;
    display: none;
    width: fit-content !important;
  }
}

body:has(.newsletters) .text-link {
  z-index: 0;
}

.newsletters {
  text-align: center;
  z-index: 2;
  grid-column: lend / rend;
  position: relative;
}

.newsletters__title {
  text-align: center;
  grid-column: lend / rend;
  max-width: 720px;
  margin: 0 auto;
}

.newsletters__title h1 {
  font: var(--font-desktop-h1);
  margin-bottom: var(--spacing-12);
}

.newsletters__title p {
  font: var(--font-desktop-body1-bold);
}

.newsletters__choose-all {
  margin-bottom: var(--spacing-32);
  justify-content: center;
  display: flex;
}

.newsletters__popup-label {
  display: block;
}

.newsletter__detail {
  padding: var(--spacing-24) var(--spacing-32);
  border: 2px solid var(--color-border-newsletter-box-default);
  margin: 0 auto var(--spacing-16);
  text-align: left;
  background-color: var(--color-surface-newsletter-box-background);
  box-shadow: var(--shadow-newsleter-detail-selected);
  max-width: 720px;
  position: relative;
}

.newsletter__detail--title {
  font: var(--font-desktop-h2);
  color: var(--color-text-newsletter-box-title);
}

.newsletter__detail--date {
  font: var(--font-desktop-body1);
  color: var(--color-text-newsletter-box-meta);
  margin-top: var(--spacing-12);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.newsletter__detail--date svg {
  padding-right: var(--spacing-8);
  display: inline-block;
  position: relative;
  top: -1px;
}

.newsletter__detail--description {
  font: var(--font-desktop-body1);
  margin-top: var(--spacing-20);
  color: var(--color-text-newsletter-box-body);
}

.newsletter__detail--note {
  font: var(--font-desktop-body1);
  color: var(--color-text-newsletter-box-meta);
  margin-top: var(--spacing-20);
}

.newsletter__detail--signup {
  top: 30px;
  right: var(--spacing-32);
  position: absolute;
}

.newsletters > .newsletter__detail--last-child {
  margin: 0 auto;
}

.newsletters__bar {
  z-index: 2;
  background: var(--color-surface-sticky-down-background);
  box-shadow: var(--shadow-m);
  padding: var(--spacing-8) 0;
  text-align: left;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.newsletters__bar--content {
  padding-left: var(--spacing-16);
  padding-right: var(--spacing-16);
  gap: var(--spacing-8);
  z-index: 3;
  flex-direction: row;
  place-content: center space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.newsletters__bar--title {
  font: var(--font-desktop-h3);
}

.newsletters__bar--summary {
  font: var(--font-desktop-body2-bold);
  flex-direction: row;
  display: flex;
}

.newsletters__bar--summary span {
  padding-left: var(--spacing-32);
  text-wrap: nowrap;
  font: var(--font-desktop-body2);
  margin-right: 20px;
  position: relative;
}

.newsletters__bar--summary span:before {
  content: "";
  background: url("/dist-isport/assets/default/icons/icon-check-circle.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
}

@media (max-width: 679px) {
  .newsletters__bar--summary span:before {
    content: none;
  }

  .newsletters__bar--summary span {
    margin-right: 10px;
    padding-left: 0;
  }

  .newsletters__bar--summary span:last-child {
    margin-right: 0;
  }
}

.newsletters__bar--summary--form {
  margin-top: var(--spacing-32);
}

.newsletters__bar--summary strong {
  text-wrap: nowrap;
}

.newsletters__bar--summary--input {
  padding: var(--spacing-12) var(--spacing-16);
  border: 1px solid var(--grey-300);
  border-radius: 2px;
  width: 260px;
}

@media (max-width: 679px) {
  .newsletters__bar--summary--input span {
    margin-right: var(--spacing-56);
    text-wrap: wrap;
    padding-left: 0;
  }

  .newsletters__bar--summary--input span:before {
    display: none;
  }

  .newsletters__bar--summary--input span:after {
    content: ", ";
  }
}

.newsletters__bar--agreement {
  font: var(--font-desktop-body1);
  margin-top: var(--spacing-12);
}

.newsletters__bar--agreement a {
  color: #000;
}

.newsletters__bar--agreement a:hover {
  text-decoration: none;
}

.newsletters__bar--agreement.notification-agreement {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  gap: 0 5px;
  display: flex;
  position: relative;
}

.newsletters__bar--agreement.notification-agreement .checkbox-wrapper {
  display: inline-block;
}

@media (max-width: 679px) {
  .newsletters__bar--agreement.notification-agreement .checkbox-wrapper {
    margin-right: var(--spacing-8);
    margin-bottom: 10px;
  }
}

.newsletters__bar--agreement.notification-agreement .checkbox-wrapper:before {
  content: "";
  background: url("/dist-isport/assets/default/icon-checkbox.svg") center no-repeat;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  display: block;
  position: relative;
}

.newsletters__bar--agreement.notification-agreement .checkbox-wrapper.selected:before {
  background: var(--color-surface-checkbox-default-checked-normal-default) url("/dist-isport/assets/default/icons/icon-checkbox-checked-white.svg") center center no-repeat;
}

.newsletters__bar--button {
  justify-content: flex-end;
  gap: 0 var(--spacing-16);
  display: flex;
}

.newsletters__bar--success {
  text-align: center;
  padding: 0 120px;
}

.newsletters__bar--your-choice {
  margin-right: var(--spacing-12);
}

@media (max-width: 679px) {
  .newsletters__bar--summary {
    flex-wrap: wrap;
    align-items: center;
  }

  .newsletters__bar--success {
    padding: 0;
  }

  .newsletters__bar--button button {
    text-wrap: nowrap;
  }

  .newsletter__detail {
    padding-bottom: var(--spacing-64);
  }

  .newsletter__detail--signup {
    top: auto;
    bottom: var(--spacing-24);
  }

  .newsletter__popup-final-step {
    width: calc(100% - var(--spacing-32));
  }
}

.newsletter__popup-final-step-close {
  cursor: pointer;
  align-self: flex-end;
  width: fit-content;
  margin-top: 5px;
  position: absolute;
}

.newsletter__popup-final-step-title {
  border-bottom: 1px solid var(--color-border-divider-default);
  padding-bottom: 16px;
}

.newsletter__popup-form {
  margin-top: var(--spacing-32);
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

@media (max-width: 679px) {
  .newsletter__popup-form {
    gap: var(--spacing-16);
  }
}

.newsletter__popup-final-step-overlay {
  z-index: 1000;
  background: #191919b3;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
}

.notification-agreement--red {
  border: 1px solid var(--color-border-error-main);
}

.newsletter__popup-final-step {
  font: var(--font-desktop-body2);
  border-radius: var(--spacing-12);
  border: 1px solid var(--light-other-outline-border);
  background-color: #fff;
  flex-flow: column;
  justify-content: stretch;
  max-width: 688px;
  min-height: 250px;
  padding: 24px;
  display: flex;
  box-shadow: 0 4px 8px #0000001a;
}

.newsletter-popup__list-of-newsletters > span {
  margin-right: var(--spacing-20);
  padding-left: var(--spacing-32);
  white-space: nowrap;
  padding-top: 2px;
  line-height: 24px;
  position: relative;
}

.newsletter-popup__list-of-newsletters > span:before {
  content: "";
  top: calc(-1 * var(--spacing-2));
  background: url("/dist-isport/assets/default/icons/icon-check-circle.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  left: 0;
}

.newsletter__popup-email-format-info {
  font: var(--font-desktop-body2);
  color: var(--color-text-error-main);
  margin-top: var(--spacing-12);
}

.newsletter-popup__title--success {
  padding-left: var(--spacing-32);
  margin-top: var(--spacing-16);
  text-align: center;
  position: relative;
}

.newsletter-popup__title--success:before {
  content: "";
  top: var(--spacing-4);
  background: url("/dist-isport/assets/default/icons/icon-check-circle.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  left: 0;
}

.newsletter-popup__checkbox {
  padding-bottom: var(--spacing-24);
}

@media (max-width: 679px) {
  .newsletter-popup__checkbox {
    padding-bottom: 0;
  }
}

.newsletter-poupup__form-sent {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.detail-match-info-tabs {
  gap: var(--spacing-16);
  margin-bottom: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 1080px) {
  .detail-match-info-tabs {
    grid-column: 1 / 3;
  }
}

@media screen and (min-width: 680px) and (max-width: 1079px) {
  .detail-match-info-tabs {
    grid-column: 1 / 4;
  }
}

@media screen and (max-width: 679px) {
  .detail-match-info-tabs {
    grid-column: 1 / 3;
  }
}

.list-articles-wrapper--vertical {
  gap: var(--spacing-32);
  flex-direction: column;
  grid-column: 1 / 3;
  display: flex;
}

@media (max-width: 679px) {
  .list-articles-wrapper--vertical {
    gap: var(--spacing-24);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .list-articles-wrapper--vertical {
    grid-column: 1 / 4;
  }
}

.article-detail__header {
  background-color: var(--color-surface-default-content-top);
  color: var(--themes-action-inverted-on-inverted);
  border-radius: 0 0 var(--spacing-12) var(--spacing-12);
  flex-flow: column;
  display: flex;
  overflow: hidden;
}

@media (max-width: 679px) {
  .article-detail__header {
    margin: 0 calc(-1 * var(--spacing-16));
    padding: var(--spacing-8) var(--spacing-16);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .article-detail__header {
    margin: 0 calc(-1 * var(--spacing-32));
    padding: var(--spacing-16);
    grid-template-columns: minmax(0, 1fr);
    display: grid;
  }

  .article-detail__header:has(.article-gallery-strip) {
    padding-bottom: 0;
  }
}

.article-detail__flag {
  margin-bottom: var(--spacing-16);
  display: flex;
}

.article-detail__top-img-description {
  color: var(--color-text-info-bar-video-text);
  padding: var(--spacing-4) 0 0;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .article-detail__top-img-description {
    padding: var(--spacing-8) var(--spacing-16) 0;
  }

  .article-detail__top-img-description:last-child {
    margin-bottom: var(--spacing-8);
  }
}

.article-detail__title {
  margin-bottom: var(--spacing-8);
}

.article-detail__title:last-child {
  margin-bottom: var(--spacing-24);
}

@media (max-width: 679px) {
  .article-detail__title {
    margin-bottom: var(--spacing-24);
    font-size: var(--typography-mobile-heading-xl-font-size);
    line-height: var(--typography-mobile-heading-xl-line-height);
  }
}

.article-detail__leadsection {
  font: var(--font-desktop-body1-bold);
  max-width: 620px;
  display: inline-block;
}

@media (max-width: 679px) {
  .article-detail__leadsection {
    font: var(--font-mobile-body1-bold);
  }
}

.article-detail__leadsection p {
  font: var(--font-desktop-body1-bold);
}

@media (max-width: 679px) {
  .article-detail__leadsection p {
    font: var(--font-mobile-body1-bold);
  }
}

.article-detail__meta-top {
  align-items: center;
  gap: var(--spacing-12);
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: 0;
  display: flex;
}

.article-detail__meta-top:not(:last-child) {
  margin-bottom: var(--spacing-32);
}

@media (max-width: 679px) {
  .article-detail__meta-top {
    flex-flow: wrap;
  }
}

.article-detail__meta-top-right {
  flex-direction: row-reverse;
  display: flex;
}

.article-detail__meta-top__partner {
  margin: var(--spacing-32) 0 0 var(--spacing-20);
  flex: calc(100% - 20px);
}

@media (max-width: 679px) {
  .article-detail__meta-top {
    gap: var(--spacing-16);
    margin-top: var(--spacing-16);
  }

  .article-detail__meta-top__partner {
    margin: var(--spacing-16) 0 0;
    flex: 100%;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .article-detail__meta-top__partner {
    margin: var(--spacing-16) 0 0;
    flex: 100%;
  }
}

.article-detail__meta-bottom {
  align-items: center;
  gap: var(--spacing-12);
  margin-top: var(--spacing-32);
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.article-detail__meta-bottom--right {
  flex-direction: row-reverse;
  display: flex;
}

@media (max-width: 679px) {
  .article-detail__meta-bottom {
    gap: var(--spacing-16);
    margin-top: var(--spacing-16);
  }
}

.social-media__wrapper {
  margin-top: var(--spacing-32);
  margin-bottom: var(--spacing-32);
}

.social-media__wrapper.instagram-media, .social-media__wrapper.twitter-media {
  justify-content: center;
  display: flex;
}

.social-media__wrapper.instagram-media iframe, .social-media__wrapper.twitter-media iframe {
  min-width: auto !important;
  max-width: 500px !important;
}

.social-media__wrapper.facebook-media .fb-post {
  width: 100%;
}

.social-media__wrapper.facebook-media .fb-post > span {
  max-width: 660px;
  width: 100% !important;
}

.social-media__wrapper.facebook-media .fb-post > span > iframe {
  width: 100% !important;
}

.social-media__wrapper.twitter-media {
  width: 100%;
}

.social-media__wrapper.twitter-media .twitter-tweet {
  max-width: 500px !important;
}

.social-media__wrapper .tiktok-embed {
  margin-left: auto;
  margin-right: auto;
}

.ios .social-media__wrapper.instagram-media > iframe, .ios .social-media__wrapper.twitter-media > iframe {
  width: calc(100% - 34px) !important;
}

.infogram-embed {
  margin-top: var(--spacing-32);
  margin-bottom: var(--spacing-32);
  max-width: 540px;
}

.infogram-embed > iframe {
  width: 100% !important;
}

.article-detail__content-body {
  margin-bottom: var(--spacing-32);
}

.article-detail__content-body > h1, .article-detail__content-body > h2, .article-detail__content-body > h3, .article-detail__content-body > h4, .article-detail__content-body > h5, .article-detail__content-body > p, .article-detail__content-body hr, .article-detail__content-body blockquote {
  margin: var(--spacing-32) 0;
}

.article-detail__content-body > p {
  max-width: 620px;
}

.article-detail__content-body > h1 + p, .article-detail__content-body > h2 + p, .article-detail__content-body > h3 + p, .article-detail__content-body > h4 + p, .article-detail__content-body > h5 + p {
  margin: var(--spacing-16) 0 var(--spacing-32);
}

:is(.article-detail__content-body > h1:has( + p), .article-detail__content-body > h2:has( + p), .article-detail__content-body > h3:has( + p), .article-detail__content-body > h4:has( + p), .article-detail__content-body > h5:has( + p)) {
  margin: var(--spacing-32) 0 var(--spacing-16);
}

.article-detail__content-body > p, .article-detail__content-body > h2, .article-detail__content-body > h3, .article-detail__content-body > h4, .article-detail__content-body > h5 {
  max-width: 620px;
}

.article-detail__content-body > ol, .article-detail__content-body > ul {
  max-width: 620px;
}

@media (max-width: 679px) {
  .article-detail__content-body > ol, .article-detail__content-body > ul {
    margin-left: 0;
    margin-right: 0;
  }
}

.article-detail__content-body > p:has(.quote) {
  padding-left: 0;
}

.article-detail__content-body > p:has(.quote) > strong {
  width: 100%;
  display: inline-block;
}

.article-detail__content-body > p > strong > .quote {
  quotes: "“" "”";
  text-align: center;
  font: var(--font-desktop-inter-title);
  width: 100%;
  display: inline-block;
}

.article-detail__content-body > p > strong > .quote:before {
  content: open-quote;
}

.article-detail__content-body > p > strong > .quote:after {
  content: close-quote;
}

:is(.article-detail__content-body > h1:has( > iframe[title*="video"]), .article-detail__content-body > h2:has( > iframe[title*="video"]), .article-detail__content-body > h3:has( > iframe[title*="video"]), .article-detail__content-body > h4:has( > iframe[title*="video"]), .article-detail__content-body > h5:has( > iframe[title*="video"]), .article-detail__content-body > h6:has( > iframe[title*="video"]), .article-detail__content-body > p:has( > iframe[title*="video"])) {
  aspect-ratio: 16 / 9;
}

@media (max-width: 679px) {
  .article-detail__content-body > h1 > iframe, .article-detail__content-body > h2 > iframe, .article-detail__content-body > h3 > iframe, .article-detail__content-body > h4 > iframe, .article-detail__content-body > h5 > iframe, .article-detail__content-body > h6 > iframe, .article-detail__content-body > p > iframe {
    max-width: 100%;
  }

  .article-detail__content-body > h1 > iframe[title*="video"], .article-detail__content-body > h2 > iframe[title*="video"], .article-detail__content-body > h3 > iframe[title*="video"], .article-detail__content-body > h4 > iframe[title*="video"], .article-detail__content-body > h5 > iframe[title*="video"], .article-detail__content-body > h6 > iframe[title*="video"], .article-detail__content-body > p > iframe[title*="video"] {
    width: 100%;
    height: 100%;
  }
}

.article-detail__content-body > h1 > strong, .article-detail__content-body > h2 > strong, .article-detail__content-body > h3 > strong, .article-detail__content-body > h4 > strong, .article-detail__content-body > h5 > strong, .article-detail__content-body > h6 > strong {
  font: inherit;
}

.article-image__description, .article-video__description {
  font: var(--font-desktop-body2);
  color: var(--color-text-default-helper);
  padding-top: var(--spacing-8);
  display: inline-block;
}

.article-image__description span, .article-video__description span {
  line-height: 20px;
}

.article-image__divider, .article-video__divider {
  color: var(--color-text-light);
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
}

.article-detail__top-img, .article-detail__top-gjvp-video, .article-detail__top-embedmalltv, .article-detail__top-audio {
  width: 100%;
  margin-left: 0;
  position: relative;
}

.article-detail__top-img audio, .article-detail__top-gjvp-video audio, .article-detail__top-embedmalltv audio, .article-detail__top-audio audio {
  width: 100%;
}

@media (max-width: 679px) {
  .article-detail__top-img, .article-detail__top-gjvp-video, .article-detail__top-embedmalltv, .article-detail__top-audio {
    width: 100%;
  }
}

.article-detail__top-embedmalltv {
  margin-bottom: var(--spacing-12);
}

.article-detail__header .small-gallery {
  margin-left: 0;
  margin-right: 0;
}

.small-gallery {
  border-radius: var(--spacing-global-image-radius);
  overflow: hidden;
}

@media (max-width: 679px) {
  .small-gallery {
    margin-left: calc(var(--spacing-16) * -1);
    margin-right: calc(var(--spacing-16) * -1);
    border-radius: 0;
  }
}

.small-gallery__item {
  border-radius: var(--spacing-global-image-radius);
  text-align: center;
  align-items: center;
  gap: var(--spacing-12);
  cursor: grab;
  flex-direction: column;
  flex-shrink: 0;
  width: auto;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 679px) {
  .small-gallery__item {
    border-radius: 0;
  }
}

.small-gallery__item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.small-gallery__item-image, .small-gallery__item-video {
  background-color: #0000;
  background-image: url("/dist/assets/default/gallery/spin-loader.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.small-gallery__item-image {
  background: none;
  width: 100%;
  height: 0;
  margin: 0;
  padding-top: 56.1765%;
  position: relative;
}

.small-gallery__item-video {
  cursor: default;
  width: 100%;
  height: auto;
}

.small-gallery__item-lock {
  background-color: var(--background-paper);
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-top: 56.1765%;
  position: relative;
  overflow: hidden;
}

.small-gallery__item-lock .subscription {
  padding: var(--spacing-24);
  flex-direction: column;
  justify-content: center;
  width: max-content;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}

@media (max-width: 679px) {
  .small-gallery__item-lock .subscription {
    left: calc(50% + 16px);
  }
}

@media (max-width: 399.999px) {
  .small-gallery__item-lock .subscription {
    width: 250px;
  }
}

@media (max-width: 575.999px) {
  .small-gallery__item-lock .subscription__logo, .small-gallery__item-lock .subscription__headline {
    display: none;
  }
}

@media (max-width: 399.999px) {
  .small-gallery__item-lock .subscription__description {
    margin-top: 0;
  }

  .small-gallery__item-lock .subscription__btns {
    margin-top: var(--spacing-16);
  }
}

@media (max-width: 679px) {
  .small-gallery__item-lock .subscription__login {
    margin-top: var(--spacing-24);
  }
}

@media (max-width: 399.999px) {
  .small-gallery__item-lock .subscription__login {
    margin-top: var(--spacing-16);
    font-size: 13px;
  }
}

@media (max-width: 679px) {
  .small-gallery__item-lock .subscription .button {
    padding: var(--spacing-8) var(--spacing-24);
    font: var(--font-mobile-button-small);
  }
}

.small-gallery .glide__arrow {
  visibility: visible;
  opacity: .7;
  cursor: pointer;
  z-index: 1;
  background-color: var(--color-surface-gallery-arrows-default);
  color: var(--color-icon-gallery-arrows-default);
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.small-gallery .glide__arrow--right {
  right: var(--spacing-16);
}

.small-gallery .glide__arrow--left {
  left: var(--spacing-16);
  transform: translate(0%, -50%)rotate(180deg);
}

@media (max-width: 679px) {
  .small-gallery .glide__arrow {
    width: 32px;
    height: 32px;
  }
}

.small-gallery .glide__arrow:hover {
  background-color: var(--color-surface-gallery-arrows-hover);
  color: var(--color-icon-gallery-arrows-default);
}

.small-gallery .glide__arrows[data-glide-controls-hidden="true"] .glide__arrow {
  display: none;
}

.small-gallery [data-photo-credit-wrapper] {
  left: var(--spacing-16);
}

.article-gallery-strip {
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .article-gallery-strip {
    margin-left: calc(-1 * var(--spacing-16));
    margin-right: calc(-1 * var(--spacing-16));
  }
}

.article-gallery-strip__description {
  padding: var(--spacing-8) var(--spacing-16) 0;
}

.article-gallery-strip--with-description {
  flex-flow: column;
}

.article-gallery-strip__wrapper {
  background-color: var(--color-surface-gallery-strip-default);
  padding: var(--spacing-12);
  justify-content: flex-start;
  gap: var(--spacing-8);
  margin: 0 0 var(--spacing-8) 0;
  width: 100%;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .article-gallery-strip__wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.article-gallery-strip__item {
  flex: 0 124px;
  padding: 0;
  position: relative;
}

.article-gallery-strip__item img {
  border-radius: 0;
  max-height: 70px;
}

.article-gallery-strip__item--locked {
  background: var(--color-surface-gallery-strip-default) url("/__redesign/svg/icon-lock.svg") center center no-repeat;
  opacity: .8;
  background-size: 24px 24px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 679px) {
  .article-gallery-strip__item[data-item-number="3"], .article-gallery-strip__item[data-item-number="4"], .article-gallery-strip__item[data-item-number="5"], .article-gallery-strip__item[data-item-number="6"], .article-gallery-strip__item[data-item-number="7"] {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .article-gallery-strip__item[data-item-number="5"], .article-gallery-strip__item[data-item-number="6"], .article-gallery-strip__item[data-item-number="7"] {
    display: none;
  }
}

.article-gallery-strip__item-icon {
  bottom: var(--spacing-8);
  right: var(--spacing-8);
  width: var(--spacing-24);
  height: var(--spacing-24);
  position: absolute;
}

.article-gallery-strip__item-icon svg {
  background-color: var(--button-video-play-icon-backround);
  border: 1px solid var(--button-video-play-icon-backround);
  border-radius: 100%;
}

.article-gallery-strip__enter {
  background-color: var(--color-surface-gallery-strip-default);
  color: var(--primary-contrast);
  text-align: center;
  font: var(--font-mobile-button-small);
  flex-wrap: wrap;
  place-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.article-gallery-strip__enter-link {
  display: flex;
}

.article-gallery-strip__icon {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.article-gallery-strip__icon svg {
  margin-right: var(--spacing-4);
}

.topic-banner {
  gap: var(--spacing-24);
  padding: var(--spacing-16) var(--spacing-32);
  background-color: var(--text-primary);
  opacity: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  transition: transform .3s ease-out;
  display: flex;
  position: absolute;
  transform: translateY(-100%);
}

@media (max-width: 679px) {
  .topic-banner {
    row-gap: var(--spacing-8);
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .topic-banner {
    justify-content: flex-start;
  }
}

.topic-banner--fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear .3s, opacity .3s, transform .3s;
  transform: translateY(-100%);
}

.topic-banner--fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility linear, opacity .3s;
  transform: translateY(0);
}

.topic-banner__label {
  color: var(--primary-contrast);
  background-color: var(--primary-main);
  padding: var(--spacing-4) var(--spacing-12);
  font: var(--font-mobile-meta-bold);
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2px;
  order: 1;
  max-height: 28px;
}

@media (max-width: 679px) {
  .topic-banner__label {
    margin: var(--spacing-6) 0;
  }
}

.topic-banner__title {
  cursor: pointer;
  color: var(--primary-contrast);
  font: var(--font-mobile-h3);
  order: 2;
  max-width: 600px;
}

@media (max-width: 679px) {
  .topic-banner__title {
    order: 3;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .topic-banner__title {
    max-width: 500px;
  }
}

.topic-banner__arrow {
  cursor: pointer;
  order: 3;
  width: 40px;
  height: 40px;
  margin: auto 0;
  padding: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 42px;
}

.topic-banner__arrow svg {
  width: 20px;
  height: 20px;
}

.topic-banner__arrow svg path {
  fill: var(--primary-contrast);
}

@media (max-width: 679px) {
  .topic-banner__arrow {
    order: 2;
    position: relative;
    right: 0;
  }
}

.ig-box {
  background-color: var(--secondary-main);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 576.001px) {
  .ig-box {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.ig-box__logo {
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  display: flex;
}

.ig-box__posts {
  padding-bottom: var(--spacing-32);
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 120px);
  min-height: 360px;
  display: grid;
}

@media (min-width: 576.001px) {
  .ig-box__posts {
    grid-template-rows: repeat(2, 120px);
    grid-template-columns: repeat(3, 1fr);
    min-height: 240px;
    padding-bottom: 0;
  }
}

.ig-logo {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.ig-logo__image {
  max-width: 120px;
  display: block;
}

.ig-post {
  width: 120px;
  height: 120px;
  display: block;
  overflow: hidden;
}

.ig-post__image {
  object-fit: cover;
  width: 100%;
  height: auto;
  display: block;
  transform: translate3d(0, 0, 1px);
}

.components-demo {
  justify-content: stretch;
  align-items: stretch;
  gap: var(--spacing-40);
  flex-direction: column;
  grid-column: lend / rend;
  display: flex;
}

@media (max-width: 679px) {
  .components-demo {
    grid-column: initial;
  }
}

.components-demo__section {
  justify-content: stretch;
  align-items: stretch;
  gap: var(--spacing-20);
  text-align: left;
  border: 1px solid var(--color-border-accent-light);
  padding: var(--spacing-20);
  flex-direction: column;
  display: flex;
}

@media (max-width: 679px) {
  .components-demo__section {
    text-align: center;
  }
}

.components-demo__item--inverted {
  background-color: var(--black);
  padding: var(--spacing-20);
  margin: 0 calc(var(--spacing-20) * -1);
  border-radius: var(--border-radius);
  display: block;
}

.components-demo__item--inverted > .typography {
  color: var(--white);
}

.components-demo__item--horizontal {
  gap: var(--spacing-12);
  display: flex;
}

.premium-header {
  text-align: center;
  padding-bottom: var(--spacing-24);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.premium-header__brand {
  margin-bottom: var(--spacing-32);
}

.premium-header__title {
  margin-bottom: var(--spacing-16);
}

.premium-header__link {
  color: var(--color-text-action-primary-main);
  text-decoration: none;
}

.premium-header__link:hover {
  text-decoration: underline;
}

.premium-offer {
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-24);
  width: 100%;
  margin: auto;
  display: flex;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .premium-offer {
    flex-flow: wrap;
    align-items: center;
  }
}

@media (max-width: 679px) {
  .premium-offer {
    flex-direction: column;
    align-items: center;
  }
}

.premium-extras {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-48);
  padding: var(--spacing-64) 0;
  flex-direction: column;
  display: flex;
}

@media (max-width: 679px) {
  .premium-extras {
    padding: var(--spacing-48) 0 var(--spacing-24) 0;
  }
}

.premium-extras__title {
  text-align: center;
}

.premium-extras__list {
  gap: var(--spacing-48);
  flex-direction: column;
  display: flex;
}

@media (min-width: 480.001px) {
  .premium-extras__list {
    flex-direction: row;
  }
}

.premium-extras__item {
  align-items: center;
  gap: var(--spacing-8);
  flex-direction: column;
  max-width: 124px;
  display: flex;
}

.premium-extras__icon {
  width: 32px;
  height: 32px;
}

.premium-extras__link {
  color: var(--color-text-default-body);
  text-align: center;
}

.premium-extras__link:hover {
  text-decoration: none;
}

.premium-print {
  background-color: var(--color-surface-default-subtle);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-48);
  margin-left: calc(var(--spacing-16) * -1);
  margin-right: calc(var(--spacing-16) * -1);
  padding: var(--spacing-64) var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.premium-print .badge-pill {
  border-radius: 6px;
}

@media (max-width: 679px) {
  .premium-print {
    padding: var(--spacing-40) var(--spacing-40);
  }
}

.premium-print__title {
  text-align: center;
}

.premium-print__info {
  gap: var(--spacing-16);
  flex-flow: column;
  align-items: flex-start;
  display: flex;
}

.premium-print__info > .badge-pill {
  text-transform: uppercase;
}

.premium-print__info > .premium-print__title {
  margin-bottom: var(--spacing-32);
}

.premium-print__info > .button {
  margin-top: var(--spacing-32);
}

@media (max-width: 679px) {
  .premium-print__info > .premium-print__title {
    text-align: left;
    margin-bottom: 0;
  }

  .premium-print__info > .button {
    margin-top: 0;
  }
}

.premium-print__content {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-24);
  flex-direction: column-reverse;
  width: 100%;
  max-width: 600px;
  display: flex;
}

@media (min-width: 900.001px) {
  .premium-print__content {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    max-width: 992px;
  }
}

.premium-print__posters {
  gap: var(--spacing-12);
  display: flex;
  position: relative;
}

.premium-print__poster {
  width: 200px;
  display: none;
}

.premium-print__poster:nth-of-type(-n+2) {
  display: block;
}

@media (max-width: 679px) {
  .premium-print__poster:nth-of-type(-n+3) {
    display: block;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .premium-print__poster {
    display: block;
  }
}

.premium-print__image {
  width: 100%;
  height: auto;
  display: block;
}

.premium-print__text {
  text-align: center;
  flex: 1;
}

@media (min-width: 900.001px) {
  .premium-print__text {
    text-align: left;
  }
}

.premium-print__link {
  color: var(--color-text-link-primary-default);
}

.premium-print__link:hover {
  text-decoration: none;
}

.premium-faq {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-32);
  padding: var(--spacing-32) 0;
  flex-direction: column;
  display: flex;
}

.premium-faq__title {
  color: var(--color-text-default-title);
}

.premium-faq__accordion {
  width: 100%;
  max-width: 820px;
}

.video-box {
  grid-column: 1 / 3;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .video-box {
    grid-column: 1 / 4;
  }
}

.video-box--type-box {
  padding: var(--spacing-24) 0;
  border-radius: 12px;
}

.video-box__header {
  padding: 0 var(--spacing-16);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16);
  flex-wrap: wrap;
  min-height: 40px;
  display: flex;
}

.video-box__header-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.video-box__header-btn {
  margin-left: auto;
}

.video-box--theme-dark {
  background-color: var(--color-surface-video-box-inverted-background);
}

.video-box--theme-dark .video-box__header-title {
  color: var(--color-text-video-box-dark-title);
}

.video-box--theme-light {
  background-color: var(--color-surface-video-box-normal-background);
}

.video-box--theme-light .video-box__header-title {
  color: var(--color-text-video-box-light-title);
}

.applications {
  gap: var(--spacing-48);
  flex-flow: column;
  grid-column: lend / rend;
  display: flex;
}

@media (max-width: 679px) {
  .applications {
    gap: var(--spacing-24);
  }
}

.applications__title {
  text-align: center;
  align-self: center;
}

.applications__content {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (max-width: 679px) {
  .applications__content {
    grid-template-columns: 1fr;
  }
}

.applications__info {
  gap: var(--spacing-48) 0;
  flex-flow: column;
  display: flex;
}

@media (max-width: 679px) {
  .applications__info {
    gap: var(--spacing-24);
  }

  .applications__info-title {
    text-align: center;
  }
}

.applications__list-heading {
  margin-bottom: -40px;
}

@media (max-width: 679px) {
  .applications__list-heading {
    margin-bottom: 0;
  }
}

.applications__downloads {
  gap: 0 var(--spacing-24);
  flex-flow: row;
  align-items: center;
  display: flex;
}

.applications__buttons {
  justify-content: center;
  gap: var(--spacing-12) 0;
  flex-flow: column;
  display: flex;
}

.applications__qr, .applications__qr-image {
  width: 114px;
  height: 114px;
}

.applications__hint {
  color: var(--color-text-default-meta);
  margin-top: var(--spacing-4);
  align-self: flex-start;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .applications__buttons {
    gap: var(--spacing-12);
    flex-direction: row;
  }

  .applications__qr, .applications__hint {
    display: none;
  }
}

@media (max-width: 679px) {
  .applications__buttons {
    gap: var(--spacing-12);
    flex-direction: column;
  }

  .applications__downloads {
    justify-content: center;
  }

  .applications__qr, .applications__hint {
    display: none;
  }
}

.applications__visual-wrapper {
  margin-top: -16px;
}

@media (max-width: 679px) {
  .applications__visual-wrapper {
    grid-row-start: 1;
  }
}

.applications__motive {
  max-width: 100%;
  height: auto;
}

.tipsport-table {
  width: 100%;
  overflow: hidden;
}

.tipsport-table__table {
  background-color: var(--color-surface-tipsport-table-content);
  border-radius: var(--spacing-12);
  width: 100%;
  position: relative;
  overflow: hidden;
}

@media (max-width: 679px) {
  .tipsport-table__table {
    margin: 0 calc(-1 * var(--spacing-16));
    width: calc(100% + 2 * var(--spacing-16));
    padding-bottom: var(--spacing-8);
    margin-bottom: calc(-1 * var(--spacing-8));
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #0000;
    border-radius: 0;
    display: block;
    overflow: auto hidden;
  }

  .tipsport-table__table::-webkit-scrollbar {
    display: none;
  }
}

.tipsport-box--no-padding .tipsport-table__table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (max-width: 679px) {
  .tipsport-table__in {
    margin: 0 var(--spacing-16);
    flex-flow: row;
    gap: 16px;
    display: flex;
  }

  .tipsport-table__in:after {
    content: "";
    width: var(--spacing-16);
    border: 1px solid #0000;
    margin-left: -1px;
  }
}

@media (min-width: 1080px), (min-width: 680px) and (max-width: 1079px) {
  .tipsport-table__row:not(:last-child) .tipsport-table__column {
    border-bottom: 1px solid var(--color-border-divider-default);
  }
}

@media (max-width: 679px) {
  .tipsport-table__row {
    justify-content: stretch;
    align-items: stretch;
    gap: var(--spacing-8);
    background-color: var(--color-surface-tipsport-table-content);
    border-radius: var(--spacing-8);
    padding: var(--spacing-16);
    flex-direction: column;
    flex: 0 0 48vw;
    display: flex;
  }

  .tipsport-box--no-padding .tipsport-table__row {
    border-radius: 0;
  }
}

.tipsport-table__column {
  padding: var(--spacing-12) var(--spacing-4);
  text-align: right;
  vertical-align: middle;
}

@media (max-width: 679px) {
  .tipsport-table__column {
    text-align: center;
    padding: 0;
  }
}

.tipsport-table__column--head {
  padding-left: var(--spacing-16);
  text-align: left;
}

@media (max-width: 679px) {
  .tipsport-table__column--head {
    text-align: center;
    margin-bottom: var(--spacing-4);
    padding: 0;
  }
}

.tipsport-table__column:last-of-type {
  padding-right: var(--spacing-16);
}

@media (max-width: 679px) {
  .tipsport-table__column:last-of-type {
    padding-right: 0;
    padding-top: var(--spacing-4);
  }
}

.tipsport-table__inline {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-4);
  flex-wrap: wrap;
  min-width: 82px;
  display: flex;
  position: relative;
}

.tipsport-table__inline-head {
  position: relative;
  top: -2px;
}

.tipsport-table__inline-button:not([href]) {
  pointer-events: none;
}

@media (max-width: 679px) {
  .tipsport-table__inline {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .tipsport-table__inline-head {
    position: absolute;
    top: 8px;
    left: 0;
  }
}

.tipsport-table__column--head .tipsport-table__inline {
  justify-content: flex-start;
  gap: var(--spacing-4) var(--spacing-12);
  color: var(--color-text-article-meta-normal-data);
  margin-bottom: var(--spacing-8);
}

@media (max-width: 679px) {
  .tipsport-table__column--head .tipsport-table__inline {
    text-align: center;
    flex-flow: wrap;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 679px) {
  .tipsport-table__column--head .tipsport-table__teams {
    text-align: center;
    justify-content: flex-start;
    align-items: stretch;
    gap: var(--spacing-4);
    flex-direction: column;
    display: flex;
  }
}

@media (max-width: 679px) {
  .tipsport-table__button {
    gap: var(--spacing-8);
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
    border-width: 2px;
    height: 40px;
    display: flex;
  }
}

@media (max-width: 679px) {
  .tipsport-table__button .button__text {
    font-size: var(--typography-mobile-button-md-font-size);
  }
}

@media (max-width: 679px) {
  .tipsport-table__separator {
    display: none;
  }
}

.tipsport-table__datetime {
  white-space: nowrap;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .header-menu-hamburger__icon {
    margin-right: var(--spacing-16);
  }

  .header-menu-hamburger {
    margin-left: var(--spacing-20);
  }

  .header-menu__item:not(:last-child) {
    margin-right: var(--spacing-24);
  }

  .header-search {
    margin-right: var(--spacing-20);
  }

  .megamenu-subcategories {
    margin-bottom: var(--spacing-32);
  }

  .megamenu-stickers__item {
    margin-right: var(--spacing-8);
    margin-bottom: var(--spacing-8);
  }

  .box__headline {
    margin-bottom: var(--spacing-32);
  }

  .article-detail__social-icon {
    margin-right: var(--spacing-12);
  }

  .tag-list li, .tag {
    margin-right: var(--spacing-8);
    margin-bottom: var(--spacing-8);
  }

  .megamenu-social__networks a:not(:last-child) {
    margin-right: var(--spacing-8);
  }

  .megamenu-applications__wrapper, .megamenu-applications__download-appstore {
    margin-right: var(--spacing-12);
  }

  .online-report__item .online-report__item-header, .online-report__item .online-report__content {
    margin: var(--spacing-16) 0;
  }

  .online-report__item .online-report__item-header .online-report__item-header-item {
    margin-right: var(--spacing-16);
  }

  .online-report__item .online-report__author-name {
    margin-bottom: var(--spacing-4);
  }
}

.gtm-highlight__track {
  outline-offset: -4px;
  outline: 4px solid red;
  padding: 3px;
}

.gtm-highlight__track--disabled {
  outline-offset: -2px;
  outline: 2px solid orange;
  padding: 3px;
}

.gtm-highlight__track-additional-info {
  color: #fff;
  z-index: 9999;
  opacity: .8;
  background-color: green;
  padding: 3px;
  font-size: .8em;
  position: absolute;
}

.gtm-highlight__track--green {
  outline-color: green;
}

.gtm-highlight__scroll-to {
  color: red;
  border: 4px solid red;
  padding: 3px;
}

.gtm-highlight__gallery {
  border: 4px solid red;
}

.gtm-highlight__track-source {
  color: #ff0;
}

.inner-wrapper {
  display: none;
}

.gradient-border {
  background: var(--gradient-2);
  z-index: -1;
  border-radius: 10px;
  width: 100%;
  height: 25px;
  position: absolute;
  top: -4px;
  left: 0;
}

.list-article-wrapper {
  gap: var(--spacing-16) 0;
  border-radius: 10px;
  flex-flow: column;
  grid-column: 1 / 3;
  width: 100%;
  position: relative;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .list-article-wrapper {
    grid-column: 1 / 4;
  }
}

.list-article-wrapper--single {
  margin-top: 54px;
}

@media (max-width: 679px) {
  .list-article-wrapper--single {
    margin-top: 42px;
  }
}

.list-article-wrapper--single .list-article-comments {
  display: flex;
}

.list-article-comments {
  background: var(--gradient-4);
  border-radius: 10px;
  width: 100%;
}

@media screen and (max-width: 679px) {
  .list-article-comments__next-link {
    padding: 0;
  }
}

.list-article-comments-header {
  padding: 0 var(--spacing-16);
  padding-top: var(--spacing-24);
  margin-bottom: var(--spacing-16);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.list-article-comments-header > a {
  background: var(--gradient-2);
  -webkit-text-fill-color: transparent;
  font-family: var(--typography-desktop-button-md-font-family);
  font-size: var(--typography-desktop-button-md-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: var(--typography-desktop-button-md-line-height);
  letter-spacing: var(--typography-desktop-button-md-letter-spacing);
  -webkit-background-clip: text;
  background-clip: text;
}

.list-article-comments-header > a > svg {
  margin-top: -2px;
}

.list-article-comments-header > a > svg > path {
  fill: url("#gradient-2");
}

@media (min-width: 680px) and (max-width: 1079px) {
  .list-article-comments-header {
    grid-column: 1 / 4;
  }
}

.list-article-comments-header__next-link {
  color: #6d6d6d;
  font: var(--font-desktop-button-small);
  text-decoration: none;
}

@media (max-width: 679px) {
  .list-article-comments-header__next-link {
    font: var(--font-mobile-button-small);
  }
}

.list-article-comments-header__next-link:hover {
  color: #4f4f4f;
  text-decoration: none;
}

.list-article-comments-header > h3 {
  color: var(--color-text-comments-box-title, #f8fafc);
}

.list-article-author {
  flex: 1;
}

.list-article-author .article-author {
  margin-top: var(--spacing-16);
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
  color: var(--text-primary);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.list-article-author .article-author:before {
  min-height: var(--spacing-68);
  background-color: #fff;
  width: 100%;
  display: block;
}

.list-article-author .article-author__content-wrapper {
  padding: var(--spacing-64) var(--spacing-24) var(--spacing-24);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-8);
  flex-flow: column;
  flex: 1;
  display: flex;
}

@media (max-width: 679px) {
  .list-article-author .article-author__content-wrapper {
    padding: var(--spacing-52) var(--spacing-16) var(--spacing-16);
  }
}

.list-article-author .article-author__image {
  z-index: 5;
  position: absolute;
  top: 0;
}

@media (max-width: 679px) {
  .list-article-author .article-author__image {
    width: var(--avatar-size-3xl);
    height: var(--avatar-size-3xl);
  }

  .list-article-author .article-author__image .avatar__icon {
    width: var(--avatar-icon-size-3xl);
    height: var(--avatar-icon-size-3xl);
  }
}

.list-article-author .article-author__title {
  text-align: center;
  color: var(--color-text-comments-card-body);
  gap: var(--spacing-16);
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-box-orient: vertical;
  flex-grow: 1;
  width: 100%;
  max-height: 5.6em;
  display: -webkit-box;
  overflow: hidden;
}

@media (max-width: 679px) {
  .list-article-author .article-author__title {
    gap: var(--spacing-8);
  }
}

.list-article-author .article-author__name {
  text-align: center;
}

.list-article-author .article-author__read-more {
  padding-top: var(--spacing-8);
  text-align: center;
  margin-top: auto;
}

.list-article-author .article-author__read-more svg {
  height: 1.3em;
}

.list-article-author .article-author__top-wrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 54px;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .list-article-author .article-author__top-wrapper {
    padding-top: 36px;
  }
}

.list-article-author--single {
  padding: 0 var(--spacing-8);
}

.list-article-author--single .article-author {
  margin-top: -54px;
}

@media (max-width: 679px) {
  .list-article-author--single .article-author {
    margin-top: -42px;
  }
}

.list-article-author--multi {
  padding: 0 0 var(--spacing-16) var(--spacing-16);
}

@media (max-width: 679px) {
  .list-article-author--multi .swiper__item {
    width: 224px;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .list-article-author--multi .swiper__item {
    flex: 1;
  }
}

.list-article-author--multi .article-author {
  margin-top: 0;
}

@media (max-width: 679px) {
  .list-article-author--multi .article-author {
    gap: var(--spacing-16);
  }
}

.list-article-author--multi .article-author__content-wrapper {
  background-color: var(--color-surface-comments-card-background);
  border-radius: var(--spacing-comments-card-radius);
}

@media (max-width: 679px) {
  .list-article-author--multi .article-author__title, .list-article-author--multi .article-author__read-more {
    text-align: left;
    align-self: flex-start;
  }
}

@media (max-width: 679px) {
  .list-article-author--multi .article-author__name {
    align-self: flex-start;
  }
}

@media (max-width: 679px) {
  .list-article-author--total-2 .swiper__item {
    flex: 1 0 auto;
    min-width: 224px;
  }
}

.comments {
  align-items: stretch;
  gap: var(--spacing-32);
  flex-direction: column;
  display: flex;
}

.comments__summary b, .comments__summary strong {
  font-family: var(--body-lg-bold-font-family);
}

.commentary-box {
  border-radius: var(--spacing-8);
  border: 1px solid var(--color-border-commentary-box-default);
  background: var(--color-surface-commentary-box-background);
  position: relative;
  overflow: hidden;
}

.commentary-box--goal {
  background: var(--gradient-2);
  border: none;
}

.commentary-box--goal:before {
  content: "";
  background: var(--color-surface-commentary-box-background);
  border-radius: calc(var(--spacing-8)  - 1px);
  display: block;
  position: absolute;
  inset: 0 1px 1px;
}

.commentary-box__header {
  min-height: var(--spacing-44);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16);
  padding: var(--spacing-8) var(--spacing-16);
  border-bottom: 1px solid var(--color-border-commentary-box-default);
  display: flex;
  position: relative;
}

.commentary-box--goal .commentary-box__header {
  background: var(--gradient-2);
  border-bottom: none;
}

.commentary-box__body {
  padding: var(--spacing-8) var(--spacing-16);
  position: relative;
}

.commentary-box__label {
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.commentary-box__label-text {
  color: var(--color-text-commentary-box-meta);
}

.commentary-box__status {
  padding: var(--spacing-8) var(--spacing-16);
  background: var(--color-surface-commentary-box-subheader);
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  margin: 0 1px;
  display: flex;
  position: relative;
}

.commentary-box__status-team {
  gap: var(--spacing-8);
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.commentary-box__status-team:last-of-type {
  flex-direction: row-reverse;
}

.commentary-box__status-score {
  text-align: center;
  flex-grow: 0;
}

.today-results {
  grid-column: lend / rend;
  position: relative;
  overflow: hidden;
}

.today-results__wrapper {
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: var(--spacing-16);
  cursor: grab;
  display: flex;
  overflow: hidden;
}

.today-results__wrapper.grabbing {
  cursor: grabbing;
  -webkit-user-select: none;
  user-select: none;
}

@media (max-width: 679px) {
  .today-results__wrapper {
    gap: var(--spacing-8);
    overflow: auto hidden;
  }
}

.today-results__wrapper + .tipsport-disclaimer {
  margin-top: var(--spacing-8);
}

.today-results__item {
  background: var(--color-surface-result-row-default);
  flex-shrink: 0;
  width: 285px;
}

.today-results__item:hover, .today-results__item--dark {
  background-color: var(--color-surface-result-row-hover);
}

.today-results::-webkit-scrollbar {
  display: none;
}

.today_results__btn-view-all {
  padding: var(--spacing-16);
  justify-content: center;
  align-items: center;
  display: flex;
}

.today-results__btn-slider {
  z-index: 1;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: calc(50% - 15px);
  transform: translateY(-50%);
}

.today-results__btn-slider svg {
  position: relative;
}

.today-results__btn-slider--left {
  left: var(--spacing-4);
  display: none;
}

.today-results__btn-slider--left:after {
  content: "";
  background: linear-gradient(90deg, #fff 0%, #fff0 94.79%);
  width: 48px;
  height: 168px;
  display: none;
  position: absolute;
  top: -56px;
  left: -4px;
}

.today-results__btn-slider--right {
  right: var(--spacing-4);
}

.today-results__btn-slider--right:after {
  content: "";
  background: linear-gradient(270deg, #fff 0%, #fff0 100%);
  width: 48px;
  height: 168px;
  display: none;
  position: absolute;
  top: -56px;
  right: -4px;
}

@media (max-width: 679px) {
  .today-results__btn-slider--right {
    display: none;
  }
}

.match-overview-header {
  border-top: 1px solid var(--color-border-title-section-default);
  background: var(--color-surface-title-section-normal);
  padding: var(--spacing-12) var(--spacing-16);
  flex-flow: column;
  display: flex;
}

.match-overview-header__title {
  font: var(--font-desktop-h4);
  color: var(--color-text-primary);
}

@media (max-width: 679px) {
  .match-overview-header__title {
    font: var(--font-mobile-h5);
  }
}

.match-overview-header__link, .match-overview-header__link:hover {
  color: var(--color-text-primary);
}

.preview-matches {
  grid-column: 1 / 3;
}

@media (min-width: 680px) and (max-width: 1079px) {
  .preview-matches {
    grid-column: 1 / 4;
  }
}

.match-overview {
  padding: var(--spacing-16) var(--spacing-16) var(--spacing-8);
  gap: 16px;
  display: flex;
}

@media (min-width: 680px) and (max-width: 1079px), (max-width: 679px) {
  .match-overview {
    padding: var(--spacing-12) var(--spacing-16);
    flex-flow: column;
  }
}

.match-overview--small {
  padding: var(--spacing-12) var(--spacing-16);
  flex-flow: column;
}

.match-overview--small .match-overview-meta {
  align-items: center;
  gap: var(--spacing-8);
  flex-flow: row;
  width: 100%;
  min-width: 105px;
}

.match-overview--small .match-overview__match-sport {
  text-transform: capitalize;
  text-overflow: ellipsis;
  order: 1;
  width: 125px;
  margin-right: auto;
  overflow: hidden;
}

.match-overview--small .match-overview-meta:has(.match-overview-meta__match-status--live) .match-overview__match-sport {
  width: 105px;
}

.match-overview--small .match-overview-meta__match-status {
  order: 2;
}

.match-overview--small .match-overview-meta__match-period--live {
  display: none;
}

.match-overview--small .match-overview-meta__match-period--fin {
  order: 3;
}

.match-overview--small .match-overview-meta__match-datetime {
  order: 4;
  margin-top: 0;
}

.match-overview--small .match-overview-team__name, .match-overview--small .match-overview-player__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.match-overview--small .match-overview__event-name {
  white-space: normal;
}

.match-overview--small .match-overview-player__name-container {
  gap: 10px;
}

.match-overview--small .match-overview-team__name {
  width: 190px;
}

.match-overview--small .match-overview-player__name {
  width: 100px;
}

.match-overview:hover {
  background-color: var(--color-surface-result-row-hover);
}

.match-overview__container {
  gap: var(--spacing-16);
  flex-flow: column;
  flex: 1;
  display: flex;
  position: relative;
}

.match-overview__row {
  display: flex;
}

.match-overview__row--space-between {
  justify-content: space-between;
  gap: var(--spacing-16);
}

.match-overview__row--offset {
  padding: 0 var(--spacing-16) var(--spacing-12);
}

.match-overview__match-link {
  color: inherit;
  text-decoration: none;
}

.match-overview__match-link:has(.match-overview--small) {
  color: var(--color-text-results-body);
}

:is(.match-overview--tennis:not(.match-overview--small):has(.match-overview-meta__match-status--live) .match-overview__container, .match-overview--tennis:not(.match-overview--small):has(.match-overview-meta__match-status--fin) .match-overview__container) {
  padding-top: var(--spacing-16);
}

:is(.match-overview--tennis:not(.match-overview--small):has(.match-overview-meta__match-status--live) .match-overview-meta, .match-overview--tennis:not(.match-overview--small):has(.match-overview-meta__match-status--fin) .match-overview-meta) {
  margin-top: var(--spacing-2);
}

.match-overview--tennis.match-overview--small {
  gap: var(--spacing-26);
}

.match-overview--tennis .match-overview-set-value {
  font: var(--font-desktop-body2);
  color: var(--color-success-main);
  text-align: center;
  width: 20px;
  display: none;
  position: relative;
}

.match-overview--tennis .match-overview-set-value.active-set {
  display: block;
}

.match-overview--tennis .match-overview-set-value sup {
  top: calc(-1 * var(--spacing-6));
  right: calc(-1 * var(--spacing-2));
  font: var(--font-desktop-helper);
  white-space: nowrap;
  position: absolute;
}

.match-overview--tennis .match-overview-player-home .match-overview-set-value {
  display: none;
}

.match-overview--tennis .match-overview-player-home .match-overview-set-value.active-set {
  display: block;
}

.match-overview--tennis .match-overview-player-home .match-overview-set-value.active-set:before {
  top: calc(-1 * var(--spacing-18));
  text-align: center;
  color: var(--color-text-results-meta);
  font: var(--font-desktop-helper);
  content: attr(data-match-overview-meta-info);
  width: 100%;
  display: block;
  position: absolute;
}

.match-overview--tennis .match-overview-player-home .live-score:before, .match-overview--tennis .match-overview-player-home .fin-score:before {
  top: calc(-1 * var(--spacing-22));
  text-align: center;
  color: var(--color-text-results-meta);
  font: var(--font-desktop-meta-bold);
  content: attr(data-match-overview-meta-info);
  width: 100%;
  display: block;
  position: absolute;
}

.match-overview__row:empty {
  display: none;
}

.match-overview__row--footer {
  padding: 0 var(--spacing-16) var(--spacing-8);
}

@media (min-width: 680px) and (max-width: 1079px), (max-width: 679px) {
  .match-overview__row--footer {
    padding: 0 var(--spacing-16) var(--spacing-12);
  }
}

.match-overview__row--footer:has(:not(.match-overview-match-bet)) .match-overview__match-link {
  margin-left: auto;
}

.match-overview__players-container, .match-overview__teams-container {
  gap: var(--spacing-8);
  flex-flow: column;
  display: flex;
}

.match-overview-player__name-container, .match-overview-team__name-container {
  justify-content: space-between;
  gap: var(--spacing-16);
  display: flex;
}

.match-overview-player__name-logo, .match-overview-team__name-logo {
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.match-overview-player__logo, .match-overview-team__logo {
  width: 25px;
  height: 25px;
}

.match-overview-player__name, .match-overview-team__name {
  font: var(--font-desktop-body2);
  color: var(--color-text-primary);
}

.match-overview-player__name--looser, .match-overview-team__name--looser {
  color: #828282;
}

.match-overview__event {
  gap: var(--spacing-16);
  font: var(--font-desktop-body2);
  flex-flow: column;
  display: flex;
}

.match-overview__event-row {
  gap: var(--spacing-8);
  flex-flow: column;
  display: flex;
}

.match-overview__event-place {
  gap: var(--spacing-8);
  align-items: center;
  display: flex;
}

.match-overview__event-result {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.match-overview__event-result--winner {
  font: var(--font-desktop-body2-bold);
  color: var(--color-text-results-body);
}

.match-overview__event-round {
  font: var(--font-desktop-body2);
  white-space: normal;
}

.match-overview-score--winner {
  font: var(--font-desktop-body2-bold);
}

.match-overview-set-container, .match-overview-score-set-container, .match-overview-score-result-container {
  align-items: center;
  display: flex;
}

.match-overview-score-result-container {
  gap: var(--spacing-8);
}

.match-overview-score-name {
  top: calc(-1 * var(--spacing-22));
  text-align: right;
  color: var(--color-text-results-meta);
  width: 100%;
  position: absolute;
}

.match-overview-score {
  align-items: center;
  gap: var(--spacing-8);
  font: var(--font-desktop-body2);
  display: flex;
  position: relative;
}

.match-overview-score .live-score {
  color: var(--color-text-results-result);
  transition: transform .3s ease-in-out;
  position: relative;
}

.match-overview-score .fin-score {
  position: relative;
}

.match-overview-score .zoom-effect {
  transition: transform .3s ease-in-out;
  transform: scale(1.3);
}

.match-overview-list {
  flex-flow: column;
  flex: 1;
  display: flex;
}

.match-overview-list:hover {
  background-color: var(--color-surface-result-row-hover);
}

.match-overview-list__footer {
  display: flex;
}

.match-overview-list__btn-read-online {
  margin-left: auto;
}

.match-overview-meta {
  gap: var(--spacing-8);
  color: var(--color-text-secondary);
  font: var(--font-mobile-helper-text);
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.match-overview-meta__match-datetime {
  color: var(--color-text-results-meta);
  font-size: 13px;
  line-height: 13px;
}

.match-overview-meta__match-status .match-overview-meta__match-status-label, .match-overview-meta__match-status .match-overview-meta__match-status-live-icon {
  display: none;
}

.match-overview-meta__match-status.match-overview-meta__match-status--live {
  align-items: center;
  gap: var(--spacing-4);
  color: var(--color-text-results-live);
  display: flex;
}

.match-overview-meta__match-status.match-overview-meta__match-status--live .match-overview-meta__match-status-label {
  line-height: 1;
  display: inline-block;
}

.match-overview-meta__match-status.match-overview-meta__match-status--live .match-overview-meta__match-status-live-icon {
  background-color: var(--color-surface-badge-success);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: 1.5s infinite pulse;
  display: inline-block;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

.match-overview-meta__match-period {
  transition: transform .3s ease-in-out;
}

.match-overview-meta__match-period.zoom-effect {
  transition: transform .3s ease-in-out;
  transform: scale(1.1);
}

.match-overview-meta__match-period--live {
  font: var(--font-desktop-body2);
  color: var(--color-text-results-result);
}

@media (max-width: 679px) {
  .match-overview-meta__match-period--live {
    font: var(--font-mobile-body2);
  }
}

.match-overview-meta__match-period--fin {
  font: var(--font-desktop-body2-bold);
  color: var(--color-text-results-meta);
}

@media (max-width: 679px) {
  .match-overview-meta__match-period--fin {
    font: var(--font-mobile-body2-bold);
  }
}

.match-overview__badge-status {
  border-radius: var(--spacing-badge-pilled);
  text-transform: uppercase;
}

@media (max-width: 679px) {
  .match-overview__badge-status.hide-on-mobile {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1079px) {
  .match-overview__badge-status.hide-on-tablet {
    display: none;
  }
}

.match-overview-meta__match-status--postponed, .match-overview-meta__match-status--cancel {
  align-items: center;
  gap: var(--spacing-4);
  padding: var(--spacing-2) var(--spacing-8);
  border-radius: var(--spacing-2);
  display: flex;
}

.match-overview-meta__match-status--postponed {
  color: var(--color-action-disabled);
  background-color: var(--action-disabled-background);
}

.match-overview-meta__match-status--cancel {
  color: var(--color-error-main);
  background-color: var(--color-primary-states-outlined-hover-background-light);
}

.matches-overview-with-table {
  flex-flow: column;
  display: flex;
}

.matches-overview-with-table:has(.table-result) {
  gap: var(--spacing-30);
}

.match-overview-meta__series-score {
  font: var(--font-desktop-meta-regular);
  color: var(--color-text-results-meta);
}

.match-oveview-video-list__header {
  gap: var(--spacing-8);
  font: var(--font-desktop-body2);
  padding: var(--spacing-8) var(--spacing-16);
  border-bottom: 1px solid var(--other-divider);
  background-color: var(--action-hover);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.match-oveview-video-list__title {
  gap: var(--spacing-8);
  align-items: center;
  display: flex;
}

.match-oveview-video-list__video-count {
  font: var(--font-desktop-body2-bold);
}

.match-oveview-video-list__dropdown {
  align-items: center;
  gap: var(--spacing-4);
  font: var(--font-desktop-button-small);
  color: var(--color-primary-main);
  background: none;
  border: none;
  transition: rotate .3s;
  display: flex;
}

.match-oveview-video-list__dropdown--open svg {
  transform: rotate(180deg);
}

.match-oveview-video-list__content {
  width: 100%;
  display: none;
  position: relative;
}

.match-oveview-video-list__content--open {
  display: grid;
}

.match-oveview-video-list__btn-slider {
  background-color: var(--grey-900);
  opacity: .7;
  z-index: 1;
  border: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  top: 60px;
}

.match-oveview-video-list__btn-slider:hover {
  opacity: 1;
}

.match-oveview-video-list__btn-slider svg {
  position: relative;
}

.match-oveview-video-list__btn-slider--left {
  left: var(--spacing-4);
  display: none;
}

.match-oveview-video-list__btn-slider--left svg {
  transform: rotate(180deg);
}

.match-oveview-video-list__btn-slider--right {
  right: var(--spacing-4);
}

@media (max-width: 679px) {
  .match-oveview-video-list__btn-slider {
    display: none !important;
  }
}

.match-oveview-video-list__articles {
  column-gap: var(--spacing-24);
  padding: var(--spacing-16);
  -ms-overflow-style: none;
  scrollbar-width: none;
  grid-auto-flow: column;
  display: grid;
  position: relative;
  overflow: hidden;
}

@media (max-width: 679px) {
  .match-oveview-video-list__articles {
    column-gap: var(--spacing-16);
    overflow: auto hidden;
  }
}

.match-oveview-video-list__articles .article-card {
  width: 240px;
}

@media (max-width: 679px) {
  .match-oveview-video-list__articles .article-card {
    width: 210px;
  }
}

.match-overview-match-bet {
  gap: var(--spacing-8);
  margin-left: calc(-1 * var(--spacing-4));
  display: flex;
}

.match-overview-match-bet__button {
  transition: transform .3s ease-in-out;
}

.match-overview-match-bet__button .zoom-effect {
  transition: transform .3s ease-in-out;
  transform: scale(1.1);
}

.match-overview-match-bet__link {
  padding: var(--spacing-8);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);
  font: var(--font-mobile-button-small);
  border-radius: var(--spacing-4);
  background-color: var(--tipsport-Blue02);
  color: var(--text-text-contrast);
  flex-basis: auto;
  width: 74px;
  height: 32px;
  display: flex;
}

.match-overview-match-bet__link:hover {
  color: var(--text-text-contrast);
  text-decoration: none;
}

.match-overview-tipsport-tv-link {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);
  padding: var(--spacing-8) 0;
  font: var(--font-mobile-button-small);
  color: var(--tipsport-Blue02);
  width: 70px;
  height: 32px;
  display: flex;
}

.match-overview-tipsport-tv-link:hover {
  color: var(--tipsport-Blue02);
  text-decoration: none;
}

.match-overview-phase-dropdown {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.match-overview-phase-dropdown__toggle-button {
  color: var(--color-text-dropdown-default);
  cursor: pointer;
  white-space: nowrap;
  font: var(--font-desktop-body2-bold);
  background-color: #0000;
  border: none;
  outline: none;
  position: relative;
}

@media (max-width: 679px) {
  .match-overview-phase-dropdown__toggle-button {
    font: var(--font-mobile-body2-bold);
  }
}

.match-overview-phase-dropdown__toggle-button-spinner {
  left: calc(-1 * var(--spacing-20));
  position: absolute;
}

.match-overview-phase-dropdown__list {
  z-index: 10;
  gap: var(--spacing-8);
  border-radius: var(--spacing-4);
  background: var(--color-surface-dropdown-default);
  box-shadow: var(--shadow-m);
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.match-overview-phase-dropdown__list--one-column {
  grid-template-columns: 1fr;
}

.match-overview-phase-dropdown__list--two-columns {
  grid-template-columns: repeat(2, 1fr);
}

.match-overview-phase-dropdown__list--three-columns {
  grid-template-columns: repeat(3, 1fr);
}

.match-overview-phase-dropdown__list--active {
  display: grid;
}

.match-overview-phase-dropdown__list-item {
  padding: var(--spacing-8) var(--spacing-16);
  width: 100%;
}

.match-overview-phase-dropdown__list-item:hover {
  background-color: var(--color-surface-dropdown-hover);
}

.match-overview-phase-dropdown__list-item:focus {
  border: var(--spacing-2) solid var(--color-border-dropdown-focus);
  background: var(--color-surface-dropdown-focus);
}

.match-overview-phase-dropdown__list-item--active {
  background-color: var(--color-surface-dropdown-active);
}

.match-overview-phase-dropdown__list-item-button {
  color: var(--color-text-dropdown-default);
  cursor: pointer;
  white-space: nowrap;
  font: var(--font-desktop-body2);
  background-color: #0000;
  border: none;
  outline: none;
  width: 100%;
}

@media (max-width: 679px) {
  .match-overview-phase-dropdown__list-item-button {
    font: var(--font-mobile-body2);
  }
}

.match-overview-phase-dropdown__list-item-button--active {
  font: var(--font-desktop-body2-bold);
}

@media (max-width: 679px) {
  .match-overview-phase-dropdown__list-item-button--active {
    font: var(--font-mobile-body2-bold);
  }
}

.match-statistics__header {
  padding: var(--spacing-12) var(--spacing-16);
  background: var(--color-surface-title-section-inverted);
  color: var(--color-text-title-section-inverted-title);
  gap: var(--spacing-8);
  align-items: center;
  display: flex;
}

.match-statistics__header svg * {
  fill: var(--color-text-title-section-inverted-title);
}

.match-statistics__title {
  color: var(--color-text-title-section-inverted-title);
}

.match-statistics__body {
  gap: var(--spacing-16);
  padding: var(--spacing-24) var(--spacing-16);
  flex-flow: column wrap;
  display: flex;
}

@media screen and (max-width: 679px) {
  .match-statistics__body {
    padding: var(--spacing-12) 0;
  }
}

.match-statistics__row {
  gap: var(--spacing-8);
  flex-flow: wrap;
  display: flex;
  position: relative;
}

.match-statistics__left {
  flex: 0 0 calc(50% - var(--spacing-8) / 2);
  text-align: left;
  order: 1;
}

.match-statistics__right {
  flex: 0 0 calc(50% - var(--spacing-8) / 2);
  text-align: right;
  order: 2;
}

.match-statistics__center {
  text-align: center;
  order: 0;
  width: 100%;
}

.match-statistics__graph {
  border-radius: var(--spacing-4);
  height: var(--spacing-8);
  background-color: var(--color-surface-stats-background);
  justify-content: flex-end;
  display: flex;
  overflow: hidden;
}

.match-statistics__right .match-statistics__graph {
  justify-content: flex-start;
}

.match-statistics__graph-value {
  bottom: var(--spacing-8);
  margin-bottom: var(--spacing-8);
  color: var(--color-text-stats-meta);
  position: absolute;
}

.match-statistics__left .match-statistics__graph-value {
  left: 0;
}

.match-statistics__right .match-statistics__graph-value {
  text-align: right;
  right: 0;
}

.match-statistics__graph-indicator {
  height: var(--spacing-8);
  background: var(--color-surface-stats-worse);
}

.match-statistics__graph-indicator--is-better {
  background: var(--color-surface-stats-better);
}

.detail-match-overview {
  padding: 0 var(--spacing-16) var(--spacing-24) var(--spacing-16);
  flex-direction: column;
  align-items: stretch;
  max-width: 640px;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 679px) {
  .detail-match-overview {
    margin-left: calc(-1 * var(--spacing-16));
    margin-right: calc(-1 * var(--spacing-16));
  }
}

@media (min-width: 680px) and (max-width: 1079px), (max-width: 679px) {
  .detail-match-overview {
    padding: 0 var(--spacing-16) var(--spacing-16) var(--spacing-16);
  }
}

@media (max-width: 679px) {
  .detail-match-overview--teams {
    gap: var(--spacing-8);
    padding-top: var(--spacing-16);
  }
}

.detail-match-overview--tennis, .detail-match-overview--other {
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex-direction: row;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis, .detail-match-overview--other {
    flex-direction: column;
    align-items: stretch;
  }
}

.detail-match-overview__container {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.detail-match-overview__row {
  justify-content: center;
  gap: var(--spacing-16);
  flex-direction: row;
  display: flex;
}

.detail-match-overview__teams-container {
  justify-content: center;
  gap: var(--spacing-16);
  text-align: center;
  flex-direction: row;
  display: flex;
}

.detail-match-overview__players-container {
  gap: var(--spacing-16);
  flex-flow: column;
  flex: 1;
  display: flex;
}

.detail-match-overview__players-container--has-sets {
  margin-top: var(--spacing-24);
}

.detail-match-overview__score-container {
  display: block;
}

@media (min-width: 680px) and (max-width: 1079px), (min-width: 1080px) {
  .detail-match-overview--other .detail-match-overview__meta-container, .detail-match-overview--teams .detail-match-overview__meta-container {
    flex: 0 160px;
  }
}

.detail-match-overview--other .detail-match-overview__meta-container {
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

@media (min-width: 680px) and (max-width: 1079px), (min-width: 1080px) {
  .detail-match-overview--other .detail-match-overview__meta-container {
    padding-top: var(--spacing-4);
  }
}

@media (min-width: 680px) and (max-width: 1079px), (min-width: 1080px) {
  .detail-match-overview--tennis .detail-match-overview__meta-container {
    flex: 0 160px;
  }
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview__meta-container {
    gap: var(--spacing-16);
    flex-direction: column;
    display: flex;
  }
}

.detail-match-overview__event {
  gap: var(--spacing-24);
  flex-direction: column;
  flex: 1;
  display: flex;
}

@media (max-width: 679px) {
  .detail-match-overview__event {
    gap: var(--spacing-20);
  }
}

.detail-match-overview__event-row {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.detail-match-overview__event-result, .detail-match-overview__event-place {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
  flex-direction: row;
  display: flex;
}

.detail-match-overview__event-result--winner {
  font: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  color: var(--color-text-results-body);
  gap: 0;
}

@media (max-width: 679px) {
  .detail-match-overview__event-result--winner {
    font: var(--font-mobile-body2-bold);
  }
}

.detail-match-overview--tennis .live-score {
  color: var(--color-text-top-event-result);
}

.detail-match-overview--tennis .detail-match-overview-set-container, .detail-match-overview--tennis .detail-match-overview-score-set-container, .detail-match-overview--tennis .detail-match-overview-score-result-container {
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-set-container, .detail-match-overview--tennis .detail-match-overview-score-set-container, .detail-match-overview--tennis .detail-match-overview-score-result-container {
    gap: var(--spacing-4);
  }
}

.detail-match-overview--tennis .detail-match-overview-set-container--live .active-live-set {
  color: var(--color-text-top-event-result);
}

.detail-match-overview--tennis .detail-match-overview-set-container--live .active-live-set.zoom-effect {
  transition: transform .3s ease-in-out;
  transform: scale(1.1);
}

.detail-match-overview--tennis .detail-match-overview-set, .detail-match-overview--tennis .detail-match-overview-score {
  min-width: var(--spacing-32);
  justify-content: center;
  position: relative;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-set, .detail-match-overview--tennis .detail-match-overview-score {
    min-width: var(--spacing-18);
  }
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-set .typography, .detail-match-overview--tennis .detail-match-overview-score .typography {
    min-width: var(--spacing-18);
    font-size: var(--typography-mobile-body-md-font-size);
    line-height: var(--typography-mobile-body-md-line-height);
  }
}

.detail-match-overview--tennis .detail-match-overview-set-value {
  text-align: center;
  font: var(--font-desktop-subtitle2);
  color: var(--color-success-main);
  width: 20px;
  display: none;
  position: relative;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-set-value .typography {
    font-size: var(--typography-mobile-body-md-font-size);
    line-height: var(--typography-mobile-body-md-line-height);
  }
}

.detail-match-overview--tennis .detail-match-overview-set-value.active-set {
  display: block;
}

.detail-match-overview--tennis .detail-match-overview-set-value sup {
  top: calc(-1 * var(--spacing-2));
  right: calc(-1 * var(--spacing-12));
  font: var(--font-desktop-helper);
  text-align: left;
  white-space: nowrap;
  width: 15px;
  position: absolute;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-set-value sup {
    right: calc(-1 * var(--spacing-10));
    top: 2px;
  }
}

.detail-match-overview--tennis .detail-match-overview-score__value {
  min-width: var(--spacing-32);
  display: none;
}

.detail-match-overview--tennis .detail-match-overview-score__value.active-set {
  display: block;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-score__value {
    min-width: var(--spacing-18);
  }
}

.detail-match-overview--tennis .detail-match-overview-score__name {
  width: var(--spacing-32);
  left: auto;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-score__name {
    width: var(--spacing-24);
  }
}

.detail-match-overview--tennis .detail-match-overview-player-home .detail-match-overview-set-value:before {
  top: calc(-1 * var(--spacing-22));
  font: var(--font-desktop-helper);
  color: var(--color-text-results-meta);
  text-align: center;
  content: attr(data-match-overview-meta-info);
  width: 100%;
  display: block;
  position: absolute;
}

.detail-match-overview--tennis .detail-match-overview-player-home .live-score:before, .detail-match-overview--tennis .detail-match-overview-player-home .fin-score:before {
  top: calc(-1 * var(--spacing-22));
  width: var(--spacing-10);
  font: var(--font-desktop-helper);
  color: var(--color-text-results-meta);
  text-align: center;
  content: attr(data-match-overview-meta-info);
  display: block;
  position: absolute;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-player-home .live-score:before, .detail-match-overview--tennis .detail-match-overview-player-home .fin-score:before {
    top: calc(-1 * var(--spacing-26));
  }
}

.detail-match-overview-team {
  flex: 0 320px;
}

.detail-match-overview-team--home {
  order: 1;
  animation: .3s ease-out both fade-in-left;
}

.detail-match-overview-team--away {
  order: 3;
  animation: .3s ease-out both fade-in-right;
}

@media (max-width: 679px) {
  .detail-match-overview-team__avatar {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 679px) {
  .detail-match-overview-team__flag {
    width: 40px;
  }
}

@media (max-width: 679px) {
  .detail-match-overview-team__name .typography {
    font-size: var(--typography-mobile-body-md-font-size);
    line-height: var(--typography-mobile-body-md-line-height);
  }
}

.detail-match-overview-team__name-container {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16);
  flex-direction: row;
  max-width: 320px;
  display: flex;
}

.detail-match-overview-team__name-logo {
  gap: var(--spacing-8);
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.detail-match-overview-player__name-container {
  justify-content: space-between;
  gap: var(--spacing-32);
  display: flex;
}

.detail-match-overview-player__name-logo {
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

@media (max-width: 679px) {
  .detail-match-overview-player__name .typography {
    font-size: var(--typography-mobile-body-md-font-size);
    line-height: var(--typography-mobile-body-md-line-height);
  }
}

@media (max-width: 679px) {
  .detail-match-overview-player__avatar {
    width: 28px;
    height: 28px;
  }
}

.detail-match-overview-score {
  display: flex;
}

.detail-match-overview-score .zoom-effect {
  transition: transform .3s ease-in-out;
  transform: scale(1.3);
}

.detail-match-overview--teams .detail-match-overview-score {
  padding-top: var(--spacing-4);
  gap: var(--spacing-4);
  flex-direction: column;
  flex: 1 0 auto;
  order: 2;
}

@media (max-width: 679px) {
  .detail-match-overview--teams .detail-match-overview-score {
    padding-top: var(--spacing-12);
    justify-content: center;
    align-items: center;
  }
}

.detail-match-overview--tennis .detail-match-overview-score {
  align-items: center;
  gap: var(--spacing-8);
  position: relative;
}

.detail-match-overview-score__details-period.live-score, .detail-match-overview-score__value.live-score {
  color: var(--color-text-top-event-result);
}

.detail-match-overview--teams .detail-match-overview-score__value {
  animation: .5s cubic-bezier(.68, -.55, .265, 1.55) .5s both bubble;
}

.detail-match-overview--teams .detail-match-overview-score__value.zoom-effect {
  transition: transform .3s ease-in-out;
  animation: none;
  transform: scale(1.3);
}

.detail-match-overview-meta {
  gap: var(--spacing-2);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.detail-match-overview--teams .detail-match-overview-meta {
  align-items: center;
  max-width: 280px;
  margin: 0 auto;
}

.detail-match-overview--tennis .detail-match-overview-meta, .detail-match-overview--other .detail-match-overview-meta {
  gap: var(--spacing-12);
}

.detail-match-overview-meta__score-details-period, .detail-match-overview-meta__score-details-period:before, .detail-match-overview-meta__score-details-period:after {
  font: var(--font-desktop-meta-regular);
  color: var(--color-text-top-event-meta);
}

.detail-match-overview-meta__score-details-period:empty {
  display: none;
}

.detail-match-overview-meta__score-details-period:before {
  content: "(";
}

.detail-match-overview-meta__score-details-period:after {
  content: ")";
}

@media (max-width: 679px) {
  .detail-match-overview-meta__score-details-period {
    font: var(--font-mobile-meta-regular);
  }
}

.detail-match-overview-meta--top {
  animation: .3s ease-in .15s both fade-in;
}

.detail-match-overview--teams .detail-match-overview-meta--top {
  margin-bottom: var(--spacing-4);
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-meta--top, .detail-match-overview--other .detail-match-overview-meta--top {
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--spacing-8);
    flex-flow: wrap;
    flex: 1;
    display: flex;
  }
}

@media (max-width: 679px) {
  .detail-match-overview--other .detail-match-overview-meta--top {
    justify-content: flex-start;
    align-items: center;
  }
}

.detail-match-overview-meta--bottom {
  gap: var(--spacing-4);
  flex-direction: column;
  align-items: center;
  animation: .3s ease-in .8s both fade-in;
  display: none;
}

@media (min-width: 1080px), (min-width: 680px) and (max-width: 1079px) {
  .detail-match-overview--teams .detail-match-overview-meta--bottom {
    margin-top: calc(-1 * var(--spacing-48));
    min-height: var(--spacing-48);
  }
}

.detail-match-overview--tennis .detail-match-overview-meta--bottom {
  align-items: flex-start;
}

@media (max-width: 679px) {
  .detail-match-overview--tennis .detail-match-overview-meta--bottom {
    max-width: 120px;
  }
}

@media (max-width: 679px) {
  .detail-match-overview--other .detail-match-overview-meta--bottom {
    gap: var(--spacing-8);
    flex-flow: wrap;
    justify-content: flex-start;
  }
}

.detail-match-overview-meta--bottom__match-period {
  color: var(--color-text-top-event-meta);
}

.detail-match-overview-meta--bottom:empty {
  display: none;
}

.detail-match-overview-meta--bottom:has(.detail-match-overview-meta__score-details:not(:empty), .match-overview-meta__match-period:not(:empty)) {
  display: flex;
}

.detail-match-overview-meta__match-datetime {
  color: var(--color-text-results-meta);
  font-size: 13px;
  line-height: 13px;
}

.detail-match-overview-meta__match-status--live {
  align-items: center;
  gap: var(--spacing-4);
  color: var(--color-text-results-live);
  display: flex;
}

.detail-match-overview-meta__match-status-live-icon {
  background-color: var(--color-surface-badge-success);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: 1.5s infinite pulse;
  display: inline-block;
}

.detail-match-overview-meta__match-status--sched {
  color: var(--color-text-top-event-info);
}

.detail-match-overview-meta__match-status--fin {
  color: var(--color-text-top-event-meta);
}

.detail-match-overview-meta__match-period {
  color: var(--color-text-results-result);
  transition: transform .3s ease-in-out;
}

.detail-match-overview-meta__match-period.zoom-effect {
  transition: transform .3s ease-in-out;
  transform: scale(1.1);
}

.detail-match-overview-meta__match-period--live {
  font: var(--font-desktop-body2);
  color: var(--color-text-results-live);
}

@media (max-width: 679px) {
  .detail-match-overview-meta__match-period--live {
    font: var(--font-mobile-body2);
  }
}

.detail-match-overview-meta__match-period--fin {
  font: var(--font-desktop-body2-bold);
  color: var(--color-text-results-meta);
}

@media (max-width: 679px) {
  .detail-match-overview-meta__match-period--fin {
    font: var(--font-mobile-body2-bold);
  }
}

@keyframes bubble {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  20% {
    opacity: .5;
    transform: scale(1.25);
  }

  40% {
    opacity: .7;
    transform: scale(.95);
  }

  60% {
    opacity: .8;
    transform: scale(1.05);
  }

  75% {
    opacity: .9;
    transform: scale(.975);
  }

  85% {
    opacity: .95;
    transform: scale(1.015);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.detail-match-overview-bet {
  background-color: var(--color-surface-tipsport-bet-background);
  justify-content: center;
  display: flex;
}

.detail-match-overview-bet__in {
  padding: var(--spacing-16);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-8) var(--spacing-32);
  flex: 0 auto;
  display: flex;
}

@media (max-width: 679px) {
  .detail-match-overview-bet__in {
    flex-direction: column;
  }
}

.detail-match-overview-bet__links {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-4) var(--spacing-8);
  flex-wrap: wrap;
  flex: 0 auto;
  display: flex;
}

@media (max-width: 679px) {
  .detail-match-overview-bet__links {
    justify-content: center;
  }
}

.detail-match-overview-bet__courses {
  gap: var(--spacing-16) var(--spacing-40);
  flex-wrap: wrap;
  flex: 1 0 auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.detail-match-overview-bet__course {
  gap: var(--spacing-4);
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 680px) and (max-width: 1079px), (max-width: 679px) {
  .detail-match-overview-bet__button {
    height: var(--spacing-36);
  }
}

.detail-match-overview-bet__button .button__text {
  color: var(--color-text-tipsport-bet-text);
  line-height: 1.2;
}

.detail-match-overview-bet__stream {
  margin-top: var(--spacing-8);
}

@media (min-width: 680px) and (max-width: 1079px) {
  .detail-match-overview-bet__stream {
    margin-top: var(--spacing-24);
  }
}

.detail-match-overview-bet__statistics {
  margin-top: var(--spacing-24);
  display: none;
}

.detail-match-overview-bet__statistics--active {
  display: block;
}

.table-result {
  width: 100%;
}

.table-result + .table-result, .table-result + .table-result__title {
  margin-top: var(--spacing-30);
}

.table-result__title {
  margin-bottom: var(--spacing-8);
  display: block;
}

.table-result__table {
  border-collapse: collapse;
  width: 100%;
}

.table-result__table--small .table-result__row, .table-result__table--small .table-result__legend-item {
  gap: var(--spacing-6);
  padding: var(--spacing-8);
}

.table-result__table--small .table-result__column--team-name {
  gap: var(--spacing-6);
}

.table-result__table--small .table-result__column--hide-mobile {
  display: none;
}

.table-result__row {
  border-bottom: 1px solid var(--color-border-result-cells-default);
}

.table-result__row--header {
  background: var(--color-surface-result-cells-subheader);
}

.table-result__row, .table-result .table-result__legend-item {
  gap: var(--spacing-12);
  padding: var(--spacing-8) var(--spacing-16);
  display: flex;
}

.table-result__row .rank, .table-result .table-result__legend-item .rank {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.table-result__row--primary .rank, .table-result .table-result__legend-item--primary .rank {
  background-color: var(--color-surface-rank-circle);
  color: var(--color-text-rank-circle);
  border-radius: 50%;
}

.table-result__row--secondary .rank, .table-result .table-result__legend-item--secondary .rank {
  color: var(--color-text-rank-square);
  background-color: var(--color-surface-rank-square);
}

.table-result__row--tertiary .rank, .table-result .table-result__legend-item--tertiary .rank {
  color: var(--color-text-rank-hexagon);
  background-color: var(--color-surface-rank-hexagon);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.table-result__row--quaternary .rank, .table-result .table-result__legend-item--quaternary .rank {
  color: var(--color-text-rank-square);
  background-color: var(--poggers-tangaroa-100);
}

.table-result__row--quinary .rank, .table-result .table-result__legend-item--quinary .rank {
  color: var(--color-text-rank-square);
  background-color: var(--color-red-100);
}

.table-result__column {
  align-items: center;
  padding: 0;
  display: flex;
}

.table-result__column--team-name {
  align-items: center;
  gap: var(--spacing-12);
  flex: 1;
  min-width: 106px;
  display: flex;
}

.table-result__column--rank, .table-result__column--matches, .table-result__column--winnings, .table-result__column--winnings-in-extension, .table-result__column--ties, .table-result__column--losses, .table-result__column--losses-in-extension, .table-result__column--points {
  flex: 0 0 18px;
  justify-content: center;
}

.table-result__column--score {
  flex: 0 0 53px;
  justify-content: center;
}

.table-result td.table-result__column--points {
  color: var(--color-text-result-cells-points);
}

.table-result__team-logo {
  flex-shrink: 0;
}

.table-result__legend {
  display: flex;
}

@media (max-width: 679px) {
  .table-result__legend {
    margin-top: var(--spacing-4);
    flex-flow: column;
  }
}

.table-result .table-result__legend-item {
  align-items: center;
  gap: var(--spacing-12);
  padding-top: var(--spacing-12);
  display: flex;
}

@media (max-width: 679px) {
  .table-result .table-result__row, .table-result .table-result__legend-item {
    gap: var(--spacing-6);
    padding: var(--spacing-8);
  }

  .table-result .table-result__column--team-name {
    gap: var(--spacing-6);
  }

  .table-result .table-result__column--hide-mobile {
    display: none;
  }
}

.table-result--artobject {
  max-width: 540px;
}

.table-scorers__title {
  font: var(--font-desktop-h3);
  margin-bottom: var(--spacing-8);
}

.table-scorers__data-table {
  flex-direction: column;
  display: flex;
}

.table-scorers__row {
  padding: var(--spacing-8) var(--spacing-16);
  border-bottom: 1px solid var(--color-border-result-cells-default);
  align-items: center;
  gap: var(--spacing-8);
  width: 100%;
  display: flex;
}

@media (max-width: 679px) {
  .table-scorers__row {
    gap: var(--spacing-6);
    padding: var(--spacing-8);
  }
}

.table-scorers__row--header {
  background: var(--color-surface-result-cells-subheader);
}

.table-scorers__column--rank, .table-scorers__column--player, .table-scorers__column--team, .table-scorers__column--goals {
  align-items: center;
  display: flex;
}

.table-scorers__column--rank {
  width: var(--spacing-20);
  justify-content: center;
}

.table-scorers__column--team {
  gap: var(--spacing-12);
}

@media (max-width: 679px) {
  .table-scorers__column--team {
    gap: var(--spacing-6);
  }
}

.table-scorers__column--team-logo {
  flex-shrink: 0;
}

.table-scorers__column--goals {
  width: var(--spacing-30);
  flex-shrink: 0;
  justify-content: flex-end;
}

.table-scorers__team-logo {
  flex-shrink: 0;
}

.table-scorers--football .table-scorers__column--player {
  width: calc(50% - var(--spacing-25));
}

.table-scorers--football .table-scorers__column--team {
  width: calc(50% - var(--spacing-25));
  flex-shrink: 0;
}

.table-scorers--hockey .table-scorers__column--goals, .table-scorers--hockey .table-scorers__column--assistance, .table-scorers--hockey .table-scorers__column--canadian-scoring, .table-scorers--hockey .table-scorers__column--games, .table-scorers--hockey .table-scorers__column--participation, .table-scorers--hockey .table-scorers__column--penalty-minutes, .table-scorers--hockey .table-scorers__column--shots {
  width: var(--spacing-25);
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-scorers--hockey .table-scorers__column--canadian-scoring {
  justify-content: flex-end;
}

.table-scorers--hockey .table-scorers__column--player {
  width: calc(50% - var(--spacing-98));
}

.table-scorers--hockey .table-scorers__column--team {
  width: calc(50% - var(--spacing-98));
  flex-shrink: 0;
}

.table-scorers--small .table-scorers__column--team {
  gap: var(--spacing-6);
}

.table-scorers--small.table-scorers--hockey .table-scorers__column--games, .table-scorers--small.table-scorers--hockey .table-scorers__column--participation, .table-scorers--small.table-scorers--hockey .table-scorers__column--penalty-minutes, .table-scorers--small.table-scorers--hockey .table-scorers__column--shots {
  display: none;
}

.table-scorers--small.table-scorers--hockey .table-scorers__column--player, .table-scorers--small.table-scorers--hockey .table-scorers__column--team {
  width: calc(50% - var(--spacing-42));
}

@media (max-width: 679px) {
  .table-scorers--hockey .table-scorers__column--games, .table-scorers--hockey .table-scorers__column--participation, .table-scorers--hockey .table-scorers__column--penalty-minutes, .table-scorers--hockey .table-scorers__column--shots {
    display: none;
  }

  .table-scorers--hockey .table-scorers__column--player, .table-scorers--hockey .table-scorers__column--team {
    width: calc(50% - var(--spacing-42));
  }
}

.tipsport-box {
  border-radius: var(--spacing-8);
  overflow: hidden;
}

.tipsport-box__header {
  background: var(--color-surface-ticketarena-header);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16);
  padding: var(--spacing-4) var(--spacing-16);
  min-height: var(--spacing-40);
  flex-wrap: wrap;
  display: flex;
}

.tipsport-box__title {
  color: var(--color-text-button-betting-primary-inverted-text-default);
  font-size: var(--typography-desktop-button-md-font-size);
  line-height: var(--typography-desktop-button-md-line-height);
}

.tipsport-box__content {
  background: var(--color-surface-ticketarena-background);
  gap: var(--spacing-8) var(--spacing-16);
  padding: var(--spacing-16) var(--spacing-16) var(--spacing-8) var(--spacing-16);
  text-align: center;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  position: relative;
}

.tipsport-box--no-padding .tipsport-box__content {
  padding: 0 0 var(--spacing-8) 0;
}

.tipsport-box--white-bg .tipsport-box__content {
  background: var(--color-surface-tipsport-table-background);
}

@media (max-width: 679px) {
  .tipsport-box--gradients .tipsport-box__content:after, .tipsport-box--gradients .tipsport-box__content:before {
    content: "";
    width: var(--spacing-16);
    z-index: 1;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .tipsport-box--gradients .tipsport-box__content:before {
    background: linear-gradient(270deg, transparent 0%, var(--color-surface-ticketarena-background) 100%);
    left: 0;
  }

  .tipsport-box--gradients .tipsport-box__content:after {
    background: linear-gradient(90deg, transparent 0%, var(--color-surface-ticketarena-background) 100%);
    right: 0;
  }

  .tipsport-box--white-bg.tipsport-box--gradients .tipsport-box__content:before {
    background: linear-gradient(270deg, transparent 0%, var(--color-surface-tipsport-table-background) 100%);
  }

  .tipsport-box--white-bg.tipsport-box--gradients .tipsport-box__content:after {
    background: linear-gradient(90deg, transparent 0%, var(--color-surface-tipsport-table-background) 100%);
  }
}

.tipsport-box--no-padding .tipsport-box__footer {
  padding: 0 var(--spacing-16);
}

.tipsport-box--no-padding.tipsport-box--white-bg .tipsport-box__footer {
  padding: 0;
}

.tipsport-overview {
  background: var(--color-surface-ticketarena-background);
  gap: var(--spacing-16);
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 679px) {
  .tipsport-overview {
    gap: var(--spacing-8);
  }
}

.tipsport-overview:not(:first-of-type) {
  margin-top: var(--spacing-16);
}

.tipsport-overview__bet-statistics {
  justify-content: center;
  align-items: stretch;
  gap: var(--spacing-16);
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.tipsport-overview__bet-statistic {
  background: var(--color-surface-card-percent-card-default);
  gap: var(--spacing-4);
  padding: var(--spacing-16);
  color: inherit;
  flex-direction: column;
  flex: 0 1 0;
  min-width: 120px;
  text-decoration: none;
  display: flex;
}

.tipsport-overview__bet-statistic > .typography:hover {
  text-decoration: none;
}

@media (max-width: 679px) {
  .tipsport-overview__bet-statistic {
    flex: 1 0 auto;
    min-width: 80px;
    max-width: 120px;
  }
}

@media (max-width: 679px) {
  .tipsport-overview__title {
    font-size: var(--typography-mobile-body-lg-font-size);
  }
}

.tipsport-overview__percentual {
  margin-top: auto;
}

.tipsport-overview__indicator {
  border-radius: var(--spacing-4);
  background: var(--color-surface-card-percent-progressbar-empty);
  height: var(--spacing-4);
  margin-top: var(--spacing-8);
  overflow: hidden;
}

.tipsport-overview__indicator-value {
  text-indent: -999px;
  background: var(--color-surface-card-percent-progressbar-fill);
  height: var(--spacing-4);
  width: 0;
  display: block;
  overflow: hidden;
}

.tipsport-overview__tickets {
  justify-content: center;
  gap: var(--spacing-16);
  text-align: center;
  width: calc(100% + 2 * var(--spacing-16));
  margin: 0 calc(-1 * var(--spacing-16));
  flex-flow: column;
  flex: 1;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.tipsport-overview__ticket {
  border-radius: var(--spacing-8);
  background: var(--color-surface-card-ticket-background);
  padding: var(--spacing-16) var(--spacing-12);
  align-items: stretch;
  gap: var(--spacing-22);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1080px), (min-width: 680px) and (max-width: 1079px) {
  .tipsport-overview__ticket {
    min-width: 182px;
    max-width: 230px;
  }
}

@media (max-width: 679px) {
  .tipsport-overview__ticket {
    width: calc(45.5vw - var(--spacing-16));
  }
}

@media (max-width: 460px) {
  .tipsport-overview__ticket {
    width: calc(75vw - var(--spacing-16));
  }
}

.tipsport-overview__ticket-params {
  align-items: flex-start;
  gap: var(--spacing-4);
  text-align: left;
  flex-flow: wrap;
  align-self: stretch;
  display: flex;
}

.tipsport-overview__ticket-title, .tipsport-overview__ticket-value {
  flex: 1 0 calc(50% - 2 * var(--spacing-4));
}

@media (max-width: 679px) {
  .tipsport-overview__ticket-title {
    flex: 1 0 calc(30% - 2 * var(--spacing-4));
  }
}

@media (max-width: 679px) {
  .tipsport-overview__ticket-value {
    flex: 1 0 calc(70% - 2 * var(--spacing-4));
  }
}

.tipsport-overview__ticket-author {
  padding: var(--spacing-4);
  align-items: flex-start;
  gap: var(--spacing-4);
  border-radius: var(--spacing-4);
  background: var(--color-surface-card-ticket-card);
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.tipsport-overview__ticket-user {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-4);
  flex-direction: row;
  max-width: 100%;
  display: flex;
}

.tipsport-overview__ticket-user-avatar {
  flex-shrink: 0;
}

.tipsport-overview__ticket-user-name {
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}

.tipsport-overview__ticket-user-inspirations {
  color: var(--color-text-card-ticket-description);
  padding-left: var(--spacing-20);
}

.tipsport-disclaimer {
  align-items: center;
  gap: var(--spacing-8);
  color: var(--color-text-ticketarena-description);
  text-align: left;
  flex-wrap: nowrap;
  justify-content: flex-start;
  display: flex;
}

.tipsport-disclaimer .typography.typography--helper-text {
  font-size: var(--spacing-10);
}

.team-list {
  grid-gap: var(--spacing-16);
  grid-column: lend / rend;
  grid-template-columns: repeat(auto-fill, 134px);
  justify-content: center;
  display: grid;
}

.team-list__card {
  padding: var(--spacing-16);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  border-radius: var(--spacing-8);
  background: var(--color-surface-team-card-default);
  box-shadow: var(--shadow-m);
  color: var(--color-text-team-card-body);
  text-align: center;
  flex-direction: column;
  width: 134px;
  height: 120px;
  text-decoration: none;
  transition: box-shadow .3s ease-in-out;
  display: inline-flex;
}

.team-list__card:hover {
  background: var(--color-surface-team-card-hover);
  box-shadow: var(--shadow-xl);
  text-decoration: none;
}

.team-list__card--active {
  position: relative;
}

.team-list__card--active:before {
  content: "";
  border-radius: var(--spacing-8);
  background: var(--gradient-2);
  padding: 2px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#000 0 0), linear-gradient(#000 0 0);
  mask-image: linear-gradient(#000 0 0), linear-gradient(#000 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-origin: content-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.team-list__card-logo {
  flex-shrink: 0;
}

.tabs-sportmod-title {
  margin-bottom: var(--spacing-6);
  align-items: center;
  gap: 16px;
  display: flex;
}

@media (max-width: 679px) {
  .tabs-sportmod-title {
    margin-bottom: var(--spacing-8);
  }
}

.day-result-datepicker {
  background: var(--color-surface-default-homepage);
  margin: 0 calc(-1 * var(--spacing-16));
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (max-width: 679px) {
  .day-result-datepicker {
    padding: var(--spacing-16) var(--spacing-16) 0;
  }
}

.day-result-datepicker__selected-day {
  cursor: pointer;
  padding: var(--spacing-10) var(--spacing-12);
  border-radius: var(--spacing-input-radius);
  border: 1px solid var(--color-border-input-normal-default);
  background: var(--color-surface-input-normal-default);
  color: var(--color-text-input-inverted-value-default);
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  display: flex;
}

@media (max-width: 679px) {
  .day-result-datepicker__selected-day {
    flex-basis: 100%;
  }
}

.day-result-datepicker__selected-day-item {
  height: var(--spacing-16);
}

.day-result-datepicker__dropdown {
  top: 100%;
  left: var(--spacing-8);
  right: var(--spacing-8);
  z-index: 10;
  border-radius: var(--spacing-4);
  background: var(--color-surface-dropdown-default);
  box-shadow: var(--shadow-m);
  flex-direction: column;
  align-items: center;
  display: none;
  position: absolute;
}

.day-result-datepicker__dropdown--active {
  display: flex;
}

.day-result-datepicker__dropdown-item {
  padding: var(--spacing-8) var(--spacing-16);
  width: 100%;
}

.day-result-datepicker__dropdown-item:hover {
  background-color: var(--color-surface-dropdown-hover);
}

.day-result-datepicker__dropdown-item:focus {
  border: var(--spacing-2) solid var(--color-border-dropdown-focus);
  background: var(--color-surface-dropdown-focus);
}

.day-result-datepicker__dropdown-item--active {
  background-color: var(--color-surface-dropdown-active);
}

.day-result-datepicker__date-button {
  color: var(--color-text-dropdown-default);
  cursor: pointer;
  white-space: nowrap;
  background-color: #0000;
  border: none;
  outline: none;
  width: 100%;
}

.day-result-header-filter {
  margin: var(--spacing-32) 0;
  grid-template-columns: 210px 1fr;
  width: 100%;
  display: grid;
}

@media (max-width: 679px) {
  .day-result-header-filter {
    margin: var(--spacing-24) 0;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }
}

.day-result-matches__league:not(:has(.match-overview-list:not([style*="display: none"]))) {
  display: none;
}

.day-result-matches__sport {
  display: none;
}

.day-result-matches__sport:has(.match-overview-list:not([style*="display: none"])) {
  display: block;
}

.sportmod-tabs-menu-subleagues {
  gap: var(--spacing-8);
  margin-bottom: var(--spacing-16);
  flex-flow: column;
  display: flex;
}

.sportmod-tabs-menu-subleagues__chip-active {
  border-color: var(--color-border-chip-secondary-outlined-active);
  background-color: var(--color-surface-chip-secondary-outlined-active);
}

.sportmod-tabs-menu-subleagues__chip-active .chip__label, .sportmod-tabs-menu-subleagues__chip-active .chip__label:hover, .sportmod-tabs-menu-subleagues__chip-active.chip--outlined:hover .chip__label {
  color: var(--color-text-chip-secondary-outlined-active);
}

.sportmod-tabs-menu-subleagues-spinner {
  margin: var(--spacing-10);
}

.seo-box {
  gap: var(--spacing-16);
  padding: var(--spacing-24);
  border-top: 1px solid var(--other-common-black);
  flex-flow: column;
  display: flex;
}

.seo-box p {
  font: var(--font-desktop-body1);
}

.seo-box a:not(.seo-box__tag, .seo-box__title) {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
}

.seo-box a:not(.seo-box__tag, .seo-box__title):hover {
  text-decoration: none;
}

.seo-box h3 {
  font: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
}

.seo-box h4 {
  font: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-default);
}

.seo-box__tag {
  margin-right: var(--spacing-8);
  margin-bottom: var(--spacing-8);
  display: inline-flex;
}

.seo-box .seo-box__tag a {
  color: var(--color-text-secondary);
}

.seo-box__link {
  position: relative;
}

.seo-box__link + .seo-box__link {
  margin-left: var(--spacing-20);
}

.seo-box__link + .seo-box__link:before {
  height: var(--spacing-28);
  left: calc(-1 * var(--spacing-12));
  content: "•";
  color: var(--color-text-default-meta);
  position: absolute;
}

.seo-box__title {
  font: var(--font-desktop-h4);
  color: var(--color-text-primary);
}

.seo-box__body {
  gap: var(--spacing-16);
  flex-flow: column;
  display: flex;
}

.seo-box__team-link {
  gap: var(--spacing-8);
  align-items: center;
  margin-bottom: 14px;
  margin-right: 14px;
  display: inline-flex;
}

@media (max-width: 679px) {
  .seo-box__team-link {
    width: 100%;
  }
}

